import React from 'react';

import currencies from 'assets/currencies';
import axios from 'axios';
import Select from 'common/pickers/Select.component';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useLocale } from 'context/LanguageContext';
import { useUserCredentialsQuery } from 'queries/userCredentials';
import { Item } from 'react-stately';
import styles from './ConversionCurrencyPicker.module.css';

const ConversionCurrencyPicker = ({ currency }) => {
  const { LL } = useLocale();
  const { refetch } = useUserCredentialsQuery();
  const { conversionCurrency, setConversionCurrency } =
    useCurrencyAndThemeContext();

  const COINS = [
    { coin: 'usd' },
    { coin: 'eur' },
    { coin: 'gel' },
    { coin: 'rub' },
    { coin: 'btc' },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.currencyContainer}>
        <div>Functional Currency</div>
        <Select
          items={COINS}
          selectedKey={conversionCurrency}
          onSelectionChange={async (key) => {
            const update = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URI}users/functional-currency`,
              data: { currency: key },
              headers: {
                Authorization: localStorage.getItem('token'),
              },
            });
            if (update.data.success) setConversionCurrency(key);
          }}
        >
          {COINS.map((el) => (
            <Item
              key={el.coin}
              textValue={LL.currency[el.coin]()}
            >
              {LL.currency[el.coin]()}
              {currencies[el.coin] ? (
                <img
                  src={currencies[el.coin]}
                  alt=""
                  data-slot="icon"
                />
              ) : (
                <React.Fragment data-slot="icon" />
              )}
            </Item>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default ConversionCurrencyPicker;

{
  /* <div
      className={styles.currencyContainer}
      onClick={() => {
        setConversionCurrency('usd');
      }}
    >
      <span>
        <BsCurrencyDollar />
      </span>
      <span
        className={styles.info}
        style={conversionCurrency === 'usd' ? { fontWeight: '1000' } : {}}
      >
        USD
      </span>
    </div>
    <div
      className={styles.currencyContainer}
      onClick={() => {
        setConversionCurrency('eur');
      }}
    >
      <span>
        <MdEuroSymbol />
      </span>
      <span
        className={styles.info}
        style={conversionCurrency === 'eur' ? { fontWeight: '1000' } : {}}
      >
        Euro
      </span>
    </div> */
}
