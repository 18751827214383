// @ts-check
import CurrencyShowcase from 'components/content/CurrencyShowcase.component';
import BufferingWheel from 'components/loader/LoadingAnimation.component';
import { useLocale } from 'context/LanguageContext';
import { useBalancesQuery } from 'queries/balances';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import styles from './Markets.module.css';

export default function Markets() {
  const { LL } = useLocale();
  const { data: markets, isError, isLoading } = useBalancesQuery();

  const [selectedTab, setSelectedTab] = React.useState('Crypto');

  if (isError) {
    return (
      <div style={{ color: 'var(--md-ref-palette-error-50)' }}>
        {LL.markets.accessingError({ pageHeader: LL.general.markets() })}
      </div>
    );
  }

  if (isLoading) {
    return <BufferingWheel color="var(--color-logo)" />;
  }

  return (
    <div className={styles.container}>
      <Level>
        <span className={styles.pageHeader}>
          <H className={styles.pageHeadline}>{LL.navbar.markets()}</H>
          <div
            className={
              selectedTab === 'Crypto' ? styles.pageTabColored : styles.pageTab
            }
            onClick={() => setSelectedTab('Crypto')}
          >
            {LL.general.crypto()}
          </div>
          <div
            className={
              selectedTab === 'Fiat' ? styles.pageTabColored : styles.pageTab
            }
            onClick={() => setSelectedTab('Fiat')}
          >
            {LL.general.fiat()}
          </div>
        </span>
        <div>
          {selectedTab === 'Crypto' ? (
            <div className={styles.markets}>
              {markets.map(
                (market) =>
                  market.coin !== 'usd' &&
                  market.coin !== 'rub' &&
                  market.coin !== 'gel' &&
                  market.coin !== 'eur' && (
                    <CurrencyShowcase
                      key={market.id}
                      size="lg"
                      currency={market.coin}
                    />
                  )
              )}
            </div>
          ) : (
            <div className={styles.markets}>
              {markets.map(
                (market) =>
                  (market.coin === 'usd' ||
                    market.coin === 'rub' ||
                    market.coin === 'gel' ||
                    market.coin === 'eur') && (
                    <CurrencyShowcase
                      key={market.id}
                      size="lg"
                      currency={market.coin}
                    />
                  )
              )}
            </div>
          )}
        </div>
      </Level>
    </div>
  );
}
