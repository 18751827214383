import * as React from 'react';

/**
 *
 * @param {object} props
 * @param {boolean} [props.isDisabled]
 * @param {boolean} [props.attachesBackground]
 * @param {boolean} [props.pressesBackground]
 * @param {number} [props.attachRadius]
 * @param {string} [props.attachHighlight] rgba
 * @param {number} [props.attachWidth]
 * @param {number} [props.pressRadius] if undefined attachRadius is used
 * @param {string} [props.pressHighlight] if undefined attachHighlight is used
 * @param {number} [props.pressWidth] if undefined attachWidth is used
 */
function useBackgroundInteractions({
  attachesBackground,
  pressesBackground,
  attachRadius,
  attachHighlight,
  attachWidth,
  pressRadius,
  pressHighlight,
  pressWidth,
  isDisabled,
}) {
  // TODO: detach & press cancel when attachesBackground or pressesBackground changes
  return React.useMemo(
    () => ({
      onPressChange: (isPressed) => {
        if (!isPressed) {
          document.dispatchEvent(new CustomEvent('backgroundPressCancel'));
        }
      },
      onPressStart: (
        /** @type {import('@react-types/shared').PressEvent} */ e
      ) => {
        if (!pressesBackground || isDisabled) return;
        const rect = e.target.getBoundingClientRect();
        const scrollTop = document.documentElement.scrollTop;
        document.dispatchEvent(
          new CustomEvent('backgroundPressStart', {
            detail: {
              x: rect.left + rect.width / 2,
              y: rect.top + rect.height / 2 + scrollTop,
              highlightRGBA: pressHighlight || attachHighlight,
              impactRadius: pressRadius || attachRadius,
              higlightWidth: pressWidth || attachWidth,
            },
          })
        );
      },
      onPress: () => {
        document.dispatchEvent(new CustomEvent('backgroundPressEnd'));
      },
      onHoverStart: (e) => {
        if (!attachesBackground || isDisabled) return;
        const rect = e.target.getBoundingClientRect();
        const scrollTop = document.documentElement.scrollTop;
        document.dispatchEvent(
          new CustomEvent('attachBackgroundPoint', {
            detail: {
              x: rect.left + rect.width / 2,
              y: rect.top + rect.height / 2 + scrollTop,
              highlightRGBA: attachHighlight,
              impactRadius: attachRadius,
              higlightWidth: attachWidth,
            },
          })
        );
      },
      onHoverEnd: (e) => {
        document.dispatchEvent(new CustomEvent('detachBackgroundPoint'));
      },
    }),
    [
      isDisabled,
      attachHighlight,
      attachRadius,
      attachWidth,
      attachesBackground,
      pressHighlight,
      pressRadius,
      pressWidth,
      pressesBackground,
    ]
  );
}

export default useBackgroundInteractions;
