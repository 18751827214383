import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './two-factor.styles.css';

import FilledButton from 'common/buttons/FilledButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { InlineError } from 'components/content/InlineError';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import reactStringReplace from 'react-string-replace';
import {
  tfaDisableStart,
  tfaGenerateStart,
  tfaNewDeviceStart,
  tfaVerifyStart,
} from '../../../redux/user/user.actions';
import {
  selectTfa,
  selectTfaEnabled,
  selectTfaNewDevice,
} from '../../../redux/user/user.selectors';

const TwoFactorPage = ({
  tfa,
  tfaGenerateStart,
  tfaVerifyStart,
  tfaDisableStart,
  tfaNewDeviceStart,
  tfaEnabled,
  tfaNewDevice,
  user,
}) => {
  const [verifyToken, setVerifyToken] = useState('');
  const [disableToken, setDisableToken] = useState('');
  const [newDeviceToken, setNewDeviceToken] = useState('');
  const [verifyError, setVerifyError] = useState(false);

  const { LL } = useLocale();

  const { colorTest4 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };

  useEffect(() => {
    !tfaEnabled && tfaGenerateStart();
  }, [tfaEnabled, tfaGenerateStart]);

  const onSubmitEnable = (e) => {
    e.preventDefault();
    setVerifyToken('');
    tfaVerifyStart(verifyToken);
    if (!tfaEnabled) {
      setTimeout(() => setVerifyError(true), 500);
      setTimeout(() => setVerifyError(false), 5000);
    }
  };

  const onSubmitDisable = (e) => {
    e.preventDefault();
    setDisableToken('');
    tfaDisableStart(disableToken);
    if (tfaEnabled) {
      setTimeout(() => setVerifyError(true), 500);
      setTimeout(() => setVerifyError(false), 5000);
    }
  };

  const onSubmitNewDevice = (e) => {
    e.preventDefault();
    tfaNewDeviceStart(newDeviceToken);
  };

  return (
    <Surface
      className="tfa"
      surfaceColor={surfaceColor}
    >
      <div className="tfa-enable">
        <div className="tfa__info">
          <h1>{LL.security['2fa'].registrationHeader()}</h1>
          <p>{LL.security['2fa'].registrationDescription()}</p>
        </div>

        <div className="tfa__main">
          {tfaEnabled ? (
            <p>{LL.security['2fa'].guideActivated()}</p>
          ) : (
            <p>
              {reactStringReplace(
                LL.security['2fa'].guideUnactivated(),
                /<(.*?)>/g, // match anything within <>
                (match, i) => {
                  if (i === 1)
                    return <a href="https://freeotp.github.io/">{match}</a>;
                  if (i === 3) {
                    return (
                      <a href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid">
                        {match}
                      </a>
                    );
                  }
                }
              )}
            </p>
          )}

          {!tfaEnabled && (
            <div className="enable__img">
              <img
                src={tfa}
                alt=""
              />
            </div>
          )}

          {tfaEnabled ? (
            <form
              className="disable__form"
              onSubmit={(e) => onSubmitDisable(e)}
            >
              <label htmlFor="piCode">{LL.security.pin()}</label>
              {verifyError && (
                <InlineError
                  children={'Invalid Token'}
                  onClose={() => setVerifyError(false)}
                  success={false}
                ></InlineError>
              )}
              <TextField
                type="text"
                value={disableToken}
                onChange={(e) => setDisableToken(e)}
              />
              {/* TODO: use buttons from our design system instead */}
              <FilledButton
                type="submit"
                onPress={onSubmitDisable}
              >
                {LL.security['2fa'].disable()}
              </FilledButton>
            </form>
          ) : (
            <form
              className="enable__form"
              onSubmit={(e) => onSubmitEnable(e)}
            >
              <label htmlFor="piCode">{LL.security.pin()}</label>
              {verifyError && (
                <InlineError
                  children={'Invalid Token'}
                  onClose={() => setVerifyError(false)}
                  success={false}
                ></InlineError>
              )}
              <TextField onChange={(e) => setVerifyToken(e)} />
              <FilledButton
                type="submit"
                onPress={onSubmitEnable}
              >
                {LL.security['2fa'].enable()}
              </FilledButton>
            </form>
          )}
        </div>
      </div>

      <div className="tfa-new-device">
        <div className="tfa__info">
          <h1>{LL.security['2fa'].newAuthenticatorHeader()}</h1>
          <p>{LL.security['2fa'].newAuthenticatorDescription()}</p>
        </div>

        <div className="tfa__main">
          {tfaNewDevice ? (
            <img
              src={tfaNewDevice}
              alt=""
            />
          ) : (
            <>
              {!tfaEnabled && (
                <p style={{ color: 'red' }}>
                  {LL.security['2fa'].newUnactivatedMessage()}
                </p>
              )}

              <form
                className="enable__form"
                onSubmit={(e) => onSubmitNewDevice(e)}
              >
                <label
                  htmlFor="piCode"
                  className="label"
                >
                  {LL.security.pin()}
                </label>
                <TextField onChange={(e) => setNewDeviceToken(e)} />
                <FilledButton
                  type="submit"
                  isDisabled={!tfaEnabled}
                >
                  {LL.security['2fa'].newSetUp()}
                </FilledButton>
              </form>
            </>
          )}
        </div>
      </div>
    </Surface>
  );
};

const mapStateToProps = (state) => ({
  tfa: selectTfa(state),
  tfaNewDevice: selectTfaNewDevice(state),
  tfaEnabled: selectTfaEnabled(state),
  user: state,
});

export default connect(mapStateToProps, {
  tfaGenerateStart,
  tfaVerifyStart,
  tfaDisableStart,
  tfaNewDeviceStart,
})(TwoFactorPage);
