import axios from 'axios';
import qs from 'qs';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SET_ALERT_START } from '../alert/alert.types';
import {
  fetchIpWhitelistSuccess,
  fetchUserAddressFailure,
  fetchUserAddressSuccess,
  fetchUserBalanceSuccess,
  fetchUserCredentialsSuccess,
  onTfaError,
  tfaDisable,
  tfaGenerateFailure,
  tfaGenerateSuccess,
  tfaNewDevice,
  tfaVerify,
} from './user.actions';

import { logOut } from '../auth/auth.actions';

import userActionTypes from './user.types';

export function* fetchUserAddressAsync({ payload }) {
  try {
    const res = yield axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}address`,
      data: qs.stringify({
        coin: payload.coin,
        new: payload.new,
        default: payload.default,
        network: payload.network,
      }),
      headers: {
        Authorization: localStorage.getItem('token'),
        'content-type': 'application/x-www-form-urlencoded',
      },
    });

    let address;
    if (payload.currency === 'bch') {
      address = res.data.address.replace('bchtest:', '');
    } else {
      address = res.data.address;
    }
    let destTag = null;

    if (payload.coin === 'xrp' || payload.coin === 'xlm') {
      destTag = res.data.dest_tag;
    }

    yield put(fetchUserAddressSuccess({ address, destTag }));
  } catch (err) {
    yield put(fetchUserAddressFailure(err));
  }
}

export function* fetchUserBalance() {
  try {
    const userBalance = yield axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}balances/v1`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    yield put(fetchUserBalanceSuccess(userBalance.data));
  } catch (err) {
    console.log(err);
  }
}

export function* tfaGenerateAsync() {
  try {
    const res = yield axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}2fa/generate`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    yield put(tfaGenerateSuccess(res.data.qr));
  } catch (error) {
    yield put(tfaGenerateFailure());
  }
}

export function* tfaVerifyAsync({ payload, history }) {
  try {
    yield axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}2fa/verify`,
      data: qs.stringify({
        token: payload,
      }),
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    yield put(tfaVerify());
    window.location.reload();
  } catch (error) {
    if (error.response.data.success === false) {
      const payload = { msg: 'Invalid Token', alertType: 'danger' };
      yield put({ type: SET_ALERT_START, payload });
    }
  }
}

export function* tfaDisableAsycn({ payload, history }) {
  try {
    yield axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}2fa/disable `,
      data: qs.stringify({
        token: payload,
      }),
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    yield put(tfaDisable());
    window.location.reload();
  } catch (error) {
    if (error.response.data.success === false) {
      const payload = { msg: 'Invalid Token', alertType: 'danger' };
      yield put({ type: SET_ALERT_START, payload });
    }
  }
}

export function* tfaNewDeviceAsync({ payload }) {
  try {
    const res = yield axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}2fa/add-device`,
      data: qs.stringify({
        token: payload,
      }),
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    yield put(tfaNewDevice(res.data.qr));
  } catch (error) {
    if (error.response.data.success === false) {
      yield put(onTfaError());
    }
  }
}

export function* FetchUserCredentialsAsync() {
  try {
    const { data } = yield axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}users/profile`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    const userCreds = {};

    if (data.BusinessAccount) {
      userCreds.name = data.BusinessAccount.contact_first_name;
      userCreds.lastName = data.BusinessAccount.contact_last_name;
    } else {
      userCreds.name = data.PersonalAccount.first_name;
      userCreds.lastName = data.PersonalAccount.last_name;
    }

    userCreds.email = data.email;
    userCreds.id = data.id;
    yield put(fetchUserCredentialsSuccess(userCreds));
  } catch (err) {
    if (err.response.status === 401) {
      document.cookie = `creds= ; Expires= Thu, 01 Jan 1970 00:00:00 GMT; Domain=paybitx.com`;
      return yield put(logOut());
    }
  }
}

export function* fetchIpwhitelistAsync() {
  try {
    const res = yield axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}ip-whitelist`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    yield put(fetchIpWhitelistSuccess(res.data));
  } catch (err) {
    console.log(err.response);
  }
}

export function* fetchUserAddressStart() {
  yield takeLatest(
    userActionTypes.FETCH_USERADDRESS_START,
    fetchUserAddressAsync
  );
}

export function* onFetchUserBalance() {
  yield takeLatest(userActionTypes.FETCH_USER_BALANCE_START, fetchUserBalance);
}

export function* onTfaGenerate() {
  yield takeLatest(userActionTypes.TFA_GENERATE_START, tfaGenerateAsync);
}

export function* onTfaVerifyStart() {
  yield takeLatest(userActionTypes.TFA_VERIFY_START, tfaVerifyAsync);
}

export function* onTfaDisableStart() {
  yield takeLatest(userActionTypes.TFA_DISABLE_START, tfaDisableAsycn);
}

export function* onTfaNewDeviceStart() {
  yield takeLatest(userActionTypes.TFA_NEW_DEVICE_START, tfaNewDeviceAsync);
}

export function* FetchUserCredentials() {
  yield takeLatest(
    userActionTypes.FETCH_USER_CREDENTIALS_START,
    FetchUserCredentialsAsync
  );
}

export function* onFetchIpWhitelistStart() {
  yield takeLatest(
    userActionTypes.FETCH_IP_WHITELIST_START,
    fetchIpwhitelistAsync
  );
}

export function* userSagas() {
  yield all([
    call(fetchUserAddressStart),
    call(onFetchUserBalance),
    call(onTfaGenerate),
    call(onTfaVerifyStart),
    call(onTfaDisableStart),
    call(onTfaNewDeviceStart),
    call(FetchUserCredentials),
    call(onFetchIpWhitelistStart),
  ]);
}
