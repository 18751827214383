// @ts-check
import { getLocalTimeZone } from '@internationalized/date';
import FilledButton from 'common/buttons/FilledButton';
import OutlinedButton from 'common/buttons/OutlinedButton';
import TextButton from 'common/buttons/TextButton';
import DatePicker from 'common/calendar/DatePicker.component';
import SearchField from 'common/fields/SearchFieldV2.component';
import { Checkbox, CheckboxGroup } from 'common/forms/Checkbox.component';
import Surface from 'common/surfaces/Surface.component';
import MultiCurrencyPicker from 'components/currency-fields/MultiCurrencyPicker.component';
import TransactionsTable from 'components/transactions/TransactionsTable.component';
import { useLocale } from 'context/LanguageContext';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import { useId } from 'react-aria';
import { RiCloseLine, RiFilter2Line as RiFilter2Fill } from 'react-icons/ri';
import styles from './Transactions.module.css';

/** @typedef {import("components/transactions/TransactionsTable.component").TransactionsTableProps["filters"]} TransactionFilters */

export default function Transactions() {
  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const ref = React.useRef(null);
  const [scrollContainer, setScrollContainer] = React.useState(null);
  React.useEffect(() => {
    // currently [class=scrollable] of DashboardLayout scrolls the page.
    // we need a better way, not sure what to do yet
    setScrollContainer(ref.current?.parentElement?.parentElement);
  }, []);
  const [txIdValidate, setTxIdValidate] = React.useState(false);
  const [addressValidate, setAddressValidate] = React.useState(false);
  const { LL } = useLocale();

  // TODO: add types
  // /** @type {[TransactionFilters["type"], React.Dispatch<TransactionFilters["type"]>]} */
  // const [type, setType] = React.useState(null);
  // /** @type {[TransactionFilters["currencies"], React.Dispatch<TransactionFilters["currencies"]>]} */
  // const [currencies, setCurrencies] = React.useState(null);
  // /** @type {[import('@internationalized/date').DateValue, React.Dispatch<import('@internationalized/date').DateValue>]} */
  // const [from, setFrom] = React.useState(null);
  // /** @type {[import('@internationalized/date').CalendarDate, React.Dispatch<import('@internationalized/date').CalendarDate>]} */
  // const [to, setTo] = React.useState(null);
  // /** @type {[TransactionFilters["txId"], React.Dispatch<TransactionFilters["txId"]>]} */
  // const [txId, setTxId] = React.useState('');
  // /** @type {[TransactionFilters["address"], React.Dispatch<TransactionFilters["address"]>]} */
  // const [address, setAddress] = React.useState('');
  // const [filter, setFilter] = React.useState({});
  const initialState = {
    currencies: [],
    from: null,
    to: null,
    type: null,
    address: '',
    txId: '',
  };
  const [filters, setFilters] = React.useState(initialState);
  const reducer = (
    /** @type {any} */ state,
    /** @type {{ type: any; }} */ action
  ) => {
    switch (action.type) {
      case 'CHANGE_INPUT':
        return {
          ...state,
          // @ts-ignore
          [action.payload.name]: action.payload.value,
        };
      case 'RESET':
        return initialState;
      default:
        return state;
    }
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleChange = (e, fieldName) => {
    // @ts-ignore
    dispatch({
      type: 'CHANGE_INPUT',
      payload: { name: fieldName, value: e },
    });
  };

  const handleReset = () => {
    // @ts-ignore
    dispatch({ type: 'RESET' });
    setFilters(initialState);
  };

  return (
    <Level>
      <Surface
        ref={ref}
        className={styles.container}
      >
        <H className={styles.mainHeading}>{LL.navbar.transactions()}</H>
        <TransactionsTable
          scrollContainer={scrollContainer}
          maxRows={Infinity}
          className={styles.table}
          filters={filters}
        >
          <Expandable filters={filters}>
            <div className={styles.filterContainer}>
              <div className={styles.checkboxGroup}>
                <CheckboxGroup
                  label={LL.transactionsPage.transactionType()}
                  orientation="horizontal"
                  onChange={(value) => {
                    handleChange(value.length ? value.at(-1) : null, 'type');
                  }}
                  value={state.type ? [state.type] : []}
                >
                  <Checkbox value="deposit">
                    {LL.transactionsTable.operationColumn.depositVisibleText()}
                  </Checkbox>
                  <Checkbox value="withdrawal">
                    {LL.transactionsTable.operationColumn.withdrawalVisibleText()}
                  </Checkbox>
                  <Checkbox value="invoice">
                    {LL.transactionsTable.operationColumn.invoiceVisibleText()}
                  </Checkbox>
                  <Checkbox value="exchange">
                    {LL.transactionsTable.operationColumn.swapVisibleText()}
                  </Checkbox>
                </CheckboxGroup>
              </div>

              <div className={styles.picker}>
                <MultiCurrencyPicker
                  label={LL.transactionsPage.pickCurrencies()}
                  defaultText={LL.transactionsPage.pickCurrencies()}
                  maxCoinDisplay={5}
                  onSelectionChange={(value) => {
                    if (value === 'all' || value.size === 0) {
                      handleChange(value, 'currencies');
                    } else {
                      handleChange([...value], 'currencies');
                    }
                  }}
                  resetState={state.currencies}
                />
              </div>

              <div className={styles.fromField}>
                <DatePicker
                  label={LL.transactionsPage.from()}
                  onChange={(value) => {
                    handleChange(value.toDate(getLocalTimeZone()), 'from');
                  }}
                />
              </div>

              <div className={styles.toField}>
                <DatePicker
                  label={LL.transactionsPage.to()}
                  onChange={(value) =>
                    handleChange(
                      value.add({ days: 1 }).toDate(getLocalTimeZone()),
                      'to'
                    )
                  }
                />
              </div>
              <div className={styles.txIdField}>
                <SearchField
                  label={LL.transactionsPage.transactionId()}
                  onChange={(value) =>
                    !txIdValidate && handleChange(value, 'txId')
                  }
                  onFocus={() =>
                    !state.currencies.length && setTxIdValidate(true)
                  }
                  onBlur={() => setTxIdValidate(false)}
                  validationState={txIdValidate ? 'invalid' : null}
                  errorMessage={txIdValidate && LL.transactionsPage.warning()}
                  value={state.txId}
                />
              </div>
              <div className={styles.addressField}>
                <SearchField
                  label={LL.transactionsPage.addressId()}
                  onChange={(value) =>
                    !addressValidate && handleChange(value, 'address')
                  }
                  onFocus={() =>
                    !state.currencies.length && setAddressValidate(true)
                  }
                  onBlur={() => setAddressValidate(false)}
                  validationState={addressValidate ? 'invalid' : null}
                  errorMessage={
                    addressValidate && LL.transactionsPage.warning()
                  }
                  value={state.address}
                />
              </div>
              <div className={styles.filterButtons}>
                <OutlinedButton
                  className={styles.resetButton}
                  onPress={handleReset}
                >
                  {LL.transactionsPage.reset()}
                </OutlinedButton>
                <FilledButton
                  className={styles.submitButton}
                  onPress={() => setFilters(state)}
                >
                  {LL.transactionsPage.applyFilter()}
                </FilledButton>
              </div>
            </div>
          </Expandable>
        </TransactionsTable>
      </Surface>
    </Level>
  );
}

function Expandable({ filters, children }) {
  const { LL } = useLocale();

  const contentId = useId();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [applyFilter, setApplyFilter] = React.useState(false);
  console.log(filters);
  /** @param {import('@react-types/shared').PressEvent} e */
  const handleDownload = (e) => {
    fetch(`${process.env.REACT_APP_API_URI}operations/csv`, {
      method: 'post',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filters),
    })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', 'transactions.csv');
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('href', href);
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  };

  return (
    <div>
      <div className={styles.filterButton}>
        <TextButton
          aria-expanded={isExpanded}
          aria-controls={contentId}
          left={isExpanded ? <RiCloseLine /> : <RiFilter2Fill />}
          onPress={() => setIsExpanded(!isExpanded)}
        >
          {LL.transactionsPage.filterButton()}
        </TextButton>

        <TextButton onPress={handleDownload}>
          {LL.transactionsPage.downloadCsvButton()}
        </TextButton>
      </div>
      <div id={contentId}>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              variants={{
                expanded: { height: 'auto', opacity: 1 },
                collapsed: {
                  height: 0,
                  opacity: 0,
                  transition: { height: { delay: 0.2 } },
                },
              }}
              initial={'collapsed'}
              animate={'expanded'}
              exit={'collapsed'}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
