import * as alertTypes from './alert.types';

const INIT_STATE = null;

const alertReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case alertTypes.SET_ALERT:
      return action.payload;
    case alertTypes.REMOVE_ALERT:
      return null;
    default:
      return state;
  }
};

export default alertReducer;
