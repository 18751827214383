import CurrencyNames from 'assets/data/currency-names';
import LandingShowcase from 'components/content/LandingShowcase.component';
import Footer from 'components/footer/footer.component';
import { ExplorerPovider, useExplorerContext } from 'context/ExplorerContext';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import { Navigation } from 'layouts/DashboardLayout.layout';
import * as React from 'react';
import { Level } from 'react-accessible-headings';
import { useLocation } from 'react-router-dom';
import { hexToRGBA } from 'utils/colorUtils';
import ExchangeExplorer from './ExchangeExplorer.component';
import Explorer from './Explorer.component';
import ExplorerData from './ExplorerData.component';
import styles from './ExplorerPage.module.css';

export default function ExplorerPage(props) {
  const { isDarkTheme, colorOnSuccess, MdRefPaletteTertiary95 } =
    useThemeVariables();
  const [navLogo, setNavLogo] = React.useState(0);
  const refScrollable = React.useRef(null);
  const isMobileScreen = useExplorerContext();
  const location = useLocation();
  const { LL } = useLocale();

  return (
    <sentient-background
      class={styles.sentientBackground}
      // highlightRgba={hexToRGBA(highlightRGBA)}
      highlightRgba={hexToRGBA(
        isDarkTheme ? colorOnSuccess : MdRefPaletteTertiary95
      )}
      itemRgba={hexToRGBA(
        isDarkTheme ? colorOnSuccess : MdRefPaletteTertiary95
      )}
      cellsize={24}
      highlightradius={200}
      highlightwidth={12}
      itemthickness={2}
    >
      <div className={styles.container}>
        <Navigation navLogo={navLogo} />
        <ExplorerPovider>
          {isMobileScreen && <Explorer />}
          <div
            ref={refScrollable}
            onScroll={() =>
              refScrollable.current?.scrollTop === 0
                ? setNavLogo(1)
                : setNavLogo(2)
            }
            className={styles.scrollable}
          >
            <div className={styles.pageBody}>
              {!isMobileScreen && (
                <main className={styles.main}>
                  <Explorer />
                </main>
              )}
              <ExplorerData />
              {location.pathname.startsWith('/explorer/exchange') && (
                <ExchangeExplorer />
              )}
              <div className={styles.marketsContainer}>
                <Level>
                  <h1 className={styles.marketsHeadline}>
                    {LL.explorer.exchangeRates()}
                  </h1>
                  <div className={styles.markets}>
                    {CurrencyNames.map((currency, index) => (
                      <LandingShowcase
                        key={index}
                        size="lg"
                        currency={currency.symbol}
                        // currencyName={currency.coin_name}
                      />
                    ))}
                  </div>
                  <Footer />
                </Level>
              </div>
            </div>
          </div>
        </ExplorerPovider>
      </div>
    </sentient-background>
  );
}
