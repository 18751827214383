import ComboBoxBase from 'common/pickers/ComboboxBase.component';
import React from 'react';
import styles from './ComboBox.module.css';

/**
 * @template {{}} T
 * @param {Omit<import('common/pickers/ComboboxBase.component').ComboboxProps<T>, "classNames">} props
 **/
export default function ComboBox(props) {
  return (
    <ComboBoxBase
      {...props}
      classNames={styles}
    />
  );
}
