//@ts-check
import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import styles from './sign-up.module.css';

import { useFormik } from 'formik';
import * as Yup from 'yup';

// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import FilledButton from 'common/buttons/FilledButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import TextField from 'common/fields/TextField';
import { Checkbox } from 'common/forms/Checkbox.component';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import LocationPicker from 'components/location-fields/LocationPicker.component';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import { useCityQuery } from 'queries/cities';
import { useCountriesQuery } from 'queries/countries';
import { usePhoneCodesQuery } from 'queries/phoneCodes';
import { H } from 'react-accessible-headings';

// Initialize an agent at application startup.
// const fpPromise = FingerprintJS.load({
//   apiKey: 'USfRL9GRaAdf7Cir2Dq2',
//   region: 'eu',
// });

// Get the visitor identifier when you need it.

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

function useFormError(errorMap, resetDeps = []) {
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    formError: errorMap[errorKey],
    setFormError: setErrorKey,
  };
}

const SignUp = () => {
  const { colorTest4 } = useThemeVariables();
  const surfaceColor = { background: colorTest4 };

  const [phoneCodeArray, setPhoneCodeArray] = useState(null);
  const [showPhoneVerificationForm, setShowPhoneVerificationForm] =
    useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [pin, setPin] = useState(null);
  const [defaultCountry, setDefaultCountry] = useState(null);
  const [displayPhoneNumber, setDisplayPhoneNumber] = useState(null);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userCity = userTimeZone.substring(userTimeZone.lastIndexOf('/') + 1);

  const { LL } = useLocale();

  // useEffect(() => {
  //   fpPromise
  //     .then((fp) => fp.get())
  //     .then((result) => console.log(result.visitorId));
  // }, []);

  async function generatePin(email) {
    const data = { email: email };
    try {
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}users/pin/generate`,
        data: qs.stringify(data),
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  const navigate = useNavigate();

  const { data: countries } = useCountriesQuery();

  const { data: city } = useCityQuery(userCity, { enabled: !!countries });

  useEffect(() => {
    if (city && countries.find((e) => e.id === city.country_id) !== undefined) {
      setDefaultCountry(
        String(countries.find((e) => e.id === city.country_id).id)
      );
    } else {
      setDefaultCountry('');
    }
  }, [city, countries]);

  const { data: phoneCodes } = usePhoneCodesQuery({
    onSuccess: () => {
      setPhoneCodeArray(phoneCodes.map((el) => el.phone_code));
    },
  });

  let validSchema = {};

  // phoneCodes.map((a) => phoneCodeArray.push(a.phone_code));
  validSchema = !showPhoneVerificationForm
    ? {
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required').min(6),
        emailConfirmation: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('email')], 'Email must be the same'),
        password: Yup.string()
          .min(6, 'must be at least 6 characters')
          .required('Required'),
        passwordConfirmation: Yup.string()
          .min(6, 'must be at least 6 characters')
          .required('Required')
          .oneOf([Yup.ref('password')], 'Password must be the same'),
        countryId: Yup.string().required('Required'),
        phoneCodeId: Yup.string()
          .test({
            message: LL.exchange.serverErrors.incorrectPhoneCode(),
            test: (value, { parent }) =>
              phoneCodes.find((val) => val.phone_code === value),
          })
          .required('Required'),
        phoneNumber: Yup.number()
          .typeError('Cannot contain anything other than numbers')
          .required('Required'),
        ageCheck: Yup.boolean().oneOf([true], 'Field must be filled'),
      }
    : {
        pin: Yup.string()
          .length(6, 'must contain exactly 6 digits')
          .required('Required'),
      };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      countryId: defaultCountry,
      ageCheck: false,
      contactFirstName: '',
      contactLastName: '',
      businessName: '',
      businessWebsite: '',
      businessRegistrationNumber: '',
      phoneCodeId: '',
      phoneNumber: '',
      pin: '',
    },
    // @ts-ignore
    validationSchema: Yup.object(validSchema),
    enableReinitialize: true,
    onSubmit: async (values) => {
      let formData = {};

      !showPhoneVerificationForm
        ? (formData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            emailConfirmation: values.emailConfirmation,
            password: values.password,
            passwordConfirmation: values.passwordConfirmation,
            countryId: defaultCountry,
            phoneCodeId: phoneCodes.find(
              (val) => val.phone_code === values.phoneCodeId
            ).id,
            phoneNumber: values.phoneNumber.toString().replace(/\s/g, ''),
            ageCheck: values.ageCheck,
          })
        : (formData = {
            email: userEmail,
            pin: values.pin.replace(/\s/g, ''),
          });

      try {
        if (!showPhoneVerificationForm) {
          const res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URI}users/personal`,
            data: qs.stringify(formData),
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
          });

          setUserEmail(formData.email);
          setShowPhoneVerificationForm(true);
          setDisplayPhoneNumber(
            values.phoneCodeId.substring(
              values.phoneCodeId.toString().indexOf('+'),
              values.phoneCodeId.toString().length
            ) + values.phoneNumber
          );
        } else {
          const res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URI}users/pin/verify`,
            data: qs.stringify(formData),
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
          });

          const token = res.data.token;
          localStorage.setItem('token', token);
        }
      } catch (error) {
        if (error.response && errorMap[error.response.data.error]) {
          console.log('Error: ', error.response.data.error);

          setFormError(error.response.data.error);
        } else {
          console.warn('PIN_Verification unexpected error', error);
          setFormError('ERR_GENERIC');
        }

        document.getElementById('page').scrollTo(0, 0);
        formik.resetForm();
      }
    },
  });

  const errorMap = {
    ERR_GENERIC: 'Unexpected error. please try again later',
    ERR_USER_ALREADY_EXISTS: 'Email already registered',
    ERR_PHONE_NUMBER_ALREADY_REGISTERED: 'Phone number already registered',
    ERR_PHONE_UNVERIFIED:
      'Phone unverified! please try signing in to verify your phone number',
    ERR_PIN_EXPIRED:
      'Your PIN has expired, press the resend button to generate a new one',
  };

  const { formError, setFormError } = useFormError(errorMap, []);

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  if (localStorage.getItem('token')) {
    return <Navigate to="/overview" />;
  }

  return (
    <Surface
      elementType="form"
      onSubmit={formik.handleSubmit}
      surfaceColor={surfaceColor}
      className={styles.signUp}
    >
      <ErrorContainer>
        {formError && (
          <InlineError
            onClose={() => setFormError(null)}
            success={false}
          >
            {formError}
          </InlineError>
        )}
      </ErrorContainer>
      <H className={styles.signUpHeader}>
        <div className={styles.signUpAdditional}>
          {LL.signUp.header()}{' '}
          <span
            onClick={() => navigate('/')}
            style={{ color: 'var(--color-logo)' }}
          >
            {LL.signUp.signIn()}
          </span>
        </div>
        <h3 className={styles.signUpTitle}>{LL.signUp.createNewAccount()}</h3>
      </H>

      <div className={styles.pairInput}>
        <TextField
          label={LL.signUp.firstName()}
          placeholder={LL.signUp.firstName()}
          value={formik.values.firstName}
          onChange={(val) => formik.setFieldValue('firstName', val, false)}
          onBlur={handleBlur('firstName')}
          validationState={getValidationState(formik, 'firstName')}
          errorMessage={formik.errors.firstName}
        />
        <TextField
          label={LL.signUp.lastName()}
          placeholder={LL.signUp.lastName()}
          value={formik.values.lastName}
          onChange={(val) => formik.setFieldValue('lastName', val, false)}
          onBlur={handleBlur('lastName')}
          validationState={getValidationState(formik, 'lastName')}
          errorMessage={formik.errors.lastName}
        />
      </div>

      <div className={styles.pairInput}>
        <TextField
          label={LL.signUp.email()}
          placeholder={LL.signUp.email()}
          value={formik.values.email}
          onChange={(val) => formik.setFieldValue('email', val, false)}
          onBlur={handleBlur('email')}
          validationState={getValidationState(formik, 'email')}
          errorMessage={formik.errors.email}
        />
        <TextField
          label={LL.signUp.confirmEmail()}
          placeholder={LL.signUp.confirmEmail()}
          value={formik.values.emailConfirmation}
          onChange={(val) =>
            formik.setFieldValue('emailConfirmation', val, false)
          }
          onBlur={handleBlur('emailConfirmation')}
          validationState={getValidationState(formik, 'emailConfirmation')}
          errorMessage={formik.errors.emailConfirmation}
        />
      </div>

      <div className={styles.pairInput}>
        <TextField
          label={LL.signUp.password()}
          placeholder={LL.signUp.password()}
          type="password"
          value={formik.values.password}
          onChange={(val) => formik.setFieldValue('password', val, false)}
          onBlur={handleBlur('password')}
          validationState={getValidationState(formik, 'password')}
          errorMessage={formik.errors.password}
        />
        <TextField
          label={LL.signUp.confirmPassword()}
          placeholder={LL.signUp.confirmPassword()}
          type="password"
          value={formik.values.passwordConfirmation}
          onChange={(val) =>
            formik.setFieldValue('passwordConfirmation', val, false)
          }
          onBlur={handleBlur('passwordConfirmation')}
          validationState={getValidationState(formik, 'passwordConfirmation')}
          errorMessage={formik.errors.passwordConfirmation}
        />
      </div>

      <div className={styles.signUpDropdown}>
        <LocationPicker
          label={LL.signUp.chooseCountry()}
          placeholder="Country"
          locations={countries}
          selectedKey={defaultCountry}
          onSelectionChange={(key) => {
            setDefaultCountry(key);
            formik.setFieldValue('countryId', key, false);
          }}
          onBlur={handleBlur('countryId')}
          validationState={getValidationState(formik, 'countryId')}
          // @ts-ignore
          errorMessage={formik.errors.countryId}
        />
      </div>

      <div className={styles.left}>
        <LocationPicker
          label={LL.signUp.phoneCode()}
          placeholder={LL.signUp.code()}
          locations={!!phoneCodes && phoneCodes}
          inputValue={formik.values.phoneCodeId}
          onSelectionChange={(key) => {
            const val =
              key &&
              phoneCodes.find(
                // @ts-ignore
                (val) => (val.id - 1).toString() === key.replace('$.', '')
              );
            if (val) {
              formik.setFieldValue('phoneCodeId', val.phone_code, false);
            }
          }}
          onInputChange={(val) => {
            formik.setFieldValue('phoneCodeId', val, false);
          }}
          onBlur={handleBlur('phoneCodeId')}
          validationState={getValidationState(formik, 'phoneCodeId')}
          errorMessage={formik.errors.phoneCodeId}
          allowsCustomValue
        />

        <TextField
          label={LL.signUp.phoneNumber()}
          placeholder=""
          value={formik.values.phoneNumber}
          onChange={(val) => formik.setFieldValue('phoneNumber', val, false)}
          onBlur={handleBlur('phoneNumber')}
          validationState={getValidationState(formik, 'phoneNumber')}
          errorMessage={formik.errors.phoneNumber}
        />
      </div>

      <div className={styles.signUpCheckbox}>
        <Checkbox
          isSelected={formik.values.ageCheck}
          onChange={(val) => formik.setFieldValue('ageCheck', val, false)}
          onBlur={handleBlur('ageCheck')}
          validationState={getValidationState(formik, 'ageCheck')}
        >
          {LL.signUp.terms()}
        </Checkbox>
        {formik.touched.ageCheck && formik.errors.ageCheck ? (
          <div className={styles.formikError}>{formik.errors.ageCheck}</div>
        ) : null}
      </div>

      {showPhoneVerificationForm && (
        <>
          <H className={styles.headerSignUp}>
            {LL.signUp.verifyPhone()}
            <br style={{ marginBottom: '1rem' }}></br>
            {LL.signUp.verifyMessage()} {displayPhoneNumber}.
          </H>

          <div className={styles.pinSignUp}>
            <TextField
              value={formik.values.pin}
              onChange={(val) => {
                formik.setFieldValue('pin', val, false);
              }}
              onBlur={handleBlur('pin')}
              validationState={getValidationState(formik, 'pin')}
              errorMessage={formik.errors.pin}
            />
            <FilledButton
              className={styles.resendButton}
              onPress={() => generatePin(userEmail)}
            >
              {LL.signUp.resend()}
            </FilledButton>
          </div>
          <div className={styles.verifyButtonSignUp}>
            <FilledButton
              attachesBackground
              pressesBackground
              type="submit"
              isDisabled={formik.isSubmitting}
            >
              {LL.signUp.verifyPIN()}
            </FilledButton>
          </div>
        </>
      )}

      {!showPhoneVerificationForm && (
        <div className={styles.formSubmit}>
          <OutlineButton
            attachesBackground
            pressesBackground
            type="reset"
            onPress={formik.handleReset}
          >
            {LL.signUp.clear()}
          </OutlineButton>

          <FilledButton
            attachesBackground
            pressesBackground
            type="submit"
            isDisabled={formik.isSubmitting}
          >
            {LL.signUp.register()}
          </FilledButton>
        </div>
      )}
    </Surface>
  );
};

export default SignUp;
