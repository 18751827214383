// @ts-check
import { OverlayTriggerContext } from 'common/overlays/base/OverlayTriggerContext';
import PopoverBase from 'common/overlays/base/PopoverBase.component';
import * as React from 'react';
import { mergeProps, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { getRef } from 'utils';

/**
 * Takes two elements as children: an element with an onPress prop (e.g a button),
 * and a function or element that contains popover content. It then renders the popover content by
 * the press of the button and provides the popover content ability to close itself with
 * the close function passed as an argument to the render function.
 * Note, if the second child is not a function, then `OverlayTriggerState`
 * (including the close function) is available inside the child through `OverlayTriggerContext`
 *
 * @param {PopoverTriggerProps} props
 */
export default function PopoverTrigger({
  children,
  type = 'dialog',
  ...props
}) {
  const triggerRef = React.useRef();
  const state = useOverlayTriggerState(props);
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type },
    state,
    triggerRef
  );

  const [button, popoverContent] = children;

  // If press listener is already defined on the button,
  // execute it before opening the popover
  const handlePress = async () => {
    if (button.props.onPress) {
      await button.props.onPress();
    }
    state.open();
  };

  return (
    <>
      {/* Pass down onPress and aria props to the trigger button */}
      {React.cloneElement(
        button,
        mergeProps({
          ...triggerProps,
          onPress: handlePress,
          ref: getRef(triggerRef, button),
        })
      )}

      {state.isOpen && (
        <PopoverBase
          {...props}
          triggerRef={triggerRef}
          state={state}
        >
          {typeof popoverContent === 'function' ? (
            React.cloneElement(popoverContent(state.close), overlayProps)
          ) : (
            <OverlayTriggerContext.Provider value={state}>
              {popoverContent}
            </OverlayTriggerContext.Provider>
          )}
        </PopoverBase>
      )}
    </>
  );
}

/** @typedef {LocalProps & PopoverProps} PopoverTriggerProps */
/** @typedef {Omit<import('common/overlays/base/PopoverBase.component').PopoverBaseProps, "state" | "children" | "triggerRef">} PopoverProps */

/**
 * @typedef LocalProps
 *
 * @property {boolean} [isOpen]
 * Whether the overlay is open by default (controlled).
 *
 * @property {boolean} [defaultOpen]
 * Whether the overlay is open by default (uncontrolled).
 *
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * Handler that is called when the overlay's open state changes.
 *
 * @property {'dialog' | 'menu' | 'listbox' | 'tree' | 'grid'} [type="dialog"]
 *
 * @property {[React.ReactElement, ((close: () => void) => React.ReactElement) | React.ReactElement]} children
 * A button or any element wich has an onPress prop,
 * and a function or element that contains the popover's content.
 * `onPress` event is automatically bound to the button element,
 * so that pressing the button triggers popover.
 * If the second child is an element instead of a function,
 * `overlayTriggerState`, including close function is available for
 * the element through `OverlayTriggerContext`
 */
