// @ts-check
import * as React from 'react';
import Select from 'common/pickers/Select.component';
import { Item } from '@react-stately/collections';
import { useBalancesQuery } from 'queries/balances';
import currencies from 'assets/currencies';
import Currency from 'components/content/Currency.component';
import FieldSelect from 'common/fields/FieldSelect.component';
import { useLocale } from 'context/LanguageContext';

/**
 * @typedef {import("queries/balances").Balance} Balance
 *
 *
 * @typedef Props
 * @property {boolean} [isInline] whether to use inline variant (as a companion to a field)
 * @property {string} [defaultText]
 * @property {React.ReactNode} [label]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {string} [placeholder]
 * @property {string | null} [selectedKey] // shorthand
 * @property {(key: React.Key) => void} [onSelectionChange]
 * @property {boolean} [autoFocus]
 * @property {( e : React.FocusEvent ) => void} [onFocus]
 * @property {( e : React.FocusEvent ) => void} [onBlur]
 * @property {( isFocused : boolean ) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyUp]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {React.MutableRefObject<HTMLElement>} [positionRef]
 *
 * @param {Props} props
 */
export default function CurrencyPicker(props) {
  const { LL } = useLocale();

  const balances = useBalancesQuery();

  if (balances.isError) {
    console.log('error', balances.error);
    // TODO: idk about this error message.
    return <>Problem trying to load Currencies. Please try reloading</>;
  }
  const SelectComponent = props.isInline ? FieldSelect : Select;

  return (
    <SelectComponent
      renderValue={props.isInline && ((balance) => balance.coin.toUpperCase())}
      isLoading={balances.isLoading}
      items={balances.data || []}
      {...props}
    >
      {(balance) => (
        <Item
          key={balance.coin}
          textValue={LL.currency[balance.coin]()}
        >
          {LL.currency[balance.coin]()}
          {currencies[balance.coin] ? (
            <img
              src={currencies[balance.coin]}
              alt=""
              data-slot="icon"
            />
          ) : (
            <React.Fragment data-slot="icon" />
          )}
          <Currency
            data-slot="description"
            currency={balance.coin}
          >
            {balance.confirmed_balance}
          </Currency>
        </Item>
      )}
    </SelectComponent>
  );
}
