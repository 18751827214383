// @ts-check
import cx from 'classnames';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import * as React from 'react';
import { VisuallyHidden } from 'react-aria';
import styles from './Status.module.css';
/**
 * @typedef StatusProps
 * @property {"pending" | "success" | "fail" | "expired"} type
 * @property {"lg" | "sm"} size
 *
 * @param {StatusProps} props
 */
export default function Status({ type, size = 'lg' }) {
  const { LL } = useLocale();
  const { isDarkTheme } = useThemeVariables();

  return (
    <div
      className={cx(
        styles.container,
        type === 'success' && isDarkTheme ? styles.successDark : styles[type],
        {
          '--sizeLarge': size === 'lg',
          '--sizeSmall': size === 'sm',
        }
      )}
    >
      {size === 'lg' && LL.transactionsTable.statusColumn.type[type]()}
      {size === 'sm' && (
        <VisuallyHidden>
          {LL.transactionsTable.statusColumn.type[type]()}
        </VisuallyHidden>
      )}
    </div>
  );
}
