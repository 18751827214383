import React from 'react';
import styles from './footer.module.css';

import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.sections}>
          <h3>Navigation</h3>
          <a>Main</a>
          <a>My Wallets</a>
          <a>Markets</a>
          <a>Transactions</a>
          <a>Exchange</a>
          <a>Explorer</a>
        </div>
        <div className={styles.sections}>
          <h3>Community</h3>
          <a>Discord</a>
          <a>Telegram</a>
          <a>Forum</a>
          <a>T & C</a>
          <a>FAQ</a>
          <a>News</a>
        </div>
        <div className={styles.sections}>
          <h3>Contact</h3>
          <a>Support</a>
          <a>Contact Info</a>
          <a>Forum</a>
          <a>T & C</a>
          <a>FAQ</a>
          <a>News</a>
        </div>
      </div>
      <div className={styles.footerAppendix}>
        <span>
          <span className={styles.copyright}>Copyright</span> © 2010-2022 Your
          Company S.L. All rights reserved
        </span>
        <span className={styles.footerAppendixIcons}>
          <FaFacebookSquare className={styles.fbIcon} />{' '}
          <FaTwitterSquare className={styles.twIcon} />{' '}
          <FaLinkedin className={styles.liIcon} />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
