import {
  FETCH_NETWORKS_START,
  FETCH_NETWORKS_SUCCESS,
  FETCH_NETWORKS_FAIL,
} from './network.types';

export const fetchNetworksStart = (payload) => ({
  type: FETCH_NETWORKS_START,
  payload,
});

export const fetchNetworksSuccess = (payload) => ({
  type: FETCH_NETWORKS_SUCCESS,
  payload,
});

export const fetchNetworksFail = (payload) => ({
  type: FETCH_NETWORKS_FAIL,
  payload,
});
