// @ts-check
import OutlinedButton from 'common/buttons/OutlinedButton';
import TextButton from 'common/buttons/TextButton';
import { Slot, Template } from 'common/template/Template';
import useThemeVariables from 'hooks/useThemeVariables';
import * as React from 'react';
import { RiAlertFill, RiErrorWarningFill } from 'react-icons/ri';
import Dialog from './Dialog.component';
import styles from './AlertDialog.module.css';
import { useOverlayTriggerContext } from 'common/overlays/base/OverlayTriggerContext';
import { IconContext } from 'react-icons';

/**
 * AlertDialogs are a specific type of Dialog.
 * They display important information that users need to acknowledge.
 * Unlike it's counterpart - Dialog - this variant's layout is very cut-in-stone,
 * which makes AlertDialogs default choice for most use cases.
 *
 * When to choose Dialog over AlertDialog:
 * {@link https://stackoverflow.com/questions/53349338/what-is-the-difference-between-dialog-and-alertdialog}
 *
 *
 * This component uses slots pattern. Available slots are:
 * `icon` - Icon that appears at the top of the modal.
 *          Some variants have icons by default.
 * `default` - Optional description of the dialog.
 * `primaryActionLabel` - Label on the primary button. All variants have their
 *                        default primary action label.
 * `primaryActionIcon` - Icon on the primary button.
 *                       Some variants have primary icons by default.
 * `secondaryActionLabel` - Label on the secondary button.
 *                          Secondary action button only renders
 *                          when label or icon is provided
 * `secondaryActionIcon` - Icon on the secondaty button.
 *                         Secondary action button only renders
 *                         when label or icon is provided
 * `cancelLabel` - Cancel Label. Cancel button only renders
 *                 when cancelLabel is provided
 * @param {AlertDialogProps} props
 **/
export default function AlertDialog({
  children,
  variant = 'confirmation',
  title,
  onCancel,
  onPrimaryAction,
  onSecondaryAction,
  ...rest
}) {
  const { colorError } = useThemeVariables();
  const overlayTriggerState = useOverlayTriggerContext();

  const handleClose = (action) => async () => {
    // If passed as a second argument to ModalTrigger,
    // Buttons will call actions (onCancel, onPrimaryAction, onSecondaryAction)
    // and close the modal automatically. If not, actions must handle closing the
    // modal on their own
    if (action) {
      await action();
    }
    if (overlayTriggerState) {
      overlayTriggerState.close();
    }
  };

  return (
    <Dialog
      role="alertdialog"
      title={title}
      classNames={styles}
      {...rest}
    >
      {/* TODO: bad Template/Slot code but will be fixed if I ever finish the library */}
      <Template content={children}>
        {({
          defaults,
          icon,
          cancelLabel,
          secondaryActionLabel,
          primaryActionIcon,
          secondaryActionIcon,
        }) => (
          <>
            <IconContext.Provider value={{ size: '2.8rem' }}>
              {/* Wrap in div only when icon is provided, default icons only when error or warning */}
              {(icon || ['error', 'warning'].includes(variant)) && (
                <div className={styles.icon}>
                  <Slot name="icon">
                    {variant === 'error' && <RiAlertFill color={colorError} />}
                    {variant === 'warning' && <RiAlertFill color={'orange'} />}
                  </Slot>
                </div>
              )}
            </IconContext.Provider>

            {defaults.length > 0 && (
              <div className={styles.description}>
                <Slot />
              </div>
            )}

            <div className={styles.actions}>
              {(cancelLabel || onCancel) && (
                <TextButton onPress={handleClose(onCancel)}>
                  <Slot name="cancelLabel">Cancel</Slot>
                </TextButton>
              )}

              {(secondaryActionLabel || onSecondaryAction) && (
                <TextButton
                  onPress={handleClose(onSecondaryAction)}
                  left={
                    secondaryActionIcon && <Slot name="secondaryActionIcon" />
                  }
                >
                  <Slot name="secondaryActionLabel">Remind me later</Slot>
                </TextButton>
              )}

              <OutlinedButton
                onPress={handleClose(onPrimaryAction)}
                left={
                  (primaryActionIcon || variant === 'destructive') && (
                    <Slot name="primaryActionIcon">
                      {variant === 'destructive' && (
                        <RiErrorWarningFill color={colorError} />
                      )}
                    </Slot>
                  )
                }
              >
                <Slot name="primaryActionLabel">Continue</Slot>
              </OutlinedButton>
            </div>
          </>
        )}
      </Template>
    </Dialog>
  );
}

/**
 * @typedef {import("./Dialog.component").DialogProps & LocalProps}  AlertDialogProps
 */

/**
 * @typedef LocalProps
 * @property {React.ReactNode} title
 * @property {React.ReactNode} [children]
 * @property {keyof AlertDialogVariants} [variant="confirmation"]
 * @property {(() => void | Promise<any>)} [onPrimaryAction]
 * @property {(() => void | Promise<any>)} [onSecondaryAction]
 * @property {(() => void | Promise<any>)} [onCancel]
 */

/**
 * @typedef AlertDialogVariants
 *
 * @property {"confirmation"} confirmation
 * This is the default variant for alert dialogs.
 * Use a confirmation variant for asking a user to confirm a choice.
 * For now, this variant is identical to "information", but it may change later
 *
 * @property {"information"} information
 * Information alert dialogs communicate important information
 * that a user needs to acknowledge. Before using this kind of alert dialog,
 * make sure it's the appropriate communication channel
 * for the message instead of a toast or a more lightweight messaging option.
 *
 * @property {"warning"} warning
 * Warning alert dialogs communicate important information to users in relation
 * to an issue that needs to be acknowledged, but does not block
 * the user from moving forward. It has an orange warning icon near the title
 * to reinforce its importance.
 *
 * @property {"destructive"} destructive
 * Destructive alert dialogs are for when a user needs to confirm an action
 * that will impact their data or experience in a potentially negative way,
 * such as deleting files or contacts. This alert dialog has a red exclemation
 * icon next to primary action to highlight the destructivness
 *
 * @property {"error"} error
 * Error alert dialogs communicate critical information about an issue
 * that a user needs to acknowledge. This alert dialog has a warning icon
 * to reinforce its importance.
 *
 */
