// @ts-check

import { Item } from '@react-stately/collections';
import Surface from 'common/surfaces/Surface.component';
import Tabs from 'common/tabs/Tabs.component';
import TransactionsTable from 'components/transactions/TransactionsTable.component';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import InvoicePayment from './InvoicePayment.component';
import styles from './Merchant.module.css';
import P2PInvoice from './P2PInvoice.component';
import Payables from './Payables.component';

export default function Merchant() {
  sessionStorage.clear();
  const { LL } = useLocale();
  const [tab, setTab] = useSearchParams();

  /** @type {React.MutableRefObject<React.ElementRef<Tabs>>} */
  const tabRef = React.useRef(null);
  // prettier-ignore
  const [selectedTab, setSelectedTab] = React.useState(tab ? tab.get("tab") : null);
  const [navigateInvoiceId, setInvoiceId] = React.useState(null);

  const filters = {
    currencies: [],
    from: null,
    to: null,
    type: 'invoice',
    address: '',
    txId: '',
  };
  const { colorTest4 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };

  const location = useLocation();

  React.useEffect(() => {
    if (
      typeof location.state === 'object' &&
      location.state !== null &&
      'focus' in location.state
    ) {
      tabRef.current?.focusIntoPanel();
    }
    console.log(location.state);
  }, [location.state]);

  const handleTabChange = () => {};

  return (
    <Surface
      className={styles.container}
      surfaceColor={surfaceColor}
      baseElevation={0}
    >
      <Level>
        <H className={styles.mainHeadline}>{LL.app.exchange()}</H>
      </Level>

      <Tabs
        selectedKey={selectedTab}
        onSelectionChange={(key) => setSelectedTab(key.toString())}
        classNames={{ tabs: styles.tab, tabPanel: styles.tabPanel }}
        ref={tabRef}
      >
        <Item
          key={'Create'}
          title={LL.merchant.createTab()}
        >
          <P2PInvoice />
        </Item>
        <Item
          key={'Invoices'}
          title={LL.merchant.listTab()}
        >
          <div className={styles.tableHeader}>
            <span>Latest 10 invoices created</span>
            <span>
              If you want to view all invoices please visit the transactions
              page
            </span>
          </div>
          <TransactionsTable
            maxRows={Infinity}
            // @ts-ignore
            filters={filters}
            className={styles.table}
          />
        </Item>
        <Item
          key={'Payables'}
          title={LL.merchant.payableTab()}
        >
          <Payables
            setSelectedTab={setSelectedTab}
            setInvoiceId={setInvoiceId}
          />
        </Item>
        <Item
          key={'Payment'}
          title={LL.merchant.paymentTab()}
        >
          <InvoicePayment navigateInvoiceId={navigateInvoiceId} />
        </Item>
      </Tabs>
    </Surface>
  );
}
