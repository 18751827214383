import { useLocale } from 'context/LanguageContext';
import React from 'react';
import { useCalendarGrid } from 'react-aria';
import CalendarCell from './CalendarCell.component';
import styles from './CalendarGrid.module.css';

export default function CalendarGrid({ state, ...props }) {
  const { LL } = useLocale();
  let { gridProps, headerProps } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  //   let weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
  let weekDays = [];
  for (let i = 0; i < 7; i++) {
    weekDays.push(LL.weekSymbols[`${i}`]());
  }
  return (
    <table
      {...gridProps}
      cellPadding="0"
      className={styles.grid}
    >
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(6).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state.getDatesInWeek(weekIndex).map((date, i) =>
              date ? (
                <CalendarCell
                  key={i}
                  state={state}
                  date={date}
                />
              ) : (
                <td key={i} />
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
