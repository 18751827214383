import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import './landing.styles.css';

import SignUp from '../sign-up/sign-up.component';
import TfaForm from '../signIn/2fa/2fa-form';
import SingIn from '../signIn/signIn.component';

import useThemeVariables from 'hooks/useThemeVariables';
import { Level } from 'react-accessible-headings';

import CurrencyNames from 'assets/data/currency-names';
import LandingShowcase from 'components/content/LandingShowcase.component';
import useOnClickOutside from '../../hooks/useOnClickOutside';
// import PageOptionsComponent from 'components/page-options/page-options.component';

const LandingPage = ({ show2faForm, showPhoneVerification, darkMode }) => {
  const [showNavItems, setShowNavItems] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setShowNavItems(false));

  const { isDarkTheme, colorOnSuccess, MdRefPaletteTertiary95 } =
    useThemeVariables();

  return (
    <div className="landing-page">
      <nav className="landing-nav">
        <div className="header">
          <h1>Pay instantly with payBitX</h1>

          <span>
            the smartest crypto wallet , payment API and invoice generator;
            First bitcoin P2P payment platform; JOIN payBitX
          </span>
        </div>
      </nav>

      <div className="landing-content">
        {showSignUp ? (
          <SignUp />
        ) : (
          <>
            <div className="markets-container">
              <Level>
                <h1 className={'markets-headline'}>Markets</h1>
                <div className={'markets'}>
                  {CurrencyNames.map((currency, index) => (
                    <LandingShowcase
                      key={index}
                      size="md"
                      currency={currency.symbol}
                    />
                  ))}
                </div>
              </Level>
            </div>

            <section className="content__form">
              {show2faForm ? <TfaForm /> : <SingIn />}
            </section>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  show2faForm: state.auth.show2faForm,
  showPhoneVerification: state.auth.showPhoneVerification,
  darkMode: state.theme.dark,
});

export default connect(mapStateToProps)(LandingPage);

/* <div className="landing-currencies">
                <div className="landing-currencies-imgs">
                  <img
                    src={images.btc}
                    alt=""
                  />
                  <img
                    src={images.eth}
                    alt=""
                  />
                  <img
                    src={images.ltc}
                    alt=""
                  />
                  <img
                    src={images.xlm}
                    alt=""
                  />
                  <img
                    src={images.xrp}
                    alt=""
                  />
                  <img
                    src={images.bch}
                    alt=""
                  />
                </div>

                <span>
                  Bitcoin, Ethereum, Litecoin, Stellar, Ripple, Bitcoin Cash
                </span>
              </div> */
