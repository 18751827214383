// @ts-check
import { Accordion, AccordionItem } from 'common/accordion/Accordion.component';
import IconButton from 'common/buttons/IconButton';
import TextButton from 'common/buttons/TextButton';
import useThemeVariables from 'hooks/useThemeVariables';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RiCss3Line, RiReactjsLine } from 'react-icons/ri';
import styles from './Colors.module.css';

/** @param {string} string */
function toCssVariable(string) {
  return (
    '--' + string.replace(/[A-Z]/g, (letter) => '-' + letter.toLowerCase())
  );
}

function Color({ jsName, value, cssName }) {
  return (
    <div
      style={{ background: value }}
      className={styles.color}
    >
      <div className={styles.colorName}>{jsName}</div>

      <div className={styles.colorActions}>
        <CopyToClipboard text={jsName}>
          <IconButton aria-label="copy js">
            <RiReactjsLine />
          </IconButton>
        </CopyToClipboard>
        <CopyToClipboard text={cssName}>
          <IconButton aria-label="copy css">
            <RiCss3Line />
          </IconButton>
        </CopyToClipboard>
        <CopyToClipboard text={value}>
          <TextButton>Value</TextButton>
        </CopyToClipboard>
      </div>
    </div>
  );
}

export default function Colors() {
  const tokens = useThemeVariables();

  const { themeColors, pallete } = React.useMemo(() => {
    const themeColors = [];
    const pallete = [];

    for (const [key, value] of Object.entries(tokens)) {
      if (typeof value === 'string') {
        if (key.startsWith('color')) {
          themeColors.push(
            <Color
              {...{ jsName: key, cssName: toCssVariable(key), value, key }}
            />
          );
        } else if (key.startsWith('MdRefPalette')) {
          pallete.push(
            <Color
              {...{ jsName: key, cssName: toCssVariable(key), value, key }}
            />
          );
        }
      }
    }

    return { themeColors, pallete };
  }, [tokens]);

  return (
    <Level>
      <div className={styles.container}>
        <H className="h type-headline-medium">Colors used in PBX</H>

        <Accordion>
          <AccordionItem summary="Theme colors">
            <div className={styles.colors}>{themeColors}</div>
          </AccordionItem>
          <AccordionItem summary="Pallete">
            <div className={styles.colors}>{pallete}</div>
          </AccordionItem>
        </Accordion>
      </div>
    </Level>
  );
}
