// @ts-check
import NiceModal from '@ebay/nice-modal-react';
import { CurrencyAndThemePovider } from 'context/CurrencyAndThemeContext';
import { LanguageProvider } from 'context/LanguageContext';
import { QueryClientProvider } from 'queries/queryClient';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { IconContext } from 'react-icons';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import { persistor, store } from './redux/store';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider>
        <BrowserRouter>
          <IconContext.Provider
            value={{ size: '2rem', style: { verticalAlign: 'middle' } }}
          >
            <LanguageProvider>
              <CurrencyAndThemePovider>
                <NiceModal.Provider>
                  <HelmetProvider>
                    <App />
                  </HelmetProvider>
                </NiceModal.Provider>
              </CurrencyAndThemePovider>
            </LanguageProvider>
          </IconContext.Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);
