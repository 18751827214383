// @ts-check
import Queue from '../../utils/Queue';
import GridItem from './GridItem';
import * as Vector from '../../utils/Vector';
import debounce from 'just-debounce';

const RESIZE_DELAY = 200;
/**
 * @typedef {import("../../utils/Vector").Vec2} Vec2
 */

export default class Grid {
  _cellsize;
  _gridItemColor;
  _gridContainer;
  /** @type {GridItem[][]} */
  _gridItems = [];
  _ctx;

  constructor() {
    this._gridContainer = document.createElement('canvas');
    this._gridContainer.width = 0;
    this._gridContainer.height = 0;
    this._gridContainer.setAttribute('class', 'grid-container');

    // styles
    this._gridContainer.style.position = 'absolute';
    this._gridContainer.style.top = '0';
    this._gridContainer.style.left = '0';
    this._gridContainer.style.overflow = 'hidden';

    this._ctx = this._gridContainer.getContext('2d');

    // ctx config
    this._ctx.translate(0.5, 0.5);
    // this._ctx.translate(0.5, 0.5);
    // this._ctx.lineCap = "round";
    // this._ctx.lineWidth = 20;
  }

  get domNode() {
    return this._gridContainer;
  }

  get items() {
    return this._gridItems;
  }

  /** @returns {[xSize: number, ySize: number]} */
  get size() {
    return [this.columns, this.rows];
  }

  get rows() {
    return this._gridItems.length;
  }

  get columns() {
    return (this._gridItems[0] || []).length;
  }

  /** @returns {[width: number, height: number]} */
  get clientSize() {
    return [this._gridContainer.width, this._gridContainer.height];
  }

  get cellsize() {
    return this._cellsize;
  }
  set cellsize(newCellsize) {
    if (this._cellsize === newCellsize) return;
    // update cellsize
    this._cellsize = newCellsize;

    // remove all the elemens & clear the canvas;
    this._gridItems = [];
    this._ctx.clearRect(0, 0, this.clientSize[0], this.clientSize[1]);

    // create new grid with updated cellsize
    const [xSize, ySize] = this.indicesOf(this.clientSize);
    this._gridItems = Grid.createMatrix(ySize + 1, xSize + 1);

    for (let y = 0; y < ySize + 1; y++) {
      for (let x = 0; x < xSize + 1; x++) {
        const gridItem = this._createGridItem([x, y]);
        this._gridItems[y][x] = gridItem;
        gridItem.draw(this.cellsize);
      }
    }
  }

  /** @param {any} value */
  set gridItemColor(value) {
    this._gridItemColor = value;
    this.forEach((gridItem) => {
      gridItem.rgba = value;
      gridItem.draw(this.cellsize);
    });
  }

  /** @param {number} value */
  set gridItemThickness(value) {
    this._gridItemThickness = value;
    this.forEach((gridItem) => {
      gridItem.lineWidth = value;
      gridItem.draw(this.cellsize);
    });
  }

  /** @param {ResizeObserverEntry[]} entries*/
  resizeObserverCallback = debounce(
    (entries) => {
      for (let entry of entries) {
        let width, height;

        if (entry.borderBoxSize) {
          // read inlineSize and blockSize for dimensions in new specification:
          // Firefox implements `borderBoxSize` as a single content rect, rather than an array
          const borderBoxSize = Array.isArray(entry.borderBoxSize)
            ? entry.borderBoxSize[0]
            : entry.borderBoxSize;
          width = borderBoxSize.inlineSize;
          height = borderBoxSize.blockSize;
        } else {
          // older, maybe-future-depricated version:
          width = entry.contentRect.width;
          height = entry.contentRect.height;
        }

        // copy the image data and paint after resize

        width = Math.floor(width);
        height = Math.floor(height);

        this._gridContainer.width = width;
        this._gridContainer.height = height;

        // order is important. vertical adjustments must come before horizontal
        this._shrinkVertically(height);
        this._shrinkHorizontally(width);
        this._expandVertically(height);
        this._expandHorizontally(width);

        this.forEach((gridItem) => {
          gridItem.draw(this.cellsize, true);
        });
      }
    },
    RESIZE_DELAY,
    false,
    true
  );

  /**
   * @param {(gridItem: GridItem, indexes: Vec2) => void} fn
   */
  forEach(fn) {
    const [xSize, ySize] = this.size;
    for (let y = 0; y < ySize; y++) {
      for (let x = 0; x < xSize; x++) {
        const indexes = /** @type {Vec2} */ ([x, y]);
        fn(this.getItem(indexes), indexes);
      }
    }
  }

  /**
   * @param {Vec2} startCoords
   * @param {Vec2} endCoords
   * @param {(element?: GridItem, indexes?: Vec2) => void} fn
   */
  forEachInRect(startCoords, endCoords, fn) {
    const startIndex = this.indicesOf(startCoords);
    const endIndex = this.indicesOf(endCoords);

    for (let y = startIndex[1]; y <= endIndex[1]; y++) {
      for (let x = startIndex[0]; x <= endIndex[0]; x++) {
        const indexes = /** @type {Vec2} */ ([x, y]);
        fn(this.getItem(indexes), indexes);
      }
    }
  }

  /**
   * return true from callback to continue search
   * @param {Vec2} startIndex
   * @param {(element?: GridItem, indexes?: Vec2) => boolean} fn
   */
  forEachBFS(startIndex, fn) {
    const firstItem = this.getItem(startIndex);
    if (!firstItem) return;

    // allocate
    const neighbours = [
      Vector.fromValues(0, 1),
      Vector.fromValues(0, -1),
      Vector.fromValues(1, 0),
      Vector.fromValues(-1, 0),
    ];

    const q = new Queue();

    // track visited items in Weakset
    const visited = new WeakSet();
    visited.add(firstItem);

    if (fn(firstItem, startIndex)) {
      q.enqueue(startIndex);
    }

    while (q.size !== 0) {
      const v = q.dequeue();
      for (const n of neighbours) {
        const neighbour = Vector.create();
        Vector.add(neighbour, v, n);

        const item = this.getItem(neighbour);
        if (item && !visited.has(item) && fn(item, neighbour)) {
          q.enqueue(neighbour);
          visited.add(item);
        }
      }
    }
  }

  /**
   * @param {Vec2} index
   */
  getItem(index) {
    const row = this._gridItems[index[1]];
    if (row) {
      return row[index[0]];
    } else {
      return undefined;
    }
  }

  /**
   * @param {Vec2} coordinates
   * @returns {Vec2} indices
   */
  indicesOf(coordinates) {
    return [this.toIndex(coordinates[0]), this.toIndex(coordinates[1])];
  }

  /**
   * @param {Vec2} indexes
   * @returns {Vec2} indices
   */
  coordsOf(indexes) {
    return [this.toCoord(indexes[0]), this.toCoord(indexes[1])];
  }

  /** @param {number} coordinate */
  toIndex(coordinate) {
    return Grid.toIndex(this._cellsize, coordinate);
  }

  /** @param {number} index */
  toCoord(index) {
    return Grid.toCoord(this._cellsize, index);
  }

  /** @param {number} newWidth */
  _expandHorizontally(newWidth) {
    const newColumns = this.toIndex(newWidth) + 1;
    if (newColumns <= this.columns) return;
    const currentColumns = this.columns;
    const currentRows = this.rows;

    for (let y = 0; y < currentRows; y++) {
      for (let x = currentColumns; x < newColumns; x++) {
        const gridItem = this._createGridItem([x, y]);
        this._gridItems[y].push(gridItem);
      }
    }
  }

  /** @param {number} newHeight */
  _expandVertically(newHeight) {
    const newRows = this.toIndex(newHeight) + 1;
    if (newRows <= this.rows) return;

    const currentColumns = this.columns;
    const currentRows = this.rows;

    for (let y = currentRows; y < newRows; y++) {
      this._gridItems[y] = [];
      for (let x = 0; x < currentColumns; x++) {
        const gridItem = this._createGridItem([x, y]);
        this._gridItems[y].push(gridItem);
      }
    }
  }

  /** @param {number} newWidth */
  _shrinkHorizontally(newWidth) {
    const newColumns = this.toIndex(newWidth) + 1;
    if (newColumns >= this.columns) return;

    const currentRows = this.rows;
    for (let y = 0; y < currentRows; y++) {
      this._gridItems[y].splice(newColumns - 1);
    }
  }

  /** @param {number} newHeight */
  _shrinkVertically(newHeight) {
    const newRows = this.toIndex(newHeight) + 1;
    if (newRows >= this.rows) return;

    this._gridItems.splice(newRows - 1);
  }

  /** @param {Vec2} gridIndexes */
  _createGridItem(gridIndexes) {
    return new GridItem(
      this._ctx,
      this.coordsOf(gridIndexes),
      this._gridItemColor,
      this._gridItemThickness
    );
  }
}

Grid.toIndex = (cellsize, coordinate) => {
  return Math.floor(coordinate / cellsize);
};

Grid.toCoord = (cellsize, index) => {
  return cellsize * index;
};

/**
 * @param {number} cellsize
 * @param {Vec2} coords
 * @returns {Vec2} indices
 */
Grid.indicesOf = (cellsize, coords) => {
  return Vector.fromValues(
    Grid.toIndex(cellsize, coords[0]),
    Grid.toIndex(cellsize, coords[1])
  );
};

/**
 * @param {number} cellsize
 * @param {Vec2} indices
 * @returns {Vec2} indices
 */
Grid.coordsOf = (cellsize, indices) => {
  return Vector.fromValues(
    Grid.toCoord(cellsize, indices[0]),
    Grid.toCoord(cellsize, indices[1])
  );
};

Grid.createMatrix = (rows, cols) => {
  return Array.from({ length: rows }, () => Array.from({ length: cols }));
};
