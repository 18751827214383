import {
  FETCH_ADMIN_BALANCE_START,
  FETCH_TOTAL_WALLET_BALANCE_SUCCESS,
  FETCH_TOTAL_PBX_BALANCE_SUCCESS,
} from './admin.types';

export const fetchAdminBalanceStart = () => ({
  type: FETCH_ADMIN_BALANCE_START,
});

export const fetchTotalWalletBalanceSuccess = (data) => ({
  type: FETCH_TOTAL_WALLET_BALANCE_SUCCESS,
  payload: data,
});

export const fetchTotalPbxBalanceSuccess = (data) => ({
  type: FETCH_TOTAL_PBX_BALANCE_SUCCESS,
  payload: data,
});
