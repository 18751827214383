import { NumberParser } from '@internationalized/number';
import CurrencyFormats from 'assets/data/currency-formats';
import { CorruptedField } from 'common/fields/CorruptedField.component';
import NumberField from 'common/fields/NumberField';
import { useConversion } from 'components/conversion/ConversionGroup';
import { useLocale } from 'context/LanguageContext';
import * as React from 'react';
import { mergeProps } from 'react-aria';

/** @typedef {CurrencyFieldLocalProps & Omit<import("common/fields/NumberField").NumberFieldProps, "formatOptions">} CurrencyFieldProps */

/** @typedef {{currency: import("currencies").Currency}} CurrencyFieldLocalProps */

/**
 * This component is a wrapper around the NumberField component
 * that adds support for currency formatting. It can also be used
 * to automatically convert a value to another currency, when used
 * inside a ConversionGroup.
 *
 * @param {CurrencyFieldProps} props
 */
export default function CurrencyField({ currency, ...props }) {
  const conversion = useConversion(currency);

  if (conversion) {
    return (
      <ConversionField
        conversion={conversion}
        currency={currency}
        numberFieldProps={props}
      />
    );
  }

  return (
    <NumberField
      {...props}
      formatOptions={CurrencyFormats[currency]}
    />
  );
}

/**
 *
 * @param {object} props
 * @param {import("currencies").Currency} props.currency
 * @param {import("common/fields/NumberField").NumberFieldProps} props.numberFieldProps
 * @param {import("components/conversion/ConversionGroup").ConversionResult} props.conversion
 */
function ConversionField({ conversion, currency, numberFieldProps }) {
  // Throw if value or defaultValue is provided
  if (numberFieldProps.value || numberFieldProps.defaultValue) {
    throw new Error(
      'CurrencyField: value and defaultValue are not supported when using a conversion. Define those on the ConversionGroup instead.'
    );
  }

  const formatOptions = CurrencyFormats[currency];

  const { trueLocale } = useLocale();
  const numberParser = React.useMemo(() => {
    return new NumberParser(trueLocale, formatOptions);
  }, [trueLocale, formatOptions]);

  /** @type {Partial<import("common/fields/NumberField").NumberFieldProps>} */
  const localProps = {
    value: conversion.value,
    onChange: (val) => {
      conversion.setValue(val);
      // Necessary because incrementing/decrementing the value with arrow keys
      // triggers onChange but not onInput.
      conversion.updateOthers(val);
    },
    onInput: (e) => {
      // We should not update the value here because it forces
      // number to be parsed and formatted. When this happens while user is typing
      // value changes in ways user does not expect.
      const parsed = numberParser.parse(e.currentTarget.value);
      conversion.updateOthers(parsed);
    },
    formatOptions: formatOptions,
  };

  if (conversion.error) {
    // Set input to 0
    // Maybe better approach would be to set it to the last known value
    return (
      <CorruptedField
        onRetry={() => localProps.onChange(0)}
        label={numberFieldProps.label}
        description={numberFieldProps.description}
      />
    );
  }

  return <NumberField {...mergeProps(numberFieldProps, localProps)} />;
}
