// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef {Array.<Object>} TemplateEmails
 * @property {Object} data
 * @property {number} data.id
 * @property {string} data.email
 * @property {string} data.coin
 */

/**
 * @returns {Promise<TemplateEmails>}
 */

export async function getTemplateEmails() {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}templates/p2p`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * @param {{enabled?: boolean, retry?: boolean, onSuccess?: any, onError?: any}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<TemplateEmails, Error>}
 **/
export function useTemplateEmailsQuery(options) {
  return useQuery(['templateEmails'], getTemplateEmails, {
    ...options,
  });
}
