// @ts-check
import * as React from 'react';

/** @typedef {import("react-stately").OverlayTriggerState | undefined} OverlayTriggerContextValue */

/** @type {React.Context<OverlayTriggerContextValue>} */
export const OverlayTriggerContext = React.createContext(undefined);

export function useOverlayTriggerContext() {
  return React.useContext(OverlayTriggerContext);
}
