import * as React from 'react';

/**
 * @typedef {Error} ErrorMapContextValue
 */

/**
 * @typedef ErrorMapProviderProps
 * @property {ErrorMapContextValue} error
 * @property {React.ReactNode} children
 */

const ErrorMapContext = React.createContext(
  /** @type {ErrorMapContextValue} */ (null)
);

export function ErrorMapProvider({ children, error }) {
  return (
    <ErrorMapContext.Provider value={error}>
      {children}
    </ErrorMapContext.Provider>
  );
}

/**
 * Get curret error from ErrorMap. For multiple error cases
 * use useErrorMap multiple times with different errorTypes.
 *
 * @template {new() => Error} T
 * @param {T} errorType
 */
export function useErrorMap(errorType) {
  const error = React.useContext(ErrorMapContext);
  if (error instanceof errorType) {
    return /** @type {InstanceType<T>} */ (error);
  }
  return null;
}
