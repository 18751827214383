// @ts-check
import { createInterpolator } from 'range-interpolator';

/**
 * @typedef {number[]} RGBA
 * @param {object} params
 * @param {number[]} params.inputRange
 * @param {RGBA[]} params.outputRGBARange
 * @param {'extend' | 'identity' | 'clamp'} [params.extrapolate]
 */
export function createRGBAInterpolator({
  inputRange,
  outputRGBARange,
  extrapolate,
}) {
  let outputRed = [];
  let outputGreen = [];
  let outputBlue = [];
  let outputAlpha = [];

  for (const rgba of outputRGBARange) {
    outputRed.push(rgba[0]);
    outputGreen.push(rgba[1]);
    outputBlue.push(rgba[2]);
    outputAlpha.push(rgba[3]);
  }

  const redInterpolator = createInterpolator({
    inputRange,
    outputRange: outputRed,
    extrapolate,
  });
  const greenInterpolator = createInterpolator({
    inputRange,
    outputRange: outputGreen,
    extrapolate,
  });
  const blueInterpolator = createInterpolator({
    inputRange,
    outputRange: outputBlue,
    extrapolate,
  });
  const alphaInterpolator = createInterpolator({
    inputRange,
    outputRange: outputAlpha,
    extrapolate,
  });

  /** @type {RGBA} */
  const output = [null, null, null, null];
  return (val) => {
    output[0] = Math.floor(redInterpolator(val));
    output[1] = Math.floor(greenInterpolator(val));
    output[2] = Math.floor(blueInterpolator(val));
    output[3] = alphaInterpolator(val);
    return output;
  };
}

/**
 * @param {string} rgbaString
 * @returns {RGBA}
 *
 * @example
 * parseRGBA("rgba(1, 2, 3, 0.5)")
 * // -> [1, 2, 3, 0.5]
 *
 * parseRGBA("rgba(1,2 , 3   , .5)")
 * // -> [1, 2, 3, 0.5]
 */
export function parseRGBA(rgbaString) {
  const rgba = rgbaString
    .substring(5, rgbaString.length - 1)
    .split(',')
    .map((str) => {
      const float = parseFloat(str);
      if (float !== 0 && !float)
        throw new Error(`value you provided is not rgba: ${rgbaString}`);
      return float;
    });

  if (rgba.length !== 4) {
    throw new Error(`value you provided is not rgba: ${rgbaString}`);
  }

  return rgba;
}

/**
 * @param {string} h hex color value
 *
 * @example
 * hexToRGBA("#000")
 * // -> rgba(0, 0, 0, 1)
 *
 * hexToRGBA("#000000")
 * // -> rgba(0, 0, 0, 1)
 */
export function hexToRGBA(h) {
  let r = '',
    g = '',
    b = '';

  // 3 digits
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return 'rgba(' + +r + ',' + +g + ',' + +b + ', 1)';
}

export const rgbaEquals = (rgba1, rgba2) => {
  return (
    rgba1[0] === rgba2[0] &&
    rgba1[1] === rgba2[1] &&
    rgba1[2] === rgba2[2] &&
    rgba1[3] === rgba2[3]
  );
};
