// @ts-check
import { Item } from '@react-stately/collections';
import currencies from 'assets/currencies';
import Select from 'common/pickers/Select.component';
import { useLocale } from 'context/LanguageContext';
import * as React from 'react';

const COINS = [
  { coin: 'usdt' },
  { coin: 'trx' },
  { coin: 'ltc' },
  { coin: 'bch' },
  { coin: 'link' },
  { coin: 'eth' },
  { coin: 'btc' },
  { coin: 'xrp' },
  { coin: 'uni' },
  { coin: 'usdc' },
  { coin: 'xlm' },
  { coin: 'busd' },
  { coin: 'enj' },
  { coin: 'reef' },
  { coin: 'shib' },
  { coin: 'doge' },
  { coin: 'bnb' },
  { coin: 'btt' },
  { coin: 'ton' },
];

/**
 *
 *
 * @typedef Props
 * @property {boolean} [isInline] whether to use inline variant (as a companion to a field)
 * @property {string} [defaultText]
 * @property {React.ReactNode} [label]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {string} [placeholder]
 * @property {string | null} [selectedKey] // shorthand
 * @property {(key: React.Key) => void} [onSelectionChange]
 * @property {boolean} [autoFocus]
 * @property {( e : React.FocusEvent ) => void} [onFocus]
 * @property {( e : React.FocusEvent ) => void} [onBlur]
 * @property {( isFocused : boolean ) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyUp]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {React.MutableRefObject<HTMLElement>} [positionRef]
 *
 * @param {Props} props
 */
export default function PlainCurrencyPicker(props) {
  const { LL } = useLocale();
  return (
    <Select
      items={COINS}
      {...props}
    >
      {COINS.map((el) => (
        <Item
          key={el.coin}
          textValue={LL.currency[el.coin]()}
        >
          {LL.currency[el.coin]()}
          {currencies[el.coin] ? (
            <img
              src={currencies[el.coin]}
              alt=""
              data-slot="icon"
            />
          ) : (
            <React.Fragment data-slot="icon" />
          )}
        </Item>
      ))}
    </Select>
  );
}
