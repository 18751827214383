// @ts-check

/**
 * interface for objects like GridItem, but you know, more generalised
 * @typedef {object} Particle
 * @property {(eraseSize: number) => void} draw
 */

/**
 * @description
 * Keeps track of which elements need to be drawn and which are no longer animated.
 * */
export default class UpdateTracker {
  /** @type {Set<Particle>} */
  previousUpdates = new Set();
  /** @type {Set<Particle>} */
  currentUpdates = new Set();

  /** @param {Particle} particle */
  add(particle) {
    this.currentUpdates.add(particle);
  }

  /**
   * @param {number} eraseArea
   * @param {(particle)=> void} resetter
   */
  draw(eraseArea, resetter) {
    let { previousUpdates, currentUpdates } = this;

    currentUpdates.forEach((particle) => {
      particle.draw(eraseArea);
    });

    previousUpdates.forEach((particle) => {
      // this lookup should be O(1) based on what i read
      if (!currentUpdates.has(particle)) {
        resetter(particle);
      }
    });

    previousUpdates.clear();
    [this.previousUpdates, this.currentUpdates] = [
      currentUpdates,
      previousUpdates,
    ];
  }
}
