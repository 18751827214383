// @ts-check

import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import cx from 'classnames';
import Surface from 'common/surfaces/Surface.component';
import useDefaultRef from 'hooks/useDefaultRef';
import useThemeVariables from 'hooks/useThemeVariables';
import * as React from 'react';
import { Overlay, usePopover } from 'react-aria';
import { breakpoints } from 'styles';
import style from './Button.module.css';
import getoverlayClassNames from './styles/getOverlayClassNames';
import WithDismiss from './WithDissmiss.component';

/**
 * Popover is an overlay element that is positioned relative to
 * the trigger element. They are used to display transient content
 * such as menus, options, additional actions, and more.
 * @param {PopoverBaseProps} param0
 */
function PopoverBase(
  {
    children,
    smVariant = 'popover',
    containerPadding = 16,
    offset = 4,
    state,
    classNames = {},
    maxWidth = '60ch',
    ...props
  },
  parentRef
) {
  const popoverRef = useDefaultRef(parentRef);
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      containerPadding,
      offset,
      popoverRef,
    },
    state
  );

  const isLarge = useMediaQuery(`(min-width: ${breakpoints.minSmallTablet}px)`);
  const styles = getoverlayClassNames(isLarge ? 'popover' : smVariant);

  // Remove popover's positioning when on small screens.
  // Would be good to find a way that does not pierce through the abstraction
  // but don't want to write bounch of !importants either
  if (!isLarge && smVariant !== 'popover') {
    popoverProps.style = {};
  }

  const { isDarkTheme } = useThemeVariables();

  // // make the width of the overlay be greater of equal to the trigger ref.
  const minWidth = props.triggerRef.current?.getBoundingClientRect()?.width;

  return (
    <Overlay>
      {!props.isNonModal && (
        <div
          {...underlayProps}
          // Have to set theme again because modal is rendered outside the React entry
          className={cx(
            classNames.underlay,
            styles.underlay,
            isDarkTheme ? '--is-dark-theme' : '--is-light-theme'
          )}
        />
      )}
      <Surface
        {...popoverProps}
        baseElevation={1}
        elevationType={'both'}
        ref={popoverRef}
        className={cx(
          classNames.overlay,
          styles.overlay,
          isDarkTheme ? '--is-dark-theme' : '--is-light-theme'
        )}
        style={{
          ...popoverProps.style,
          // @ts-ignore
          '--min-width': minWidth && minWidth + 'px',
          '--max-width': maxWidth,
        }}
      >
        {props.isMultiSelect && (
          <button
            className={style.button}
            onClick={state.close}
          >
            OK
          </button>
        )}
        <WithDismiss
          placement="both"
          onDismiss={state.close}
        >
          {children}
        </WithDismiss>
      </Surface>
    </Overlay>
  );
}

/**
 * @typedef ClassNames
 * @property {string} [underlay]
 * @property {string} [overlay]
 */

/**
 * @typedef PopoverBaseProps
 *
 * @property {ClassNames} [classNames]
 *
 * @property {string | number | null} [maxWidth]
 * Maximum width of the container. If null the container will hug the content
 *
 * @property {React.RefObject<Element>} triggerRef
 * The ref for the element which the popover positions itself with respect to.
 *
 * @property {import("react-stately").OverlayTriggerState} state
 * State object, able to identify if the modal is open and holding
 * the methods to close and open it. Most definitely should
 * come from the react-stately's hook.
 *
 * @property {React.ReactNode} children
 * Content of the popover
 *
 * @property {"popover" | "modal" | "bottom sheet" | "full screen"} [smVariant="modal"]
 *
 * @property {boolean} [isNonModal=false]
 * Whether the popover is non-modal, i.e. elements outside the popover may be
 * interacted with by assistive technologies. Most popovers should not use
 * this option as it may negatively impact the screen reader experience.
 * Only use with components such as combobox, which are designed
 * to handle this situation carefully.
 *
 * @property {boolean} [isMultiSelect=false]
 *
 * @property {boolean} [isKeyboardDismissDisabled=false]
 * Whether pressing the escape key to close the popover should be disabled.
 * Most popovers should not use this option. When set to true,
 * an alternative way to close the popover with a keyboard must be provided.
 *
 * @property {Element} [boundaryElement=document.body]
 * Element that that serves as the positioning boundary.
 *
 * @property {React.RefObject<Element>} [scrollRef]
 * A ref for the scrollable region within the popover. If not provided, popover
 * container will assume to be scrollable.
 *
 * @property {boolean} [shouldUpdatePosition]
 * Whether the popover should update its position automatically.
 *
 * @property {number} [maxHeight]
 * The max height specified for the popover element. By default, it will take
 * all space up to the current viewport height. Note that this option wont
 * affect non-popover variants on smaller screens such as "bottom sheet",
 * "modal", or "full screen"
 *
 * @property {import("@react-types/overlays").Placement} [placement="bottom"]
 * The placement of the element with respect to its anchor element.
 * Note that this option wont affect non-popover variants on smaller
 * screens such as "bottom sheet", "modal", or "full screen"
 *
 * @property {number} [containerPadding=16]
 * The placement padding that should be applied between the element
 * and its surrounding container. Note that this option wont
 * affect non-popover variants on smaller screens such as "bottom sheet",
 * "modal", or "full screen"
 *
 * @property {number} [offset=0]
 * The additional offset applied along the main axis between the element
 * and its anchor element.  Note that this option wont affect non-popover
 * variants on smaller screens such as "bottom sheet", "modal", or "full screen"
 *
 * @property {number} [crossOffset=0]
 * The additional offset applied along the cross axis between the element
 * and its anchor element. Note that this option wont affect non-popover
 * variants on smaller screens such as "bottom sheet", "modal", or "full screen"
 *
 * @property {boolean} [shouldFlip=true]
 * Whether the element should flip its orientation (e.g. top to bottom or left to right)
 * when there is insufficient room for it to render completely. Note that this option wont
 * affect non-popover variants on smaller screens such as "bottom sheet",
 * "modal", or "full screen"
 */

export default React.forwardRef(PopoverBase);
