import React, { useState } from 'react';
import styles from './Inputs.module.css';

import CurrencyColors from 'assets/data/currency-colors';
import OutlinedButton from 'common/buttons/OutlinedButton';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import ExplorerCopy from 'components/copy-to-clipboard/ExplorerCopy.component';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import { Level } from 'react-accessible-headings';

// ripple, etherium, stellar
const eths = [
  'busd',
  'chz',
  'enj',
  'link',
  'mkr',
  'omg',
  'reef',
  'snx',
  'uni',
  'usdc',
  'usdt',
  'vet',
  'zrx',
  'eth',
  'trx',
  'bnb',
  'shib',
];

const Inputs = ({ data, coin, txid }) => {
  const [showAll, setShowAll] = useState(false);

  const { colorTest7 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest7,
  };

  const { LL } = useLocale();

  return (
    <Level>
      <TranslucentSurface
        elementType={'article'}
        className={styles.inputs}
        surfaceColor={CurrencyColors[coin]}
      >
        {txid.startsWith('PBXINTRA') ? (
          <>
            <div className={styles.inputs_user}>
              <span className={styles.inputs_user__address}>
                {LL.explorer.data.paybitxUser()}: {data.from}
                <ExplorerCopy
                  margin={true}
                  text={data.from}
                />
              </span>
            </div>
          </>
        ) : data.intra || eths.includes(coin) || coin === 'btt' ? (
          <div className={styles.inputs_user}>
            <span className={styles.inputs_user__address}>
              {data.from}
              <ExplorerCopy
                margin={true}
                text={data.from}
              />
            </span>
          </div>
        ) : (
          data.vin &&
          data.vin.slice(0, 5).map((vin, i) => (
            <div
              className={styles.inputs_user}
              key={i}
            >
              <span className={styles.inputs_user__address}>
                {vin.address}
                <ExplorerCopy
                  margin={true}
                  text={vin.address}
                />
              </span>
              <span className="bold">
                {vin.value} {coin}
              </span>
            </div>
          ))
        )}

        {showAll &&
          data.vin &&
          data.vin.slice(5).map((vin, i) => (
            <div
              className={styles.inputs_user}
              key={i}
            >
              <span className={styles.inputs_user__address}>
                {vin.address}
                <ExplorerCopy
                  margin={true}
                  text={vin.address}
                />
              </span>
              <span className="bold">
                {vin.value} {coin}
              </span>
            </div>
          ))}

        {data.vin && data.vin.length > 5 && (
          <OutlinedButton
            onPress={() => setShowAll((prevState) => !prevState)}
            // styles={{ backgroundColor: '#ff9800', width: '250px' }}
          >
            {showAll ? LL.explorer.showLess() : LL.explorer.showMore()}
          </OutlinedButton>
        )}
      </TranslucentSurface>
    </Level>
  );
};

export default Inputs;
