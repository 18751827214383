// @ts-check
import { Item } from '@react-stately/collections';
import currencies from 'assets/currencies';
import MultiSelect from 'common/pickers/MultiSelect.component';
import Currency from 'components/content/Currency.component';
import { useBalancesQuery } from 'queries/balances';
import * as React from 'react';

/**
 * @typedef {import("queries/balances").Balance} Balance
 *
 *
 * @typedef Props
 * @property {string} [defaultText]
 * @property {number} [maxCoinDisplay=2]
 * @property {React.ReactNode} [label]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {string[]} [selectedKeys] // shorthand
 * @property {(keys: Set<import("queries/balances").Balance["coin"]> | "all") => void} [onSelectionChange]
 * @property {boolean} [autoFocus]
 * @property {( e : React.FocusEvent ) => void} [onFocus]
 * @property {( e : React.FocusEvent ) => void} [onBlur]
 * @property {( isFocused : boolean ) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyUp]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {React.MutableRefObject<HTMLElement>} [positionRef]
 * @property {String[]} [resetState] Hack to reset the state of the component.
 *
 * @param {Props} props
 */
export default function MultiCurrencyPicker(props) {
  const balances = useBalancesQuery();

  if (balances.isError) {
    console.log('error', balances.error);
    // TODO: idk about this error message.
    return <>Problem trying to load Currencies. Please try reloading</>;
  }

  /** @param {Set<React.Key> | "all"} keys */
  const renderValue = (keys) => {
    if (keys === 'all') {
      return 'All currencies selected';
    }

    if (keys.size <= props.maxCoinDisplay) {
      return [...keys].join(', ');
    }

    return `${keys.values().next().value} and ${keys.size - 1} more`;
  };

  return (
    <MultiSelect
      renderValue={renderValue}
      isLoading={balances.isLoading}
      items={balances.data || []}
      resetState={props.resetState}
      {...props}
    >
      {(balance) => (
        <Item
          key={balance.coin}
          textValue={balance.coin_name}
        >
          {balance.coin_name}
          {currencies[balance.coin] ? (
            <img
              src={currencies[balance.coin]}
              alt=""
              data-slot="icon"
            />
          ) : (
            <React.Fragment data-slot="icon" />
          )}
          <Currency
            data-slot="description"
            currency={balance.coin}
          >
            {balance.confirmed_balance}
          </Currency>
        </Item>
      )}
    </MultiSelect>
  );
}
