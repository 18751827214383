// @ts-check
import Surface from 'common/surfaces/Surface.component';
import * as React from 'react';
import { useModalOverlay, Overlay } from 'react-aria';
import cx from 'classnames';
import useThemeVariables from 'hooks/useThemeVariables';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { breakpoints } from 'styles';
import getoverlayClassNames from './styles/getOverlayClassNames';

/**
 * Handles preventing page scrolling while the modal is open,
 * hiding content outside the modal from screen readers,
 * and optionally closing it when the user interacts outside or presses the Escape key.
 *
 * While this component comes with complete styling, it is not sufficient in creating
 * fully accessible dialogs. Modal should wrap the Dialog component (or similar overlays)
 * to create accessible dialogs with labeled title and the mechanism
 * to set focus back to an element that triggered the modal; on it's own Modal can't do that.
 *
 * Modals can also wrap collection elements like Menus and ListBoxes
 *
 * @param {ModalBaseProps} props
 */
export default function ModalBase({
  isDismissable,
  isKeyboardDismissDisabled,
  state,
  children,
  smVariant = 'modal',
  maxWidth = '65ch',
}) {
  const ref = React.useRef();

  let { modalProps, underlayProps } = useModalOverlay(
    { isDismissable, isKeyboardDismissDisabled },
    state,
    ref
  );

  const isLarge = useMediaQuery(`(min-width: ${breakpoints.minSmallTablet}px)`);
  const styles = getoverlayClassNames(isLarge ? 'modal' : smVariant);

  const { isDarkTheme } = useThemeVariables();

  return (
    <Overlay>
      <div
        className={cx(
          styles.underlay,
          isDarkTheme ? '--is-dark-theme' : '--is-light-theme'
        )}
        {...underlayProps}
      />
      <Surface
        baseElevation={1}
        elevationType="both"
        className={cx(
          styles.overlay,
          // Have to set theme again because modal is rendered outside the React entry
          isDarkTheme ? '--is-dark-theme' : '--is-light-theme'
        )}
        style={{
          // @ts-ignore
          '--max-width': maxWidth,
        }}
        ref={ref}
        {...modalProps}
      >
        {children}
      </Surface>
    </Overlay>
  );
}

/**
 * @typedef ModalBaseProps
 *
 * @property {boolean} [isDismissable]
 * Whether to close the modal when the user interacts outside it.
 *
 * @property {boolean} [isKeyboardDismissDisabled]
 * Whether pressing the escape key to close the modal should be disabled.
 *
 * @property {import("react-stately").OverlayTriggerState} state
 * State object, able to identify if the modal is open and holding
 * the methods to close and open it. Most definitely should
 * come from the react-stately's hook
 *
 * @property {React.ReactNode} children
 *
 * @property {string | number | null} [maxWidth]
 * Maximum width of the container. If null the container will hug the content
 *
 * @property {"bottom sheet" | "modal" | "full screen"} [smVariant="bottom sheet"]
 * Controls the style of modal on small screens.
 * "bottom sheet" will position the modal
 * to the bottom of the window, animating upwards.
 * The default - "modal" - will display similar variant as larger screens.
 */
