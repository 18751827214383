import FieldBase from 'common/fields/FieldBase';
import FieldIconButton from 'common/fields/FieldIconButton';
import FieldWrapper from 'common/fields/FieldWrapper';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useSearchField } from 'react-aria';
import { RiCloseLine, RiSearchLine } from 'react-icons/ri';
import { useSearchFieldState } from 'react-stately';
import styles from './DefaultField.module.css';

/** @param {SearchFieldProps} props */
export default function SearchField(props) {
  let state = useSearchFieldState(props);
  let ref = React.useRef(null);
  let {
    labelProps,
    inputProps,
    clearButtonProps,
    descriptionProps,
    errorMessageProps,
  } = useSearchField(props, state, ref);

  return (
    <FieldWrapper
      classNames={styles}
      validationState={props.validationState}
      isDisabled={props.isDisabled}
      leftButton={
        <FieldIconButton
          excludeFromTabOrder
          aria-label="Search"
          onPress={() => props.onSubmit?.(state.value)}
          isDisabled={props.isDisabled}
        >
          <RiSearchLine />
        </FieldIconButton>
      }
      rightButton={
        <motion.span
          animate={{
            // visibility: state.value ? 'visible' : 'hidden',
            opacity: state.value ? 1 : 0,
          }}
        >
          <FieldIconButton
            {...clearButtonProps}
            elementType="button"
            onPressStart={(e) => {
              if (!state.value) {
                ref.current?.focus();
              }
              clearButtonProps.onPressStart(e);
            }}
            aria-label={clearButtonProps['aria-label'] || 'Clear'}
            isDisabled={props.isDisabled}
            // TODO: change button cursor when invisible
          >
            <RiCloseLine />
          </FieldIconButton>
        </motion.span>
      }
    >
      <FieldBase
        ref={ref}
        classNames={styles}
        inputElementType="input"
        validationState={props.validationState}
        isDisabled={props.isDisabled}
        label={props.label}
        description={props.description}
        errorMessage={props.errorMessage}
        labelProps={labelProps}
        inputProps={inputProps}
        descriptionProps={descriptionProps}
        errorMessageProps={errorMessageProps}
      />
    </FieldWrapper>
  );
}

/**
 * @typedef SearchFieldProps
 * @property {( value : string) => void} [onSubmit]
 * @property {( ) => void} [onClear]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isReadOnly]
 * @property {"valid" | "invalid" | null} [validationState]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {boolean} [autoFocus]
 * @property {( e : React.FocusEvent) => void} [onFocus]
 * @property {( e : React.FocusEvent) => void} [onBlur]
 * @property {( isFocused : boolean) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent) => void} [onKeyUp]
 * @property {string} [placeholder]
 * @property {string} [value]
 * @property {string} [defaultValue]
 * @property {(  value : string )  => void} [onChange]
 * @property {React.ReactNode} [label]
 * @property {string} [aria-activedescendant]
 * @property {'none' | 'inline' | 'list' | 'both'} [aria-autocomplete]
 * @property {boolean | 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'} [aria-haspopup]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {string} [id]
 * @property {string} [autoComplete]
 * @property {number} [maxLength]
 * @property {number} [minLength]
 * @property {string} [name]
 * @property {string} [pattern]
 * @property {'text' | 'search' | 'url' | 'tel' | 'email' | 'password' } [type]
 * @property {'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'} [inputMode]
 * @property {React.ClipboardEventHandler < HTMLInputElement >} [onCopy]
 * @property {React.ClipboardEventHandler < HTMLInputElement >} [onCut]
 * @property {React.ClipboardEventHandler < HTMLInputElement >} [onPaste]
 * @property {React.CompositionEventHandler < HTMLInputElement >} [onCompositionStart]
 * @property {React.CompositionEventHandler < HTMLInputElement >} [onCompositionEnd]
 * @property {React.CompositionEventHandler < HTMLInputElement >} [onCompositionUpdate]
 * @property {React.ReactEventHandler < HTMLInputElement >} [onSelect]
 * @property {React.FormEventHandler < HTMLInputElement >} [onBeforeInput]
 * @property {React.FormEventHandler < HTMLInputElement >} [onInput]
 * @property {string} [aria-errormessage]
 */
