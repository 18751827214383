import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';

import FilledButton from 'common/buttons/FilledButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer } from 'components/content/InlineError';
import { useLocale } from 'context/LanguageContext';
import { useCountdown } from 'hooks/useCountdown';
import useThemeVariables from 'hooks/useThemeVariables';
import { H } from 'react-accessible-headings';
import { check2faToken } from '../../../redux/auth/auth.actions';
import styles from './2fa-form.module.css';

const TfaForm = ({ check2faToken, accountData, show2faError }) => {
  const { LL } = useLocale();
  const navigate = useNavigate();
  const countDown = useCountdown(60000);
  const [tfaToken, setTfaToken] = useState('');
  const { colorTest4 } = useThemeVariables();
  const surfaceColor = {
    background: colorTest4,
  };
  if (countDown <= 0) {
    navigate('/');
    navigate(0);
  }
  if (localStorage.getItem('token')) {
    return <Navigate to="/overview" />;
  }
  console.log(accountData);
  return (
    <Surface
      surfaceColor={surfaceColor}
      className={styles.container}
    >
      {show2faError && (
        <ErrorContainer>
          <div
            style={{
              color: 'var(--md-ref-palette-error-50)',
              fontWeight: '600',
            }}
          >
            Invalid Token
          </div>
        </ErrorContainer>
      )}
      <H className={styles.heading}>{LL.security['2faHeader']()}</H>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          check2faToken({
            email: accountData.email,
            password: accountData.password,
            token: tfaToken,
          });
        }}
        className={styles.form}
      >
        <div className={styles.counter}>
          {LL.security.timeRemaining()}:{' '}
          <span style={{ color: 'var(--color-logo)' }}>{countDown}</span>
        </div>
        <TextField
          label={LL.security['2faToken']()}
          placeholder={LL.security['token']()}
          value={tfaToken}
          onChange={(e) => setTfaToken(e)}
        />

        <FilledButton type="submit">{LL.security.verify()}</FilledButton>
      </form>
    </Surface>
  );
};

const mapDispatchToProps = (dispatch) => ({
  check2faToken: (tokens) => dispatch(check2faToken(tokens)),
});

const mapStateToProps = (state) => ({
  accountData: state.auth.data,
  show2faError: state.auth.show2faError,
});

export default connect(mapStateToProps, mapDispatchToProps)(TfaForm);
