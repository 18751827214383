// @ts-check

import * as React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import ModalBase from 'common/overlays/base/ModalBase.component';

/**
 * @typedef {Omit<import('common/overlays/base/ModalBase.component').ModalBaseProps, "children" | "state">} NoiceModalConfig
 */

/**
 * It's Noice
 * {@link https://www.youtube.com/watch?v=h3uBr0CCm58}
 *
 * @example
 * const MyModal = NoiceModal.create(() => <Dialog title="Hello"/>)
 * NoiceModal.register("my-modal", MyModal);
 * // somewhere throughout the existance of application:
 * NoiceModal.show("my-modal");
 *
 */
const NoiceModal = {
  ...NiceModal,
  /**
   * Wrapper around `NiceModal.create` for those times when you want to
   * trigger the dialog programmatically (e.g after asyn event) instead of triggering it
   * after a click (note: in that case you should use `ModalTrigger`).
   * What this wrapper achieves is that it takes away the boilerplate of
   * wrapping provided component in a `ModalBase` and hooks up our ModalBase API to `NiceModal`'s.
   *
   * @see {@link https://github.com/eBay/nice-modal-react}
   *
   * @template {{}} P
   * @param {(props: P) => React.ReactElement} DialogComponent
   * @param {NoiceModalConfig} [config]
   * @returns {(props: NoiceModalConfig & P) => React.ReactElement}
   **/
  create(DialogComponent, config = {}) {
    return NiceModal.create((props) => {
      const modal = useModal();

      const state = {
        isOpen: modal.visible,
        setOpen: (isOpen) => (isOpen ? modal.hide() : modal.show()),
        open: modal.show,
        close: modal.hide,
        toggle: () => (modal.visible ? modal.hide() : modal.show()),
      };

      const mergeConfig = configMerger(config, props);

      return (
        state.isOpen && (
          <ModalBase
            {...mergeConfig([
              'isDismissable',
              'isKeyboardDismissDisabled',
              'maxWidth',
              'smVariant',
            ])}
            state={state}
          >
            <DialogComponent {...props} />
          </ModalBase>
        )
      );
    });
  },

  /**
   * Does exactly what NiceModal.show does but types are overridden to provide type safety
   * @template P
   * @param {(props: P) => React.ReactElement} DialogComponent
   * @param {P} props
   */
  show(DialogComponent, props) {
    return NiceModal.show(DialogComponent, props);
  },
};

export default NoiceModal;

/**
 * @template A, B
 * @param {A} defaultConfig
 * @param {B} overridableConfig
 * @returns {<Key extends keyof (A | B)>(keys: Key[]) =>  {[index: Key]: A[Key] | B[Key] } }
 */
const configMerger = (defaultConfig, overridableConfig) => (keys) => {
  /** @type {any} */
  let mergedObj = {};
  for (const key of keys) {
    mergedObj[key] = overridableConfig[key] || defaultConfig[key];
  }
  return mergedObj;
};
