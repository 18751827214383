// @ts-check

import currencies from 'assets/currencies';
import cx from 'classnames';
import ConversionCurrency from 'components/content/ConversionCurrency.component';
import { useRatesQuery } from 'queries/rates';
import React from 'react';
import { H, Level } from 'react-accessible-headings';
import styles from './LandingShowcase.module.css';

import CurrencyColors from 'assets/data/currency-colors';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useLocale } from 'context/LanguageContext';

/**
 * @typedef LandingShowcaseProps
 * @property {"sm" | "md" | "lg"} size
 * @property {import('currencies').Currency} currency
 *
 */
export default function LandingShowcase({ size = 'md', currency }) {
  const { LL } = useLocale();
  const { conversionCurrency } = useCurrencyAndThemeContext();
  const minMd = ['md', 'lg'].includes(size);
  const isLg = size === 'lg';
  const isMd = size === 'md';
  const isSm = size === 'sm';

  const HeadingElement = isSm ? 'strong' : H;
  const containerElement = isSm ? 'div' : 'article';
  const { data: rates } = useRatesQuery();

  return (
    <Level>
      <TranslucentSurface
        elementType={containerElement}
        className={cx(styles.container, {
          '--sizeSmall': isSm,
          '--sizeMedium': isMd,
          '--sizeLarge': isLg,
        })}
        surfaceColor={CurrencyColors[currency]}
      >
        <img
          className={styles.icon}
          src={currencies[currency]}
          alt=""
        />

        <HeadingElement
          className={cx(
            styles.currencyName,
            isSm ? 'type-title-medium' : 'type-title-large'
          )}
        >
          {LL.currency[currency]()}
        </HeadingElement>

        {rates && minMd && (
          <p className={styles.rate}>
            <span>
              {currency.toUpperCase()}/{conversionCurrency.toUpperCase()}:{' '}
            </span>
            <strong>
              <ConversionCurrency currency={currency}>{1}</ConversionCurrency>
            </strong>
          </p>
        )}
      </TranslucentSurface>
    </Level>
  );
}
