// @ts-check
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {object} City
 * @property {number} City.id
 * @property {number} City.country_id
 * @property {number} City.state_id
 * @property {string} City.name
 *
 * @typedef GetCity
 * @property {string} userCity
 */

/**
 * @returns {Promise<City>}
 */

/** @param {GetCity} userCity */
export async function getCity(userCity) {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URI}locations/cities/${userCity}`
  );

  return data;
}

/**
 * @typedef useCityQuery
 * @property {string} userCity
 * @param {{enabled?: boolean, retry?: boolean, onSuccess?: any, onError?: any}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<City, Error>}
 **/

export function useCityQuery(userCity, options) {
  return useQuery(['city', userCity], () => getCity(userCity), {
    ...options,
  });
}
