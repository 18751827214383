// @ts-check
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {Array.<Object>} Countries
 * @property {Object} country
 * @property {number} country.id
 * @property {string} country.name
 */

/**
 * @returns {Promise<Countries>}
 */

export async function getCountries() {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URI}locations/countries`
  );

  return data;
}

/**
 * @param {{enabled: boolean}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<Countries, Error>}
 **/
export function useCountriesQuery(options) {
  return useQuery(['countries'], getCountries, {
    staleTime: 30_0000,
    ...options,
  });
}
