import { FETCH_INVOICE_START } from '../invoice/invoice.types';
import {
  FETCH_NETWORKS_START,
  FETCH_NETWORKS_SUCCESS,
  FETCH_NETWORKS_FAIL,
} from './network.types';

const INIT_STATE = {
  networks: {},
  loadingNetworks: false,
  networksError: null,
};

const networkReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_NETWORKS_START:
      return {
        ...state,
        loadingNetworks: true,
        networksError: null,
      };
    case FETCH_NETWORKS_SUCCESS:
      return {
        ...state,
        networks: payload,
        loadingNetworks: false,
        networksError: null,
      };
    case FETCH_NETWORKS_FAIL:
      return {
        ...state,
        loadingNetworks: false,
        networksError: payload,
      };
    default:
      return state;
  }
};

export default networkReducer;
