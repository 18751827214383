import {
  CHECK_2FA_TOKEN,
  CHECK_2FA_TOKEN_FAIL,
  CHECK_PHONE_VERIFICATION,
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOG_OUT,
  SHOW_2FA_FORM,
} from './auth.types';

export const loginStart = (data) => {
  return { type: LOGIN_START, payload: data };
};

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

export const loginFail = (data) => ({
  type: LOGIN_FAIL,
  payload: data,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const show2faForm = (data) => ({
  type: SHOW_2FA_FORM,
  payload: data,
});

export const check2faToken = (token) => ({
  type: CHECK_2FA_TOKEN,
  payload: token,
});

export const check2faTokenFail = () => ({
  type: CHECK_2FA_TOKEN_FAIL,
});

export const checkPhoneVerification = (data) => ({
  type: CHECK_PHONE_VERIFICATION,
  payload: data,
});
