// @ts-check
import * as React from 'react';
import SelectBase from '../pickers/SelectBase.component';
import styles from './DefaultFieldSelect.module.css';

/**

 *
 * */

/**
 * @template {{}} T
 * @param {Omit<React.ComponentProps<typeof SelectBase<T>>, "classNames" |"errorMessage">} props
 */
function FieldSelect(props) {
  return (
    <SelectBase
      {...props}
      defaultText={props.defaultText || '_'}
      classNames={styles}
    />
  );
}

export default FieldSelect;
