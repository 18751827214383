import ConversionCurrencyPicker from 'components/currency-fields/ConversionCurrencyPicker.component';
import MobilePageOptions from 'components/page-options/MobilePageOptions';
import MobileThemeToggler from 'components/theme-toggler/MobileThemeToggler';
import { useLocale } from 'context/LanguageContext';
import { useUserCredentialsQuery } from 'queries/userCredentials';
import React, { useRef, useState } from 'react';
import { IoStorefrontOutline } from 'react-icons/io5';
import {
  RiAccountCircleLine,
  RiLogoutCircleLine,
  RiProfileLine,
  RiShieldUserLine,
} from 'react-icons/ri';
import { TbGridDots } from 'react-icons/tb';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { logOut } from '../../redux/auth/auth.actions';
import styles from './NewHeader.module.css';

const NewHeader = ({ logOut, darkMode, className, setMenu }) => {
  const { LL } = useLocale();

  const [visible, setVisible] = useState(false);
  let navigate = useNavigate();
  const dropdownRef = useRef();
  const dropdownRefMobile = useRef();
  useOnClickOutside(dropdownRef, () => setVisible(false));
  useOnClickOutside(dropdownRefMobile, () => setVisible(false));

  const { isLoading, data: userCreds } = useUserCredentialsQuery();
  return (
    <div className={className}>
      <div className={styles.additionalWrapper}>
        <div className={styles.headerAdditional}>
          <div
            className={styles.headerSettings}
            ref={dropdownRef}
          >
            <TbGridDots
              className={styles.headerIcon}
              onClick={() => setVisible(!visible)}
              size="2.7rem"
            />
            {visible && (
              <div className={styles.settingsOptions}>
                {!isLoading && userCreds && (
                  <div className={styles.userInfo}>
                    <RiAccountCircleLine
                      className={styles.textIcon}
                      size="2.5rem"
                    />
                    <span> {userCreds.email} &nbsp;</span>
                  </div>
                )}

                <div className={styles.headerAdditional}>
                  {userCreds && (
                    <Link
                      to={'/profile'}
                      className={styles.headerNav}
                    >
                      <RiProfileLine className={styles.textIcon} />
                      {LL.settings.profile()}
                    </Link>
                  )}
                  <MobileThemeToggler darkMode={darkMode} />
                  <MobilePageOptions />
                  {userCreds && (
                    <ConversionCurrencyPicker
                      currency={userCreds.functionalCurrency}
                    />
                  )}
                  {/* <span>
                      <RiApps2Line className={styles.textIcon} />
                      <FormattedMessage
                        id="settings.general"
                        defaultMessage="General"
                      />
                    </span>
                    {userCreds.isAdmin && (
                      <span onClick={() => navigate('/general-balance')}>
                        <RiCurrencyLine className={styles.textIcon} />
                        General Balance
                      </span>
                    )} */}
                  {userCreds && (
                    <Link
                      to={'/security'}
                      className={styles.headerNav}
                    >
                      <RiShieldUserLine className={styles.textIcon} />
                      {LL.settings.security()}
                    </Link>
                  )}
                  {userCreds && (
                    <span onClick={() => logOut()}>
                      <RiLogoutCircleLine className={styles.textIcon} />
                      {LL.settings.signout()}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.additionalWrapperMobile}>
        {!isLoading && userCreds && (
          <div className={styles.userInfoMobile}>
            <RiAccountCircleLine className={styles.textIcon} />
            <span> {userCreds.email} &nbsp;</span>
          </div>
        )}

        <div className={styles.headerAdditionalMobile}>
          {userCreds && (
            <span>
              <Link
                to={'/profile'}
                onClick={() => setMenu(2)}
                className={styles.headerNav}
              >
                <RiProfileLine className={styles.textIcon} />
                {LL.settings.profile()}
              </Link>
            </span>
          )}
          {userCreds && (
            <span>
              <Link
                to={'/markets'}
                onClick={() => setMenu(2)}
                className={styles.headerNav}
              >
                <IoStorefrontOutline className={styles.textIcon} />
                {LL.settings.markets()}
              </Link>
            </span>
          )}
          <MobileThemeToggler darkMode={darkMode} />
          <MobilePageOptions />
          {userCreds && (
            <ConversionCurrencyPicker currency={userCreds.functionalCurrency} />
          )}
          {/* <span>
              <RiApps2Line className={styles.textIcon} />
              <FormattedMessage
                id="settings.general"
                defaultMessage="General"
              />
            </span>
            {userCreds.isAdmin && (
              <span
                onClick={() => {
                  navigate('/general-balance');
                  setMenu(2);
                }}
              >
                <RiCurrencyLine className={styles.textIcon} />
                General Balance
              </span>
            )} */}
          {userCreds && (
            <span>
              <Link
                to={'/security'}
                onClick={() => setMenu(2)}
                className={styles.headerNav}
              >
                <RiShieldUserLine className={styles.textIcon} />
                {LL.settings.security()}
              </Link>
            </span>
          )}
          {userCreds && (
            <span onClick={() => logOut()}>
              <RiLogoutCircleLine className={styles.textIcon} />
              {LL.settings.signout()}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
});

const mapStateToProps = (state) => ({
  darkMode: state.theme.dark,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);
