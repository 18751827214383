import * as React from 'react';
import { useComboBox, useFilter } from 'react-aria';
import { useComboBoxState } from 'react-stately';
// Reuse the ListBox, Popover, and Button from your component library. See below for details.
import FieldBase from 'common/fields/FieldBase';
import FieldIconButton from 'common/fields/FieldIconButton';
import FieldWrapper from 'common/fields/FieldWrapper';
import ListBox from 'common/listoboxes/ListBox.component';
import PopoverBase from 'common/overlays/base/PopoverBase.component';
import selectBaseStyles from 'common/pickers/SelectBase.module.css';
import { useExplorerContext } from 'context/ExplorerContext';
import { RiArrowDownSLine } from 'react-icons/ri';

/**
 * @template {{}} T
 * @param {ComboboxProps<T>} props
 **/
export default function ComboBoxBase({ classNames, ...props }) {
  // Setup filter function and state.
  let { contains } = useFilter({ sensitivity: 'base' });
  let state = useComboBoxState({
    ...props,
    defaultFilter: !props.noFilter && contains,
  });
  const { isMobileScreen } = useExplorerContext();

  // Setup refs and get props for child elements.
  let buttonRef = React.useRef(null);
  let inputRef = React.useRef(null);
  let listBoxRef = React.useRef(null);
  let popoverRef = React.useRef(null);

  let {
    buttonProps,
    inputProps,
    listBoxProps,
    labelProps,
    descriptionProps,
    errorMessageProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    },
    state
  );

  return (
    <div className={classNames.container}>
      <FieldWrapper
        validationState={props.validationState}
        classNames={classNames}
        isDisabled={props.isDisabled}
        rightButton={
          <FieldIconButton
            aria-label="" // no need because buttonProps excludes button from tab order
            ref={buttonRef}
            {...buttonProps}
          >
            <RiArrowDownSLine />
          </FieldIconButton>
        }
      >
        <FieldBase
          ref={inputRef}
          classNames={classNames}
          validationState={props.validationState}
          isDisabled={props.isDisabled}
          label={props.label}
          description={props.description}
          errorMessage={props.errorMessage}
          labelProps={labelProps}
          inputProps={inputProps}
          descriptionProps={descriptionProps}
          errorMessageProps={errorMessageProps}
        />
      </FieldWrapper>
      {state.isOpen && (
        <PopoverBase
          state={state}
          triggerRef={inputRef}
          ref={popoverRef}
          isNonModal // input should maintain focus while the popover is open
          maxHeight={isMobileScreen && 150}
          shouldFlip={false}
          placement="bottom start"
          smVariant="popover"
        >
          <ListBox
            {...listBoxProps}
            ref={listBoxRef}
            state={state}
            classNames={{ listbox: selectBaseStyles.padding }}
          />
        </PopoverBase>
      )}
    </div>
  );
}

/**
 * @template {{}} T
 * @typedef {ComboboxLocalProps<T> & import('types').AriaLabelingProps} ComboboxProps
 */

/**
 * @template {{}} T
 * @typedef ComboboxLocalProps
 *
 * @property {ClassNames} [classNames]
 *
 * @property {import('common/pickers/SelectBase.component').CollectionChildren<T>} children
 *
 * @property {boolean} [shouldFocusWrap]
 * Whether keyboard navigation in popover is circular.
 *
 * @property {Iterable<T>} [defaultItems]
 * The list of ComboBox items (uncontrolled).
 *
 * @property {Iterable<T>} [items]
 * The list of ComboBox items (controlled).
 *
 * @property {(isOpen: boolean, menuTrigger?: "focus" | "input" | "manual") => void} [onOpenChange]
 * Method that is called when the open state of the menu changes.
 * Returns the new open state and the action that caused the opening of the menu.
 *
 * @property {string} [inputValue]
 * The value of the ComboBox input (controlled).
 *
 * @property {string} [defaultInputValue]
 * The value of the ComboBox input (uncontrolled).
 *
 * @property {(value : string ) => void} [onInputChange]
 * Handler that is called when the ComboBox input value changes.
 *
 * @property {boolean} [allowsCustomValue]
 * Whether the ComboBox allows a non-item matching input value to be set.
 *
 * @property {"focus" | "input" | "manual"} [menuTrigger]
 * The interaction required to display the ComboBox menu.
 *
 * @property {string} [name]
 * The name of the input element, used when submitting an HTML form.
 *
 * @property {Iterable<React.Key>} [disabledKeys]
 * The item keys that are disabled. These items cannot be selected,
 * focused, or otherwise interacted with.
 *
 * @property {React.Key | null} [selectedKey]
 * The currently selected key in the collection (controlled).
 *
 * @property {React.Key} [defaultSelectedKey]
 * The currently selected key in the collection (uncontrolled).
 *
 * @property {(key : React.Key) => any} [onSelectionChange]
 * Handler that is called when the selection changes.
 *
 * @property {boolean} [isDisabled]
 * Whether the input is disabled.
 *
 * @property {boolean} [isReadOnly]
 * Whether the input can be selected but not changed by the user.
 *
 * @property {string} [placeholder]
 * Temporary text that occupies the text input when it is empty.
 *
 * @property {boolean} [noFilter]
 * Disable combobox default filter.
 *
 * @property {"valid" | "invalid" | null} [validationState]
 * Wheter the input should display its "valid" or "invalid" styling
 *
 * @property {boolean} [autoFocus]
 * Whether the element should receive focus on render.
 *
 * @property {React.ReactNode} [label]
 *
 * @property {React.ReactNode} [description]
 *
 * @property {React.ReactNode} [errorMessage]
 *
 * @property {(e : React.FocusEvent) => void} [onFocus]
 * @property {(e : React.FocusEvent) => void} [onBlur]
 * @property {(isFocused : boolean) => void} [onFocusChange]
 * @property {(e : React.KeyboardEvent) => void} [onKeyDown]
 * @property {(e : React.KeyboardEvent) => void} [onKeyUp]
 */

/**
 * @typedef ClassNames
 * = Local classNames ==========================
 * @property {string} [container]
 * = FieldWrapper classNames ===================
 * @property {string} [fieldWrapper]
 * @property {string} [fieldWrapperLeft]
 * @property {string} [leftButton]
 * @property {string} [leftDecoration]
 * @property {string} [fieldWrapperRight]
 * @property {string} [rightDecoration]
 * @property {string} [rightValidation]
 * @property {string} [rightButton]
 * = FieldBase classNames ======================
 * @property {string | string[]} [description]
 * @property {string | string[]} [errorMessage]
 * @property {string | string[]} [label]
 * @property {string | string[]} [input]
 */
