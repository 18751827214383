import { takeLatest, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import { fetchNetworksSuccess, fetchNetworksFail } from './network.actions';
import { FETCH_NETWORKS_START } from './network.types';

export function* fetchNetworksAsync({ payload }) {
  let baseUrl = `${process.env.REACT_APP_API_URI}networks`;
  if (payload) baseUrl = `${process.env.REACT_APP_API_URI}networks/${payload}`;

  try {
    const { data } = yield axios({
      method: 'get',
      url: baseUrl,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });


    yield put(fetchNetworksSuccess(data));
  } catch (err) {
    yield put(fetchNetworksFail(err));
  }
}

export function* fetchNetworks() {
  yield takeLatest(FETCH_NETWORKS_START, fetchNetworksAsync);
}

export function* networksSagas() {
  yield all([call(fetchNetworks)]);
}
