// @ts-check

import * as React from 'react';
import { motion } from 'framer-motion';

const ElevationContext = React.createContext(0);

/**
 * @param {object} props
 * @param {number} [props.elevation]
 * @param {any} props.children
 */
export function ElevationProvider({ elevation, children }) {
  return (
    <ElevationContext.Provider value={elevation}>
      {children}
    </ElevationContext.Provider>
  );
}

export function useElevation() {
  return React.useContext(ElevationContext);
}
