// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef PayableInvoice
 * @property {number} id
 * @property {import('currencies').Currency} currency
 * @property {string[]} p2p_tx_ids
 * @property {{ btc: string }} amount_paid
 * @property {string} email
 * @property {string} tag
 * @property {number} amount_invoiced
 * @property {number} amount_invoiced_fiat
 * @property {number} total_paid
 * @property {number} total_paid_fiat
 * @property {Date} valid_till
 * @property {boolean} is_paid
 * @property {number} user_id
 * @property {Date} createdAt
 * @property {Date} updatedAt
 */

/**
 * @typedef Payload
 * @property {number} pages
 * @property {number} pages
 * @property {number} limit
 * @property {PayableInvoice[]} invoices
 */

/**
 * @returns {Promise<Payload>}
 */
export async function getPayableInvoices() {
  const { data } = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URI}invoices/payable-invoices`,
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });

  return data;
}

/**
 * @param {{enabled: boolean}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<Payload, Error>}
 **/
export function usePayableInvoicesQuery(options) {
  return useQuery(['payableInvoices'], getPayableInvoices, {
    ...options,
    staleTime: Infinity,
  });
}
