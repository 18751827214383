import currencies from 'assets/currencies';
import CurrencyColors from 'assets/data/currency-colors';
import cx from 'classnames';
import IconButton from 'common/buttons/IconButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import ConversionCurrency from 'components/content/ConversionCurrency.component';
import Currency from 'components/content/Currency.component';
import { useExchangeContext } from 'context/ExchangeContext';
import { useLocale } from 'context/LanguageContext';
import { useCurrencyNetworksQuery } from 'queries/currencyNetworks';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import {
  RiArrowDownCircleLine,
  RiArrowUpCircleLine,
  RiSwapLine,
} from 'react-icons/ri';
import { useOutletContext } from 'react-router-dom';
import styles from './Wallet.module.css';

/**
 * @param {object} props
 * @param {"sm" | "md" | "lg"} props.size
 * @param {import('currencies').Currency} props.currency
 * @param {number} props.depositedAmount
 * @param {number} props.unconfirmedDeposit
 * @param {number} props.unconfirmedWithdraw
 */
export default function Wallet({
  size,
  currency: coin,
  depositedAmount,
  unconfirmedDeposit,
  unconfirmedWithdraw,
}) {
  const { LL } = useLocale();

  const minMd = ['md', 'lg'].includes(size);
  const isLg = size === 'lg';
  const isMd = size === 'md';
  const isSm = size === 'sm';

  // TODO: watterfall
  const { data: networks } = useCurrencyNetworksQuery();

  const { navigate } = useExchangeContext();
  const [, setOpenQuickExchange] = useOutletContext();

  // TODO: Remove try catch
  try {
    // why the long check:
    // * `networks` can be `null | CurrencyNetworks[]`
    // * `networks[currency]` is always `Network[]` but sometimes the length is 0
    // * `networks[currency][0]` can be undefined or `Network`
    // * if none of these values are nullish, network will be a string representing the network's name,
    //   otherwise null (because p2p, send, and receive exchanges accept null as a valid value for the network field)
    const network =
      (networks && networks[coin][0] && networks[coin][0].network_name) || null;

    const HeadingElement = isSm ? 'strong' : H;
    const containerElement = isSm ? 'div' : 'article';

    return (
      <Level>
        <TranslucentSurface
          elementType={containerElement}
          className={cx(styles.container, {
            '--sizeSmall': isSm,
            '--sizeMedium': isMd,
            '--sizeLarge': isLg,
          })}
          surfaceColor={CurrencyColors[coin]}
        >
          <HeadingElement className={styles.currencyName}>
            {LL.currency[coin]()}
          </HeadingElement>
          <img
            className={styles.icon}
            src={currencies[coin]}
            alt=""
          />

          <p className={styles.amount}>
            <div>
              <strong>
                <Currency currency={coin}>{depositedAmount}</Currency>
              </strong>
              {minMd && (
                <ConversionCurrency currency={coin}>
                  <span> = </span>
                  {depositedAmount}
                </ConversionCurrency>
              )}
            </div>
            <div className={styles.pending}>
              {(unconfirmedDeposit >= 0.00001 ||
                unconfirmedWithdraw >= 0.00001) &&
                `Pending Dep/(Wd)
              ${
                unconfirmedDeposit >= 0.00001
                  ? unconfirmedDeposit.toFixed(5)
                  : ''
              } (${
                  unconfirmedWithdraw >= 0.00001
                    ? '-' + unconfirmedWithdraw.toFixed(5)
                    : ''
                })`}
            </div>
          </p>

          {minMd && (
            <div className={styles.actions}>
              <Button
                label={LL.app.send()}
                onPress={async () => {
                  await setOpenQuickExchange(true);
                  coin === 'xlm' || coin === 'xrp' || coin === 'ton'
                    ? navigate('send', { coin, network, xrpOrXlm: true })
                    : navigate('send', {
                        coin,
                        network,
                      });
                }}
                icon={<RiArrowUpCircleLine />}
                isLg={isLg}
              />
              <Button
                label={LL.app.deposit()}
                onPress={async () => {
                  await setOpenQuickExchange(true);
                  navigate('receive', {
                    coin,
                    network,
                  });
                }}
                icon={<RiArrowDownCircleLine />}
                isLg={isLg}
              />

              <OutlineButton
                onPress={async () => {
                  await setOpenQuickExchange(true);
                  navigate('p2p', {
                    coin,
                    network,
                  });
                }}
              >
                P2P
              </OutlineButton>
              <Button
                label={LL.app.swap()}
                onPress={async () => {
                  await setOpenQuickExchange(true);
                  navigate('swap', {
                    from: coin,
                  });
                }}
                icon={<RiSwapLine />}
                isLg={isLg}
              />
            </div>
          )}
        </TranslucentSurface>
      </Level>
    );
  } catch (error) {
    console.log(error);
  }
}

function Button({ label, onPress, icon, isLg }) {
  return isLg ? (
    <OutlineButton onPress={onPress}>{label}</OutlineButton>
  ) : (
    <IconButton
      aria-label={label}
      onPress={onPress}
    >
      {icon}
    </IconButton>
  );
}
