import {
  FETCH_TOTAL_WALLET_BALANCE_SUCCESS,
  FETCH_TOTAL_PBX_BALANCE_SUCCESS,
} from './admin.types';

const INIT_VALUE = {
  totalWalletBalance: null,
  totalPbxBalance: null,
};

const adminReducer = (state = INIT_VALUE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TOTAL_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        totalWalletBalance: payload,
      };
    case FETCH_TOTAL_PBX_BALANCE_SUCCESS:
      return {
        ...state,
        totalPbxBalance: payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
