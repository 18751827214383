// @ts-check
import big from 'bigjs-literal/macro';
import ConversionCurrency from 'components/content/ConversionCurrency.component';
import Currency from 'components/content/Currency.component';
import useFee from 'hooks/useFee';
import { useExchangeRateQuery } from 'queries/exchangeRate';
import * as React from 'react';
import styles from './FeeInfo.module.css';

/**
 * @template {"transfer" | "exchange" | "p2p"} T
 * @typedef FeeInfoProps
 * @property {import("hooks/useFee").FeeType<T>} feeType
 * @property {import("hooks/useFee").Payload<T>} payload
 * @property {string} currency
 * @property {number} amount
 */

/**
 * @template {"transfer" | "exchange" | "p2p"} T
 * @param {FeeInfoProps<T>} props
 */
function FeeInfo({ currency, feeType, payload, amount }) {
  const { fee, addFee, subFee } = useFee(feeType, payload);
  const { data: exchangeRate } = useExchangeRateQuery(
    { from: payload.from, to: payload.to },
    { enabled: feeType === 'exchange' && !!payload.from && !!payload.to }
  );
  const loaded = fee !== null && fee !== undefined;
  return (
    <dl className={styles.container}>
      <dt>Amount&nbsp;ex.&nbsp;fee</dt>
      {loaded && feeType === 'exchange' && exchangeRate && amount && (
        <dd className={styles.netAmount}>
          <Currency currency={currency}>
            {amount ? +big`${amount} * ${exchangeRate}` : 'Awaiting input'}
          </Currency>
          {!!amount && (
            <ConversionCurrency currency={currency}>
              {' '}
              ({+big`${amount} * ${exchangeRate}`})
            </ConversionCurrency>
          )}
        </dd>
      )}
      <dt>Transaction&nbsp;fee</dt>
      {loaded && feeType === 'exchange' && exchangeRate && amount ? (
        <dd className={styles.transactionFee}>
          <Currency currency={currency}>
            {amount
              ? +big`${amount} * ${exchangeRate} * ${fee} / 100`
              : 'Awaiting input'}
          </Currency>
          {!!amount && (
            <ConversionCurrency currency={currency}>
              {' '}
              ({+big`${amount} * ${exchangeRate} * ${fee} / 100`})
            </ConversionCurrency>
          )}
        </dd>
      ) : (
        loaded && <dd className={styles.transactionFee}>{'Awaiting Input'}</dd>
      )}

      <span style={{ color: 'var(--color-success)' }}>Net amount</span>
      {loaded && feeType === 'exchange' && exchangeRate && amount ? (
        <dd className={styles.netAmount}>
          <Currency currency={currency}>
            {amount
              ? +big`${subFee(amount)} * ${exchangeRate}`
              : 'Awaiting input'}
          </Currency>
          {!!amount && (
            <ConversionCurrency currency={currency}>
              {' '}
              ({+big`${subFee(amount)} * ${exchangeRate}`})
            </ConversionCurrency>
          )}
        </dd>
      ) : (
        loaded && (
          <dd className={styles.netAmount}>
            <Currency currency={currency}>
              {amount ? subFee(amount) : 'Awaiting input'}
            </Currency>
            {!!amount && (
              <ConversionCurrency currency={currency}>
                {' '}
                ({subFee(amount)})
              </ConversionCurrency>
            )}
          </dd>
        )
      )}
    </dl>
  );
}

export default FeeInfo;
