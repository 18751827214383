// @ts-check

import { Item } from '@react-stately/collections';
import Surface from 'common/surfaces/Surface.component';
import Tabs from 'common/tabs/Tabs.component';
import { useExchangeContext } from 'context/ExchangeContext';
import DepositTab from 'pages/exchange/DepositTab.component';
import P2PTab from 'pages/exchange/P2PTab.component';
import SendTab from 'pages/exchange/SendTab.component';
import SwapTab from 'pages/exchange/SwapTab.component';
import * as React from 'react';
import { Level, H } from 'react-accessible-headings';
import { useLocation } from 'react-router';
import styles from './Exchange.module.css';
import useThemeVariables from 'hooks/useThemeVariables';
import { useLocale } from 'context/LanguageContext';

export default function Exchange() {
  const { LL } = useLocale();

  /** @type {React.MutableRefObject<React.ElementRef<Tabs>>} */
  const tabRef = React.useRef(null);
  // prettier-ignore
  const { 
    selectedTab, 
    setSelectedTab, 
    tabs, 
    addEventListener 
  } = useExchangeContext();

  const { colorTest4 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };

  const location = useLocation();

  React.useEffect(() => {
    if (
      typeof location.state === 'object' &&
      location.state !== null &&
      'focus' in location.state
    ) {
      tabRef.current?.focusIntoPanel();
    }
  }, [location.state]);

  React.useEffect(() => {
    const removeEventListener = addEventListener('exchangeNavigation', () => {
      tabRef.current?.focusIntoPanel();
    });
    return removeEventListener;
  }, [addEventListener]);

  return (
    <Surface
      className={styles.container}
      surfaceColor={surfaceColor}
      baseElevation={0}
    >
      <Level>
        <H className={styles.mainHeadline}>{LL.app.exchange()}</H>
      </Level>

      <Tabs
        selectedKey={selectedTab}
        onSelectionChange={setSelectedTab}
        classNames={{ tabs: styles.tab, tabPanel: styles.tabPanel }}
        ref={tabRef}
      >
        <Item
          key={tabs.send}
          title={LL.app.send()}
        >
          <SendTab />
        </Item>
        <Item
          key={tabs.receive}
          title={LL.app.deposit()}
        >
          <DepositTab />
        </Item>
        <Item
          key={tabs.p2p}
          title={LL.app.p2p()}
        >
          <P2PTab />
        </Item>
        <Item
          key={tabs.swap}
          title={LL.app.swap()}
        >
          <SwapTab />
        </Item>
      </Tabs>
    </Surface>
  );
}
