import ConversionUpdater from 'components/conversion/ConversionUpdater';
import * as React from 'react';

const ConversionContext = React.createContext(
  /** @type {ConversionUpdater} */ (undefined)
);

/**
 * @typedef ConversionGroupProps
 * @property {string} currency
 * @property {number} [value]
 * @property {number} [defaultValue]
 * @property {(value: number) => void} [onChange]
 * @property {React.ReactNode} children
 */

/**
 * @typedef ConversionResult
 * @property {number} value
 * @property {Error} error
 * @property {(value: number) => void} setValue
 * @property {(value: number) => void} updateOthers
 */

/**
 * This component is used to group components that need to be able to convert
 * values between different currencies.
 * It provides a context that can be used by the useConversion hook.
 * @throws {ConversionError}
 *
 * @param {ConversionGroupProps} props
 */
export function ConversionGroup({
  value: valueProp,
  defaultValue,
  currency: currencyProp,
  onChange,
  children,
}) {
  // Keep an internal reference to value & currency to not have to pass them to react hooks
  const valueRef = React.useRef(valueProp ?? defaultValue ?? NaN);

  const currencyRef = React.useRef(currencyProp);
  currencyRef.current = currencyProp;

  // TODO: throw when controlled and uncontrolled are mixed
  // TODO: throw when defaultValue updates
  // TODO: throw when value changes from defined to undefined

  // Context value
  // TODO: maybe memory leak when not deleting conversionUpdater on unmount?
  const conversionUpdater = React.useMemo(() => {
    return new ConversionUpdater(async (initialize, handlerCurrency) => {
      // When a new handler is registered, initialize it with the current value
      // converted to the handler's currency
      if (isNaN(valueRef.current)) return;
      const value = await ConversionUpdater.convert(
        valueRef.current,
        currencyRef.current,
        handlerCurrency
      );
      initialize(value);
    });
  }, []);

  // Update valueRef and call onChange when a component
  // down the tree changes the value and notifies us.
  const valueUpdater = React.useMemo(
    () => ({
      currency: currencyRef.current,
      onInitialize: () => {},
      onChange: (value) => {
        valueRef.current = value ?? NaN;
        onChange?.(value);
      },
      onError(error) {
        // Handle it in an error boundary
        throw error;
      },
    }),
    [onChange]
  );

  // Notify handlers down the tree when a new value is passed down through props
  React.useEffect(() => {
    if (valueProp !== undefined && valueProp !== valueRef.current) {
      valueRef.current = valueProp ?? NaN;
      conversionUpdater.notifyOtherHandlers(valueUpdater, valueProp);
    }
  }, [conversionUpdater, valueProp, valueUpdater]);

  // Register the valueUpdater
  React.useEffect(() => {
    conversionUpdater.register(valueUpdater);
    return () => conversionUpdater.unregister(valueUpdater);
  }, [conversionUpdater, valueUpdater]);

  return (
    <ConversionContext.Provider value={conversionUpdater}>
      {children}
    </ConversionContext.Provider>
  );
}

/**
 * This hook is used to get the value of a currency in the context of a ConversionGroup.
 * It returns an object with a value property that is updated when the value of the currency
 * changes.
 * @param {string} currency
 * @returns {ConversionResult}
 */
export function useConversion(currency) {
  /** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
  const [value, setValue] = React.useState(NaN);
  /** @type {[Error, React.Dispatch<React.SetStateAction<Error>>]} */
  const [error, setError] = React.useState(null);

  const conversionUpdater = React.useContext(ConversionContext);

  const setValueWithError = React.useCallback((value) => {
    setValue(value);
    setError(null);
  }, []);

  /** @type {import('components/conversion/ConversionUpdater').ConversionHandler} */
  const valueUpdater = React.useMemo(() => {
    return {
      currency,
      onInitialize: (value) => setValueWithError(value),
      onChange: (value) => setValueWithError(value),
      onError: (error) => setError(error),
    };
  }, [currency, setValueWithError]);

  // TODO: useSyncExternalStore from react might be useful here when we start using concurrent mode.
  React.useEffect(() => {
    if (conversionUpdater) {
      conversionUpdater.register(valueUpdater);
      return () => {
        conversionUpdater.unregister(valueUpdater);
      };
    }
  }, [conversionUpdater, valueUpdater]);

  if (!conversionUpdater) {
    // If used without ConversionGroup, return undefined
    // just like useContex would
    return undefined;
  }

  return {
    value,
    error,
    setValue: setValueWithError,
    updateOthers: (value) => {
      conversionUpdater.notifyOtherHandlers(valueUpdater, value);
    },
  };
}
