// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef ExchangeRecord
 * @property {object} data
 * @property {string} id
 * @property {string} from
 * @property {string} to
 * @property {string} amount_from
 * @property {string} amount_to
 * @property {string} rate
 * @property {string} fee
 * @property {string} createdAt
 *
 * @typedef ExchangeRecordPayload
 * @property {string} id
 */

export async function getExchangeRecord({ id }) {
  /** @type {import('axios').AxiosResponse<ExchangeRecord>} */
  const response = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URI}exchange/record`,
    data: { id },
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });

  return response.data;
}

/**
 * @param {object} payload
 * @param {string} payload.id
 */
export function useExchangeRecord(payload) {
  return useQuery(
    ['exchangeRecord', payload],
    () => getExchangeRecord(payload),
    { staleTime: Infinity }
  );
}
