import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchIpWhitelistStart } from '../../../redux/user/user.actions';

import FilledButton from 'common/buttons/FilledButton';
import OutlinedButton from 'common/buttons/OutlinedButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import { FaTrash } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { selectTfaEnabled } from 'redux/user/user.selectors';
import './ip-whitelist.styles.css';

const errors = {
  ERR_INVALID_2FA_TOKEN: '2FA token is invalid',
  'Access Forbidden!': 'Your IP is not in the whitelist',
  ERR_TOKEN_REQUIRED: '2FA token is required',
  '"address" is not allowed to be empty': 'IP address required',
};
const IpWhitelistPage = ({
  fetchIpWhitelistStart,
  ipWhitelist,
  tfaEnabled,
}) => {
  const [stateIpList, setStateIpList] = useState([]);
  const [ipAddress, setIpAddress] = useState('');
  const [tfaPin, setTfaPin] = useState('');
  const [tfaPopup, setTfaPopup] = useState('');
  const [removeIpId, setRemoveIpId] = useState(null);
  const [error, setError] = useState(null);

  const { LL } = useLocale();

  const { colorTest4, colorTest6 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };
  const surfaceColorPopup = {
    background: colorTest6,
  };

  useEffect(() => {
    setStateIpList(ipWhitelist);
  }, [ipWhitelist]);

  useEffect(() => {
    fetchIpWhitelistStart();
  }, [fetchIpWhitelistStart]);

  const addIpToWhitelist = async () => {
    try {
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}ip-whitelist`,
        data: qs.stringify({
          address: ipAddress,
          tfaToken: tfaPin.replace(' ', ''),
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: localStorage.getItem('token'),
        },
      });

      setStateIpList((prevState) => [res.data, ...prevState]);
      setIpAddress('');
      setTfaPin('');
      setTfaPopup('');
    } catch (err) {
      const errMessage = err.data?.message
        ? err.data.message
        : err.response?.data?.error
        ? err.response.data.error
        : 'Unexpected error';

      setError(errMessage);
      setTimeout(() => setError(null), 5000);
    }
  };

  const RemoveIpFromWhitelist = async (id) => {
    try {
      await axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URI}ip-whitelist/${id}`,
        data: { tfaToken: tfaPin.replace(' ', '') },
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });

      const updatedIps = stateIpList.filter((ip) => ip.id !== id);
      setStateIpList(updatedIps);
      setTfaPin('');
      setTfaPopup('');
    } catch (err) {
      const errMessage = err.data?.message
        ? err.data.message
        : err.response?.data?.error
        ? err.response.data.error
        : 'Unexpected error';

      setError(errMessage);
      setTimeout(() => setError(null), 5000);
    }
  };

  return (
    <>
      <Surface
        className="ip-whitelist-wrapper"
        surfaceColor={surfaceColor}
        style={stateIpList.length > 0 ? { gap: '11.4rem' } : { gap: '2.8rem' }}
      >
        {['add', 'delete'].includes(tfaPopup) && (
          <Surface
            surfaceColor={surfaceColorPopup}
            className="tfa-check"
          >
            <OutlinedButton
              className="tfa-check-close"
              onPress={() => {
                setTfaPopup('');
                setTfaPin('');
                setRemoveIpId(null);
              }}
            >
              <RiCloseLine />
            </OutlinedButton>
            {error && (
              <div className="inline-error">
                <ErrorContainer>
                  <InlineError
                    children={
                      errors[error] ? errors[error] : 'Unexpected error'
                    }
                    onClose={() => setError(null)}
                    success={false}
                  ></InlineError>
                </ErrorContainer>
              </div>
            )}
            <span className={`${error ? 'error' : ''}`}>
              {LL.security.ipWhitelist.tfaPinHeader()}
            </span>
            <TextField
              type="text"
              placeholder={LL.security.ipWhitelist.tfaPin()}
              value={tfaPin}
              onChange={(e) => setTfaPin(e)}
            />
            <FilledButton
              onPress={() =>
                tfaPopup === 'delete'
                  ? RemoveIpFromWhitelist(removeIpId)
                  : tfaPopup === 'add' && addIpToWhitelist()
              }
            >
              {LL.general.confirm()}
            </FilledButton>
          </Surface>
        )}
        <div className="ip-list-wrapper">
          <h1>{LL.security.ipWhitelist.ipHeader()}</h1>
          <div className="ip-list">
            {stateIpList.length > 0 ? (
              stateIpList.map((ip) => (
                <span
                  className="ip"
                  key={ip.id}
                >
                  {ip.address}{' '}
                  {tfaEnabled && (
                    <span
                      className="remove-icon"
                      onClick={() => {
                        setRemoveIpId(ip.id);
                        setTfaPopup('delete');
                      }}
                    >
                      <FaTrash />
                    </span>
                  )}
                </span>
              ))
            ) : (
              <span className="ip-not-found">
                {LL.security.ipWhitelist.ipList()}
              </span>
            )}
          </div>
        </div>
        {tfaEnabled ? (
          <form className="add-new-ip">
            <TextField
              type="text"
              placeholder={LL.security.ipWhitelist.ipAddress()}
              value={ipAddress}
              onChange={(e) => setIpAddress(e)}
            />
            <FilledButton onPress={() => setTfaPopup('add')}>
              {LL.security.ipWhitelist.ipAdd()}
            </FilledButton>
          </form>
        ) : (
          <div className="enable-tfa-message">
            {LL.security.ipWhitelist.ipTfaWarning()}
          </div>
        )}
      </Surface>
    </>
  );
};

const mapStateToProps = (state) => ({
  ipWhitelist: state.user.ipWhitelist,
  tfaEnabled: selectTfaEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchIpWhitelistStart: () => dispatch(fetchIpWhitelistStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IpWhitelistPage);
