import {
  FETCH_INVOICE_START,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAIL,
} from './invoice.types';

export const fetchInvoiceStart = (invoiceId) => ({
  type: FETCH_INVOICE_START,
  payload: invoiceId,
});

export const fetchInvoiceSuccess = (data) => ({
  type: FETCH_INVOICE_SUCCESS,
  payload: data,
});

export const fetchInvoiceFail = (err) => ({
  type: FETCH_INVOICE_FAIL,
  payload: err,
});
