import React, { useState } from 'react';
import styles from './Details.module.css';

import CurrencyColors from 'assets/data/currency-colors';
import cx from 'classnames';
import OutlinedButton from 'common/buttons/OutlinedButton';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import ExplorerCopy from 'components/copy-to-clipboard/ExplorerCopy.component';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import { Level } from 'react-accessible-headings';

const eths = [
  'busd',
  'chz',
  'enj',
  'link',
  'mkr',
  'omg',
  'reef',
  'snx',
  'uni',
  'usdc',
  'usdt',
  'vet',
  'zrx',
  'eth',
  'trx',
  'bnb',
  'shib',
  'btt',
];

const getDate = (date) => {
  const fullDate = new Date(date).toString();
  const index = fullDate.indexOf('(');
  const updatedDate = fullDate.slice(0, index);
  return updatedDate;
};
const getDateFromTimestamp = (date) => {
  const fullDate = new Date(date * 1000).toString();
  const index = fullDate.indexOf('(');
  const updatedDate = fullDate.slice(0, index);
  return updatedDate;
};

const Details = ({ size, data, coin, txid }) => {
  const isLg = size === 'lg';
  const isMd = size === 'md';
  const isSm = size === 'sm';

  const [showAdvanced, setShowAdvanced] = useState(false);
  const { LL } = useLocale();

  const { colorTest7 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest7,
  };

  return (
    <Level>
      <TranslucentSurface
        elementType={'article'}
        className={cx(styles.container, {
          '--sizeSmall': isSm,
          '--sizeMedium': isMd,
          '--sizeLarge': isLg,
        })}
        surfaceColor={CurrencyColors[coin]}
      >
        {coin === 'xrp' || coin === 'xlm' ? (
          coin === 'xrp' ? (
            <>
              {!data.intra && (
                <div className={styles.detail}>
                  <span>{LL.explorer.details.transactionType()}</span>
                  {data.type[0].toUpperCase() + data.type.slice(1)}
                </div>
              )}

              <div className={styles.detail}>
                <span>
                  {data.intra
                    ? LL.explorer.details.transactionHash()
                    : LL.explorer.details.transactionId()}
                  :
                </span>
                <div className={styles.detailHighlight}>
                  {data.intra ? data.hash : data.id}
                  <ExplorerCopy text={data.intra ? data.hash : data.id} />
                </div>
              </div>

              <div className={styles.detail}>
                <span>{LL.explorer.details.fees()} </span>
                {data.intra ? data.fee : data.outcome && data.outcome.fee}
              </div>
              {data.intra && (
                <>
                  <div className={styles.detail}>
                    <span>{LL.explorer.details.createdAt()} </span>
                    {getDate(data.createdAt)}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className={styles.detail}>
                <span>{LL.explorer.operations.sourceAccount()}:</span>
                <div className={styles.detailHighlight}>
                  {data.source_account}
                  <ExplorerCopy text={data.source_account} />
                </div>
              </div>

              <div className={styles.detail}>
                <span>{LL.explorer.details.fees()} </span>
                {data.intra ? data.fee : (data.fee_charged / 100000).toFixed(4)}
                {' ' + coin.toUpperCase()}
              </div>
              {!data.intra && coin === 'xlm' && (
                <div className={styles.detail}>
                  <span>{`Memo (${data.memo_type}): `}</span>
                  <span className="primary">{data.memo || 'Empty'}</span>
                </div>
              )}

              {data.intra && (
                <>
                  <div className={styles.detail}>
                    <span>{LL.explorer.details.createdAt()} </span>
                    {getDate(data.createdAt)}
                  </div>
                </>
              )}
            </>
          )
        ) : (
          <>
            <div className={styles.detail}>
              <span>{LL.explorer.details.blockHash()} </span>
              <div className={styles.detailHighlight}>
                {data.intra ? data.hash : data.blockhash || data.blockHash}
                <ExplorerCopy
                  text={
                    data.intra ? data.hash : data.blockhash || data.blockHash
                  }
                />
              </div>
            </div>

            {data.intra ? (
              <>
                <div className={styles.detail}>
                  <span>{LL.explorer.details.createdAt()} </span>
                  {getDate(data.createdAt)}
                </div>
              </>
            ) : eths.includes(coin) && coin !== 'trx' && coin !== 'btt' ? (
              <>
                <div className={styles.detail}>
                  <span>{LL.explorer.details.gasPrice()} </span>
                  {data.gasPrice}
                </div>

                <div className={styles.detail}>
                  <span>{LL.explorer.details.gasUsed()} </span>
                  {data.gasUsed}
                </div>
              </>
            ) : coin === 'trx' || coin === 'btt' ? (
              <>
                <div className={styles.detail}>
                  <span>{LL.explorer.details.blockNumber()}</span>
                  {data.blockNumber}
                </div>
                <div className={styles.detail}>
                  <span>{LL.explorer.details.contractAddress()}</span>
                  {data.contractAddress ? (
                    <>
                      {data.contractAddress}
                      <ExplorerCopy text={data.contractAddress} />
                    </>
                  ) : (
                    LL.explorer.summary.unavailable()
                  )}
                </div>
              </>
            ) : (
              <>
                <div className={styles.detail}>
                  <span>{LL.explorer.details.size()} </span>
                  {data.size}
                </div>

                <div className={styles.detail}>
                  <span>{LL.explorer.details.version()} </span>
                  {data.version}
                </div>
              </>
            )}
          </>
        )}

        <div className={showAdvanced ? styles.advancedShow : styles.advanced}>
          <div className={styles.detail}>
            <span>{LL.explorer.details.version()} </span>
            {data.weight}
          </div>

          <div className={styles.detail}>
            <span>{LL.explorer.details.locktime()} </span>
            {getDateFromTimestamp(data.locktime)}
          </div>

          <div className={styles.detail}>
            <span>{LL.explorer.details.amount()} </span>
            <span className={styles.detailHighlight}>
              {Number(data.amount)}
            </span>
          </div>

          <div className={styles.detail}>
            <span>{LL.explorer.details.fees()} </span>
            {Number(data.fees)}
          </div>
        </div>

        {coin !== 'xrp' &&
          coin !== 'xlm' &&
          !data.intra &&
          !eths.includes(coin) && (
            <div className={styles.advancedBtn}>
              <OutlinedButton
                attachesBackground
                pressesBackground
                onPress={() => setShowAdvanced((prevState) => !prevState)}
                // styles={{ backgroundColor: '#ff9800', width: '250px' }}
              >
                <div style={{ color: 'var(--color-surface)' }}>
                  {showAdvanced
                    ? LL.explorer.showLess()
                    : LL.explorer.showMore()}
                </div>
              </OutlinedButton>
            </div>
          )}
      </TranslucentSurface>
    </Level>
  );
};

export default Details;
