// @ts-check

import * as React from 'react';
import WithElevation from '../with-elevation/WithElevation.component';
import { motion } from 'framer-motion';
import styles from './Surface.module.css';
import classNames from 'classnames';
import useSurfaceColor from '../../hooks/useSurfaceColor';

/**
 * @typedef {object} LocalProps
 * @property {number} [baseElevation]
 * @property {number} [hoverElevation]
 * @property {number} [pressElevation]
 * @property {"tint" | "shadow" | "both"} [elevationType]
 * @property {boolean} [isolate]
 * @property {any} children
 * @property {string} [elementType]
 * @property {import('../../hooks/useSurfaceColor').SurfaceColor} [surfaceColor]
 *
 * @typedef {LocalProps & React.HTMLAttributes} SurfaceProps
 *
 * @param {SurfaceProps} props
 */

function SurfaceBase(
  {
    baseElevation,
    hoverElevation,
    pressElevation,
    elevationType,
    isolate,
    children,
    elementType = 'div',
    className,
    surfaceColor = 'surface',
    style,
    ...props
  },
  ref
) {
  const element = React.createElement(
    elementType,
    {
      ...props,
      className: classNames(className, styles.surface),
      style: { ...useSurfaceColor(surfaceColor), ...style },
      ref,
    },
    children
  );

  return (
    <WithElevation
      base={baseElevation}
      whileHover={hoverElevation}
      whilePress={pressElevation}
      isolate={isolate}
      type={elevationType}
    >
      {element}
    </WithElevation>
  );
}

const Surface = React.forwardRef(SurfaceBase);
export default Surface;

export const MotionSurface = motion(Surface);
