// @ts-check
import cx from 'classnames';
import { ExplorerPovider } from 'context/ExplorerContext';
import { useLocale } from 'context/LanguageContext';
import Explorer from 'pages/explorer/Explorer.component';
import ExplorerData from 'pages/explorer/ExplorerData.component';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import styles from './ExplorerApp.module.css';

export default function ExplorerApp() {
  let { locale, setLocale } = useLocale();
  const [themeParams, setThemeParams] = useSearchParams();
  const [localeParams, setLocaleParams] = useSearchParams();
  const isDarkTheme = themeParams.get('theme') === 'dark' ? true : false;
  const currLocale = localeParams.get('locale');
  React.useEffect(() => {
    if (currLocale) {
      // @ts-ignore
      setLocale(currLocale);
    }
  }, []);

  return (
    <div
      className={cx(styles.container, {
        '--is-dark-theme': isDarkTheme,
        '--is-light-theme': !isDarkTheme,
        '--locale-en': locale === 'en',
        '--locale-ka': locale === 'ka',
      })}
    >
      <Helmet>
        <style>{`body {background-color: ${
          isDarkTheme ? '#191c1d' : '#fbfdfd'
        };}`}</style>
      </Helmet>
      <ExplorerPovider>
        <div style={{ display: 'none' }}>
          <Explorer />
        </div>
        <ExplorerData />
      </ExplorerPovider>
    </div>
  );
}
