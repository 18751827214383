import CurrencyColors from 'assets/data/currency-colors';
import FilledButton from 'common/buttons/FilledButton';
import Surface from 'common/surfaces/Surface.component';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import ExplorerCopy from 'components/copy-to-clipboard/ExplorerCopy.component';
import { useLocale } from 'context/LanguageContext';
import { format, parseISO } from 'date-fns';
import useThemeVariables from 'hooks/useThemeVariables';
import React from 'react';
import { Level } from 'react-accessible-headings';
import styles from './ExplorerOperations.module.css';

// TODO: translations
// TODO: onClick event on spans. a11y concern
// TODO: navigator.clipboard.writeText prob wont work on safari
// TODO: prefer remix icons over ionions + default size should be 20px, only override when neccessary

const ExplorerOperations = ({ data, coin }) => {
  const { LL } = useLocale();
  const [showMore, setShowMore] = React.useState(false);
  const { colorTest7 } = useThemeVariables();
  const surfaceColor = {
    background: colorTest7,
  };

  return (
    <Level>
      <div className={styles.operationsContainer}>
        {data?.operations?.records
          ?.slice(...(showMore ? [] : [0, 5]))
          .map((record) => (
            <TranslucentSurface
              className={styles.container}
              surfaceColor={CurrencyColors[coin]}
            >
              {!data.intra && (
                <div className={styles.detail}>
                  <Surface
                    className={styles.operationItemType}
                    surfaceColor={surfaceColor}
                  >
                    {LL.explorer.details.transactionType()}{' '}
                    {record &&
                      record.type
                        .replaceAll('_', ' ')
                        .split(' ')
                        .map((word) => word[0].toUpperCase() + word.slice(1))
                        .join(' ')}
                  </Surface>
                  <Surface
                    className={styles.operationItem}
                    surfaceColor={surfaceColor}
                  >
                    {record.type === 'manage_buy_offer' ? (
                      <>
                        <span className={styles.address}>
                          {LL.explorer.operations.buyer()}
                          {': '}
                          {record.source_account.slice(0, 6)}....
                          {record.source_account.slice(-7, -1)}
                          <ExplorerCopy text={record.source_account} />
                        </span>
                        <br />
                        <span className={styles.address}>
                          {LL.explorer.operations.seller()}
                          {': '}
                          {record.buying_asset_issuer.slice(0, 6)}....
                          {record.buying_asset_issuer.slice(-7, -1)}
                          <ExplorerCopy text={record.buying_asset_issuer} />
                        </span>
                      </>
                    ) : record.type === 'manage_sell_offer' ? (
                      <>
                        <span className={styles.address}>
                          {LL.explorer.operations.seller()}
                          {': '}
                          {record.source_account.slice(0, 6)}....
                          {record.source_account.slice(-7, -1)}
                          <ExplorerCopy text={record.source_account} />
                        </span>
                        <br />
                        <span className={styles.address}>
                          {LL.explorer.operations.buyer()}
                          {': '}
                          {record.selling_asset_issuer.slice(0, 6)}....
                          {record.selling_asset_issuer.slice(-7, -1)}
                          <ExplorerCopy text={record.selling_asset_issuer} />
                        </span>
                      </>
                    ) : record.type === 'path_payment_strict_send' ||
                      record.type === 'payment' ? (
                      <>
                        <span className={styles.address}>
                          {LL.explorer.operations.issuer()}
                          {': '}
                          {(record.source_asset_issuer || record.from).slice(
                            0,
                            6
                          ) +
                            '....' +
                            (record.source_asset_issuer || record.from).slice(
                              -7,
                              -1
                            )}
                          <ExplorerCopy
                            text={
                              record.type === 'payment'
                                ? record.from
                                : record.source_asset_issuer
                            }
                          />
                        </span>
                        {record.type === 'path_payment_strict_send' && (
                          <>
                            <br />
                            {LL.explorer.details.amount()}{' '}
                            {(record.source_amount * 1).toFixed(5)}{' '}
                            {record.source_asset_code?.toLocaleUpperCase()}
                          </>
                        )}
                      </>
                    ) : (
                      <span className={styles.address}>
                        {LL.explorer.operations.issuer()}
                        {': '}
                        {record.source_account.slice(0, 6) +
                          '....' +
                          record.source_account.slice(-7, -1)}
                        <ExplorerCopy text={record.source_account} />
                      </span>
                    )}
                  </Surface>
                  {record.amount &&
                  record.selling_asset_type &&
                  record.buying_asset_type ? (
                    <Surface
                      className={styles.operationItemAmount}
                      surfaceColor={surfaceColor}
                    >
                      {LL.explorer.operations.assetSold()}
                      {': '}
                      {record.selling_asset_type === 'native'
                        ? (record.amount * record.price).toFixed(5) +
                          ' ' +
                          coin.toLocaleUpperCase()
                        : (record.amount * 1).toFixed(5) +
                          ' ' +
                          record.selling_asset_code?.toLocaleUpperCase()}
                      <br />
                      {LL.explorer.operations.assetBought()}
                      {': '}
                      {record.buying_asset_type === 'native'
                        ? (record.amount * record.price).toFixed(5) +
                          ' ' +
                          coin.toLocaleUpperCase()
                        : (record.amount * 1).toFixed(5) +
                          ' ' +
                          record.buying_asset_code?.toLocaleUpperCase()}
                    </Surface>
                  ) : (record.type === 'payment' ||
                      record.type === 'path_payment_strict_send') &&
                    record.amount ? (
                    <Surface
                      className={styles.operationItemAmountAddress}
                      surfaceColor={surfaceColor}
                    >
                      <span className={styles.address}>
                        {LL.explorer.operations.destination()}
                        {': '}
                        {record.to.slice(0, 6) +
                          '....' +
                          record.to.slice(-7, -1)}
                        <ExplorerCopy text={record.to} />
                      </span>
                      {LL.explorer.details.amount()}{' '}
                      {(record.amount * 1).toFixed(5) +
                        ' ' +
                        (record.asset_type === 'native'
                          ? coin.toLocaleUpperCase()
                          : record.asset_code?.toLocaleUpperCase())}
                      <br />
                    </Surface>
                  ) : (
                    <Surface
                      className={styles.operationItemAmountAddress}
                      surfaceColor={surfaceColor}
                    >
                      <span className={styles.address}>
                        {LL.explorer.operations.destination()}
                        {': '}
                        {(record.asset_issuer
                          ? record.asset_issuer
                          : record.source_account
                        ).slice(0, 6) +
                          '....' +
                          (record.asset_issuer
                            ? record.asset_issuer
                            : record.source_account
                          ).slice(-7, -1)}
                        <ExplorerCopy
                          text={
                            record.asset_issuer
                              ? record.asset_issuer
                              : record.source_account
                          }
                        />
                      </span>
                    </Surface>
                  )}
                  <Surface
                    className={styles.operationItemDate}
                    surfaceColor={surfaceColor}
                  >
                    {LL.explorer.summary.createdAt()}{' '}
                    {format(parseISO(record.created_at), 'yyyy-MM-dd HH:mm:ss')}
                  </Surface>
                </div>
              )}
            </TranslucentSurface>
          ))}

        {data?.operations?.records.length > 5 && (
          <FilledButton onPress={() => setShowMore(!showMore)}>
            {showMore ? 'Show Less' : 'Show More'}
          </FilledButton>
        )}
      </div>
    </Level>
  );
};

export default ExplorerOperations;
