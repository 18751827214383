// @ts-check
import { OverlayTriggerContext } from 'common/overlays/base/OverlayTriggerContext';
import * as React from 'react';
import { mergeProps, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { getRef } from 'utils';
import ModalBase from '../base/ModalBase.component';

/**
 * Takes two elements as children: an element with an onPress prop (e.g a button),
 * and a function or element that contains modal content. It then renders the modal content by
 * the press of the button and provides the modal content ability to close itself with
 * the close function passed as an argument to the render function.
 * Note, if the second child is not a function, then `OverlayTriggerState`
 * (including the close function) is available inside the child through `OverlayTriggerContext`
 *
 * @param {ModalTriggerProps} props
 */
export default function ModalTrigger({ children, type = 'dialog', ...props }) {
  const triggerRef = React.useRef();
  const state = useOverlayTriggerState(props);
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type },
    state,
    triggerRef
  );

  const [button, modalContent] = children;

  // If press listener is already defined on the button, execute it before opening the modal
  const handlePress = async () => {
    if (button.props.onPress) {
      await button.props.onPress();
    }
    state.open();
  };

  return (
    <>
      {/* Pass down onPress and aria props to the trigger button */}
      {React.cloneElement(
        button,
        mergeProps({
          ...triggerProps,
          onPress: handlePress,
          ref: getRef(triggerRef, button),
        })
      )}

      {state.isOpen && (
        <ModalBase
          {...props}
          state={state}
        >
          {typeof modalContent === 'function' ? (
            React.cloneElement(modalContent(state.close), overlayProps)
          ) : (
            <OverlayTriggerContext.Provider value={state}>
              {modalContent}
            </OverlayTriggerContext.Provider>
          )}
        </ModalBase>
      )}
    </>
  );
}

/** @typedef {LocalProps & ModalProps} ModalTriggerProps */
/** @typedef {Omit<import('common/overlays/base/ModalBase.component').ModalBaseProps, "state" | "children">} ModalProps */

/**
 * @typedef LocalProps
 *
 * @property {boolean} [isOpen]
 * Whether the overlay is open by default (controlled).
 *
 * @property {boolean} [defaultOpen]
 * Whether the overlay is open by default (uncontrolled).
 *
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * Handler that is called when the overlay's open state changes.
 *
 * @property {'dialog' | 'menu' | 'listbox' | 'tree' | 'grid'} [type="dialog"]
 *
 * @property {[React.ReactElement, ((close: () => void) => React.ReactElement) | React.ReactElement]} children
 * A button or any element wich has an onPress prop,
 * and a function or element that contains the modal's content.
 * `onPress` event is automatically bound to the button element,
 * so that pressing the button triggers modal.
 * If the second child is an element instead of function,
 * `overlayTriggerState`, including close function is available for
 * the element through `OverlayTriggerContext`
 */
