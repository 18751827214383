import { useCollator } from '@react-aria/i18n';
import { setInteractionModality } from '@react-aria/interactions';
import { useField } from '@react-aria/label';
import { useMenuTrigger } from '@react-aria/menu';
import { ListKeyboardDelegate, useTypeSelect } from '@react-aria/selection';
import { chain, filterDOMProps, mergeProps, useId } from '@react-aria/utils';
import { FocusableElement } from '@react-types/shared';
import { FocusEvent, RefObject, useMemo } from 'react';
import {
  AriaMultiSelectOptions,
  MultiSelectAria,
  MultiSelectState,
} from './types';

/**
 * Temp implementation of MultiSelect component before react-aria team implements one.
 * Might include some bugs and features might not be complete.
 * @param props - Props for the select.
 * @param state - State for the select, as returned by `useListState`.
 */
export function useMultiSelect<T>(
  props: AriaMultiSelectOptions<T>,
  state: MultiSelectState<T>,
  ref: RefObject<FocusableElement>
): MultiSelectAria<T> {
  let { keyboardDelegate, isDisabled } = props;

  // By default, a KeyboardDelegate is provided which uses the DOM to query layout information (e.g. for page up/page down).
  // When virtualized, the layout object will be passed in as a prop and override this.
  let collator = useCollator({ usage: 'search', sensitivity: 'base' });
  let delegate = useMemo(
    () =>
      keyboardDelegate ||
      new ListKeyboardDelegate(
        state.collection,
        state.disabledKeys,
        null,
        collator
      ),
    [keyboardDelegate, state.collection, state.disabledKeys, collator]
  );

  let { menuTriggerProps, menuProps } = useMenuTrigger<T>(
    {
      isDisabled,
      type: 'listbox',
    },
    state,
    ref
  );

  /* Removed the lef/right arrow keys handler on trigger because it doesn't make sense on multi select*/
  // let onKeyDown = (e: KeyboardEvent) => {

  //   switch (e.key) {
  //     case 'ArrowLeft': {
  //       // prevent scrolling containers
  //       e.preventDefault();

  //       let key = state.selectedKey != null ? delegate.getKeyAbove(state.selectedKey) : delegate.getFirstKey();
  //       if (key) {
  //         state.setSelectedKey(key);
  //       }
  //       break;
  //     }
  //     case 'ArrowRight': {
  //       // prevent scrolling containers
  //       e.preventDefault();

  //       let key = state.selectedKey != null ? delegate.getKeyBelow(state.selectedKey) : delegate.getFirstKey();
  //       if (key) {
  //         state.setSelectedKey(key);
  //       }
  //       break;
  //     }
  //   }
  // };

  let { typeSelectProps } = useTypeSelect({
    keyboardDelegate: delegate,
    selectionManager: state.selectionManager,
    onTypeSelect(key) {
      // Changed default behaviour of typing when trigger is focused.
      // Instead of selecting the first match of user's provided text, we should just open the
      // listbox and move focus to the first match.
      state.open();
      // state.setSelectedKey(key);
    },
  });

  let { labelProps, fieldProps, descriptionProps, errorMessageProps } =
    useField({
      ...props,
      labelElementType: 'span',
    });

  typeSelectProps.onKeyDown = typeSelectProps.onKeyDownCapture;
  delete typeSelectProps.onKeyDownCapture;

  let domProps = filterDOMProps(props, { labelable: true });
  let triggerProps = mergeProps(typeSelectProps, menuTriggerProps, fieldProps);

  let valueId = useId();

  return {
    labelProps: {
      ...labelProps,
      onClick: () => {
        if (!props.isDisabled) {
          ref.current.focus();

          // Show the focus ring so the user knows where focus went
          setInteractionModality('keyboard');
        }
      },
    },
    triggerProps: mergeProps(domProps, {
      ...triggerProps,
      isDisabled,
      onKeyDown: chain(triggerProps.onKeyDown, props.onKeyDown),
      onKeyUp: props.onKeyUp,
      'aria-labelledby': [
        triggerProps['aria-labelledby'],
        triggerProps['aria-label'] && !triggerProps['aria-labelledby']
          ? triggerProps.id
          : null,
        valueId,
      ]
        .filter(Boolean)
        .join(' '),
      onFocus(e: FocusEvent) {
        if (state.isFocused) {
          return;
        }

        if (props.onFocus) {
          props.onFocus(e);
        }

        if (props.onFocusChange) {
          props.onFocusChange(true);
        }

        state.setFocused(true);
      },
      onBlur(e: FocusEvent) {
        if (state.isOpen) {
          return;
        }

        if (props.onBlur) {
          props.onBlur(e);
        }

        if (props.onFocusChange) {
          props.onFocusChange(false);
        }

        state.setFocused(false);
      },
    }),
    valueProps: {
      id: valueId,
    },
    menuProps: {
      ...menuProps,
      selectedKeys: state.selectedKeys,
      autoFocus: state.focusStrategy || true,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: false,
      disallowEmptySelection: false,
      selectionMode: 'multiple', // added this
      onBlur: (e) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
          return;
        }

        if (props.onBlur) {
          props.onBlur(e);
        }

        if (props.onFocusChange) {
          props.onFocusChange(false);
        }

        state.setFocused(false);
      },
      'aria-labelledby': [
        fieldProps['aria-labelledby'],
        triggerProps['aria-label'] && !fieldProps['aria-labelledby']
          ? triggerProps.id
          : null,
      ]
        .filter(Boolean)
        .join(' '),
    },
    descriptionProps,
    errorMessageProps,
  };
}
