const INITIAL_STATE = {
  isRequestToggled: false,
  isShearableToggled: false,
  isOutputToggled: false,
  isSendToggled: false,
  requestData: null,
  shearableCurrency: '',
  outputInfo: {},
  sendData: 'bitcoin',
  handleP2p: false,
};

const formsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'HANDLE_REQUEST':
      return {
        ...state,
        isRequestToggled: !state.isRequestToggled,
        requestData: action.payload,
        isShearableToggled: false,
        isOutputToggled: false,
        isSendToggled: false,
      };
    case 'HANDLE_SHEARABLE':
      return {
        ...state,
        isShearableToggled: !state.isShearableToggled,
        shearableCurrency: action.payload,
        isRequestToggled: false,
        isOutputToggled: false,
      };
    case 'HANDLE_OUTPUT':
      return {
        ...state,
        isOutputToggled: !state.isOutputToggled,
        outputInfo: action.payload,
        isRequestToggled: false,
        isShearableToggled: false,
      };
    case 'HANDLE_SEND':
      return {
        ...state,
        isSendToggled: !state.isSendToggled,
        sendData: action.payload,
        isRequestToggled: false,
        handleP2p: false,
      };
    case 'HANLDE_P2P':
      return {
        ...state,
        isSendToggled: !state.isSendToggled,
        sendData: null,
        isRequestToggled: false,
        handleP2p: true,
      };
    case 'HANDLE_BACKDROP':
      return {
        ...state,
        isRequestToggled: false,
        isShearableToggled: false,
        isOutputToggled: false,
        isSendToggled: false,
      };
    default:
      return state;
  }
};

export default formsReducer;
