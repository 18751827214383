// @ts-check
import * as React from 'react';
import { DismissButton } from '@react-aria/overlays';

/**
 * Visually hidden buttons that can be used to allow screen reader users
 * to dismiss a modal or popup when there is no visual affordance to do so.
 *
 * @typedef  Props
 * @property {React.ReactNode} children
 * @property {"start" | "end" | "both"} [placement]
 * @param {Props & React.ComponentPropsWithRef<DismissButton>} props
 */
function WithDismiss({ children, placement = 'end', ...props }) {
  return (
    <>
      {['start', 'both'].includes(placement) && <DismissButton {...props} />}
      {children}
      {['end', 'both'].includes(placement) && <DismissButton {...props} />}
    </>
  );
}

export default WithDismiss;
