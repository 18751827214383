//@ts-check
import SearchTextField from 'common/fields/SearchTextField.component';
import Surface from 'common/surfaces/Surface.component';
import PlainCurrencyPicker from 'components/currency-fields/PlainCurrencyPicker.component';
import BurgerMenu from 'components/navigation/BurgerMenu';
import useThemeVariables from 'hooks/useThemeVariables';
import React, { useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { TbArrowRight, TbGridDots } from 'react-icons/tb';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styles from './Explorer.module.css';

import IconButton from 'common/buttons/IconButton';
import NetworkPicker, {
  getInitialSelectedKey,
} from 'components/currency-fields/NetworkPicker.component';
import NewHeader from 'components/new-header/NewHeader';
import { useExplorerContext } from 'context/ExplorerContext';
import { useLocale } from 'context/LanguageContext';
import { useFormik } from 'formik';
import { useExplorerDataQuery } from 'queries/explorerData';
import * as Yup from 'yup';
import LogoDark from '../../assets/mobile-logo-dark.png';
import Logo from '../../assets/mobile-logo.png';

const Explorer = () => {
  const {
    data,
    setData,
    dataFetched,
    setDataFetched,
    serverError,
    setServerError,
  } = useExplorerContext();
  const navigate = useNavigate();
  const { coin, network, txid } = useParams();
  const [atm, setAtm] = useSearchParams();
  const [explorerVisible, setExplorerVisible] = useState(false);
  const [menu, setMenu] = useState(0);
  const [opacity, setOpacity] = useState(false);
  const { colorTest6, isDarkTheme } = useThemeVariables();

  const { LL } = useLocale();

  const surfaceColor = {
    background: colorTest6,
  };

  useExplorerDataQuery(
    { coin, network, txid, atm: atm.get('atm') },
    {
      enabled: !!coin && !!network && !!txid,
      retry: false,
      onSuccess: (data) => {
        setData(data);
        setDataFetched(true);
        setServerError(null);
      },
      onError: async (error) => {
        setData(null);
        setDataFetched(false);
        if (
          (error.response && error.response.statusText === 'Not Found') ||
          error.message === 'Transaction not found' ||
          error.response.data.message ===
            'No such transaction found. Please check your hash or try later' ||
          error.response.data.message === 'Request failed with status code 400'
        ) {
          await setServerError('Transaction Not Found');
        } else if (
          error.message === 'Network Error' ||
          error.message === 'Request failed with status code 404'
        ) {
          await setServerError(
            'There is a problem with your connection, or the server is down'
          );
        } else {
          await setServerError('Unexpected error, please try again later');
        }
      },
    }
  );

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  function getValidationState(sendForm, field) {
    return !sendForm.touched[field] ? null : sendForm.errors[field];
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      coin: coin || '',
      txid: '',
      network: network || '',
    },
    validationSchema: Yup.object({
      coin: Yup.string().required('Required'),
      txid: Yup.string().required('Required'),
      network: Yup.string().required('Required'),
    }),
    onSubmit: async ({ coin, network, txid }) => {
      setData(null);
      setDataFetched(null);
      navigate(`/explorer/${coin}/${network}/${txid}`);

      formik.resetForm();
    },
  });

  return (
    <>
      <Surface
        elementType="form"
        baseElevation={0}
        elevationType="tint"
        className={styles.container}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.mobileScreen}>
          <img
            src={isDarkTheme ? LogoDark : Logo}
            className={styles.logo}
            onClick={() => navigate('/overview')}
            alt=""
          />
          <div>
            <IconButton
              aria-label="Explorer"
              className={styles.searchButton}
              onClick={() => setExplorerVisible(!explorerVisible)}
            >
              <RiSearchLine
                style={explorerVisible && { color: 'var(--color-logo)' }}
              />
            </IconButton>

            <IconButton
              aria-label="Burger Menu"
              className={styles.gridButton}
              onClick={() => {
                setMenu(1);
              }}
            >
              <TbGridDots size="2.2rem" />
            </IconButton>
          </div>
        </div>

        <div className={styles.largeScreen}>
          <fieldset className={styles.advanced}>
            <PlainCurrencyPicker
              defaultText={LL.general.coin()}
              selectedKey={formik.values.coin}
              onSelectionChange={async (key) => {
                const network = await getInitialSelectedKey(key + '');
                formik.setFieldValue('coin', key, false);
                formik.setFieldValue('network', network.network_name, false);
              }}
            />
            {formik.values.coin ? (
              <NetworkPicker
                explorer
                coin={formik.values.coin}
                selectedKey={formik.values.network}
                onSelectionChange={(value) => {
                  formik.setFieldValue('network', value, false);
                }}
              />
            ) : (
              <div style={{ marginLeft: '0.2rem' }}></div>
            )}

            <SearchTextField
              placeholder={LL.explorer.searchPlaceholder()}
              value={formik.values.txid}
              onChange={(val) => formik.setFieldValue('txid', val, false)}
              onFocusChange={() => setOpacity(!opacity)}
              leftButton={
                <RiSearchLine
                  size="1.8rem"
                  className={styles.searchIcon}
                  style={{
                    fill: 'url(#svg-gradient)',
                    opacity: opacity ? '1' : '0.6',
                  }}
                ></RiSearchLine>
              }
            />
            <IconButton
              aria-label="Explorer Search"
              type="submit"
              isDisabled={formik.isSubmitting}
              className={styles.searchButton}
            >
              <TbArrowRight size="2.5rem" />
            </IconButton>
            <NewHeader
              className={styles.header}
              setMenu={setMenu}
            />
          </fieldset>
        </div>

        {explorerVisible && (
          <div className={styles.smallScreen}>
            <fieldset className={styles.advanced}>
              <PlainCurrencyPicker
                defaultText="Coin"
                selectedKey={formik.values.coin}
                onSelectionChange={async (key) => {
                  const network = await getInitialSelectedKey(key + '');
                  formik.setFieldValue('coin', key, false);
                  formik.setFieldValue('network', network.network_name, false);
                }}
              />
              {formik.values.coin ? (
                <NetworkPicker
                  explorer
                  coin={formik.values.coin}
                  selectedKey={formik.values.network}
                  onSelectionChange={(value) => {
                    formik.setFieldValue('network', value, false);
                  }}
                />
              ) : (
                <div style={{ marginLeft: '0.2rem' }}></div>
              )}
              <SearchTextField
                placeholder="Search Transactions, Addresses, Blocks"
                value={formik.values.txid}
                onChange={(val) => formik.setFieldValue('txid', val, false)}
                onFocusChange={() => setOpacity(!opacity)}
                leftButton={
                  <RiSearchLine
                    size="1.8rem"
                    className={styles.searchIcon}
                    style={{
                      fill: 'url(#svg-gradient)',
                      opacity: opacity ? '1' : '0.6',
                    }}
                  ></RiSearchLine>
                }
              />
              <IconButton
                aria-label="Explorer Search"
                type="submit"
                isDisabled={formik.isSubmitting}
                className={styles.searchButton}
              >
                <TbArrowRight size="2.5rem" />
              </IconButton>
            </fieldset>
          </div>
        )}
      </Surface>
      <BurgerMenu
        menu={menu}
        setMenu={setMenu}
      />
      {/* Weird solution to adding linear-gradient to react icons
        TODO: Find a better solution
       */}
      <svg
        width="0"
        height="0"
        style={{ position: 'absolute' }}
      >
        <linearGradient
          id="svg-gradient"
          x1="100%"
          y1="100%"
          x2="0%"
          y2="0%"
        >
          <stop
            stopColor="#fe8B00"
            offset="0%"
          />
          <stop
            stopColor="#ff4500"
            offset="100%"
          />
        </linearGradient>
      </svg>
    </>
  );
};

export default Explorer;
