import React from 'react';
import { connect } from 'react-redux';

import './alert.styles.css';

const Alert = ({ alert }) =>
  alert !== null && (
    <div className={`alert alert-${alert.alertType}`}>{alert.msg}</div>
  );

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps)(Alert);
