// @ts-check
import * as React from 'react';
import ListBox from './ListBox.component';
import { useListState } from '@react-stately/list';

// obj === object; obj !== any ¯\_(ツ)_/¯
/** @typedef {NonNullable<Parameters<typeof Object.create>[0]>} obj */

/**
 * @template  T
 * @typedef LocalProps
 * @property {import("@react-types/shared").CollectionChildren<T>} children
 * @property {boolean} [isVariant]
 * @property {(nodes: Iterable<import("@react-types/shared").Node<T>>) => Iterable<import("@react-types/shared").Node<T>>} [filter]
 *
 * @property {'toggle' | 'replace'} [selectionBehavior]
 * How multiple selection should behave in the collection.
 *
 * @property {boolean} [allowDuplicateSelectionEvents]
 * Whether onSelectionChange should fire even if the new set of keys is the same as the last.
 *
 * @property { boolean } [isVirtualized]
 *
 * @property { boolean } [shouldUseVirtualFocus]
 * Whether the listbox items should use virtual focus instead of being focused directly.
 *
 * @property { boolean } [shouldSelectOnPressUp]
 * Whether selection should occur on press up instead of press down.
 *
 * @property { boolean } [shouldFocusOnHover]
 * Whether options should be focused when the user hovers over them.
 *
 * @property { React.ReactNode } [label]
 *
 * @property { boolean | "first" | "last" } [autoFocus]
 * Whether to auto focus the listbox or an option.
 *
 * @property { boolean } [shouldFocusWrap]
 * Whether focus should wrap around when the end/start is reached.
 *
 * @property { Iterable<T> } [items]
 * @property { Iterable <React.Key> } [disabledKeys]
 * @property { 'none' | 'single' | 'multiple' } [selectionMode]
 * @property { boolean } [disallowEmptySelection]
 * @property { 'all' | Iterable <React.Key> } [selectedKeys]
 * @property { (keys : 'all' | Set<React.Key>) => void } [onSelectionChange]
 * @property { (e : React.FocusEvent) => void } [onFocus]
 * @property { (e : React.FocusEvent) => void } [onBlur]
 * @property { (isFocused : boolean) => void } [onFocusChange]
 * @property { string } [id]
 * @property { string } [aria-label]
 * @property { string } [aria-labelledby]
 * @property { string } [aria-describedby]
 * @property { string } [aria-details]
 *
 */

/**
 * Most of the time parent component should use `ListBox` with `useListBoxState` instead of this.
 * In rare cases where Standalone ListBox is desiered, this component is available
 *
 * @template {obj} T
 * @param {LocalProps<T>} props
 */
function StatefulListBox(props) {
  const ref = React.useRef();
  const state = useListState(props);

  return (
    <ListBox
      ref={ref}
      {...props}
      state={state}
    />
  );
}

export default StatefulListBox;
