// @ts-check
import * as React from 'react';
import styles from './ElevatedButton.module.css';
import ButtonBase from './ButtonBase';
import classNames from 'classnames';
import useHighlightRadius from '../../hooks/useHighlightRadius';
import useDefaultRef from './../../hooks/useDefaultRef';

/**
 * @typedef ElevatedButtonProps
 *
 * @property {any} children
 * @property {string} [id]
 * @property {string} [className]
 * @property {any} [left] icon or image on the left side of button content
 * @property {any} [right] icon or image on the right side of button contet
 * @property {boolean} [attachesBackground] whether to do background attach animation on hover
 * @property {boolean} [pressesBackground] whether to do background press animation on press
 * @property {boolean} [isDisabled]
 * @property {(e: import("@react-types/shared").PressEvent) => void} [onPressStart]
 * @property {(isPressed: boolean) => void} [onPressChange] Handler that is called when the press state changes.
 * @property {(e: import("@react-types/shared").PressEvent) => void} [onPressEnd]
 * @property {(e: import("@react-types/shared").PressEvent) => void} [onPress]
 * @property {(e: import("@react-types/shared").KeyboardEvent) => void} [onKeyDown]
 * @property {(e: import("@react-types/shared").KeyboardEvent) => void} [onKeyUp]
 * @property {(isFocused: boolean) => void} [onFocusChange] Handler that is called when the element's focus status changes.
 * @property {(e:React.FocusEvent<Element, Element>) => void} [onBlur]
 * @property {boolean} [autoFocus]
 * @property {'button' | 'submit'| 'reset'} [type] The behavior of the button when used in an HTML form.
 *
 * @property {"a" | "button"} [elementType]
 * @property {string} [href] href for anchor tag
 * @property {string} [target] target for anchor tag
 * @property {string} [rel] The relationship between the linked resource and the current page when elementType="a"
 *
 * @property {boolean} [excludeFromTabOrder]
 * Whether to exclude the element from the sequential tab order.
 * If true, the element will not be focusable via the keyboard by tabbing.
 * This should be avoided except in rare scenarios where an alternative means
 * of accessing the element or its functionality via the keyboard is available.
 *
 * @property {boolean} [aria-expanded]
 * Indicates whether the element, or another grouping element it controls,
 * is currently expanded or collapsed.
 *
 * @property {boolean | "dialog" | "menu" | "listbox" | "tree" | "grid"} [aria-haspopup]
 * Indicates the availability and type of interactive popup element,
 * such as menu or dialog, that can be triggered by an element.
 *
 * @property {string} [aria-controls]
 * Identifies the element (or elements) whose contents or presence are controlled by the current element.
 *
 * @property {boolean} [aria-pressed]
 * Indicates the current "pressed" state of toggle buttons.
 *
 * @property {string} [aria-label]
 * Defines a string value that labels the current element. (for icons and images)
 *
 * @property {string} [aria-labelledby]
 * Identifies the element (or elements) that labels the current element.
 *
 * @property {string} [aria-describedby]
 * dentifies the element (or elements) that describes the object.
 *
 * @property {string} [aria-details]
 * Identifies the element (or elements) that provide a detailed, extended description for the object.
 *
 * @property {(...args: any[]) => any} [onClick]
 * Should not be used in most cases. is an alias for onPress and is here to bridge the gap
 * for the libraries that assume onClick
 *
 * @param {ElevatedButtonProps} props
 * @returns
 */
function ElevatedButtonWithRef(
  {
    children,
    id,
    className,
    left,
    right,
    attachesBackground,
    pressesBackground,
    isDisabled,
    onPressStart,
    onPressChange,
    onPressEnd,
    onPress,
    onKeyDown,
    onKeyUp,
    onFocusChange,
    onBlur,
    autoFocus,
    excludeFromTabOrder,
    type,
    elementType,
    href,
    target,
    rel,
    'aria-expanded': ariaExpanded,
    'aria-haspopup': ariaHasPopup,
    'aria-controls': ariaControls,
    'aria-pressed': ariaPressed,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedby,
    'aria-details': ariDetails,
    onClick,
  },
  parentRef
) {
  const ref = useDefaultRef(parentRef);

  const interactionProps = useHighlightRadius(ref);

  return (
    <ButtonBase
      ref={ref}
      classNames={{
        button: classNames(className, 'type-label-large', styles.button, {
          [styles.hasLeftIcon]: left,
          [styles.hasRightIcon]: right,
        }),
        focusRing: styles.focusRing,
      }}
      id={id}
      isDisabled={isDisabled}
      onPressStart={onPressStart}
      onPressChange={onPressChange}
      onPressEnd={onPressEnd}
      onPress={onPress || onClick}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onFocusChange={onFocusChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      attachesBackground={attachesBackground}
      pressesBackground={pressesBackground}
      {...interactionProps}
      pressWidth={10}
      attachWidth={12}
      type={type}
      elementType={elementType}
      href={href}
      target={target}
      rel={rel}
      excludeFromTabOrder={excludeFromTabOrder}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHasPopup}
      aria-controls={ariaControls}
      aria-pressed={ariaPressed}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedby}
      // false positive
      // eslint-disable-next-line jsx-a11y/aria-props
      aria-details={ariDetails}
      whileTap={{ scale: 0.98 }}
      baseElevation={isDisabled ? 0 : 1}
      hoverElevation={isDisabled ? 0 : 2}
      pressElevation={isDisabled ? 0 : 1}
      elevationType="both"
    >
      <span className={styles.buttonContent}>
        {left}
        <span className={styles.label}>{children}</span>
        {right}
      </span>
    </ButtonBase>
  );
}

export default React.forwardRef(ElevatedButtonWithRef);
