// @ts-check

import React from 'react';
import './App.css';
import './styles';

import { Route, Routes } from 'react-router-dom';

// import SignIn from './pages/signIn-page/signIn.page';
import AppLayout from 'layouts/AppLayout.layout';
import DashboardLayout from 'layouts/DashboardLayout.layout';
import ExplorerApp from 'layouts/ExplorerApp.layout';
import LandingLayout from 'layouts/LandingLayout.layout';
import Exchange from 'pages/exchange/Exchange.page';
import ExchangeExplorer from 'pages/explorer/ExchangeExplorer.component';
import Explorer from 'pages/explorer/Explorer.component';
import ExplorerPage from 'pages/explorer/Explorer.page';
import Markets from 'pages/markets/Markets.page';
import Merchant from 'pages/merchant/Merchant.page';
import MyWallets from 'pages/my-wallets/MyWallets.page';
import Overview from 'pages/overview/Overview.page';
import Profile from 'pages/profile/Profile.page';
import SignUpPage from 'pages/sign-up/sign-up.page';
import PasswordRecovery from 'pages/signIn/PasswordRecovery/PasswordRecovery.page';
import PasswordReset from 'pages/signIn/PasswordRecovery/PasswordReset.component';
import Transactions from 'pages/transactions/Transactions.page';
import PrivateLayout from './layouts/PrivateLayout';
import DesignSystem from './pages/design-system/DesignSystem.page';
import ErrorPage from './pages/error-page/error.page';
import InvoicePage from './pages/invoice-page/invoice.page';
import LandingPage from './pages/landing/landing.page';
import Security from './pages/security-page/security.page';

const App = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        {/* <div className={darkMode ? 'bg-wrapper' : 'bg-wrapper-light'}></div> */}
        <Route element={<LandingLayout />}>
          <Route
            path="*"
            element={<ErrorPage />}
          />

          <Route
            path="/"
            element={<LandingPage />}
          />

          <Route
            path="/design-system/*"
            element={<DesignSystem />}
          ></Route>

          <Route
            path="/signup"
            element={<SignUpPage />}
          />

          <Route
            path="/password-recovery"
            element={<PasswordRecovery />}
          />
          <Route
            path="/password-recovery/email/verify"
            element={<PasswordReset />}
          />
        </Route>
        <Route
          path="/explorer"
          element={<ExplorerPage />}
        >
          <Route
            path=":coin/:network/:txid"
            element={<Explorer />}
          />
          <Route
            path="exchange/:id"
            element={<ExchangeExplorer />}
          />
        </Route>

        <Route element={<PrivateLayout />}>
          <Route
            element={
              <DashboardLayout
                hideQuickExchangeOn={['/exchange']}
                quickExchangeOpen={false}
              />
            }
          >
            <Route
              path="/overview"
              element={<Overview />}
            />
            <Route
              path="/my-wallets"
              element={<MyWallets />}
            />
            <Route
              path="/transactions"
              element={<Transactions />}
            />
            <Route
              path="/exchange"
              element={<Exchange />}
            />
            <Route
              path="/markets"
              element={<Markets />}
            />
            <Route
              path="/merchant"
              element={<Merchant />}
            />
            <Route
              path="/profile"
              element={<Profile />}
            />
            <Route
              path="/security"
              element={<Security />}
            />
            <Route
              path="/payment-requested/:id"
              // @ts-ignore
              element={<InvoicePage />}
            />
          </Route>
        </Route>
      </Route>
      <Route
        path="/explorer/:coin/:network/:txid/app"
        element={<ExplorerApp />}
      />
    </Routes>
  );

  // return (
  //   <Routes>
  //     <Route element={<AppLayout />}>
  //       <Route path="/payment-requested/:id" element={<InvoicePage />} />
  //       <Route path="/design-system/*" element={<DesignSystem />} />

  //       <Route
  //         path="/dashboard/:lang"
  //         element={
  //           <PrivateRoute>
  //             <HomePage />
  //           </PrivateRoute>
  //         }
  //       />
  //       <Route
  //         path="/dashboard"
  //         element={
  //           <PrivateRoute>
  //             <HomePage />
  //           </PrivateRoute>
  //         }
  //       />
  //       <Route
  //         path="/security"
  //         element={
  //           <PrivateRoute>
  //             <Security />
  //           </PrivateRoute>
  //         }
  //       />
  //       <Route
  //         path="/security/two-factor-auth"
  //         element={
  //           <PrivateRoute>
  //             <TwoFactorPage />
  //           </PrivateRoute>
  //         }
  //       />
  //       <Route
  //         path="/security/ip-whitelist"
  //         element={
  //           <PrivateRoute>
  //             <IpWhitelistPage />
  //           </PrivateRoute>
  //         }
  //       />
  //       <Route
  //         path="/security/api-keys"
  //         element={
  //           <PrivateRoute>
  //             <ApiKeyPage />
  //           </PrivateRoute>
  //         }
  //       />
  //       <Route
  //         path="/general-balance"
  //         element={
  //           <PrivateRoute>
  //             <GeneralBalance />
  //           </PrivateRoute>
  //         }
  //       />
  //     </Route>
  //   </Routes>
  // );
};

export default App;
