// @ts-check
import * as React from 'react';
import ReactCopyToClipboard from 'react-copy-to-clipboard';

function ClickDelegate({ onClick, children }) {
  const child = React.isValidElement(children) ? children : false;

  if (!child) {
    throw new Error(
      'Please provide valid one-element child to CopyToClicpboard component with an onPress handle'
    );
  }

  return React.cloneElement(child, { onPress: onClick });
}

/**
 * CopyToClipboard but works with react-aria components (uses onPress instead of onClick)
 * @param {CopyToClipboard.Props} props
 */
export default function CopyToClipboard({ children, ...rest }) {
  return (
    <ReactCopyToClipboard {...rest}>
      {/* @ts-ignore CopyToClipboard provides onClick prop */}
      <ClickDelegate>{children}</ClickDelegate>
    </ReactCopyToClipboard>
  );
}
