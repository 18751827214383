// @ts-check
import { useId } from '@react-aria/utils';
import axios from 'axios';
import { useLocale } from 'context/LanguageContext';
import { fiatCurrencies } from 'currencies';
import { useFormik } from 'formik';
import qs from 'qs';
import { useBalancesQuery } from 'queries/balances';
import { useRatesQuery } from 'queries/rates';
import { useUserCredentialsQuery } from 'queries/userCredentials';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { isVisible } from 'utils';
import { boolean, number, object, string } from 'yup';
/**
 * @template {Record<string, string>} T
 * @param {T} errorMap
 * @param {any[]} [resetDeps] // when an item in an array changes error resets.
 **/
function useServerError(errorMap, resetDeps = []) {
  /** @type {[keyof T, React.Dispatch<React.SetStateAction<keyof T>> ]} */
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    serverError: errorMap[errorKey],
    setServerError: setErrorKey,
  };
}

/**
 * @typedef {"send" | "receive" | "p2p" | "swap"} Tab
 *
 * @typedef ExchangeContextValue
 * @property {ReturnType<typeof useSendForm>} send
 * @property {ReturnType<typeof useReceiveForm>} receive
 * @property {ReturnType<typeof useP2PForm>} p2p
 * @property {ReturnType<typeof useSwapForm>} swap
 * @property {Tab | null} selectedTab
 * @property {string} quickExchangeId
 * @property {React.Dispatch<Tab>} setSelectedTab,
 * @property {Record<Tab, Tab>} tabs
 * @property {<T extends Tab>(tab: T, state: Partial<ExchangeContextValue[T]["values"]>) => void} navigate
 * @property {(type: "quickExchange" | "exchangeNavigation", listener: () => void) => () => void} addEventListener
 */

/** @type {React.Context<ExchangeContextValue | undefined>} */
const ExchangeContext = React.createContext(undefined);

function useSendForm() {
  const { LL } = useLocale();
  const { data } = useBalancesQuery();
  const { data: rates } = useRatesQuery();
  const statusRef = React.useRef(null);
  const [success, setSuccess] = React.useState(false);
  const [url, setUrl] = React.useState('');

  const scrollToStatus = () => {
    statusRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
    statusRef.current.focus({ preventScroll: true });
  };

  // TODO: amount <= balance
  // TODO: amount >= min required amount
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      coin: /** @type {import('currencies').Currency | null} */ (null),
      address: '',
      amount: NaN,
      comment: '',
      // TODO: WTF
      comment_to: 'test',
      tag: null,
      fee_type: /** @type {import("types").FeeType} */ ('standard'),
      network: '',
      xrpOrXlm: false,
      templateName: '',
    },
    validationSchema: object({
      coin: string().nullable().required(LL.exchange.errors.chooseBalance()),
      amount: number()
        .test({
          message: LL.exchange.serverErrors.funds(),
          test: (amount, { parent }) =>
            amount <=
            data.filter((el) => el.coin === parent.coin)[0].confirmed_balance,
        })
        .when('coin', {
          is: (coin) => fiatCurrencies.includes(coin),
          then: number().test({
            message: 'Minumum amount should be equivalent of 20 USD',
            test: (amount, { parent }) => {
              console.log(rates['usd'][parent.coin]);
              return amount >= 20 * rates['usd'][parent.coin];
            },
          }),
        })
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(LL.exchange.errors.amount())
        .positive(LL.exchange.errors.amountPositive()),
      xrpOrXlm: boolean(),
      address: string().when('coin', (coin, schema) => {
        if (!fiatCurrencies.includes(coin))
          return schema.required(LL.exchange.errors.address());
        return schema;
      }),
      comment: string(),
      tag: string().nullable().when('xrpOrXlm', {
        is: true,
        then: string(),
      }),
      fee_type: string().when('coin', (coin, schema) => {
        if (!fiatCurrencies.includes(coin)) return schema.required();
        return schema;
      }),
      network: string().when('coin', (coin, schema) => {
        if (!fiatCurrencies.includes(coin)) return schema.required();
        return schema;
      }),
      templateName: string(),
    }),
    onSubmit: async (values, { resetForm, setTouched }) => {
      const data = {
        coin: values.coin,
        address: values.address,
        amount: values.amount,
        comment: values.comment,
        // comment_to: 'test',
        // tag: values.tag,
        fee_type: values.fee_type,
        network: values.network,
      };

      if (values.xrpOrXlm) {
        data['tag'] = values.tag;
      }

      try {
        // @ts-ignore
        const response = !fiatCurrencies.includes(values.coin)
          ? await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URI}withdraw`,
              data: qs.stringify(data),
              headers: {
                'content-type': 'application/x-www-form-urlencoded',
                Authorization: localStorage.getItem('token'),
              },
            })
          : await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URI}fiat/withdrawal`,
              data: qs.stringify({
                currency: values.coin,
                amount: values.amount,
              }),
              headers: {
                'content-type': 'application/x-www-form-urlencoded',
                Authorization: localStorage.getItem('token'),
              },
            });

        setServerError(null);
        setSuccess(true);

        if (!response?.data?.url) {
          setTimeout(() => {
            setSuccess(false);
          }, 15000);
          resetForm();
        }
        if (response?.data?.url) setUrl(response.data.url);
      } catch (error) {
        // reset client side validation
        setTouched({});

        if (error.response && errorMap[error.response.data.error]) {
          // The request was made and server responded with an error
          setServerError(error.response.data.error);
        } else {
          // The request did not go through (or)
          // no response was received (or)
          // response.data.error is something we could not predict
          console.warn('useSendForm unexpected error', error);
          setServerError('ERR_GENERIC');
        }
      }
    },
  });
  React.useEffect(() => {
    console.warn(
      'ERR_INSUFFICIENT_FUNDS of withdrawal is not implemented yet and instead server returns a `Request failed with status code 500` error'
    );
  }, []);
  const errorMap = {
    ERR_INVALID_ADDRESS_PARAM: LL.exchange.serverErrors.address(),
    ERR_INSUFFICIENT_FUNDS: LL.exchange.serverErrors.funds(),
    ERR_GENERIC: LL.exchange.serverErrors.generic(),
    ERR_INVALID_AMOUNT: LL.exchange.serverErrors.invalidAmount(),

    'Invalid Amount': LL.exchange.serverErrors.funds(),
    'Not enough coins.': LL.exchange.serverErrors.funds(),
    'Template already exists': LL.exchange.serverErrors.templateExists(),
    'Template name already exists':
      LL.exchange.serverErrors.templateNameExists(),
    'Template not found': LL.exchange.serverErrors.templateNotFound(),
  };

  const { serverError, setServerError } = useServerError(errorMap, [
    formik.values.coin,
  ]);

  return {
    ...formik,
    serverError,
    setServerError,
    success,
    setSuccess,
    statusRef,
    scrollToStatus,
    url,
  };
}

function useReceiveForm() {
  const { LL } = useLocale();
  const { data } = useUserCredentialsQuery();
  const [url, setUrl] = React.useState('');

  const formik = useFormik({
    initialValues: {
      coin: /** @type {import('currencies').Currency | null} */ (null),
      network: /** @type {import("types").NetworkType | null} */ (null),
      requestAmount: NaN,
    },
    validationSchema: object({
      coin: string().nullable().required(LL.exchange.errors.chooseBalance()),
      network: string().nullable().required(),
      requestAmount: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(LL.exchange.errors.amount())
        .positive(LL.exchange.errors.amountPositive()),
    }),
    onSubmit: async ({ coin, requestAmount }) => {
      setUrl('');
      try {
        const response =
          // @ts-ignore
          fiatCurrencies.includes(coin) &&
          (await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URI}fiat/deposit`,
            data: qs.stringify({
              currency: coin,
              amount: requestAmount,
              client_name: data.name,
              client_email: data.email,
            }),
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              Authorization: localStorage.getItem('token'),
            },
          }));

        if (response?.data?.url) setUrl(response.data.url);
      } catch (error) {
        console.warn('useSendForm unexpected error', error);
      }
    },
  });

  return {
    ...formik,
    url,
    setUrl,
  };
}

function useP2PForm() {
  const { LL } = useLocale();
  const { data } = useBalancesQuery();
  const statusRef = React.useRef(null);
  const [success, setSuccess] = React.useState(false);

  const scrollToStatus = () => {
    statusRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
    statusRef.current.focus({ preventScroll: true });
  };

  const formik = useFormik({
    initialValues: {
      coin: /** @type {import('currencies').Currency | null} */ (null),
      email: '',
      amount: NaN,
      comment: '',
      network: null,
    },
    validationSchema: object({
      coin: string().nullable().required(LL.exchange.errors.chooseBalance()),
      email: string().email().required(LL.exchange.errors.email()),
      amount: number()
        .test({
          message: LL.exchange.serverErrors.funds(),
          test: (amount, { parent }) =>
            amount <
            data.filter((el) => el.coin === parent.coin)[0].confirmed_balance,
        })
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(LL.exchange.errors.amount())
        .positive(LL.exchange.errors.amountPositive()),
      comment: string(),
      network: string().nullable().required(),
    }),
    onSubmit: async (values, { resetForm, setTouched }) => {
      const data = {
        toUserEmail: values.email,
        currency: values.coin,
        amount: values.amount,
        network: values.network,
      };

      try {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URI}p2p-transactions`,
          data: qs.stringify(data),
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: localStorage.getItem('token'),
          },
        });

        setServerError(null);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
        resetForm();
      } catch (error) {
        // reset client side validation
        setTouched({});

        /** [docs about error handling]{@link https://axios-http.com/docs/handling_errors} */

        if (error.response && errorMap[error.response.data.error]) {
          // The request was made and server responded with an error
          setServerError(error.response.data.error);
        } else {
          // The request did not go through (or)
          // no response was received (or)
          // response.data.error is something we could not predict
          console.warn('useP2PForm unexpected error', error);
          setServerError('ERR_GENERIC');
        }
      }
    },
  });

  const errorMap = {
    ERR_GENERIC: LL.exchange.serverErrors.generic(),
    ERR_INSUFFICIENT_FUNDS: LL.exchange.serverErrors.insufficientCoin({
      coin: formik.values.coin || '',
    }),
    ERR_INVALID_TO_USER_EMAIL: LL.exchange.serverErrors.email(),
    ERR_INVALID_AMOUNT: LL.exchange.serverErrors.invalidAmount(),
    'Template already exists': LL.exchange.serverErrors.templateExists(),
    'Template not found': LL.exchange.serverErrors.templateEmailNotFound(),
  };

  const { serverError, setServerError } = useServerError(errorMap, [
    formik.values.coin,
  ]);

  return {
    ...formik,
    serverError,
    setServerError,
    success,
    setSuccess,
    statusRef,
    scrollToStatus,
  };
}

function useSwapForm() {
  const { LL } = useLocale();
  const { data } = useBalancesQuery();
  const statusRef = React.useRef(null);
  const [success, setSuccess] = React.useState(false);
  const [swapId, setSwapId] = React.useState(null);

  const scrollToStatus = () => {
    statusRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
    statusRef.current.focus({ preventScroll: true });
  };

  const formik = useFormik({
    initialValues: {
      from: /** @type {import('currencies').Currency | null} */ (null),
      to: /** @type {import('currencies').Currency | null} */ (null),
      amount: NaN,
    },
    validationSchema: object({
      from: string().nullable().required(LL.exchange.errors.chooseBalance()),
      to: string().nullable().required(LL.exchange.errors.currency()),
      amount: number()
        .test({
          message: LL.exchange.serverErrors.funds(),
          test: (amount, { parent }) =>
            amount <
            data.filter((el) => el.coin === parent.from)[0].confirmed_balance,
        })
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(LL.exchange.errors.amount())
        .positive(LL.exchange.errors.amountPositive()),
    }),
    onSubmit: async (values, { resetForm, setTouched }) => {
      const data = {
        from: values.from,
        to: values.to,
        amount: values.amount,
      };

      try {
        const response = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URI}exchange`,
          data: qs.stringify(data),
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: localStorage.getItem('token'),
          },
        });

        setServerError(null);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
        setSwapId(response.data.id);
        console.log(response.data.id);
        resetForm();
      } catch (error) {
        // reset client side validation
        setTouched({});

        if (error.response && errorMap[error.response.data.error]) {
          // The request was made and server responded with an error
          setServerError(error.response.data.error);
        } else {
          // The request did not go through (or)
          // no response was received (or)
          // response.data.error is something we could not predict
          console.warn('useSwapForm unexpected error', error);
          setServerError('ERR_GENERIC');
        }
      }
    },
  });

  const { from } = formik.values;
  const errorMap = {
    ERR_NO_RELIABLE_RATE: LL.exchange.serverErrors.rates(),
    ERR_NO_RELIABLE_RATES_SOURCE: LL.exchange.serverErrors.ratesSource(),
    ERR_INSUFFICIENT_FUNDS: LL.exchange.serverErrors.insufficientCoin({
      coin: from,
    }),
    ERR_GENERIC: LL.exchange.serverErrors.generic(),
  };

  const { serverError, setServerError } = useServerError(errorMap, [from]);

  return {
    ...formik,
    serverError,
    setServerError,
    success,
    setSuccess,
    statusRef,
    scrollToStatus,
    swapId,
    setSwapId,
  };
}

export function ExchangeProvider({ children }) {
  /** @type {[Tab | null, React.Dispatch<React.SetStateAction<Tab>>]} */
  const [selectedTab, setSelectedTab] = React.useState('send');
  const quickExchangeId = useId();

  const sendForm = useSendForm();
  const receiveForm = useReceiveForm();
  const p2pForm = useP2PForm();
  const swapForm = useSwapForm();

  /** @type {React.MutableRefObject<(() => void)[]>} */
  const quickExchangeListeners = React.useRef([]);
  /** @type {React.MutableRefObject<(() => void)[]>} */
  const exchangeNavigationListeners = React.useRef([]);

  const navigate = useNavigate();

  const value = React.useMemo(() => {
    /** @type {ExchangeContextValue} */
    const forms = {
      send: sendForm,
      receive: receiveForm,
      p2p: p2pForm,
      swap: swapForm,
      selectedTab,
      /** @type {Record<Tab, Tab>} */
      tabs: {
        send: 'send',
        receive: 'receive',
        p2p: 'p2p',
        swap: 'swap',
      },
      quickExchangeId,
      setSelectedTab: (tab) => {
        forms[tab].resetForm();
        setSelectedTab(tab);
      },

      navigate: (tab, state) => {
        // Set tab state
        value.setSelectedTab(tab);
        if (state) {
          // Set formik state
          // @ts-ignore idk what to do. I think it's ts being dumb
          forms[tab].setValues({ ...forms[tab].initialValues, ...state });
        }

        const isQuickExchangeVisible = isVisible(
          document.getElementById(quickExchangeId)
        );

        if (isQuickExchangeVisible) {
          quickExchangeListeners.current.forEach((listener) => {
            listener();
          });
        } else {
          navigate('/exchange', { state: { focus: true } });
        }
      },
      addEventListener: (type, listener) => {
        const filterFn = (addedListener) => addedListener !== listener;

        if (type === 'quickExchange') {
          quickExchangeListeners.current.push(listener);

          return () => {
            quickExchangeListeners.current =
              quickExchangeListeners.current.filter(filterFn);
          };
        } else if (type === 'exchangeNavigation') {
          exchangeNavigationListeners.current.push(listener);

          return () => {
            exchangeNavigationListeners.current =
              exchangeNavigationListeners.current.filter(filterFn);
          };
        }
      },
    };

    return forms;
  }, [
    navigate,
    p2pForm,
    quickExchangeId,
    receiveForm,
    selectedTab,
    sendForm,
    swapForm,
  ]);

  return (
    <ExchangeContext.Provider value={value}>
      {children}
    </ExchangeContext.Provider>
  );
}

export function useExchangeContext() {
  const context = React.useContext(ExchangeContext);
  if (context === undefined) {
    throw new Error('useExchange must be used within ExchangeProvider');
  }
  return context;
}
