/**
 * Do not edit directly
 * Generated on Tue, 09 Aug 2022 09:47:51 GMT
 */

export const MdSysColorPrimaryLight = "#b32b00";
export const MdSysColorOnPrimaryLight = "#ffffff";
export const MdSysColorPrimaryContainerLight = "#ffdacf";
export const MdSysColorOnPrimaryContainerLight = "#3c0800";
export const MdSysColorSecondaryLight = "#914c00";
export const MdSysColorOnSecondaryLight = "#ffffff";
export const MdSysColorSecondaryContainerLight = "#ffdcc1";
export const MdSysColorOnSecondaryContainerLight = "#2f1400";
export const MdSysColorTertiaryLight = "#006878";
export const MdSysColorOnTertiaryLight = "#ffffff";
export const MdSysColorTertiaryContainerLight = "#9eefff";
export const MdSysColorOnTertiaryContainerLight = "#001f25";
export const MdSysColorErrorLight = "#ba1b1b";
export const MdSysColorErrorContainerLight = "#ffdad4";
export const MdSysColorOnErrorLight = "#ffffff";
export const MdSysColorOnErrorContainerLight = "#410001";
export const MdSysColorBackgroundLight = "#fbfdfd";
export const MdSysColorOnBackgroundLight = "#191c1d";
export const MdSysColorSurfaceLight = "#fbfdfd";
export const MdSysColorOnSurfaceLight = "#191c1d";
export const MdSysColorSurfaceVariantLight = "#f5ded9";
export const MdSysColorOnSurfaceVariantLight = "#251916";
export const MdSysColorOutlineLight = "#85736f";
export const MdSysColorInverseOnSurfaceLight = "#eff1f1";
export const MdSysColorInverseSurfaceLight = "#2d3132";
export const MdSysColorInversePrimaryLight = "#ffb49e";
export const MdSysColorShadowLight = "#000000";
export const MdSysColorPrimaryDark = "#ffb49e";
export const MdSysColorOnPrimaryDark = "#611300";
export const MdSysColorPrimaryContainerDark = "#891e00";
export const MdSysColorOnPrimaryContainerDark = "#ffdacf";
export const MdSysColorSecondaryDark = "#fe8b00";
export const MdSysColorOnSecondaryDark = "#4e2600";
export const MdSysColorSecondaryContainerDark = "#b23400";
export const MdSysColorOnSecondaryContainerDark = "#ffdcc1";
export const MdSysColorTertiaryDark = "#22d9f5";
export const MdSysColorOnTertiaryDark = "#00363f";
export const MdSysColorTertiaryContainerDark = "#004e5a";
export const MdSysColorOnTertiaryContainerDark = "#9eefff";
export const MdSysColorErrorDark = "#ffb4a9";
export const MdSysColorErrorContainerDark = "#930006";
export const MdSysColorOnErrorDark = "#680003";
export const MdSysColorOnErrorContainerDark = "#ffdad4";
export const MdSysColorBackgroundDark = "#191c1d";
export const MdSysColorOnBackgroundDark = "#e0e3e3";
export const MdSysColorSurfaceDark = "#191c1d";
export const MdSysColorOnSurfaceDark = "#e0e3e3";
export const MdSysColorSurfaceVariantDark = "#534340";
export const MdSysColorOnSurfaceVariantDark = "#ffede8";
export const MdSysColorOutlineDark = "#a08c87";
export const MdSysColorInverseOnSurfaceDark = "#191c1d";
export const MdSysColorInverseSurfaceDark = "#e0e3e3";
export const MdSysColorInversePrimaryDark = "#b32b00";
export const MdSysColorShadowDark = "#000000";
export const MdRefPalettePrimary100 = "#ffffff";
export const MdRefPalettePrimary99 = "#fcfcfc";
export const MdRefPalettePrimary95 = "#ffede7";
export const MdRefPalettePrimary90 = "#ffdacf";
export const MdRefPalettePrimary80 = "#ffb49e";
export const MdRefPalettePrimary70 = "#ff8b68";
export const MdRefPalettePrimary60 = "#ff561f";
export const MdRefPalettePrimary50 = "#df3800";
export const MdRefPalettePrimary40 = "#b32b00";
export const MdRefPalettePrimary30 = "#891e00";
export const MdRefPalettePrimary20 = "#611300";
export const MdRefPalettePrimary10 = "#3c0800";
export const MdRefPalettePrimary0 = "#000000";
export const MdRefPaletteSecondary100 = "#ffffff";
export const MdRefPaletteSecondary99 = "#fcfcfc";
export const MdRefPaletteSecondary95 = "#ffede0";
export const MdRefPaletteSecondary90 = "#ffdcc1";
export const MdRefPaletteSecondary80 = "#ffb77a";
export const MdRefPaletteSecondary70 = "#ff8e08";
export const MdRefPaletteSecondary60 = "#dc7600";
export const MdRefPaletteSecondary50 = "#b66100";
export const MdRefPaletteSecondary40 = "#914c00";
export const MdRefPaletteSecondary30 = "#6f3900";
export const MdRefPaletteSecondary20 = "#4e2600";
export const MdRefPaletteSecondary10 = "#2f1400";
export const MdRefPaletteSecondary0 = "#000000";
export const MdRefPaletteTertiary100 = "#ffffff";
export const MdRefPaletteTertiary99 = "#f6fdff";
export const MdRefPaletteTertiary95 = "#d0f7ff";
export const MdRefPaletteTertiary90 = "#9eefff";
export const MdRefPaletteTertiary80 = "#22d9f5";
export const MdRefPaletteTertiary70 = "#00bcd7";
export const MdRefPaletteTertiary60 = "#009fb6";
export const MdRefPaletteTertiary50 = "#008396";
export const MdRefPaletteTertiary40 = "#006878";
export const MdRefPaletteTertiary30 = "#004e5a";
export const MdRefPaletteTertiary20 = "#00363f";
export const MdRefPaletteTertiary10 = "#001f25";
export const MdRefPaletteTertiary0 = "#000000";
export const MdRefPaletteNeutral100 = "#ffffff";
export const MdRefPaletteNeutral99 = "#fbfdfd";
export const MdRefPaletteNeutral95 = "#eff1f1";
export const MdRefPaletteNeutral90 = "#e0e3e3";
export const MdRefPaletteNeutral80 = "#c4c7c7";
export const MdRefPaletteNeutral70 = "#a9acac";
export const MdRefPaletteNeutral60 = "#8e9192";
export const MdRefPaletteNeutral50 = "#747778";
export const MdRefPaletteNeutral40 = "#5c5f60";
export const MdRefPaletteNeutral30 = "#444748";
export const MdRefPaletteNeutral20 = "#2d3132";
export const MdRefPaletteNeutral10 = "#191c1d";
export const MdRefPaletteNeutral0 = "#000000";
export const MdRefPaletteNeutralVariant100 = "#ffffff";
export const MdRefPaletteNeutralVariant99 = "#fcfcfc";
export const MdRefPaletteNeutralVariant95 = "#ffede8";
export const MdRefPaletteNeutralVariant90 = "#f5ded9";
export const MdRefPaletteNeutralVariant80 = "#d9c2bd";
export const MdRefPaletteNeutralVariant70 = "#bca7a2";
export const MdRefPaletteNeutralVariant60 = "#a08c87";
export const MdRefPaletteNeutralVariant50 = "#85736f";
export const MdRefPaletteNeutralVariant40 = "#6c5b57";
export const MdRefPaletteNeutralVariant30 = "#534340";
export const MdRefPaletteNeutralVariant20 = "#3b2d2a";
export const MdRefPaletteNeutralVariant10 = "#251916";
export const MdRefPaletteNeutralVariant0 = "#000000";
export const MdRefPaletteError100 = "#ffffff";
export const MdRefPaletteError99 = "#fcfcfc";
export const MdRefPaletteError95 = "#ffede9";
export const MdRefPaletteError90 = "#ffdad4";
export const MdRefPaletteError80 = "#ffb4a9";
export const MdRefPaletteError70 = "#ff897a";
export const MdRefPaletteError60 = "#ff5449";
export const MdRefPaletteError50 = "#dd3730";
export const MdRefPaletteError40 = "#ba1b1b";
export const MdRefPaletteError30 = "#930006";
export const MdRefPaletteError20 = "#680003";
export const MdRefPaletteError10 = "#410001";
export const MdRefPaletteError0 = "#000000";
export const SysColorSurfaceTintAt1Dark = "#ffb49e";
export const SysColorSurfaceTintAt2Dark = "#ffb49e";
export const SysColorSurfaceTintAt3Dark = "#ffb49e";
export const SysColorSurfaceTintAt4Dark = "#ffb49e";
export const SysColorSurfaceTintAt5Dark = "#ffb49e";
export const SysColorSurfaceTintAt1Light = "#b32b00";
export const SysColorSurfaceTintAt2Light = "#b32b00";
export const SysColorSurfaceTintAt3Light = "#b32b00";
export const SysColorSurfaceTintAt4Light = "#b32b00";
export const SysColorSurfaceTintAt5Light = "#b32b00";
export const SysColorSurfaceTintAt0Dark = "#ffb49e";
export const SysColorSurfaceTintAt0Light = "#b32b00";
export const MdSysColorSuccessLight = "#006e24";
export const MdSysColorOnSuccessLight = "#ffffff";
export const MdSysColorSuccessDark = "#58e070";
export const MdSysColorOnSuccessDark = "#00390e";
export const MdSysColorSuccessContainerDark = "#005319";
export const MdSysColorOnSuccessContainerDark = "#76fd89";
export const MdSysColorSuccessContainerLight = "#76fd89";
export const MdSysColorOnSuccessContainerLight = "#002106";
export const MdSysColorDisabledContainerLight = "#191c1d";
export const MdSysColorDisabledContentLight = "#191c1d";
export const MdSysColorDisabledContainerDark = "#e0e3e3";
export const MdSysColorDisabledContentDark = "#e0e3e3";
export const MdSysColorDisabledBorderLight = "#191c1d";
export const MdSysColorDisabledBorderDark = "#e0e3e3";
export const MdSysTypescaleDisplay1Family = "Roboto";
export const MdSysTypescaleDisplay1Weight = "400";
export const MdSysTypescaleDisplayLargeFamily = "Roboto";
export const MdSysTypescaleDisplayLargeWeight = "400";
export const MdSysTypescaleDisplayMediumFamily = "Roboto";
export const MdSysTypescaleDisplayMediumWeight = "400";
export const MdSysTypescaleDisplaySmallFamily = "Roboto";
export const MdSysTypescaleDisplaySmallWeight = "400";
export const MdSysTypescaleHeadlineLargeFamily = "Roboto";
export const MdSysTypescaleHeadlineLargeWeight = "400";
export const MdSysTypescaleHeadlineMediumFamily = "Roboto";
export const MdSysTypescaleHeadlineMediumWeight = "400";
export const MdSysTypescaleHeadlineSmallFamily = "Roboto";
export const MdSysTypescaleHeadlineSmallWeight = "400";
export const MdSysTypescaleTitleLargeFamily = "Roboto";
export const MdSysTypescaleTitleLargeWeight = "400";
export const MdSysTypescaleHeadline6Family = "Roboto";
export const MdSysTypescaleHeadline6Weight = "400";
export const MdSysTypescaleTitleMediumFamily = "Roboto";
export const MdSysTypescaleTitleMediumWeight = "500";
export const MdSysTypescaleTitleSmallFamily = "Roboto";
export const MdSysTypescaleTitleSmallWeight = "500";
export const MdSysTypescaleLabelLargeFamily = "Roboto";
export const MdSysTypescaleLabelLargeWeight = "500";
export const MdSysTypescaleBodyLargeFamily = "Roboto";
export const MdSysTypescaleBodyLargeWeight = "400";
export const MdSysTypescaleBodyMediumFamily = "Roboto";
export const MdSysTypescaleBodyMediumWeight = "400";
export const MdSysTypescaleBodySmallFamily = "Roboto";
export const MdSysTypescaleBodySmallWeight = "Regular";
export const MdSysTypescaleLabelMediumFamily = "Roboto";
export const MdSysTypescaleLabelMediumWeight = "500";
export const MdSysTypescaleLabelSmallFamily = "Roboto";
export const MdSysTypescaleLabelSmallWeight = "Medium";
export const MdSysTypescaleDisplay1Size = "6.4rem";
export const MdSysTypescaleDisplayLargeSize = "5.7rem";
export const MdSysTypescaleDisplayMediumSize = "4.5rem";
export const MdSysTypescaleDisplaySmallSize = "3.6rem";
export const MdSysTypescaleHeadlineLargeSize = "3.2rem";
export const MdSysTypescaleHeadlineMediumSize = "2.8rem";
export const MdSysTypescaleHeadlineSmallSize = "2.4rem";
export const MdSysTypescaleTitleLargeSize = "2.2rem";
export const MdSysTypescaleHeadline6Size = "1.8rem";
export const MdSysTypescaleTitleMediumSize = "1.6rem";
export const MdSysTypescaleTitleSmallSize = "1.4rem";
export const MdSysTypescaleLabelLargeSize = "1.4rem";
export const MdSysTypescaleBodyLargeSize = "1.6rem";
export const MdSysTypescaleBodyMediumSize = "1.4rem";
export const MdSysTypescaleBodySmallSize = "1.2rem";
export const MdSysTypescaleLabelMediumSize = "1.2rem";
export const MdSysTypescaleLabelSmallSize = "1.1rem";
export const InteractionLayerHovered = "0.08"; // opacity of a layer on top of interactive ui, that indicates element is hovered
export const InteractionLayerFocused = "0.12"; // opacity of a layer on top of interactive ui, that indicates element is focused
export const InteractionLayerPressed = "0.12"; // opacity of a layer on top of interactive ui, that indicates element is pressed
export const InteractionLayerDragged = "0.24"; // opacity of a layer on top of interactive ui, that indicates element is being dra
export const Umbra1 = "0px 2px 1px -1px";
export const Umbra2 = "0px 3px 1px -2px";
export const Umbra3 = "0px 3px 3px -2px";
export const Umbra4 = "0px 2px 4px -1px";
export const Umbra5 = "0px 3px 5px -1px";
export const Umbra6 = "0px 3px 5px -1px";
export const Umbra7 = "0px 4px 5px -2px";
export const Umbra8 = "0px 5px 5px -3px";
export const Umbra9 = "0px 5px 6px -3px";
export const Umbra10 = "0px 6px 6px -3px";
export const Umbra11 = "0px 6px 7px -4px";
export const Umbra12 = "0px 7px 8px -4px";
export const Umbra13 = "0px 7px 8px -4px";
export const Umbra14 = "0px 7px 9px -4px";
export const Umbra15 = "0px 8px 9px -5px";
export const Umbra16 = "0px 8px 10px -5px";
export const Umbra17 = "0px 8px 11px -5px";
export const Umbra18 = "0px 9px 11px -5px";
export const Umbra19 = "0px 9px 12px -6px";
export const Umbra20 = "0px 10px 13px -6px";
export const Umbra21 = "0px 10px 13px -6px";
export const Umbra22 = "0px 10px 14px -6px";
export const Umbra23 = "0px 11px 14px -7px";
export const Umbra24 = "0px 11px 15px -7px";
export const Penumbra1 = "0px 1px 1px 0px";
export const Penumbra2 = "0px 2px 2px 0px";
export const Penumbra3 = "0px 3px 4px 0px";
export const Penumbra4 = "0px 4px 5px 0px";
export const Penumbra5 = "0px 5px 8px 0px";
export const Penumbra6 = "0px 6px 10px 0px";
export const Penumbra7 = "0px 7px 10px 1px";
export const Penumbra8 = "0px 8px 10px 1px";
export const Penumbra9 = "0px 9px 12px 1px";
export const Penumbra10 = "0px 10px 14px 1px";
export const Penumbra11 = "0px 11px 15px 1px";
export const Penumbra12 = "0px 12px 17px 2px";
export const Penumbra13 = "0px 13px 19px 2px";
export const Penumbra14 = "0px 14px 21px 2px";
export const Penumbra15 = "0px 15px 22px 2px";
export const Penumbra16 = "0px 16px 24px 2px";
export const Penumbra17 = "0px 17px 26px 2px";
export const Penumbra18 = "0px 18px 28px 2px";
export const Penumbra19 = "0px 19px 29px 2px";
export const Penumbra20 = "0px 20px 31px 3px";
export const Penumbra21 = "0px 21px 33px 3px";
export const Penumbra22 = "0px 22px 35px 3px";
export const Penumbra23 = "0px 23px 36px 3px";
export const Penumbra24 = "0px 24px 38px 3px";
export const Ambient1 = "0px 1px 3px 0px";
export const Ambient24 = "0px 9px 46px 8px";
export const Ambient23 = "0px 9px 44px 8px";
export const Ambient22 = "0px 8px 42px 7px";
export const Ambient21 = "0px 8px 40px 7px";
export const Ambient20 = "0px 8px 38px 7px";
export const Ambient19 = "0px 7px 36px 6px";
export const Ambient18 = "0px 7px 34px 6px";
export const Ambient17 = "0px 6px 32px 5px";
export const Ambient16 = "0px 6px 30px 5px";
export const Ambient15 = "0px 6px 28px 5px";
export const Ambient14 = "0px 5px 26px 4px";
export const Ambient13 = "0px 5px 24px 4px";
export const Ambient12 = "0px 5px 22px 4px";
export const Ambient11 = "0px 4px 20px 3px";
export const Ambient10 = "0px 4px 18px 3px";
export const Ambient9 = "0px 3px 16px 2px";
export const Ambient8 = "0px 3px 14px 2px";
export const Ambient7 = "0px 2px 16px 1px";
export const Ambient6 = "0px 1px 18px 0px";
export const Ambient5 = "0px 1px 14px 0px";
export const Ambient4 = "0px 1px 10px 0px";
export const Ambient3 = "0px 1px 8px 0px";
export const Ambient2 = "0px 1px 5px 0px";
export const UmbraOpacity = "0.2";
export const PenumbraOpacity = "0.14";
export const AmbientOpacity = ".12";