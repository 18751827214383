import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAIL,
  SEEN_NOTIFICATIONS_START,
  SEEN_NOTIFICATIONS_SUCCESS,
} from './notifications.types';

export const fetchNotificationsStart = (payload) => {
  return { type: FETCH_NOTIFICATIONS_START, payload };
};

export const fetchNotificationsSuccess = (payload) => {
  return { type: FETCH_NOTIFICATIONS_SUCCESS, payload };
};

export const fetchNotificationsFail = (payload) => {
  return { type: FETCH_NOTIFICATIONS_FAIL, payload };
};

export const seenNotificationsStart = (payload) => {
  return { type: SEEN_NOTIFICATIONS_START, payload };
};

export const seenNotificationsSuccess = (payload) => {
  return { type: SEEN_NOTIFICATIONS_SUCCESS, payload };
};
