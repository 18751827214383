import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { SET_ALERT_START } from './alert.types';
import { setAlert, removeAlert } from './alert.actions';

export function* setAlertAsync({ payload }) {
  const { msg, alertType } = payload;

  yield put(setAlert({ msg, alertType }));

  yield delay(2000);
  yield put(removeAlert());
}

export function* onSetAlertStart() {
  yield takeLatest(SET_ALERT_START, setAlertAsync);
}

export function* alertSagas() {
  yield all([call(onSetAlertStart)]);
}
