// @ts-check

import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import styles from './Overview.module.css';
import BalanceOverview from 'components/content/BalanceOverview.component';
import TransactionsOverview from 'components/content/TransactionsOverview.component';
import { useLocale } from 'context/LanguageContext';

function Overview() {
  const { LL } = useLocale();
  return (
    <div className={styles.container}>
      <Level>
        <H className={styles.pageHeadline}>{LL.general.overview()}</H>

        <BalanceOverview />
        {/* <MarketsOverview /> */}

        <TransactionsOverview />
      </Level>
    </div>
  );
}

export default Overview;
