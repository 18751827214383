import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import CurrencyField from 'components/currency-fields/CurrencyField.component';
import CurrencyPicker from 'components/currency-fields/CurrencyPicker.component';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useLocale } from 'context/LanguageContext';
import { useFormik } from 'formik';
import useThemeVariables from 'hooks/useThemeVariables';
import InvoicePage from 'pages/invoice-page/invoice.page';
import qs from 'qs';
import { useUserCredentialsQuery } from 'queries/userCredentials';
import * as React from 'react';
import { H } from 'react-accessible-headings';
import { connect } from 'react-redux';
import { fetchInvoiceStart } from 'redux/invoice/invoice.actions';
import { number, object, string } from 'yup';
import styles from './P2PInvoice.module.css';

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

function useFormError(errorMap, resetDeps = []) {
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    serverError: errorMap[errorKey],
    setServerError: setErrorKey,
  };
}

function P2PInvoice({ invoice, fetchInvoiceStart }) {
  const { LL } = useLocale();
  const [invoiceId, setInvoiceId] = React.useState(null);
  const [fetchCount, setFetchCount] = React.useState(0);
  const [successfulChange, setSuccessfulChange] = React.useState(false);
  const { colorTest4 } = useThemeVariables();
  const { data } = useUserCredentialsQuery();
  const { conversionCurrency } = useCurrencyAndThemeContext();

  const surfaceColor = {
    background: colorTest4,
  };

  React.useEffect(() => {
    const autoRefetch = setInterval(() => {
      if (invoice && invoiceId && fetchCount < 20) {
        setFetchCount(fetchCount + 1);
        fetchInvoiceStart(invoiceId);
      }
    }, 4000);
    return () => clearInterval(autoRefetch);
  }, [invoiceId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: data && data.email,
      currency: /** @type {import('currencies').Currency | null} */ (
        conversionCurrency
      ),
      amount: NaN,
    },
    validationSchema: object({
      email: string().required('Required'),
      currency: string().nullable().required(LL.exchange.errors.currency()),
      amount: number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(LL.exchange.errors.amount())
        .positive(LL.exchange.errors.amountPositive()),
    }),
    onSubmit: async (values, { resetForm, setTouched }) => {
      const data = {
        email: values.email,
        currency: values.currency,
        amount: values.amount,
      };
      try {
        const res = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URI}invoices/p2p`,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: localStorage.getItem('token'),
          },
          data: qs.stringify(data),
        });

        setInvoiceId(res.data.id);
        setServerError(null);
        setSuccessfulChange(true);
        setTimeout(() => {
          setSuccessfulChange(false);
        }, 5000);
      } catch (error) {
        setTouched({});
        console.log(error);
        if (error.response && errorMap[error.response.data.error]) {
          console.log('Error: ', error.response.data.error);

          setServerError(error.response.data.error);
        } else {
          console.warn('P2PInvoice unexpected error: ', error);
          setServerError('ERR_GENERIC');
        }
      }
      resetForm();
    },
  });

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  const errorMap = {
    ERR_GENERIC: LL.profile.serverErrors.genericShort(),
    ERR_INVALID_CREDENTIALS: LL.profile.serverErrors.invalidCredentials(),
    ERR_INVALID_CURRENT_PASSWORD:
      LL.profile.serverErrors.invalidCurrentPassword(),
    SAME_PASSWORD_FORBIDDEN: LL.profile.serverErrors.samePassword(),
  };

  const { serverError, setServerError } = useFormError(errorMap, []);

  return (
    <Surface
      elementType="form"
      onSubmit={formik.handleSubmit}
      surfaceColor={surfaceColor}
      className={styles.container}
    >
      <>
        <div className={styles.formBody}>
          <H className={styles.heading}>{LL.merchant.p2pInvoice.header()}</H>
          <ErrorContainer>
            {serverError ? (
              <InlineError
                onClose={() => setServerError(null)}
                success={false}
              >
                {serverError}
              </InlineError>
            ) : (
              successfulChange && (
                <InlineError
                  onClose={() => setSuccessfulChange(false)}
                  success={true}
                >
                  {LL.merchant.p2pInvoice.success()}
                </InlineError>
              )
            )}
          </ErrorContainer>

          <CurrencyPicker
            label={LL.merchant.p2pInvoice.currency()}
            selectedKey={formik.values.currency}
            onSelectionChange={(key) => formik.setFieldValue('currency', key)}
            onBlur={handleBlur('currency')}
            validationState={getValidationState(formik, 'currency')}
            errorMessage={formik.values.currency}
          />
          <CurrencyField
            currency={formik.values.currency}
            label={LL.merchant.p2pInvoice.amount({
              currency: formik.values.currency,
            })}
            value={formik.values.amount}
            onChange={(val) => formik.setFieldValue('amount', val, false)}
            validationState={getValidationState(formik, 'amount')}
            errorMessage={formik.values.amount}
          />
        </div>
        <div className={styles.formSubmit}>
          <OutlineButton
            attachesBackground
            pressesBackground
            type="reset"
            onPress={formik.handleReset}
          >
            {LL.profile.resetFields()}
          </OutlineButton>
          <FilledButton type="submit">
            {LL.merchant.p2pInvoice.createInvoice()}
          </FilledButton>
        </div>{' '}
      </>
      {invoiceId && <InvoicePage invoiceId={invoiceId} />}
    </Surface>
  );
}

const mapStateToProps = (state) => ({
  invoice: state.invoice.invoice,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoiceStart: (id) => dispatch(fetchInvoiceStart(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(P2PInvoice);
