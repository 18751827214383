// @ts-check
import big from 'bigjs-literal/macro';
import FilledButton from 'common/buttons/FilledButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import FeeInfo from 'components/content/FeeInfo.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import CurrencyField from 'components/currency-fields/CurrencyField.component';
import CurrencyPicker from 'components/currency-fields/CurrencyPicker.component';
import { DepositPicker } from 'components/currency-fields/DepositPicker.component';
import { useExchangeContext } from 'context/ExchangeContext';
import { useLocale } from 'context/LanguageContext';
import useFee from 'hooks/useFee';
import ExchangeRecord from 'pages/explorer/ExchangeRecord.component';
import { useBalancesQuery } from 'queries/balances';
import { useExchangeRateQuery } from 'queries/exchangeRate';
import * as React from 'react';
import { RiSwapLine } from 'react-icons/ri';
import styles from './QuickSwap.module.css';

export default function QuickSwap() {
  const swapForm = useExchangeContext().swap;

  const balances = useBalancesQuery(true);

  const { LL } = useLocale();

  const from = swapForm.values.from;
  const to = swapForm.values.to;
  const amount = swapForm.values.amount;

  const { data: exchangeRate = 1 } = useExchangeRateQuery(
    { from: from, to: to },
    { enabled: !!(from && to) }
  );

  const { addFee, subFee } = useFee('exchange', {
    from: from,
    to: to,
  });

  const handleBlur = (field) => () => {
    swapForm.setFieldTouched(field, true);
  };

  const handleFromAmountChange = (val) => {
    swapForm.setFieldValue('amount', val);
  };

  const handleToAmountChange = (val) => {
    swapForm.setFieldValue('amount', +big`${addFee(val)} / ${exchangeRate}`);
  };

  function exchangeRecordClose() {
    swapForm.setSwapId(null);
  }

  /**
   * TODO: custom hook material
   * @param {(keyof swapForm["touched"]) | (keyof swapForm["touched"])[]} checkForTouch
   * @param {(keyof swapForm["errors"]) | (keyof typeof swapForm["errors"])[]} checkForErrors
   **/
  const getValidationState = (
    checkForTouch,
    checkForErrors = checkForTouch
  ) => {
    const areTouched = [checkForTouch].flat().some((field) => {
      return swapForm.touched[field];
    });

    const areErrors = [checkForErrors].flat().some((field) => {
      return swapForm.errors[field];
    });

    return !areTouched ? null : areErrors ? 'invalid' : 'valid';
  };

  return (
    <form
      onSubmit={swapForm.handleSubmit}
      onReset={swapForm.handleReset}
      className={styles.container}
    >
      <div ref={swapForm.statusRef}>
        <ErrorContainer>
          {swapForm.serverError ? (
            <InlineError
              onClose={() => swapForm.setServerError(null)}
              success={false}
            >
              {swapForm.serverError}
            </InlineError>
          ) : (
            swapForm.success && (
              <InlineError
                onClose={() => swapForm.setSuccess(false)}
                success={true}
              >
                {'Transaction Successful!'}
              </InlineError>
            )
          )}
        </ErrorContainer>
      </div>
      {!balances.isLoading && balances.data.length === 0 ? (
        LL.exchange.noDepositMessage()
      ) : (
        <>
          {swapForm.swapId && (
            <div className={styles.exchangeRecord}>
              <ExchangeRecord
                id={swapForm.swapId}
                closeButton={true}
                clickClose={exchangeRecordClose}
                small={true}
              />
            </div>
          )}
          <CurrencyField
            currency={swapForm.values.from}
            leftButton={
              <DepositPicker
                isInline
                aria-label={LL.app.currency()}
                selectedKey={from}
                onSelectionChange={(key) => swapForm.setFieldValue('from', key)}
                onBlur={handleBlur('from')}
                validationState={getValidationState(
                  ['from', 'amount'],
                  ['from']
                )}
              />
            }
            label={LL.exchange.sendAmountLabel({ currency: from })}
            value={amount}
            onChange={handleFromAmountChange}
            onBlur={handleBlur('amount')}
            validationState={getValidationState('amount', ['amount', 'from'])}
            errorMessage={swapForm.errors.from || swapForm.errors.amount}
          />
          <CurrencyField
            currency={swapForm.values.to}
            leftButton={
              <CurrencyPicker
                isInline
                aria-label={LL.app.currency()}
                selectedKey={to}
                onSelectionChange={(key) => swapForm.setFieldValue('to', key)}
                onBlur={handleBlur('to')}
                validationState={getValidationState('to')}
              />
            }
            label={LL.exchange.receiveAmountLabel({ currency: to })}
            value={isNaN(amount) ? amount : subFee(amount * exchangeRate)}
            onChange={handleToAmountChange}
            validationState={getValidationState('to')}
            errorMessage={swapForm.errors.to}
          />{' '}
        </>
      )}
      {swapForm.values.from && swapForm.values.to && (
        <FeeInfo
          currency={swapForm.values.to}
          amount={swapForm.values.amount}
          feeType={'exchange'}
          payload={{
            from: swapForm.values.from,
            to: swapForm.values.to,
          }}
        />
      )}
      <div className={styles.actions}>
        <OutlineButton
          type="reset"
          onPress={() => swapForm.setSwapId(null)}
        >
          {LL.app.reset()}
        </OutlineButton>
        <FilledButton
          type="submit"
          left={<RiSwapLine />}
          isDisabled={swapForm.isSubmitting}
          onPress={() => swapForm.scrollToStatus()}
        >
          {LL.app.swap()}
        </FilledButton>
      </div>
    </form>
  );
}
