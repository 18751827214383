const INIT_STATE = {
    dark: false
}

const themeReducer = (state = INIT_STATE, action) => {
    switch(action.type) {
        case 'SWITCH_THEME':
            return {
                ...state,
                dark: !state.dark
            }
        default:
            return state
    }
} 

export default themeReducer;