// @ts-check
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef CurrencyAddressPayload
 * @property {import("currencies").Currency} coin
 * @property {import("types").NetworkType} network
 * @property {boolean} new // create new address
 * @property {boolean} default // make new address default so that next request will get the newly generated address
 *
 * @typedef {Omit<CurrencyAddressPayload, "new" | "default">} CurrencyAddresQueryPayload
 *
 * @typedef CurrencyAddress
 * @property {string} address
 * @property {string} [dest_tag]
 * @property {import('currencies').Currency} coin
 * @property {import("types").NetworkType} network
 */

/**
 * @param {CurrencyAddressPayload} payload
 * @returns {Promise<CurrencyAddress>}
 * */
export async function getCurrencyAddress(payload) {
  const response = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URI}address`,
    // @ts-ignore new URLSearchParams({bool: true}) -> bool=true. ts is dumb
    data: new URLSearchParams(payload).toString(),
    headers: {
      Authorization: localStorage.getItem('token'),
      'content-type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
}

/**
 * @param {CurrencyAddresQueryPayload} payload
 * @param {{enabled: boolean}} [options]
 **/
export function useCurrencyAddressQuery(payload, options) {
  return useQuery(
    ['currencyAddress', payload],
    () =>
      getCurrencyAddress({
        ...payload,
        new: false,
        default: false,
      }),
    options
  );
}

export function useChangeCurrencyAddress() {
  const queryClient = useQueryClient();

  return useMutation({
    /** @param {CurrencyAddresQueryPayload} payload */
    mutationFn: (payload) =>
      getCurrencyAddress({ ...payload, new: true, default: true }),

    /**
     * @param {CurrencyAddress} data
     * @param {CurrencyAddresQueryPayload} payload
     */
    onSuccess: (data, payload) => {
      queryClient.setQueryData(['currencyAddress', payload], data);
    },
  });
}
