import * as React from 'react';
import styles from './Divider.module.css';
/**
 *
 * @param {object} props
 * @param {"horizontal" | "vertical"} [props.orientation ]
 * @param {"li" | "hr"} [props.elementType] //  hr is not allowed to be a child of ul or ol
 *
 */
function Divider({ orientation = 'horizontal', elementType = 'hr' }) {
  return React.createElement(elementType, { className: styles[orientation] });
}

export default Divider;
