import React from 'react';
import styles from './LoadingAnimation.module.css';

const BufferingWheel = ({ color }) => {
  return (
    <div className={styles.loadingio_spinner_spinner_miyed1c3d6s}>
      <div className={styles.ldio_0hsormizdxcr}>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
        <div style={{ background: `${color}` }}></div>
      </div>
    </div>
  );
};

export default BufferingWheel;
