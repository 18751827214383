import * as React from 'react';
import styles from './TransactionsOverview.module.css';
import TransactionsTable from 'components/transactions/TransactionsTable.component';
import { Level, H } from 'react-accessible-headings';
import { useLocale } from 'context/LanguageContext';

export default function TransactionsOverview() {
  const { LL } = useLocale();
  return (
    <div className={styles.container}>
      <Level>
        <H className={styles.headline}>{LL.general.transactions()}</H>
      </Level>
      <TransactionsTable
        className={styles.table}
        maxRows={10}
      />
    </div>
  );
}
