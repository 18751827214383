import CurrencyColors from 'assets/data/currency-colors';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import React from 'react';
import { Level } from 'react-accessible-headings';
import styles from './Summary.module.css';

const eths = [
  'busd',
  'enj',
  'link',
  'mkr',
  'omg',
  'reef',
  'snx',
  'uni',
  'usdc',
  'usdt',
  'vet',
  'zrx',
  'eth',
  'trx',
  'bnb',
  'shib',
];
export const getDate = (date) => {
  const fullDate = new Date(date).toString();
  const index = fullDate.indexOf('(');
  const updatedDate = fullDate.slice(0, index);
  return updatedDate;
};

export const getDateFromTimestamp = (date) => {
  const fullDate = new Date(date * 1000).toString();
  const index = fullDate.indexOf('(');
  const updatedDate = fullDate.slice(0, index);
  return updatedDate;
};

const Summary = ({ data, coin, network, txid }) => {
  const { LL } = useLocale();

  const { colorTest7 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest7,
  };
  return (
    <Level>
      <TranslucentSurface
        elementType={'article'}
        className={styles.summary}
        surfaceColor={CurrencyColors[coin]}
      >
        {txid.startsWith('PBXINTRA') ? (
          <>
            <div className={styles.summary_info}>
              Amount Transacted:
              <span className={styles.number}>
                {Number(data.amount)} {coin.toUpperCase()}
              </span>
            </div>
            <div className={styles.summary_info}>
              {' '}
              Fee:
              <span className={styles.bold}>
                {data.pbx_fee} {coin.toUpperCase()}
              </span>
            </div>
            <div className={styles.summary_info}>
              Created at:
              <span className={styles.bold}>{data.createdAt}</span>
            </div>
          </>
        ) : coin === 'xrp' || coin === 'xlm' ? (
          coin === 'xrp' ? (
            <>
              <div className={styles.summary_info}>
                {data.specification?.quantity?.value && (
                  <div className={styles.summary_info}>
                    Amount Transacted:
                    <span className={styles.number}>
                      {Number(data.specification.quantity.value)}{' '}
                      {coin.toUpperCase()}
                    </span>
                  </div>
                )}
                <div className={styles.summary_info}>
                  {LL.general.network()}:
                  <span className={styles.bold}>{network}</span>
                </div>
                <br />
                {LL.explorer.summary.success1()}
                {!data.intra && (
                  <span className="primary">
                    {` `}
                    {LL.explorer.summary.ledger()}{' '}
                    <span className={styles.number}>
                      {data.outcome && data.outcome.ledgerVersion}{' '}
                    </span>
                  </span>
                )}
                {LL.explorer.summary.date()}{' '}
                {data.intra
                  ? getDate(data.createdAt)
                  : data.outcome && getDate(data.outcome.timestamp)}
              </div>
            </>
          ) : (
            <>
              {!data.intra ? (
                <>
                  <div className={styles.summary_info}>
                    <span className="primary">
                      {LL.explorer.summary.successValidation()}{' '}
                      <span className={styles.number}>
                        {data.ledger_attr && data.ledger_attr}{' '}
                      </span>
                    </span>
                    {LL.explorer.summary.date()}{' '}
                    {data.created_at && getDate(data.created_at)}
                  </div>
                  <div className={styles.summary_info}>
                    {LL.general.network()}:
                    <span className={styles.bold}>{network}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.summary_info}>
                    {LL.explorer.summary.success2()} {getDate(data.createdAt)}
                  </div>
                  <div className={styles.summary_info}>
                    {LL.general.network()}:
                    <span className={styles.bold}>{network}</span>
                  </div>
                </>
              )}
            </>
          )
        ) : (
          <>
            <div className={styles.summary_info}>
              {LL.explorer.summary.amount()}
              <span className={styles.number}>
                {eths.includes(coin) || coin === 'btt'
                  ? Number(data.value)
                  : Number(data.amount)}{' '}
                {coin.toUpperCase()}
              </span>
            </div>
            <div className={styles.summary_info}>
              {LL.general.network()}:
              <span className={styles.bold}>{network}</span>
            </div>
            {!data.intra && (
              <div className={styles.summary_info}>
                {LL.explorer.summary.confirmations()}
                <span className={styles.bold}>
                  {data.confirmations === 0
                    ? '0'
                    : !data.confirmations
                    ? 'Data Unavailable'
                    : data.confirmations}
                </span>
              </div>
            )}
            {data.status && (
              <div className={styles.summary_info}>
                {LL.explorer.summary.status()}
                <span className={styles.bold}>
                  {data.status || data.status
                    ? LL.explorer.summary.success()
                    : 'failed'}
                </span>
              </div>
            )}
            <div className={styles.summary_info}>
              {' '}
              {LL.explorer.summary.fee()}
              {data.intra ? (
                <span className={styles.bold}>
                  {data.fee} {coin.toUpperCase()}
                </span>
              ) : (
                <span className={styles.bold}>
                  {eths.includes(coin)
                    ? Number((data.gasUsed * data.gasPrice) / 1e18) ||
                      Number(data.fee)
                    : Number(data.fees) || Number(data.fee)}{' '}
                  {network === 'ERC.20'
                    ? 'ETH'
                    : network === 'TRC.20'
                    ? 'TRX'
                    : network === 'BEP.20'
                    ? 'BNB'
                    : coin.toUpperCase()}
                </span>
              )}
            </div>
            {!data.intra && (
              <div className={styles.summary_info}>
                {LL.explorer.summary.createdAt()}
                <span className={styles.bold}>
                  {data.time
                    ? getDateFromTimestamp(data.time)
                    : LL.explorer.summary.unavailable()}
                </span>
              </div>
            )}
          </>
        )}
      </TranslucentSurface>
    </Level>
  );
};

export default Summary;
