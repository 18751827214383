//@ts-check
import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import FieldIconButton from 'common/fields/FieldIconButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import BufferingWheel from 'components/loader/LoadingAnimation.component';
import { useFormik } from 'formik';
import useThemeVariables from 'hooks/useThemeVariables';
import qs from 'qs';
import { usePasswordRecoveryTokenQuery } from 'queries/passwordRecoveryToken';
import React, { useState } from 'react';
import { H } from 'react-accessible-headings';
import { BiHide, BiShowAlt } from 'react-icons/bi';
import { Link, useSearchParams } from 'react-router-dom';
import { object, ref, string } from 'yup';
import styles from './PasswordReset.module.css';

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

function useFormError(errorMap, resetDeps = []) {
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    formError: errorMap[errorKey],
    setFormError: setErrorKey,
  };
}

function PasswordReset() {
  const [successfulChange, setSuccessfulChange] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  const [showCurrent, setShowCurrent] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const { colorTest4 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };

  const [token] = useSearchParams();
  const recoveryToken = token.get('token');
  const { data } = usePasswordRecoveryTokenQuery(
    { token: recoveryToken },
    {
      retry: false,
      onSuccess: (data) => {
        setTokenData(data);
      },
      onError: (error) => {
        setTokenData(error);
      },
    }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: object({
      newPassword: string().required('Required'),
      confirmPassword: string()
        .oneOf([ref('newPassword'), null], "Passwords don't match")
        .required('Required'),
    }),
    onSubmit: async (values, { resetForm, setTouched }) => {
      let data = {};
      data = {
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
        token: recoveryToken,
      };
      try {
        const res = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URI}users/password-recovery`,
          data: qs.stringify(data),
        });

        setFormError(null);
        setSuccessfulChange(true);
        setTimeout(() => {
          setSuccessfulChange(false);
        }, 5000);
      } catch (error) {
        setTouched({});
        console.log(error);
        if (error.response && errorMap[error.response.data.error]) {
          console.log('Error: ', error.response.data.error);

          setFormError(error.response.data.error);
        } else {
          console.warn('PIN_Verification unexpected error: ', error);
          setFormError('ERR_GENERIC');
        }
      }
      resetForm();
    },
  });

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  const errorMap = {
    ERR_GENERIC: 'Unexpected error. please try again later',
    PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
    INVALID_TOKEN: 'Invalid or expired token',
  };

  const { formError, setFormError } = useFormError(errorMap, []);

  return (
    <div className={styles.pageContainer}>
      <Surface
        elementType="form"
        onSubmit={formik.handleSubmit}
        surfaceColor={surfaceColor}
        className={styles.container}
      >
        {tokenData && tokenData.success ? (
          <>
            <div className={styles.formBody}>
              <div className={styles.header}>
                <H className={styles.heading}>Password Recovery</H>
                <Link to="/">
                  <OutlineButton>Sign in page</OutlineButton>
                </Link>
              </div>
              <ErrorContainer>
                {formError ? (
                  <InlineError
                    onClose={() => setFormError(null)}
                    success={false}
                  >
                    {formError}
                  </InlineError>
                ) : (
                  successfulChange && (
                    <InlineError
                      onClose={() => setSuccessfulChange(false)}
                      success={true}
                    >
                      {'Password has been changed successfully!'}
                    </InlineError>
                  )
                )}
              </ErrorContainer>
              <H className={styles.subHeading}>
                Please enter your new password to finish recovery.
              </H>

              <div className={styles.input}>
                <TextField
                  label={<span className={styles.label}>New password</span>}
                  rightButton={
                    <FieldIconButton
                      onPress={() => setShowCurrent(!showCurrent)}
                      aria-label="show-hide button"
                    >
                      {showCurrent ? <BiHide /> : <BiShowAlt />}
                    </FieldIconButton>
                  }
                  type={showCurrent ? 'text' : 'password'}
                  placeholder=""
                  value={formik.values.newPassword}
                  onChange={(val) =>
                    formik.setFieldValue('newPassword', val, false)
                  }
                  onBlur={handleBlur('newPassword')}
                  validationState={getValidationState(formik, 'newPassword')}
                  errorMessage={formik.errors.newPassword}
                />
                <TextField
                  label={<span className={styles.label}>Confirm password</span>}
                  rightButton={
                    <FieldIconButton
                      onPress={() => setShowCurrent(!showCurrent)}
                      aria-label="show-hide button"
                    >
                      {showCurrent ? <BiHide /> : <BiShowAlt />}
                    </FieldIconButton>
                  }
                  type={showCurrent ? 'text' : 'password'}
                  placeholder=""
                  value={formik.values.confirmPassword}
                  onChange={(val) =>
                    formik.setFieldValue('confirmPassword', val, false)
                  }
                  onBlur={handleBlur('confirmPassword')}
                  validationState={getValidationState(
                    formik,
                    'confirmPassword'
                  )}
                  errorMessage={formik.errors.confirmPassword}
                />
              </div>
            </div>
            <div className={styles.formSubmit}>
              <OutlineButton
                attachesBackground
                pressesBackground
                type="reset"
                onPress={formik.handleReset}
              >
                Reset Fields
              </OutlineButton>
              <FilledButton
                attachesBackground
                pressesBackground
                type="submit"
                isDisabled={
                  !formik.values.newPassword ||
                  !formik.values.confirmPassword ||
                  formik.isSubmitting
                }
              >
                {'Submit'}
              </FilledButton>
            </div>
          </>
        ) : tokenData && tokenData.response.data.error ? (
          <div className={styles.pageContainerError}>
            <H className={styles.heading}>The url is incorrect or expired!</H>
          </div>
        ) : (
          <div className={styles.pageContainer}>
            <BufferingWheel color="#fe8b00" />
          </div>
        )}
      </Surface>
    </div>
  );
}

export default PasswordReset;
