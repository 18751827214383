import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './ExchangeExplorer.module.css';
import ExchangeRecord from './ExchangeRecord.component';

const ExchangeExplorer = () => {
  const { id } = useParams();
  const { LL } = useLocale();

  const { colorTest4 } = useThemeVariables();

  return (
    <div className={styles.container}>
      <ExchangeRecord
        id={id}
        closeButton={false}
        clickClose={() => {}}
        small={false}
      />
    </div>
  );
};

export default ExchangeExplorer;
