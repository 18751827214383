import { createCalendar } from '@internationalized/date';
import { useLocale as useLanguageLocale } from 'context/LanguageContext';
import { useCalendar, useDateFormatter, useLocale } from 'react-aria';
import { useCalendarState } from 'react-stately';

import IconButton from 'common/buttons/IconButton';
import React from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import styles from './Calendar.module.css';
import CalendarGrid from './CalendarGrid.component';

export default function Calendar(props) {
  let { locale } = useLocale();
  let state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  let { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    state
  );
  return (
    <div
      {...calendarProps}
      className={styles.calendar}
    >
      <div className={styles.header}>
        <IconButton
          aria-label={prevButtonProps['aria-label']}
          onBlur={prevButtonProps.onBlur}
          onFocusChange={prevButtonProps.onFocusChange}
          onPress={prevButtonProps.onPress}
        >
          <RiArrowLeftSLine />
        </IconButton>
        <div className={styles.dropdowns}>
          <MonthDropdown state={state} />
          <YearDropdown state={state} />
        </div>
        <IconButton
          aria-label={nextButtonProps['aria-label']}
          onBlur={nextButtonProps.onBlur}
          onFocusChange={nextButtonProps.onFocusChange}
          onPress={nextButtonProps.onPress}
        >
          <RiArrowRightSLine />
        </IconButton>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
}

function MonthDropdown({ state }) {
  const { LL } = useLanguageLocale();
  let months = [];
  let formatter = useDateFormatter({
    month: 'long',
    timeZone: state.timeZone,
  });

  // Format the name of each month in the year according to the
  // current locale and calendar system. Note that in some calendar
  // systems, such as the Hebrew, the number of months may differ
  // between years.
  for (let i = 1; i <= 12; i++) {
    if (i < 10) {
      let date = LL.month[`0${i}`]();
      months.push(date);
    } else {
      let date = LL.month[`${i}`]();
      months.push(date);
    }
  }

  let onChange = (e) => {
    let value = Number(e.target.value);
    let date = state.focusedDate.set({ month: value });
    state.setFocusedDate(date);
  };

  return (
    <select
      aria-label="Month"
      onChange={onChange}
      value={state.focusedDate.month}
      className={styles.select}
    >
      {months.map((month, i) => (
        <option
          key={i}
          value={i + 1}
        >
          {month}
        </option>
      ))}
    </select>
  );
}

function YearDropdown({ state }) {
  let years = [];
  let formatter = useDateFormatter({
    year: 'numeric',
    timeZone: state.timeZone,
  });

  // Format 20 years on each side of the current year according
  // to the current locale and calendar system.
  for (let i = -20; i <= 20; i++) {
    let date = state.focusedDate.add({ years: i });
    years.push({
      value: date,
      formatted: formatter.format(date.toDate(state.timeZone)),
    });
  }

  let onChange = (e) => {
    let index = Number(e.target.value);
    let date = years[index].value;
    state.setFocusedDate(date);
  };

  return (
    <select
      aria-label="Year"
      onChange={onChange}
      value={20}
      className={styles.select}
    >
      {years.map((year, i) => (
        // use the index as the value so we can retrieve the full
        // date object from the list in onChange. We cannot only
        // store the year number, because in some calendars, such
        // as the Japanese, the era may also change.
        <option
          key={i}
          value={i}
        >
          {year.formatted}
        </option>
      ))}
    </select>
  );
}
