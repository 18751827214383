import currencies from 'assets/currencies';
import FieldIconButton from 'common/fields/FieldIconButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import Currency from 'components/content/Currency.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { H } from 'react-accessible-headings';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RiClipboardLine } from 'react-icons/ri';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BufferingWheel from '../../components/loader/LoadingAnimation.component';
import { fetchInvoiceStart } from '../../redux/invoice/invoice.actions';
import { getDate } from '../../utils';
import styles from './invoice.module.css';

const InvoicePage = ({
  invoice,
  fetchInvoiceStart,
  darkMode,
  error,
  invoiceId,
}) => {
  const [addressCopied, setAddressCopied] = useState(false);
  const [tagCopied, setTagCopied] = useState(false);
  const {
    id,
    currency,
    email,
    is_paid,
    tag,
    amount_invoiced,
    amount_invoiced_fiat,
    total_paid,
    total_paid_fiat,
    valid_till,
    createdAt,
  } = invoice;

  const { colorTest6, colorOnSurface, colorTest7 } = useThemeVariables();
  const surfaceColor = {
    background: colorTest7,
  };

  const { LL } = useLocale();

  let navigate = useNavigate();
  let params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchInvoiceStart(params.id || (invoiceId && invoiceId));
  }, [fetchInvoiceStart, params.id || (invoiceId && invoiceId)]);

  if (addressCopied || tagCopied) {
    setTimeout(() => {
      setAddressCopied(false);
      setTagCopied(false);
    }, 1000);
  }

  return (
    <>
      {error?.response?.data?.error === 'ERR_INVOICE_DOES_NOT_EXIST' ? (
        <ErrorContainer>
          <InlineError
            onClose={null}
            success={false}
          >
            Invoice with this ID does not exist
          </InlineError>
        </ErrorContainer>
      ) : error ? (
        <ErrorContainer>
          <InlineError
            onClose={null}
            success={false}
          >
            Something went wrong
          </InlineError>
        </ErrorContainer>
      ) : (
        <div className={styles.invoicePage}>
          {!error && Object.keys(invoice).length <= 0 ? (
            <BufferingWheel color="#fe8b00" />
          ) : (
            <>
              <Surface
                className={styles.invoiceContainer}
                surfaceColor={surfaceColor}
              >
                {currency && (
                  <div className={styles.invoiceTitle}>
                    <img
                      src={currencies[currency]}
                      alt=""
                      width={35}
                      height={35}
                    />
                    <span>{currency.toUpperCase()} Requested</span>
                  </div>
                )}
                <div className={styles.invoiceMain}>
                  <TranslucentSurface
                    className={styles.addressContainer}
                    surfaceColor={surfaceColor}
                  >
                    <H>Share using invoice QR</H>
                    <div className={styles.addressLayout}>
                      {email && (
                        <QRCodeCanvas
                          value={`${window.location.origin}/merchant?tab=Payment&id=${id}`}
                          includeMargin
                          bgColor={'transparent'}
                          fgColor={colorOnSurface}
                          size={230}
                          aria-label={LL.exchange.deposit.qrCodeLabel()}
                        ></QRCodeCanvas>
                      )}
                      <div className={styles.addressInfo}>
                        <TextField
                          label={'Or a url link'}
                          isReadOnly
                          value={`${window.location.origin}/merchant?tab=Payment&id=${id}`}
                          rightButton={
                            <CopyToClipboard
                              text={`${window.location.origin}/merchant?tab=Payment&id=${id}`}
                            >
                              <FieldIconButton
                                aria-label={LL.exchange.copyDestinationAddress()}
                              >
                                <RiClipboardLine />
                              </FieldIconButton>
                            </CopyToClipboard>
                          }
                        />

                        {tag && (
                          <TextField
                            label={LL.exchange.destinationTag()}
                            value={tag}
                            isReadOnly
                            rightButton={
                              <CopyToClipboard text={tag}>
                                <FieldIconButton
                                  aria-label={LL.exchange.copyDestinationTag()}
                                >
                                  <RiClipboardLine />
                                </FieldIconButton>
                              </CopyToClipboard>
                            }
                          />
                        )}
                      </div>
                    </div>
                  </TranslucentSurface>
                  <TranslucentSurface
                    className={styles.invoiceInfo}
                    surfaceColor={surfaceColor}
                  >
                    <H>Summary</H>
                    <Surface
                      className={styles.invoiceSummaryItem}
                      surfaceColor={surfaceColor}
                      style={{
                        color: 'var(--color-logo)',
                        fontSize: '1.7rem',
                        fontWeight: '500',
                      }}
                    >
                      Invoiced Amount:
                      <span>
                        {amount_invoiced && amount_invoiced}{' '}
                        {currency && currency.toUpperCase()}
                      </span>
                    </Surface>

                    <Surface
                      surfaceColor={surfaceColor}
                      className={styles.invoiceSummaryItem}
                    >
                      {`Invoiced Amount (USD)`} :
                      <span>
                        {' '}
                        {
                          <Currency currency="usd">
                            {amount_invoiced_fiat && amount_invoiced_fiat.usd}
                          </Currency>
                        }{' '}
                      </span>
                    </Surface>

                    <Surface
                      surfaceColor={surfaceColor}
                      className={styles.invoiceSummaryItem}
                    >
                      {`Invoiced Amount (EUR)`} :
                      <span>
                        {' '}
                        {
                          <Currency currency="eur">
                            {amount_invoiced_fiat && amount_invoiced_fiat.eur}
                          </Currency>
                        }{' '}
                      </span>
                    </Surface>
                    <Surface
                      surfaceColor={surfaceColor}
                      className={styles.invoiceSummaryItem}
                    >
                      Payment status:{' '}
                      <span>
                        {is_paid ? (
                          <span style={{ color: 'var(--color-success)' }}>
                            {' '}
                            Paid
                          </span>
                        ) : !is_paid && Date.now() > Date.parse(valid_till) ? (
                          <span
                            style={{ color: 'var(--md-ref-palette-error-50)' }}
                          >
                            {' '}
                            Expired
                          </span>
                        ) : (
                          <span
                            style={{ color: 'var(--md-ref-palette-error-50)' }}
                          >
                            {' '}
                            Unpaid
                          </span>
                        )}
                      </span>
                    </Surface>
                    <Surface
                      surfaceColor={surfaceColor}
                      className={styles.invoiceSummaryItem}
                    >
                      Amount Paid:{' '}
                      <span>
                        {total_paid && total_paid}{' '}
                        {currency.toLocaleUpperCase()}
                      </span>
                    </Surface>

                    <Surface
                      surfaceColor={surfaceColor}
                      className={styles.invoiceSummaryItem}
                    >
                      {`Amount Paid (USD): `}
                      <span>
                        {
                          <Currency currency="usd">
                            {total_paid && total_paid_fiat.usd}
                          </Currency>
                        }{' '}
                      </span>
                    </Surface>

                    <Surface
                      surfaceColor={surfaceColor}
                      className={styles.invoiceSummaryItem}
                    >
                      {`Amount Paid (EUR): `}
                      <span>
                        {
                          <Currency currency="eur">
                            {total_paid_fiat && total_paid_fiat.eur}
                          </Currency>
                        }{' '}
                      </span>
                    </Surface>

                    <Surface
                      surfaceColor={surfaceColor}
                      className={styles.invoiceSummaryItem}
                    >
                      Valid untill: <span>{getDate(valid_till)}</span>
                    </Surface>

                    <Surface
                      surfaceColor={surfaceColor}
                      className={styles.invoiceSummaryItem}
                    >
                      Created at: <span>{getDate(createdAt)}</span>
                    </Surface>
                  </TranslucentSurface>
                </div>
              </Surface>
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  invoice: state.invoice.invoice,
  darkMode: state.theme.dark,
  error: state.invoice.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoiceStart: (id) => dispatch(fetchInvoiceStart(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePage);
