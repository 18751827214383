import { takeLatest, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import {
  fetchCoinDataFailure,
  fetchCoinValueSuccess,
  fetchFeeAmountSuccess,
  fetchFeeAmountFailure,
  fetchP2pFeeAmountSuccess,
  fetchP2pFeeAmountFailure,
} from './coin.actions';

import coinActionTypes from './coin.types';

export function* fetchCoinDataAsync(action) {
  try {
    const res = yield axios.get(
      `${process.env.REACT_APP_API_URI}exchange/rates`
    );

    yield put(fetchCoinValueSuccess(res.data));
  } catch (err) {
    yield put(fetchCoinDataFailure(err));
  }
}

export function* fetchFeeAmountAsync({ payload: { coin, type } }) {
  try {
    const res = yield axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}fees`,
      data:
        coin &&
        type &&
        qs.stringify({
          coin,
          type: type.feeType,
        }),
      headers: {
        Authorization: localStorage.getItem('token'),
        'content-type': 'application/x-www-form-urlencoded',
      },
    });

    yield put(fetchFeeAmountSuccess(res.data[0].amount));
  } catch (error) {
    yield put(fetchFeeAmountFailure(error));
  }
}

export function* fetchP2pFeeAmountAsync({ payload: { coin } }) {
  try {
    const res = yield axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}p2p-transactions/fees`,
      data: {
        currencies: [coin],
      },
      headers: {
        Authorization: localStorage.getItem('token'),
        // 'content-type': 'application/x-www-form-urlencoded',
      },
    });

    yield put(fetchP2pFeeAmountSuccess(res.data[0].amount));
  } catch (error) {
    yield put(fetchP2pFeeAmountFailure(error));
  }
}

export function* fetchCoinDataStart() {
  yield takeLatest(coinActionTypes.FETCH_COINDATA_START, fetchCoinDataAsync);
}

export function* fetchFeeAmount() {
  yield takeLatest(coinActionTypes.FETCH_FEE_AMOUNT_START, fetchFeeAmountAsync);
}

export function* fetchP2pFeeAmount() {
  yield takeLatest(
    coinActionTypes.FETCH_P2P_FEE_AMOUNT_START,
    fetchP2pFeeAmountAsync
  );
}

export function* coinSagas() {
  yield all([
    call(fetchCoinDataStart),
    call(fetchFeeAmount),
    call(fetchP2pFeeAmount),
  ]);
}
