// @ts-check
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef TransferFee
 * @property {import('currencies').Currency} coin
 * @property {"standard" | "medium" | "high"} type
 * @property {number} amount // fixed fee. net amount = amount + transferFee.amount.
 * @property {import('types').NetworkType} network
 *
 * @typedef TransferFeePayload
 * @property {import('currencies').Currency} coin // shorthand name of the coin
 * @property {"standard" | "medium" | "high"} type
 * @property {string} network
 */

/**
 * @param {TransferFeePayload} payload
 */
export async function getTransferFee(payload) {
  const response = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URI}fees`,
    data: new URLSearchParams(payload).toString(),
    headers: {
      Authorization: localStorage.getItem('token'),
      'content-type': 'application/x-www-form-urlencoded',
    },
  });

  // cast returned value to a number.
  /** @type {Omit<TransferFee, "amount"> & {amount: string}} */
  const data = response.data[0];

  return {
    ...data,
    amount: +data.amount,
  };
}

/**
 * @param {TransferFeePayload} payload
 * @param {{enabled: boolean}} options
 *
 * @returns {import('@tanstack/react-query').UseQueryResult<TransferFee, Error>}
 */
export function useTransferFeeQuery(payload, options) {
  return useQuery(
    ['transferFee', payload],
    async () => {
      return await getTransferFee(payload);
    },
    options
  );
}
