import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAIL,
  ADD_NOTIFICATION,
  SEEN_NOTIFICATIONS_SUCCESS,
} from './notifications.types';

const INITIAL_STATE = {
  notifications: [],
  loadingNotifications: false,
  hasMore: false,
  unseenCount: 0,
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_NOTIFICATIONS_START:
      return {
        ...state,
        loadingNotifications: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingNotifications: false,
        notifications: [...state.notifications, ...payload.notifications],
        hasMore: payload.notifications.length === 10,
        unseenCount: payload.unseenCount,
      };
    case FETCH_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loadingNotifications: false,
      };
    case ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [payload, ...state.notifications],
        unseenCount: state.unseenCount++,
      };
    }
    case SEEN_NOTIFICATIONS_SUCCESS:
      const updatedNotifications = state.notifications.map((not) => {
        if (payload.includes(not.id)) {
          not.seen = true;
        }
        return not;
      });
      return {
        ...state,
        notifications: updatedNotifications,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
