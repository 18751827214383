// @ts-check
import * as React from 'react';
import { motion } from 'framer-motion';

export { AnimatePresence } from 'framer-motion';

/**
 * @template {keyof HTMLElementTagNameMap} T
 * @typedef LocalProps
 * @property {T} elementType
 * @property {React.ReactNode} children
 */

/**
 * @template {keyof HTMLElementTagNameMap} T
 * @typedef {Partial<Omit<HTMLElementTagNameMap[T], "children" | "animate" | "style" | "draggable" | "translate" | "contentEditable">>} ContainerAttributes
 */

/**
 * @template {keyof HTMLElementTagNameMap} T
 * @typedef {LocalProps<T> & ContainerAttributes<T>} ExpandProps
 */

/**
 * @template {keyof HTMLElementTagNameMap} T
 * @param {ExpandProps<T>} props
 */
export function Expand({ children, elementType, ...props }) {
  return (
    <MotionElement
      elementType={elementType}
      variants={variants}
      animate={'open'}
      initial={'collapsed'}
      exit={'collapsed'}
      {...props}
    >
      {children}
    </MotionElement>
  );
}

const variants = {
  open: { height: 'auto', opacity: 1 },
  collapsed: {
    height: 0,
    opacity: 0,
    transition: { height: { delay: 0.2 } },
  },
};

/**
 * @template {keyof HTMLElementTagNameMap} T
 * @param {ExpandProps<T>} props
 */
function CustomElement({ elementType, ...props }, ref) {
  return React.createElement(elementType, { ref, ...props });
}

const MotionElement = motion(React.forwardRef(CustomElement));
