// @ts-check
import * as React from 'react';

/**
 * @template T
 * @param {React.MutableRefObject<T>} ref
 * @param {T} [initialValue]
 **/
export default function useDefaultRef(ref, initialValue) {
  const defaultRef = React.useRef(initialValue);
  return ref || defaultRef;
}
