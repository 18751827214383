// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef {object}
 * @property {Boolean} success
 */

/**
 * @returns {Promise<>}
 */

export async function getPasswordRecoveryTokenVerification({ token }) {
  const { data } = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URI}users/password-recovery/email/verify`,
    data: { token: token },
  });
  if (!data) {
    throw new Error('Invalid token');
  }
  return data;
}

/**
 * @param {object} payload
 * @param {string} payload.token
 * @param {{enabled?: boolean, retry?: boolean, onSuccess?: any, onError?: any}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<getPasswordRecoveryTokenVerification, Error>}
 */

export function usePasswordRecoveryTokenQuery({ token }, options) {
  return useQuery(
    ['passwordRecoveryTokenVerification', { token }],
    () => getPasswordRecoveryTokenVerification({ token }),
    { ...options }
  );
}
