import * as React from 'react';
import { useToggleState } from 'react-stately';
import { FocusRing, useCheckbox, VisuallyHidden } from 'react-aria';
import { useCheckboxGroupState } from 'react-stately';
import { useCheckboxGroup, useCheckboxGroupItem } from 'react-aria';
import styles from './Checkbox.module.css';
import {
  RiCheckboxBlankLine,
  RiCheckboxFill,
  RiCheckboxIndeterminateFill,
} from 'react-icons/ri';
import cx from 'classnames';
import useDefaultRef from 'hooks/useDefaultRef';
import { motion } from 'framer-motion';
import useThemeVariables from 'hooks/useThemeVariables';

/** @type {React.Context<import("react-stately").CheckboxGroupState | undefined>} */
const CheckboxGroupContext = React.createContext(undefined);

/** @param {CheckboxGroupProps} props */
export function CheckboxGroup(props) {
  const state = useCheckboxGroupState(props);
  const { groupProps, labelProps } = useCheckboxGroup(props, state);

  // TODO: implement errorMessage and description with useCheckboxGroup

  const { children, label } = props;
  return (
    <div
      className={cx(styles.groupContainer, {
        '--isHorizontal': props.orientation === 'horizontal',
      })}
      {...groupProps}
    >
      {label && (
        <span
          className={styles.groupLabel}
          {...labelProps}
        >
          {label}
        </span>
      )}
      <div className={styles.checkboxesWrapper}>
        <CheckboxGroupContext.Provider value={state}>
          {children}
        </CheckboxGroupContext.Provider>
      </div>
    </div>
  );
}

export const Checkbox = React.forwardRef(
  /**
   * @param {CheckboxProps} props
   * @param {React.MutableRefObject<HTMLInputElement>} parentRef
   **/
  (props, parentRef) => {
    const ref = useDefaultRef(parentRef);

    const contextState = React.useContext(CheckboxGroupContext);

    if (contextState) {
      return (
        <CheckboxGroupItem
          {...props}
          ref={ref}
        />
      );
    }
    return (
      <StandaloneCheckbox
        {...props}
        ref={ref}
      />
    );
  }
);

const CheckboxGroupItem = React.forwardRef(
  /**
   * @param {CheckboxProps} props
   * @param {React.MutableRefObject<HTMLInputElement>} ref
   **/
  ({ value, ...props }, ref) => {
    const groupState = React.useContext(CheckboxGroupContext);
    const { inputProps } = useCheckboxGroupItem(
      {
        ...props,
        // Passed seperately because ts error:
        // Value is optional for standalone checkboxes, but required for CheckboxGroup items
        value,
      },
      groupState,
      ref
    );

    for (let key of ['isSelected', 'defaultSelected']) {
      if (key in props) {
        console.warn(
          `${key} is unsupported on individual <Checkbox> elements within a <CheckboxGroup>. Please apply these props to the group instead.`
        );
      }
    }
    if (!value) {
      console.warn(
        'A <Checkbox> element within a <CheckboxGroup> requires a `value` property.'
      );
    }

    return (
      <CheckboxMarkup
        inputProps={inputProps}
        // same semantics if the whole group is disabled or just this checkbox
        isDisabled={groupState.isDisabled || props.isDisabled}
        isIndeterminate={props.isIndeterminate}
        isSelected={groupState.isSelected(value)}
        ref={ref}
      >
        {props.children}
      </CheckboxMarkup>
    );
  }
);

const StandaloneCheckbox = React.forwardRef(
  /**
   * @param {CheckboxProps} props
   * @param {React.MutableRefObject<HTMLInputElement>} ref
   **/
  (props, ref) => {
    const state = useToggleState(props);
    const { inputProps } = useCheckbox(props, state, ref);

    return (
      <CheckboxMarkup
        inputProps={inputProps}
        isDisabled={props.isDisabled}
        isSelected={state.isSelected}
        isIndeterminate={props.isIndeterminate}
        ref={ref}
      >
        {props.children}
      </CheckboxMarkup>
    );
  }
);

const CheckboxMarkup = React.forwardRef(
  /**
   * @param {object} props
   * @param {React.InputHTMLAttributes<HTMLInputElement>} props.inputProps
   * @param {React.ReactNode} props.children
   * @param {boolean} props.isDisabled,
   * @param {boolean} props.isSelected
   * @param {boolean} props.isIndeterminate
   * @param {React.MutableRefObject<HTMLInputElement>} ref
   **/
  ({ inputProps, isDisabled, isSelected, isIndeterminate, children }, ref) => {
    const { colorPrimary } = useThemeVariables();

    const variants = {
      checked: {
        scale: 1,
        borderRadius: 0,
      },
      unchecked: {
        scale: 0.01,
        borderRadius: '50%',
      },
    };

    const checkedIcon = isIndeterminate ? (
      <RiCheckboxIndeterminateFill
        style={{ display: 'inline-block' }}
        size={'2.4rem'}
      />
    ) : (
      <RiCheckboxFill
        style={{ display: 'inline-block' }}
        size={'2.4rem'}
      />
    );

    const isChecked = isIndeterminate || isSelected;

    return (
      <FocusRing
        focusRingClass="--focusRing"
        autoFocus={inputProps.autoFocus}
        within
      >
        <label
          className={cx(styles.checkboxLabel, {
            '--isSelected': isSelected,
            '--isIndeterminate': isIndeterminate,
            '--isDisabled': isDisabled,
          })}
        >
          <VisuallyHidden>
            <input
              {...inputProps}
              ref={ref}
            />
          </VisuallyHidden>
          <span className={styles.checkMark}>
            <span>
              <RiCheckboxBlankLine size={'2.4rem'} />
            </span>
            <motion.span
              variants={variants}
              style={{ color: colorPrimary }}
              initial={isChecked ? 'checked' : 'unchecked'}
              animate={isChecked ? 'checked' : 'unchecked'}
              transition={{
                // type: 'spring',
                // bounce: 0.5,
                duration: 0.125,
              }}
            >
              {checkedIcon}
            </motion.span>
          </span>
          {children && <span>{children}</span>}
        </label>
      </FocusRing>
    );
  }
);

/**
 * @typedef {CheckboxLocalProps & import("types").AriaLabelingProps} CheckboxProps
 *
 * @typedef CheckboxLocalProps
 * @property {boolean} [isIndeterminate]
 * 👆 Indeterminism is presentational only. The indeterminate visual
 *    representation remains regardless of user interaction
 * @property {React.ReactNode} [children]
 * 👆 The label. If not provided, aria-labelledby or aria-label should be used instead
 * @property {boolean} [defaultSelected]
 * @property {boolean} [isSelected]
 * @property {(isSelected: boolean) => void} [onChange]
 * @property {string} [value]
 * 👆 The value of the input element, used when submitting an HTML form.
 *    Value is required for Standalone checkboxes but not for checkboxes
 *    inside a group
 * @property {string} [name] - The name of the input element, used when submitting an HTML form
 * @property {boolean} [isDisabled]
 * @property {boolean} [isReadOnly]
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isRequired]
 * @property {boolean} [autoFocus]
 * @property {(e: React.FocusEvent) => void} [onFocus]
 * @property {(e: React.FocusEvent) => void} [onBlur]
 * @property {(isFocused: boolean) => void} [onFocusChange]
 * @property {(e: React.KeyboardEvent) => void} [onKeyDown]
 * @property {(e: React.KeyboardEvent) => void} [onKeyUp]
 * @property {boolean} [excludeFromTabOrder]
 * @property {string} [aria-controls]
 * @property {string } [aria-errormessage]
 */

/**
 * @typedef {import("types").AriaLabelingProps & CheckboxGroupLocalProps} CheckboxGroupProps
 *
 * @typedef CheckboxGroupLocalProps
 * @property {"horizontal" | "vertical"} [orientation]
 * @property {React.ReactNode} [children]
 * 👆 Checkboxes that will share the same state
 * @property {string} [name]
 * 👆 The name of the CheckboxGroup, used when submitting an HTML form.
 * @property {string[]} [value] - Array of selected values
 * @property {string[]} [defaultValue] - Array of default selected values
 * @property {(value: string[]) => void} [onChange]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isReadOnly]
 * @property {React.ReactNode} [label]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {"valid" | "invalid" | null} [validationState]
 * @property {boolean} [isRequired]
 * @property {string} [aria-errormessage]
 * 👆 Identifies the element that provides an error message for the object
 */
