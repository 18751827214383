const userActionTypes = {
  FETCH_USERADDRESS_START: 'FETCH_USERADDRESS_START',
  FETCH_USERADDRESS_SUCCESS: 'FETCH_USERADDRESS_SUCCESS',
  FETCH_USER_BALANCE_START: 'FETCH_USER_ADDRESS_START',
  FETCH_USERBALANCE_SUCCESS: 'FETCH_USERBALANCE_SUCCESS',
  FETCH_USERADDRESS_FAILURE: 'FETCH_USERDATA_FAILURE',
  FETCH_USER_TRANSACTIONS_START: 'FETCH_USER_TRANSACTIONS_START',
  FETCH_USER_TRANSACTIONS_SUCCESS: 'FETCH_USER_TRANSACTIONS_SUCCESS',
  FETCH_USER_TRANSACTIONS_FAIL: 'FETCH_USER_TRANSACTIONS_FAIL',
  FETCH_USER_INVOICES_START: 'FETCH_USER_INVOICES_START',
  FETCH_USER_INVOICES_SUCCESS: 'FETCH_USER_INVOICES_SUCCESS',
  FETCH_USER_INVOICES_FAIL: 'FETCH_USER_INVOICES_FAIL',
  FETCH_USER_CREDENTIALS_START: 'FETCH_USER_CREDENTIALS_START',
  FETCH_USER_CREDENTIALS_SUCCESS: 'FETCH_USER_CREDENTIALS_SUCCESS',
  SEND_TOTAL_EU_BALANCE: 'SEND_TOTAL_EU_BALANCE',
  TFA_GENERATE_START: 'TFA_GENERATE_START',
  TFA_GENERATE_SUCCESS: 'TFA_GENERATE_SUCCESS',
  TFA_GENERATE_FAILURE: 'TFA_GENERATE_FAILURE',
  TFA_VERIFY_START: 'TFA_VERIFY_START',
  TFA_VERIFY: 'TFA_VERIFY',
  TFA_DISABLE_START: 'TFA_DISABLE_START',
  TFA_DISABLE: 'TFA_DISABLE',
  TFA_NEW_DEVICE_START: 'TFA_NEW_DEVICE_START',
  TFA_NEW_DEVICE: 'TFA_NEW_DEVICE',
  TFA_ERROR: 'TFA_ERROR',
  FETCH_IP_WHITELIST_START: 'FETCH_IP_WHITELIST_START',
  FETCH_IP_WHITELIST_SUCCESS: 'FETCH_IP_WHITELIST_SUCCESS',
  SEARCH_COIN: 'SEARCH_COIN',
};

export default userActionTypes;
