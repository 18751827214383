// @ts-check

import * as Vector from "./Vector";
const round2 = (val) => Math.round(val * 100) / 100;
const clamp255 = (val) => Math.max(Math.min(val, 255), 0);
const clamp1 = (val) => Math.max(Math.min(val, 1), 0);

export function createConstantTransition(speed, startValue, endValue) {
	let s = speed || 0;
	let currentValue = startValue || 0;
	let desiredValue = endValue || 0;

	return {
		value: () => currentValue,
		speed: () => s,
		desired: () => desiredValue,
		changeSpeed(/** @type {number} */ value) {
			s = value;
		},
		transition(desired, current) {
			if (desired !== undefined) {
				desiredValue = desired;
			}
			if (current !== undefined) {
				currentValue = current;
			}

			const dist = desiredValue - currentValue;

			if (Math.abs(dist) <= s) {
				currentValue = desiredValue;
				return currentValue;
			}

			currentValue += dist > 0 ? s : -s;

			return currentValue;
		},
	};
}

export function createTransition(speed, startValue, endValue) {
	let s = Math.max(Math.min(speed, 1), 0);
	let currentValue = startValue || 0;
	let desiredValue = endValue || 0;

	return {
		value: () => currentValue,
		speed: () => speed,
		desired: () => desiredValue,
		changeSpeed(/** @type {number} */ value) {
			s = Math.max(Math.min(value, 1), 0);
		},
		transition(desired, current) {
			if (desired !== undefined) {
				desiredValue = desired;
			}
			if (current !== undefined) {
				currentValue = current;
			}

			if (Math.abs(desiredValue - currentValue) <= Vector.EPSILON) {
				currentValue = desiredValue;
			} else {
				currentValue = currentValue + (desiredValue - currentValue) * s;
			}

			return currentValue;
		},
	};
}

/**
 * @param {number} [speed]
 * @param {import("./Vector").Vec2} [startVector]
 */
export function createVecTransition(speed, startVector) {
	let s = speed || 0;
	const currentVector = Vector.clone(startVector || Vector.create());
	const distVec = Vector.create();

	return {
		vector: () => currentVector,
		changeSpeed(/** @type {number} */ value) {
			s = value;
		},
		transition(desiredVector, current) {
			if (current) {
				Vector.set(currentVector, current[0], current[1]);
			}
			Vector.sub(distVec, desiredVector, currentVector);
			Vector.scale(distVec, distVec, s);
			Vector.add(currentVector, currentVector, distVec);
			return currentVector;
		},
	};
}

/** @param {number} [speed] @param {number[]} [startValue] */
export function createColorTransition(speed, startValue = [255, 255, 255, 1]) {
	const currentValue = startValue;
	const roundedCurrentValue = startValue;
	const rgbTransitions = startValue
		.slice(0, -1)
		.map((val) => createTransition(speed, Math.round(val)));
	const alphaTransition = createTransition(
		speed,
		round2(startValue[startValue.length - 1])
	);

	return {
		value: () => currentValue,
		changeSpeed(value) {
			for (let i = 0; i < rgbTransitions.length; i++) {
				const transition = rgbTransitions[i];
				transition.changeSpeed(value);
			}
			alphaTransition.changeSpeed(value);
		},
		transition(desired, current) {
			if (current !== undefined) {
				if (current.length !== 4) throw new Error("rgba is not valid");
				currentValue[0] = clamp255(current[0]);
				currentValue[1] = clamp255(current[1]);
				currentValue[2] = clamp255(current[2]);
				currentValue[3] = clamp1(current[3]);
			}

			currentValue[0] = rgbTransitions[0].transition(
				clamp255(desired[0]),
				currentValue[0]
			);
			currentValue[1] = rgbTransitions[1].transition(
				clamp255(desired[1]),
				currentValue[1]
			);
			currentValue[2] = rgbTransitions[2].transition(
				clamp255(desired[2]),
				currentValue[2]
			);
			currentValue[3] = alphaTransition.transition(
				clamp1(desired[3]),
				currentValue[3]
			);

			roundedCurrentValue[0] = Math.round(currentValue[0]);
			roundedCurrentValue[1] = Math.round(currentValue[1]);
			roundedCurrentValue[2] = Math.round(currentValue[2]);
			roundedCurrentValue[3] = round2(currentValue[3]);
			return roundedCurrentValue;
		},
	};
}
