// @ts-check
import * as React from 'react';

/**
 * @typedef {{
 *    defaults: React.ReactNode[],
 *    [index: string]: React.ReactNode,
 * }} SlotContextValue
 */

/** @type {React.Context<SlotContextValue>} */
export const SlotContext = React.createContext(undefined);

/**
 *
 * @param {object} props
 * @param {React.ReactNode} props.content
 * @param {React.ReactNode} props.children
 */
export function SlotProvider({ content, children }) {
  /** @type {React.MutableRefObject<SlotContextValue>} */
  const value = React.useRef();

  value.current = { defaults: [] };
  React.Children.forEach(content, (child, i) => {
    if (React.isValidElement(child)) {
      const slotName = child.props.slot || child.props['data-slot'];

      // we don't want the slot attribute to show up in the mark up
      const node = React.cloneElement(child, {
        slot: undefined,
        'data-slot': undefined,
        key: slotName || child.props.key || i + '',
      });

      if (!slotName) {
        value.current.defaults.push(node);
      } else {
        value.current[slotName] = node;
      }
    } else if (child) {
      // strings, numbers etc
      value.current.defaults.push(child);
    }
  });

  return (
    <SlotContext.Provider value={value.current}>
      {children}
    </SlotContext.Provider>
  );
}

export function useSlots() {
  return React.useContext(SlotContext);
}
