// @ts-check
import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import IconButton from 'common/buttons/IconButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import TextField from 'common/fields/TextField';
import ComboBox from 'common/pickers/ComboBox.component';
import DetailedFee from 'components/content/DetailedFee.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { ConversionGroup } from 'components/conversion/ConversionGroup';
import CurrencyField from 'components/currency-fields/CurrencyField.component';
import { DepositPicker } from 'components/currency-fields/DepositPicker.component';
import { getInitialSelectedKey } from 'components/currency-fields/NetworkPicker.component';
import ConversionErrorView from 'components/error-views/ConversionErrorView';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useExchangeContext } from 'context/ExchangeContext';
import { useLocale } from 'context/LanguageContext';
import { ConversionError } from 'errors';
import { ErrorMap } from 'errors/ErrorMap';
import { useBalancesQuery } from 'queries/balances';
import { useTemplateEmailsQuery } from 'queries/templateEmails';
import * as React from 'react';
import { RiDeleteBin2Line, RiSendPlaneFill } from 'react-icons/ri';
import { Item } from 'react-stately';
import styles from './QuickP2P.module.css';

function getValidationState(sendForm, field) {
  return !sendForm.touched[field]
    ? null
    : sendForm.errors[field]
    ? 'invalid'
    : 'valid';
}

export default function QuickP2P() {
  const p2pForm = useExchangeContext().p2p;
  const { LL } = useLocale();
  const { conversionCurrency } = useCurrencyAndThemeContext();
  const [emailSave, setEmailSave] = React.useState(false);
  const [emailDeleted, setEmailDeleted] = React.useState(false);

  const handleBlur = (field) => () => {
    if (field === 'amount') {
      if (p2pForm.values.amount) p2pForm.setFieldTouched(field, true);
    } else if (field !== 'coin') p2pForm.setFieldTouched(field, true);
    // if (field !== 'coin') p2pForm.validateField(field);
  };

  const balances = useBalancesQuery(true);
  const { data, refetch } = useTemplateEmailsQuery({
    enabled: !!p2pForm.values.coin,
  });

  async function saveP2PTemplate() {
    const data = { email: p2pForm.values.email };
    try {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}templates/p2p`,
        data: data,
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      setEmailSave(true);
      setTimeout(() => {
        setEmailSave(false);
      }, 5000);
    } catch (error) {
      p2pForm.setServerError(error.response.data.error);
    }
  }

  async function deleteP2PTemplate(templateId) {
    try {
      await axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URI}templates/p2p`,
        params: { templateId },
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      setEmailDeleted(true);
      setTimeout(() => {
        setEmailDeleted(false);
      }, 5000);
    } catch (error) {
      p2pForm.setServerError(error.response.data.error);
    }
  }

  return (
    <form
      onSubmit={p2pForm.handleSubmit}
      onReset={p2pForm.handleReset}
      className={styles.container}
    >
      <div ref={p2pForm.statusRef}>
        <ErrorContainer>
          {p2pForm.serverError && (
            <InlineError
              onClose={() => p2pForm.setServerError(null)}
              success={false}
            >
              {p2pForm.serverError}
            </InlineError>
          )}
          {p2pForm.success && (
            <InlineError
              onClose={() => p2pForm.setSuccess(false)}
              success={true}
            >
              {'Transaction Successful!'}
            </InlineError>
          )}
          {emailSave && (
            <InlineError
              onClose={() => p2pForm.setSuccess(false)}
              success={true}
            >
              {'Email Saved!'}
            </InlineError>
          )}
          {emailDeleted && (
            <InlineError
              onClose={() => setEmailDeleted(false)}
              success={true}
            >
              {'Email deleted!'}
            </InlineError>
          )}
        </ErrorContainer>
      </div>

      {!balances.isLoading && balances.data.length === 0 ? (
        LL.exchange.noDepositMessage()
      ) : (
        <DepositPicker
          label={LL.exchange.balance()}
          defaultText={LL.exchange.chooseBalance()}
          selectedKey={p2pForm.values.coin}
          onSelectionChange={async (key) => {
            const network = await getInitialSelectedKey(key + '');
            p2pForm.setFieldValue('coin', key, false);
            p2pForm.setFieldValue('network', network.network_name, false);
          }}
          onBlur={handleBlur('coin')}
          validationState={getValidationState(p2pForm, 'coin')}
          errorMessage={p2pForm.errors.coin}
        />
      )}
      <ComboBox
        label={LL.exchange.userEmail()}
        inputValue={p2pForm.values.email}
        onInputChange={(val) => p2pForm.setFieldValue('email', val, false)}
        onBlur={handleBlur('email')}
        validationState={getValidationState(p2pForm, 'email')}
        errorMessage={p2pForm.errors.email}
        isDisabled={!p2pForm.values.coin}
        allowsCustomValue
      >
        {data &&
          data.map((template) => (
            <Item
              key={template.id}
              textValue={template.email}
            >
              <div className={styles.comboboxItems}>
                {template.email}
                <IconButton
                  aria-label={template.id}
                  onClick={async () => {
                    await deleteP2PTemplate(template.id);
                    await refetch();
                    p2pForm.handleReset();
                  }}
                >
                  <RiDeleteBin2Line
                    style={{
                      display: 'inline-flex',
                      alignSelf: 'flex-end',
                      color: 'var(--md-ref-palette-error-50)',
                    }}
                  />
                </IconButton>
              </div>
            </Item>
          ))}
      </ComboBox>
      <ErrorMap errors={new Map([[ConversionError, <ConversionErrorView />]])}>
        <ConversionGroup
          // Value can change from useExchangeContext().navigate("p2p", {amount:123}) so it needs to be set here
          value={p2pForm.values.amount}
          onChange={(val) => p2pForm.setFieldValue('amount', val, false)}
          currency={p2pForm.values.coin}
        >
          <fieldset className={styles.amount}>
            <CurrencyField
              currency={p2pForm.values.coin}
              label={LL.exchange.amountLabel({ currency: p2pForm.values.coin })}
              onBlur={handleBlur('amount')}
              validationState={getValidationState(p2pForm, 'amount')}
              errorMessage={p2pForm.errors.amount}
              isDisabled={!p2pForm.values.coin}
            />
            <CurrencyField
              label={LL.exchange.conversionAmountLabel({
                currency: conversionCurrency,
              })}
              currency={conversionCurrency}
              onBlur={handleBlur('amount')}
              validationState={getValidationState(p2pForm, 'amount')}
              isDisabled={!p2pForm.values.coin}
            />
          </fieldset>
        </ConversionGroup>
        <TextField
          inputElementType="textarea"
          placeholder={LL.exchange.descriptionPlaceholder()}
          label={LL.exchange.description()}
          isDisabled={!p2pForm.values.coin}
        />
        <div className={styles.breathingRoom}>
          {p2pForm.values.coin && (
            <DetailedFee
              currency={p2pForm.values.coin}
              amount={p2pForm.values.amount}
              feeType={'p2p'}
              payload={p2pForm.values.coin}
              classNames={styles}
            />
          )}

          <div className={styles.actions}>
            <OutlineButton
              isDisabled={!p2pForm.values.coin || !p2pForm.values.email}
              onPress={() => saveP2PTemplate()}
            >
              {LL.exchange.saveEmail()}
            </OutlineButton>
            <OutlineButton type="reset">{LL.app.reset()}</OutlineButton>
            <FilledButton
              left={<RiSendPlaneFill />}
              isDisabled={!p2pForm.values.coin || p2pForm.isSubmitting}
              onPress={() => {
                p2pForm.handleSubmit();
                p2pForm.scrollToStatus();
              }}
            >
              {LL.exchange.sendP2P()}
            </FilledButton>
          </div>
        </div>
      </ErrorMap>
    </form>
  );
}
