import IconButton from 'common/buttons/IconButton';
import { DateField } from 'common/calendar/DateField.component';
import PopoverBase from 'common/overlays/base/PopoverBase.component';
import Dialog from 'common/overlays/Dialog.component';
import React from 'react';
import { useDatePicker } from 'react-aria';
import { BsCalendarEventFill } from 'react-icons/bs';
import { useDatePickerState } from 'react-stately';
import Calendar from './Calendar.component';
import styles from './DatePicker.module.css';

export default function DatePicker(props) {
  let state = useDatePickerState(props);
  let ref = React.useRef(null);
  let {
    groupProps,
    labelProps,
    fieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker(props, state, ref);
  return (
    <div className={styles.container}>
      <div
        className={styles.label}
        {...labelProps}
      >
        {props.label}
      </div>
      <div
        {...groupProps}
        ref={ref}
        className={styles.fields}
      >
        <DateField {...fieldProps} />
        <IconButton
          aria-label={buttonProps['aria-label']}
          onPress={buttonProps.onPress}
          id={buttonProps.id}
          aria-labelledby={buttonProps['aria-labelledby']}
          aria-haspopup={buttonProps['aria-haspopup']}
        >
          <BsCalendarEventFill
            color="var(--color-logo)"
            opacity={0.7}
          />
        </IconButton>
      </div>
      {state.isOpen && (
        <PopoverBase
          state={state}
          triggerRef={ref}
          placement="bottom start"
          containerPadding={2}
        >
          <Dialog {...dialogProps}>
            <Calendar {...calendarProps} />
          </Dialog>
        </PopoverBase>
      )}
    </div>
  );
}

<DatePicker label="Event date" />;
