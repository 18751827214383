// @ts-check

import big from 'bigjs-literal/macro';
import { useExchangeFee } from 'queries/exchangeFee';
import { useP2pFeeQuery } from 'queries/p2pFee';
import { useTransferFeeQuery } from 'queries/transferFee';

/**
 * @typedef FeePayload
 * @property {import("queries/transferFee").TransferFeePayload} transfer
 * @property {import("queries/exchangeFee").ExchangeFeePayload} exchange
 * @property {import("queries/p2pFee").P2pFeePayload} p2p
 *
 */

/**
 * @template {keyof FeePayload} T
 * @typedef {FeePayload[T]} Payload
 */

/**
 * @template {"transfer" | "exchange" | "p2p"} T
 * @typedef {T} FeeType
 **/

/**
 * @template {"transfer" | "exchange" | "p2p"} T
 * @typedef {Payload<T>} [Payload]
 */

/**
 * calculated amount with fee. If data is not loaded yet, null is returned
 * @template {"transfer" | "exchange" | "p2p"} T
 * @param {FeeType<T>} feeType
 * @param {Payload<T>} payload
 *
 * @returns {{ addFee: (amount: number) => number, subFee: (amount: number) => number,  fee: number | null }}
 */
function useFee(feeType, payload) {
  const { coin, type, network } = /** @type {FeePayload["transfer"]} */ (
    payload || {}
  );
  const transferFee = useTransferFeeQuery(
    { coin, type, network },
    { enabled: feeType === 'transfer' && !!(coin && type) }
  );

  const { from, to } = /** @type {FeePayload["exchange"]} */ (payload || {});
  const exchangeFee = useExchangeFee(
    { from, to },
    { enabled: feeType === 'exchange' && !!(from && to) }
  );

  const p2pPayload = /** @type {FeePayload["p2p"]} */ (payload);
  const p2pFee = useP2pFeeQuery(p2pPayload, {
    enabled: feeType === 'p2p' && !!payload,
  });

  if (feeType === 'transfer') {
    return {
      addFee: (amount) => +big`${amount} + ${transferFee.data?.amount || 0}`,
      subFee: (amount) => +big`${amount} - ${transferFee.data?.amount || 0}`,
      fee: transferFee.data?.amount,
    };
  } else if (feeType === 'exchange') {
    return {
      addFee: (amount) =>
        +big`(${amount}/(100 - ${exchangeFee.data?.fee})) * 100`,
      subFee: (amount) =>
        +big`${amount} - (${amount} * ${exchangeFee.data?.fee || 0}) / 100`,
      fee: exchangeFee.data?.fee,
    };
  } else if (feeType === 'p2p') {
    return {
      addFee: (amount) => +big`${amount} + ${p2pFee.data?.amount || 0}`,
      subFee: (amount) => +big`${amount} - ${p2pFee.data?.amount || 0}`,
      fee: p2pFee.data?.amount,
    };
  }

  throw new Error(
    "Please provide either 'transfer' 'exchange' or 'p2p' as a `feeType` parameter to `useAddFee`"
  );
}

export default useFee;
