import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import styles from './signIn.module.css';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import FilledButton from 'common/buttons/FilledButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import PhoneVerificationForm from 'pages/sign-up/PhoneVerification.component';
import { loginStart } from '../../redux/auth/auth.actions';

const SignIn = ({ showPhoneVerification }) => {
  const dispatch = useDispatch();
  const { LL } = useLocale();
  const showError = useSelector((state) => state.auth.authError);

  const captcha = useRef();

  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState('');
  const { colorTest4 } = useThemeVariables();
  const surfaceColor = {
    background: colorTest4,
  };
  const validSchema = {
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object(validSchema),
    onSubmit: async ({ email, password }) => {
      dispatch(loginStart({ email, password }));
      setUserEmail(email);
      captcha.current.execute();
      formik.resetForm();
    },
  });

  if (localStorage.getItem('token')) {
    return sessionStorage.getItem('p2p_invoice_url') ? (
      <Navigate
        to={sessionStorage
          .getItem('p2p_invoice_url')
          .replace(window.location.origin, '')}
      />
    ) : (
      <Navigate to="/overview" />
    );
  }

  return showPhoneVerification ? (
    <PhoneVerificationForm userEmail={userEmail} />
  ) : (
    <Surface
      className={styles.signIn}
      surfaceColor={surfaceColor}
    >
      <form
        className={styles.signInForm}
        onSubmit={formik.handleSubmit}
      >
        {showError.error && (
          <div className={styles.errorMsg}>{showError.data}</div>
        )}
        <h1>Sign In</h1>

        <div className={styles.inputWrapper}>
          <TextField
            label={LL.signUp.email()}
            placeholder={LL.signUp.email()}
            value={formik.values.email}
            onChange={(val) => formik.setFieldValue('email', val, false)}
            errorMessage={formik.errors.email}
          />
        </div>

        <div className={styles.inputWrapper}>
          <TextField
            label={LL.signUp.password()}
            placeholder={LL.signUp.password()}
            type="password"
            value={formik.values.password}
            onChange={(val) => formik.setFieldValue('password', val, false)}
            errorMessage={formik.errors.password}
          />
        </div>

        <ReCAPTCHA
          ref={captcha}
          size="invisible"
          sitekey="6Lc8Ed0ZAAAAAMBWWq-b5_HhZWeXgbBXX13Xwqy_"
          // onChange={() => setCaptchaConfirmed(true)}
          theme="dark"
        />

        <FilledButton
          type="submit"
          className={styles.signInBtn}
        >
          Sign In
        </FilledButton>
      </form>

      <div className={styles.signInAdditional}>
        <Link
          to="/password-recovery"
          style={{
            cursor: 'pointer',
            color: 'var(--color-logo)',
            textDecoration: 'none',
          }}
        >
          Forgot Password?
        </Link>
      </div>

      <div className={styles.signInAdditional}>
        If you are not Registered yet, Please{' '}
        <Link
          to="/signup"
          style={{
            cursor: 'pointer',
            color: 'var(--color-logo)',
            textDecoration: 'none',
          }}
        >
          Create a New Account
        </Link>
      </div>
    </Surface>
  );
};

const mapStateToProps = (state) => ({
  showPhoneVerification: state.auth.showPhoneVerification,
});

export default connect(mapStateToProps)(SignIn);
