// @ts-check
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export const currencyNetworksKey = ['networks'];

/**
 * @returns {Promise<import("types").CurrencyNetworks>}
 */
export async function getCurrencyNetworks() {
  const { data } = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URI}networks`,
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });

  return data;
}

/**
 * @returns {import('@tanstack/react-query').UseQueryResult<import("types").CurrencyNetworks, Error>}
 */
export function useCurrencyNetworksQuery() {
  return useQuery(currencyNetworksKey, getCurrencyNetworks);
}
