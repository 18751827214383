// @ts-check

import * as React from 'react';
import Select from 'common/pickers/Select.component';
import { Item } from '@react-stately/collections';
import { useLocale } from 'context/LanguageContext';

/**
 * @typedef Props
 * @property {(key: import('types').FeeType) => void} onSelectionChange
 * @property {React.ReactNode} [label]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {string | null} [selectedKey]
 * @property {boolean} [autoFocus]
 * @property {( e : React.FocusEvent ) => void} [onFocus]
 * @property {( e : React.FocusEvent ) => void} [onBlur]
 * @property {( isFocused : boolean ) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyUp]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {React.MutableRefObject<HTMLElement>} [positionRef]
 *
 * @param {Props} props
 */
export default function FeePicker(props) {
  const { LL } = useLocale();

  return (
    <Select
      defaultSelectedKey="standard"
      {...props}
    >
      <Item key="standard">{LL.exchange.advanced.standardFee()}</Item>
      <Item key="medium">{LL.exchange.advanced.mediumFee()}</Item>
      <Item key="high">{LL.exchange.advanced.highFee()}</Item>
    </Select>
  );
}
