// @ts-check
import { rgbaEquals } from '../../utils/colorUtils';
import * as Vector from '../../utils/Vector';

export default class GridItem {
  // can't update externally
  _offset;
  _line = Vector.create();
  // update these properties before drawing
  _direction = Vector.create();
  _pastDirection = Vector.create();

  _width = 0;
  _pastWidth = undefined;

  _lineWidth;
  _pastLineWidth = undefined;

  _rgba = [0, 0, 0, 1];
  _pastRgba = [null, null, null, null];
  _rgbaString;

  /**
   * @param {CanvasRenderingContext2D} ctx
   * @param {Vector.Vec2} offset
   * @param {number[]} rgba
   */
  constructor(ctx, offset, rgba, lineWidth) {
    this.ctx = ctx;
    this._offset = offset;
    this.rgba = rgba;
    this.lineWidth = lineWidth;
  }

  get offset() {
    return this._offset;
  }

  get direction() {
    return this._direction;
  }
  set direction(value) {
    Vector.set(this._pastDirection, this._direction[0], this._direction[1]);
    Vector.normalize(this._direction, value);
  }

  get rgba() {
    return this._rgba;
  }
  /** @param {number[]} value */
  set rgba(value) {
    if (!rgbaEquals(this.rgba, value)) {
      this._pastRgba[0] = this._rgba[0];
      this._pastRgba[1] = this._rgba[1];
      this._pastRgba[2] = this._rgba[2];
      this._pastRgba[3] = this._rgba[3];

      this._rgba[0] = value[0];
      this._rgba[1] = value[1];
      this._rgba[2] = value[2];
      this._rgba[3] = value[3];

      this._rgbaString = rgbaString(value);
    }
  }

  get width() {
    return this._width;
  }
  set width(value) {
    this._pastWidth = this._width;
    this._width = value;
  }

  get lineWidth() {
    return this._lineWidth;
  }
  set lineWidth(value) {
    this._pastLineWidth = this._lineWidth;
    this._lineWidth = value;
  }

  /** @param {number} size sjze of the square delete  */
  erase(size) {
    if (size) {
      const halfUpdateSize = size / 2;
      this.ctx.clearRect(
        this._offset[0] - halfUpdateSize,
        this._offset[1] - halfUpdateSize,
        size,
        size
      );
    }
  }

  draw(eraseSize, forceDraw) {
    if (
      forceDraw ||
      this.width !== this._pastWidth ||
      !Vector.equals(this.direction, this._pastDirection) ||
      !rgbaEquals(this.rgba, this._pastRgba) ||
      this.lineWidth !== this._pastLineWidth
    ) {
      this.erase(eraseSize);

      Vector.scale(this._line, this._direction, this.width);

      // configure ctx style
      this.ctx.lineWidth = this.lineWidth;
      this.ctx.lineCap = 'round';
      this.ctx.strokeStyle = this._rgbaString;

      this.ctx.beginPath();
      this.ctx.moveTo(
        this._offset[0] - this._line[0] / 2,
        this._offset[1] - this._line[1] / 2
      );

      this.ctx.lineTo(
        this._offset[0] + this._line[0] / 2,
        this._offset[1] + this._line[1] / 2
      );
      this.ctx.stroke();

      this._pastRgba[0] = this.rgba[0];
      this._pastRgba[1] = this.rgba[1];
      this._pastRgba[2] = this.rgba[2];
      this._pastRgba[3] = this.rgba[3];
      Vector.set(this._pastDirection, this.direction[0], this.direction[1]);
      this._pastWidth = this.width;
      this._pastLineWidth = this.lineWidth;
    }
  }
}

const rgbaString = (rgbaArr) => {
  return `rgba(${rgbaArr[0]},${rgbaArr[1]},${rgbaArr[2]},${rgbaArr[3]})`;
};
