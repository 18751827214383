import currencies from 'assets/currencies';
import CurrencyColors from 'assets/data/currency-colors';
import Currency from 'components/content/Currency.component';
import BufferingWheel from 'components/loader/LoadingAnimation.component';
import { useLocale } from 'context/LanguageContext';
import { format, parseISO } from 'date-fns';
import styles from './OutstandingFiatTransaction.module.css';

import FilledButton from 'common/buttons/FilledButton';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import React from 'react';
import { H } from 'react-accessible-headings';

const OutstandingFiatTransaction = ({ data }) => {
  const { LL } = useLocale();
  const { data: transactionData } = data;
  const {
    pbx_amount,
    amount_currency,
    pbx_fee,
    currency,
    created_at,
    url,
    status,
  } = transactionData;
  const surfaceColor = CurrencyColors[currency];

  return !data ? (
    <BufferingWheel color="var(--color-logo)" />
  ) : (
    <TranslucentSurface
      className={styles.sectionSmall}
      surfaceColor={surfaceColor}
    >
      <H className={styles.headerSmall}>
        {LL.exchange.send.outstandingTransaction()}:
      </H>
      <span className={styles.headLineSmall}>
        <img
          className={styles.icon}
          src={currencies[currency]}
          alt=""
          style={{ height: '4rem', width: '4rem' }}
        />
        <Currency currency={currency}>{Number(pbx_amount)}</Currency>
      </span>
      <div className={styles.body}>
        <div className={styles.details}>
          <a>
            {LL.explorer.exchangeRecord.createdAt()}:{' '}
            <span className={styles.detailsContent}>
              {format(parseISO(new Date(created_at).toISOString()), 'Pp')}
            </span>
          </a>
          <a>
            {LL.exchange.send.status()}{' '}
            <span className={styles.detailsContent}>{status}</span>
          </a>
          <a>
            {LL.exchange.send.pbxFee()}:{' '}
            <span className={styles.detailsContent}>
              <Currency currency={currency}>{Number(pbx_fee)}</Currency>
            </span>
          </a>
          <a>
            {LL.exchange.send.finalAmount()}{' '}
            <span className={styles.detailsContent}>
              <Currency currency={currency}>{Number(amount_currency)}</Currency>
            </span>
          </a>
        </div>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.resolveButton}
          onClick={(e) => e.persist()}
        >
          <FilledButton>{LL.exchange.send.resolve()}</FilledButton>
        </a>
      </div>
    </TranslucentSurface>
  );
};

export default OutstandingFiatTransaction;
