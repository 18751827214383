import { takeLatest, call, put, all } from 'redux-saga/effects';
import axios from 'axios';

import { FETCH_ADMIN_BALANCE_START } from './admin.types';

import {
  fetchTotalWalletBalanceSuccess,
  fetchTotalPbxBalanceSuccess,
} from './admin.actions';

export function* fetchAdminBalanceAsync() {
  try {
    const resWallet = yield axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}statistics/total-wallet-balances`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    const resPbx = yield axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}statistics/total-paybitx-balances`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    yield put(fetchTotalWalletBalanceSuccess(resWallet.data));
    yield put(fetchTotalPbxBalanceSuccess(resPbx.data));
  } catch (err) {
    console.log(err.response);
  }
}

export function* onFetchADminBalanceStart() {
  yield takeLatest(FETCH_ADMIN_BALANCE_START, fetchAdminBalanceAsync);
}

export function* adminSagas() {
  yield all([call(onFetchADminBalanceStart)]);
}
