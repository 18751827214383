// @ts-check
import { parseRGBA } from '../../utils/colorUtils';
import * as Vector from '../../utils/Vector';

/**
 *
 * @typedef {typeof state} State
 *
 * @typedef {(state: State) => void} ContinuousListener
 *
 * @typedef Interuptable
 * @property {Promise<any>} finished
 * @property {() => void} stop
 *
 * @typedef {(state: State) => Interuptable | void } InteruptableListener
 */

function addMouseMoveWithoutTouch(target, eventlistener, options) {
  let touchInitiated = false;

  const mousemove = (e) => {
    if (!touchInitiated) {
      eventlistener(e);
    }
    touchInitiated = false;
  };

  const touchstart = (e) => {
    touchInitiated = true;
  };

  target.addEventListener('touchstart', touchstart);
  target.addEventListener('mousemove', mousemove, options);

  return {
    remove: () => {
      target.removeEventListener('touchstart', touchstart);
      target.removeEventListener('mousemove', mousemove);
    },
  };
}

function updater(task) {
  let updateId = null;

  const update = () => {
    task();
    updateId = requestAnimationFrame(update);
  };

  const startUpdater = () => {
    if (!updateId) {
      updateId = requestAnimationFrame(update);
    }
  };

  const stopUpdater = () => {
    cancelAnimationFrame(updateId);
    updateId = null;
  };

  return {
    startUpdater,
    stopUpdater,
  };
}

const executeInteruptableListeners = (() => {
  /** @type {Interuptable[]} */
  let interuptables = [];
  /** @type {boolean[]} */
  let resolvedState = [];

  /** @param {InteruptableListener[]} listeners */
  return (listeners) => {
    // cancel current unresolved interuptables
    interuptables.forEach((interuptable, index) => {
      if (resolvedState[index]) {
        interuptable.stop();
      }
    });

    // back to defaults
    interuptables = [];
    resolvedState = [];

    // execute new listeners, mark resolved listeners when finished
    listeners.forEach(async (listener, index) => {
      let interuptable = listener(state);
      if (!interuptable) {
        // create non-sense in case interuptable was not provided
        interuptable = {
          finished: Promise.resolve(),
          stop: () => {},
        };
      }
      interuptables.push(interuptable);
      resolvedState.push(false);

      await interuptable.finished;
      resolvedState[index] = true;
    });

    return interuptables.map(({ finished }) => finished);
  };
})();

const state = {
  mouse: Vector.fromValues(window.innerWidth / 2, -window.innerHeight),
  attachPoint: Vector.create(),
  pressPoint: Vector.create(),
  isAttached: false,
  isPressed: false,
  attachHighlightRGBA: null,
  attachHighlightWidth: null,
  attachImpactRadius: null,
  pressHighlightRGBA: null,
  pressHighlightWidth: null,
  pressImpactRadius: null,
};

/** @type {{[index: string]: ContinuousListener[] }} */
const continuousListeners = {
  beforeUpdate: [],
  whileMove: [],
  whileAttached: [],
  whilePressed: [],
  afterUpdate: [],
};

/** @type {{[index: string]: InteruptableListener[] }} */
const interuptableListeners = {
  onAttach: [],
  onDetach: [],
  onPressStart: [],
  onPressEnd: [],
  onPressCancel: [],
};

const { stopUpdater, startUpdater } = updater(() => {
  continuousListeners.beforeUpdate.forEach((updater) => updater(state));
  if (state.isPressed) {
    continuousListeners.whilePressed.forEach((updater) => updater(state));
  } else if (state.isAttached) {
    continuousListeners.whileAttached.forEach((updater) => updater(state));
  } else {
    continuousListeners.whileMove.forEach((updater) => updater(state));
  }
  continuousListeners.afterUpdate.forEach((updater) => updater(state));
});

addMouseMoveWithoutTouch(document, (e) => {
  Vector.set(state.mouse, e.pageX, e.pageY);
});

document.addEventListener(
  'attachBackgroundPoint',
  async (/** @type {any} */ e) => {
    const { x, y, highlightRGBA, higlightWidth, impactRadius } = e.detail;

    stopUpdater();
    state.isAttached = true;
    state.attachHighlightRGBA = highlightRGBA && parseRGBA(highlightRGBA);
    state.attachHighlightWidth = higlightWidth;
    state.attachImpactRadius = impactRadius;

    Vector.set(state.attachPoint, x, y);

    try {
      await Promise.all(
        executeInteruptableListeners(interuptableListeners.onAttach)
      );
      startUpdater();
    } catch (err) {
      console.log('err', err);
    }
  }
);

document.addEventListener('detachBackgroundPoint', async () => {
  if (state.isAttached) {
    stopUpdater();
    try {
      await Promise.all(
        executeInteruptableListeners(interuptableListeners.onDetach)
      );
      state.isAttached = false;
      state.attachHighlightRGBA = null;
      state.attachHighlightWidth = null;
      state.attachImpactRadius = null;
      Vector.set(state.attachPoint, 0, 0);

      startUpdater();
    } catch (err) {
      console.log('err', err);
    }
  }
});

document.addEventListener(
  'backgroundPressStart',
  async (/** @type {any} */ e) => {
    const { highlightRGBA, higlightWidth, impactRadius } = e.detail;

    stopUpdater();
    state.isPressed = true;
    state.pressHighlightRGBA = highlightRGBA && parseRGBA(highlightRGBA);
    state.pressHighlightWidth = higlightWidth;
    state.pressImpactRadius = impactRadius;
    Vector.set(state.pressPoint, e.detail.x, e.detail.y);
    try {
      await Promise.all(
        executeInteruptableListeners(interuptableListeners.onPressStart)
      );
      startUpdater();
    } catch (err) {
      console.log('err', err);
    }
  }
);

document.addEventListener(
  'backgroundPressEnd',
  async (/** @type {any} */ e) => {
    if (state.isPressed) {
      stopUpdater();
      try {
        await Promise.all(
          executeInteruptableListeners(interuptableListeners.onPressEnd)
        );
        state.isPressed = false;
        state.pressHighlightRGBA = null;
        state.pressHighlightWidth = null;
        state.pressImpactRadius = null;
        Vector.set(state.pressPoint, 0, 0);

        startUpdater();
      } catch (err) {
        console.log('err', err);
      }
    }
  }
);

document.addEventListener(
  'backgroundPressCancel',
  async (/** @type {any} */ e) => {
    stopUpdater();
    try {
      await Promise.all(
        executeInteruptableListeners(interuptableListeners.onPressCancel)
      );
      state.isPressed = false;
      state.pressHighlightRGBA = null;
      state.pressHighlightWidth = null;
      state.pressImpactRadius = null;
      Vector.set(state.pressPoint, 0, 0);

      startUpdater();
    } catch (err) {
      console.log('err', err);
    }
  }
);

/**
 * @param {object} listeners
 * @param {ContinuousListener} [listeners.beforeUpdate]
 * @param {ContinuousListener} [listeners.whileMove]
 * @param {ContinuousListener} [listeners.whileAttached]
 * @param {ContinuousListener} [listeners.whilePressed]
 * @param {ContinuousListener} [listeners.afterUpdate]
 * @param {InteruptableListener} [listeners.onAttach]
 * @param {InteruptableListener} [listeners.onDetach]
 * @param {InteruptableListener} [listeners.onPressStart]
 * @param {InteruptableListener} [listeners.onPressEnd]
 * @param {InteruptableListener} [listeners.onPressCancel]
 */
export default function trackInteractions(listeners) {
  startUpdater();

  Object.keys(continuousListeners).forEach((key) => {
    if (listeners[key]) {
      continuousListeners[key].push(listeners[key]);
    }
  });
  Object.keys(interuptableListeners).forEach((key) => {
    if (listeners[key]) {
      interuptableListeners[key].push(listeners[key]);
    }
  });

  return function stopTracking() {
    Object.keys(listeners).forEach((key) => {
      if (continuousListeners[key]) {
        continuousListeners[key].splice(
          continuousListeners[key].indexOf(listeners[key]),
          1
        );
      } else {
        // inside interuptableListeners
        interuptableListeners[key].splice(
          interuptableListeners[key].indexOf(listeners[key]),
          1
        );
      }
    });
  };
}
