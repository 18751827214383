// @ts-check
import * as React from 'react';
import { useListBox, useOption, useListBoxSection } from '@react-aria/listbox';
import { FocusRing } from '@react-aria/focus';
import { Template, Slot } from 'common/template/Template';
import { RiCheckFill } from 'react-icons/ri';
import cx from 'classnames';

/**
 * @template T
 * @typedef {import("@react-stately/list").ListState<T>} ListState
 */

/**
 * @template T
 * @typedef {import("@react-types/shared").Node<T>} Node
 */

/**
 * @template T
 *
 * @typedef ListBoxBaseProps
 * @property {ClassNames} [classNames]
 * @property {ListState<T>} state
 * @property { boolean } [isVirtualized]
 *
 * @property { boolean } [shouldUseVirtualFocus]
 * Whether the listbox items should use virtual focus instead of being focused directly.
 *
 * @property { boolean } [shouldSelectOnPressUp]
 * Whether selection should occur on press up instead of press down.
 *
 * @property { boolean } [shouldFocusOnHover]
 * Whether options should be focused when the user hovers over them.
 *
 * @property { React.ReactNode } [label]
 *
 * @property { boolean | "first" | "last" } [autoFocus]
 * Whether to auto focus the listbox or an option.
 *
 * @property { boolean } [shouldFocusWrap]
 * Whether focus should wrap around when the end/start is reached.
 *
 * @property { Iterable<T> } [items]
 * @property { Iterable <React.Key> } [disabledKeys]
 * @property { 'none' | 'single' | 'multiple' } [selectionMode]
 * @property { boolean } [disallowEmptySelection]
 * @property { 'all' | Iterable <React.Key> } [selectedKeys]
 * @property { (keys : 'all' | Set<React.Key>) => void } [onSelectionChange]
 * @property { (e : React.FocusEvent) => void } [onFocus]
 * @property { (e : React.FocusEvent) => void } [onBlur]
 * @property { (isFocused : boolean) => void } [onFocusChange]
 * @property { string } [id]
 * @property { string } [aria-label]
 * @property { string } [aria-labelledby]
 * @property { string } [aria-describedby]
 * @property { string } [aria-details]
 *
 */

/**
 *
 * @typedef ClassNames
 * @property {string} [label]
 * @property {string} [listbox]
 * @property {string} [section]
 * @property {string} [sectionHeading]
 * @property {string} [sectionGroup]
 * @property {string} [option]
 * @property {string} [optionIcon]
 * @property {string} [optionContent]
 * @property {string} [optionLabel]
 * @property {string} [optionDescription]
 * @property {string} [optionSelectedIndicator]
 */

/**
 * @template  T
 * @param {ListBoxBaseProps<T>} props
 */
function ListBoxBaseWithRef({ state, classNames, ...props }, ref) {
  React.useEffect(() => {
    if (!ref) throw new Error('Must provide ref to ListBoxBase');
  });

  const { listBoxProps, labelProps } = useListBox(props, state, ref);

  return (
    <>
      {props.label && (
        <div
          {...labelProps}
          className={classNames.label}
        >
          {props.label}
        </div>
      )}
      <ul
        ref={ref}
        {...listBoxProps}
        className={classNames.listbox}
      >
        {[...state.collection].map((item) =>
          item.type === 'section' ? (
            <ListBoxSection
              key={item.key}
              section={item}
              state={state}
              classNames={classNames}
            />
          ) : (
            <Option
              key={item.key}
              item={item}
              state={state}
              classNames={classNames}
            />
          )
        )}
      </ul>
    </>
  );
}

/**
 * @template T
 * @param {object} props
 * @param {Node<T>} props.section
 * @param {ListState<T>} props.state
 * @param {ClassNames} props.classNames
 *
 */
function ListBoxSection({ section, state, classNames }) {
  let { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <>
      <li
        {...itemProps}
        className={classNames.section}
      >
        {section.rendered && (
          <span
            {...headingProps}
            className={classNames.sectionHeading}
          >
            {section.rendered}
          </span>
        )}
        <ul
          {...groupProps}
          className={classNames.sectionGroup}
        >
          {[...section.childNodes].map((node) => (
            <Option
              key={node.key}
              item={node}
              state={state}
              classNames={classNames}
            />
          ))}
        </ul>
      </li>
    </>
  );
}

/**
 * @template T
 * @param {object} props
 * @param {Node<T>} props.item
 * @param {ListState<T>} props.state
 * @param {ClassNames} props.classNames
 *
 */
function Option({ item, state, classNames }) {
  let ref = React.useRef();

  let {
    optionProps,
    labelProps,
    descriptionProps,
    isFocused,
    isSelected,
    isPressed,
    isDisabled,
  } = useOption(
    {
      key: item.key,
    },
    state,
    ref
  );

  const modifiers = cx({
    '--isFocused': isFocused,
    '--isSelected': isSelected,
    '--isPressed': isPressed,
    '--isDisabled': isDisabled,
  });

  return (
    <Template content={item.rendered}>
      {(slots) => (
        <FocusRing focusRingClass={'--focusVisible'}>
          <li
            {...optionProps}
            ref={ref}
            className={cx(classNames.option, modifiers)}
          >
            {slots.icon && (
              <div className={classNames.optionIcon}>
                <Slot name="icon" />
              </div>
            )}
            {/* there could be another slot for img/avatar aswell */}
            <div className={classNames.optionContent}>
              <div
                {...labelProps}
                className={classNames.optionLabel}
              >
                <Slot />
              </div>
              {slots.description && (
                <div
                  {...descriptionProps}
                  className={classNames.optionDescription}
                >
                  <Slot name="description" />
                </div>
              )}
            </div>

            {isSelected && (
              <div className={classNames.optionSelectedIndicator}>
                <Slot name="selected-indicator">
                  <RiCheckFill />
                </Slot>
              </div>
            )}
          </li>
        </FocusRing>
      )}
    </Template>
  );
}

const ListBoxBase = React.forwardRef(ListBoxBaseWithRef);
export default ListBoxBase;
