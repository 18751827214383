// @ts-check

import * as _storage from './storage';
import * as _Vector from './Vector';

export {
  createRGBAInterpolator,
  parseRGBA,
  hexToRGBA,
  rgbaEquals,
} from './colorUtils';
export { default as Queue } from './Queue';
export { default as socket } from './socket';
export const storage = _storage;
export {
  createConstantTransition,
  createTransition,
  createVecTransition,
  createColorTransition,
} from './transitions';
export const Vector = _Vector;

export const getDate = (date) => {
  const fullDate = new Date(date).toString();
  const index = fullDate.indexOf(' G');
  const updatedDate = fullDate.slice(0, index);
  return updatedDate;
};

export const formatFilterDate = (date) => {
  console.log('DATE', date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}/${month}/${day}`;
};

/**
 * initializes adapter for attributeChangedCallbacks
 * @param {any} context
 * @param {Record<string, any>} defaultState
 */
export function reactDidItBetter(context, defaultState) {
  /**
   * combines multiple attributeChangedCallbacks
   * @param {(pastState: Record<string, any>) => void} fn;
   * @param {number} timeout
   */
  return (fn, timeout = 100) => {
    let timer;
    let pastState = defaultState;
    let currentState = {};

    // @ts-ignore
    return (name, oldValue, newValue) => {
      clearTimeout(timer);
      // we assume attribute getters are defined in the component
      // eg: get disabled() { return this.hasAttribute("disabled")}
      currentState[name] = context[name];

      timer = setTimeout(() => {
        fn(pastState);
        pastState = { ...pastState, ...currentState };
        currentState = {};
      }, timeout);
    };
  };
}

/**
 * merge classnames from css modules
 *
 * @param  {...{[index: string]: string}} args
 *
 * @example
 * mergeClassNames(
 *  {container: "cntr1", isDisabled: "--dsbl"},
 *  {container: "cntr2", isDisabled: "dsbl", isKind: "yep"},
 * )
 * // -> {container: "cntr1 cntr2", isDisabled: "--dsbl dsbl", isKind: "yep"}
 *
 * // real-world usecase:
 * import css1Styles from "css1.module.css"
 * import css2styles from "css2.module.css"
 * const classNames = mergeClassNames(css1Styles, css2Styles)
 */
export function mergeClassNames(...args) {
  // Start with a base clone of the first argument. This is a lot faster than starting
  // with an empty object and adding as we go.
  const result = { ...args[0] };
  for (let i = 1; i < args.length; i++) {
    const classNames = args[i];
    for (const key in classNames) {
      const a = result[key];
      const b = classNames[key];

      if (a) {
        result[key] = a + ' ' + b;
      } else {
        result[key] = b;
      }
    }
  }
  return result;
}

/**
 * get ref in a way that does not steal it from a parent
 * https://github.com/facebook/react/issues/8873#issuecomment-275423780
 * @type {(ref: React.MutableRefObject, element: React.ReactElement) => (node: React.ReactNode)=> void}
 *
 * @example
 * React.cloneElement(children, { ref: getRef(ownRef, children) })
 */
export const getRef = (ref, element) => (node) => {
  // Keep your own reference
  ref.current = node;
  // Call the original ref, if any
  // @ts-ignore idk why, this is the official way straight from Saint Dan
  if (typeof element.ref === 'function') {
    // @ts-ignore
    element.ref(node);
    // @ts-ignore
  } else if (element.ref && 'current' in element.ref) {
    // @ts-ignore
    element.ref.current = node;
  }
  // ELSE: element does not have ref, we don't have to reassign
};

/**
 * check if element is visible in the dom
 * [stackoverflow]{@link https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom}
 *
 * @param {HTMLElement} element
 **/
export function isVisible(element) {
  return (
    element &&
    (element.clientHeight !== 0 ||
      element.getClientRects().length !== 0 ||
      getComputedStyle(element).display !== 'none')
  );
}
