// @ts-check

import currencies from 'assets/currencies';
import Surface from 'common/surfaces/Surface.component';
import React from 'react';
import styles from './CurrencyShowcase.module.css';
import cx from 'classnames';
import { useRatesQuery } from 'queries/rates';
import { H, Level } from 'react-accessible-headings';
import ConversionCurrency from 'components/content/ConversionCurrency.component';
import IconButton from 'common/buttons/IconButton';
import {
  RiArrowDownCircleLine,
  RiArrowUpCircleLine,
  RiSwapLine,
} from 'react-icons/ri';
import TextButton from 'common/buttons/TextButton';
import { useExchangeContext } from 'context/ExchangeContext';
import { useOutletContext } from 'react-router-dom';
import { useCurrencyNetworksQuery } from 'queries/currencyNetworks';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import CurrencyColors from 'assets/data/currency-colors';
import { useLocale } from 'context/LanguageContext';

const conversionCurrency = 'usd';

/**
 * @typedef CurrencyShowcaseProps
 * @property {"sm" | "md" | "lg"} size
 * @property {import('currencies').Currency} currency
 *
 * @param {CurrencyShowcaseProps & Partial<import('common/surfaces/Surface.component').SurfaceProps>} props
 */
export default function CurrencyShowcase({ size = 'md', currency }) {
  const { LL } = useLocale();
  const minMd = ['md', 'lg'].includes(size);
  const isLg = size === 'lg';
  const isMd = size === 'md';
  const isSm = size === 'sm';

  const { data: rates } = useRatesQuery();
  const { data: networks } = useCurrencyNetworksQuery();

  const HeadingElement = isSm ? 'strong' : H;
  const containerElement = isSm ? 'div' : 'article';

  const { navigate } = useExchangeContext();
  const [, setOpenQuickExchange] = useOutletContext();

  return (
    <Level>
      <TranslucentSurface
        elementType={containerElement}
        className={cx(styles.container, {
          '--sizeSmall': isSm,
          '--sizeMedium': isMd,
          '--sizeLarge': isLg,
        })}
        surfaceColor={CurrencyColors[currency]}
      >
        <img
          className={styles.icon}
          src={currencies[currency]}
          alt=""
        />

        <HeadingElement
          className={cx(
            styles.currencyName,
            isSm ? 'type-title-medium' : 'type-title-large'
          )}
        >
          {LL.currency[currency]()}
        </HeadingElement>

        {rates && minMd && (
          <p className={styles.rate}>
            <span>
              {currency.toUpperCase()}/{conversionCurrency.toUpperCase()}:{' '}
            </span>
            <strong>
              <ConversionCurrency currency={currency}>{1}</ConversionCurrency>
            </strong>
          </p>
        )}

        {isLg && (
          <div className={styles.actions}>
            <TextButton
              onPress={async () => {
                await setOpenQuickExchange(true);
                navigate('receive', {
                  coin: currency,
                  network: networks[currency][0].network_name,
                });
              }}
            >
              {LL.app.deposit()}
            </TextButton>
            <TextButton
              onPress={async () => {
                await setOpenQuickExchange(true);
                navigate('swap', {
                  to: currency,
                });
              }}
            >
              {LL.app.swap()}
            </TextButton>
          </div>
        )}
      </TranslucentSurface>
    </Level>
  );
}
