//@ts-check
import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { useLocale } from 'context/LanguageContext';
import { useFormik } from 'formik';
import useThemeVariables from 'hooks/useThemeVariables';
import qs from 'qs';
import { useUserCredentialsQuery } from 'queries/userCredentials';
import React, { useState } from 'react';
import { H } from 'react-accessible-headings';
import { object, string } from 'yup';
import styles from './NotificationUrlUpdate.module.css';

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

function NotificationUrlUpdate() {
  const { LL } = useLocale();
  const [serverError, setServerError] = useState(null);
  const [successfulChange, setSuccessfulChange] = useState(false);

  const { colorTest4 } = useThemeVariables();
  const { data: user } = useUserCredentialsQuery();

  const surfaceColor = {
    background: colorTest4,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      notificationUrl: (user && user.notificationUrl) || '',
    },
    validationSchema: object({
      notificationUrl: string().required('Required'),
    }),

    onSubmit: async (values, { resetForm, setTouched }) => {
      const data = {
        notificationUrl: values.notificationUrl.replace(/\s/g, ''),
      };
      try {
        const res = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URI}users/notification/update-url`,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: localStorage.getItem('token'),
          },
          data: qs.stringify(data),
        });
        setServerError(null);
        setSuccessfulChange(true);
        setTimeout(() => {
          setSuccessfulChange(false);
        }, 5000);
      } catch (err) {
        setTouched({});

        if (err.response.data.error === '"notificationUrl" must be a valid uri')
          setServerError(LL.profile.serverErrors.incorrectUrl());
        else setServerError(LL.profile.serverErrors.genericShort());
      }
      resetForm();
    },
  });

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  return (
    <Surface
      elementType="form"
      onSubmit={formik.handleSubmit}
      surfaceColor={surfaceColor}
      className={styles.container}
    >
      <>
        <div className={styles.formBody}>
          <H className={styles.heading}>{LL.profile.header()}</H>
          <ErrorContainer>
            {serverError ? (
              <InlineError
                onClose={() => setServerError(null)}
                success={false}
              >
                {serverError}
              </InlineError>
            ) : (
              successfulChange && (
                <InlineError
                  onClose={() => setSuccessfulChange(false)}
                  success={true}
                >
                  {LL.profile.notificationUrl.success()}
                </InlineError>
              )
            )}
          </ErrorContainer>
          <H className={styles.subHeading}>
            {LL.profile.notificationUrl.notificationUrl()}
          </H>
          <div className={styles.input}>
            {(formik.values.notificationUrl ||
              formik.values.notificationUrl === '') && (
              <TextField
                value={formik.values.notificationUrl}
                onChange={(val) => {
                  formik.setFieldValue('notificationUrl', val, false);
                }}
                onBlur={handleBlur('notificationUrl')}
                validationState={getValidationState(formik, 'notificationUrl')}
                // @ts-ignore
                errorMessage={formik.errors.notificationUrl}
              />
            )}
          </div>
        </div>
        <div className={styles.formSubmit}>
          <OutlineButton
            attachesBackground
            pressesBackground
            type="reset"
            onPress={formik.handleReset}
          >
            {LL.profile.resetFields()}
          </OutlineButton>
          <FilledButton
            attachesBackground
            pressesBackground
            type="submit"
            isDisabled={!formik.values.notificationUrl || formik.isSubmitting}
          >
            {LL.profile.confirmChange()}
          </FilledButton>
        </div>{' '}
      </>
    </Surface>
  );
}

export default NotificationUrlUpdate;
