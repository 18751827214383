import type { Currency } from 'currencies';

/**
 * Dumb ts thing to make sure CurrencyNames array includes all currencies from Currency union
 */
const arrayOfAll =
  <U>() =>
  <T extends { symbol: U; coin_name: string }[]>(
    array: T &
      ([U] extends [T[number]['symbol']] ? unknown : { invalid: 'invalid' })
  ) =>
    array;
const arrayOfAllCurrencies = arrayOfAll<Currency>();

const CurrencyNames = arrayOfAllCurrencies([
  { symbol: 'btc', coin_name: 'Bitcoin' },
  { symbol: 'eth', coin_name: 'Ethereum' },
  { symbol: 'xrp', coin_name: 'Ripple' },
  { symbol: 'usdt', coin_name: 'Tether' },
  { symbol: 'ltc', coin_name: 'Litecoin' },
  { symbol: 'xlm', coin_name: 'Stellar' },
  { symbol: 'bch', coin_name: 'Bitcoin Cash' },
  { symbol: 'trx', coin_name: 'Tron' },
  { symbol: 'uni', coin_name: 'Uniswap' },
  { symbol: 'usdc', coin_name: 'USD Coin' },
  { symbol: 'busd', coin_name: 'Binance USD' },
  { symbol: 'bnb', coin_name: 'Binance Coin' },
  { symbol: 'reef', coin_name: 'Reef' },
  { symbol: 'shib', coin_name: 'Shiba Inu' },
  { symbol: 'doge', coin_name: 'Dogecoin' },
  { symbol: 'btt', coin_name: 'BitTorrent' },
  { symbol: 'ton', coin_name: 'Toncoin' },
  { symbol: 'usd', coin_name: 'United States Dollar' },
  { symbol: 'eur', coin_name: 'Euro' },
  { symbol: 'gel', coin_name: 'Georgian Lari' },
  { symbol: 'rub', coin_name: 'Russian Ruble' },
]);

export default CurrencyNames;
