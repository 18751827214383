import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { useFormik } from 'formik';
import useThemeVariables from 'hooks/useThemeVariables';
import qs from 'qs';
import React from 'react';
import { H } from 'react-accessible-headings';
import { Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import styles from './PhoneVerification.module.css';

function useFormError(errorMap, resetDeps = []) {
  /** @type {[keyof T, React.Dispatch<React.SetStateAction<keyof T>> ]} */
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    formError: errorMap[errorKey],
    setFormError: setErrorKey,
  };
}

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

const PhoneVerificationForm = ({ userEmail }) => {
  const { colorTest4 } = useThemeVariables();
  const surfaceColor = { background: colorTest4 };

  const validSchema = {
    pin: Yup.string().required('Required'),
  };

  async function generatePin(email) {
    const data = { email: email };
    try {
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}users/pin/generate`,
        data: qs.stringify(data),
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      pin: '',
    },
    validationSchema: Yup.object(validSchema),
    onSubmit: async (values) => {
      const formData = {
        email: userEmail,
        pin: values.pin.replace(/\s/g, ''),
      };
      try {
        const res = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URI}users/pin/verify`,
          data: qs.stringify(formData),
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        });

        const token = res.data.token;
        localStorage.setItem('token', token);
      } catch (error) {
        if (error.response && errorMap[error.response.data.error]) {
          console.log('Error: ', error.response.data.error);

          setFormError(error.response.data.error);
          document.documentElement.scrollTop = 0;
        } else {
          console.warn('PIN_Verification unexpected error', error);
          setFormError('ERR_GENERIC');
        }

        formik.resetForm();
      }
    },
  });

  const errorMap = {
    ERR_GENERIC: 'Unexpected Error. Please try again later',
    ERR_INVALID_PIN: 'Incorrect PIN',
    ERR_PIN_EXPIRED: 'PIN Has Expired',
  };

  const { formError, setFormError } = useFormError(errorMap, []);

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  if (localStorage.getItem('token')) {
    return <Navigate to="/overview" />;
  }

  return (
    <Surface
      elementType="form"
      onSubmit={formik.handleSubmit}
      surfaceColor={surfaceColor}
      className={styles.formContainer}
    >
      <ErrorContainer>
        {formError && (
          <InlineError
            onClose={() => setFormError(null)}
            success={false}
          >
            {formError}
          </InlineError>
        )}
      </ErrorContainer>

      <div className={styles.formBody}>
        {formError === 'PIN Has Expired' ? (
          <H className={styles.header}>
            Your PIN Has Expired. Please Press The Resend Button For a New One.
          </H>
        ) : (
          <H className={styles.header}>
            Please Verify Your Phone Number To Complete The Registration
          </H>
        )}
        <div className={styles.pin}>
          <TextField
            className={styles.pinField}
            value={formik.values.pin}
            onChange={(val) => formik.setFieldValue('pin', val, false)}
            onBlur={handleBlur('pin')}
            validationState={getValidationState(formik, 'pin')}
            errorMessage={formik.errors.pin}
          />
          <FilledButton
            className={styles.resendButton}
            onPress={() => generatePin(userEmail)}
          >
            Resend
          </FilledButton>
        </div>
        <div className={styles.verifyButton}>
          <FilledButton
            attachesBackground
            pressesBackground
            type="submit"
            isDisabled={formik.isSubmitting}
          >
            Verify PIN
          </FilledButton>
        </div>
      </div>
    </Surface>
  );
};

export default PhoneVerificationForm;
