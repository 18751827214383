import cx from 'classnames';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import BalanceInfo from 'components/content/BalanceInfo.component';
import CurrencyShowcase from 'components/content/CurrencyShowcase.component';
import Wallet from 'components/content/Wallet.component';
import { useExchangeContext } from 'context/ExchangeContext';
import { useLocale } from 'context/LanguageContext';
import { formatDistanceToNow, parseISO } from 'date-fns';
import useBackgroundInteractions from 'hooks/useBackgroundInteractions';
import useHighlightRadius from 'hooks/useHighlightRadius';
import useThemeVariables from 'hooks/useThemeVariables';
import { useBalancesQuery } from 'queries/balances';
import { useOperationsQuery } from 'queries/operations';
import { useRatesQuery } from 'queries/rates';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import { FocusRing, useHover, usePress } from 'react-aria';
import styles from './BalanceOverview.module.css';

// Don't change maxListItemsLength without changing the css.
// Css works under the assumption that 3 list items will be rendered at all times.
const MAX_LIST_ITEMS_LENGTH = 6;

export default function BalanceOverview() {
  const { LL } = useLocale();

  const { colorSuccessContainer, colorOnSuccessContainer } =
    useThemeVariables();

  const surfaceColor = {
    background: colorSuccessContainer,
    color: colorOnSuccessContainer,
  };

  return (
    <>
      <article className={styles.balanceOverview}>
        <header className={styles.balanceInfo}>
          <Level>
            <TranslucentSurface
              className={styles.roundedContainer}
              surfaceColor={surfaceColor}
            >
              <H className={styles.mainHeadline}>{LL.general.balance()}</H>
              <p>
                <BalanceInfo />
              </p>
            </TranslucentSurface>
          </Level>
        </header>

        <section className={styles.latestTransactions}>
          <Level>
            <TranslucentSurface
              className={styles.roundedContainer}
              surfaceColor={surfaceColor}
            >
              <LatestTransactions />
            </TranslucentSurface>
          </Level>
        </section>
      </article>

      <MyWalletsSection />
    </>
  );
}

function LatestTransactions() {
  const { LL } = useLocale();

  const { data, isLoading, isError } = useOperationsQuery({
    currencies: [],
    from: null,
    to: null,
    type: null,
    address: '',
    txId: '',
  });

  if (isLoading || isError) {
    return (
      <H className={styles.latestTransactionsTitle}>
        {LL.latestTransactions.title()}
      </H>
    );
  }

  if (data.pages[0].items.length === 0) {
    return (
      <>
        <H className={styles.latestTransactionsTitle}>
          {LL.latestTransactions.title()}
        </H>
        <p>No transactions yet. Start by depositing on PayBitX</p>
      </>
    );
  }

  const transactions = data.pages[0].items.slice(0, 3);

  return (
    <>
      <H className={styles.latestTransactionsTitle}>
        {LL.latestTransactions.title()}
      </H>
      <dl className={styles.dataList}>
        {transactions.map((t) => (
          <div>
            <dt className={styles.dt}>
              {formatDistanceToNow(parseISO(t.createdAt), {
                addSuffix: true,
              })}
            </dt>
            <dd className={styles.dd}>
              {LL.latestTransactions.variants[t.type]({
                currency: t.currency,
                amount: t.amount,
              })}
            </dd>
          </div>
        ))}
      </dl>
    </>
  );
}

function MyWalletsSection() {
  const { LL } = useLocale();

  const [selectedTab, setSelectedTab] = React.useState('Crypto');

  const { data: balances = [] } = useBalancesQuery();

  const { data: rates = [] } = useRatesQuery();

  /** @type {typeof balances} */
  let wallets = [];

  let cryptoFillers = [];
  let fiatFillers = [];

  for (const balance of balances) {
    if (balance.confirmed_balance > 0) {
      wallets.push(balance);
    } else if (
      balance.coin !== 'usd' &&
      balance.coin !== 'eur' &&
      balance.coin !== 'gel'
    ) {
      addChunksOf3(cryptoFillers, balance);
    } else {
      addChunksOf3(fiatFillers, balance);
    }
  }

  const maxWallets =
    rates['btc'] &&
    wallets
      .sort(
        (a, b) =>
          b.confirmed_balance * rates[b.coin.toLocaleLowerCase()].usd -
          a.confirmed_balance * rates[a.coin.toLocaleLowerCase()].usd
      )
      .slice(0, MAX_LIST_ITEMS_LENGTH);

  const maxFillers = cryptoFillers.slice(
    0,
    MAX_LIST_ITEMS_LENGTH - (maxWallets && maxWallets.length)
  );

  return (
    <Level>
      <div className={styles.myWalletsSection}>
        {maxWallets && maxWallets.length > 0 && (
          <>
            <H
              className={styles.myWalletsHeadline}
              style={{
                // @ts-ignore
                '--grid-column': 1,
              }}
            >
              {LL.general.myWallets()}
            </H>
            {maxWallets.map((wallet) => (
              <div
                key={wallet.id}
                className={styles.listItem}
              >
                <Wallet
                  size="lg"
                  currency={wallet.coin}
                  depositedAmount={wallet.confirmed_balance}
                />
              </div>
            ))}
          </>
        )}
      </div>
      {maxFillers.length > 0 && maxWallets.length === 0 && (
        <>
          <H className={styles.myWalletsHeadline}>
            {LL.general.allAvailableCurrencies()}
          </H>
          <span className={styles.fillersTabs}>
            <div
              className={
                selectedTab === 'Crypto'
                  ? styles.fillersTabColored
                  : styles.fillersTab
              }
              onClick={() => setSelectedTab('Crypto')}
            >
              {LL.general.crypto()}
            </div>
            <div
              className={
                selectedTab === 'Fiat'
                  ? styles.fillersTabColored
                  : styles.fillersTab
              }
              onClick={() => setSelectedTab('Fiat')}
            >
              {LL.general.fiat()}
            </div>
          </span>

          <div className={styles.myWalletsSectionFillers}>
            {(selectedTab === 'Crypto' ? cryptoFillers : fiatFillers).map(
              (currencies, i) => (
                <div
                  key={i}
                  className={cx(styles.listItem, styles.filler)}
                >
                  {currencies.map((currency) => (
                    <DepositLink
                      tab="receive"
                      state={{ coin: currency.coin }}
                      key={currency.id}
                    >
                      <CurrencyShowcase
                        size="sm"
                        currency={currency.coin}
                        hoverElevation={2}
                      />
                    </DepositLink>
                  ))}
                </div>
              )
            )}
          </div>
        </>
      )}
    </Level>
  );
}

/** @type {(chunkSize: number)=> (array: any[], item: any) => void} */
const addChunks = (chunkSize) => (array, item) => {
  const lastChunk = array.at(-1);
  if (!lastChunk || lastChunk.length >= chunkSize) {
    array.push([item]);
  } else {
    lastChunk.push(item);
  }
};

const addChunksOf3 = addChunks(3);

function DepositLink({ tab, state, children }) {
  const { navigate } = useExchangeContext();
  const ref = React.useRef();

  const buttonProps = useBackgroundInteractions({
    ...useHighlightRadius(ref),
    attachesBackground: true,
    pressesBackground: true,
  });

  let { pressProps } = usePress({
    ...buttonProps,
    onPress: () => {
      navigate(tab, state);
    },
  });

  let { hoverProps } = useHover({
    ...buttonProps,
  });

  return (
    <FocusRing focusRingClass={styles.depositLinkFocusRing}>
      <div
        ref={ref}
        {...pressProps}
        {...hoverProps}
        className={styles.depositLink}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    </FocusRing>
  );
}
