// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef {object} UserCredentials
 * @property {string} name
 * @property {string} lastName
 * @property {string} email
 * @property {string} phoneNumber
 * @property {string} notificationUrl
 * @property {string} id
 * @property {boolean} isEmailVerified
 * @property {string} functionalCurrency
 * */

export async function getUserCredentials() {
  const { data } = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URI}users/profile`,
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });
  /** @type {UserCredentials} */
  const userCreds = {};

  if (data.BusinessAccount) {
    userCreds.name = data.BusinessAccount.contact_first_name;
    userCreds.lastName = data.BusinessAccount.contact_last_name;
  } else {
    userCreds.name = data.PersonalAccount.first_name;
    userCreds.lastName = data.PersonalAccount.last_name;
  }

  userCreds.email = data.email;
  userCreds.id = data.id;
  userCreds.isEmailVerified = data.is_email_verified;
  userCreds.phoneNumber = data.phone_number;
  userCreds.notificationUrl = data.notification_url;
  userCreds.functionalCurrency = data.functional_currency;

  return userCreds;
}

export function useUserCredentialsQuery(options) {
  return useQuery(['userCredentials'], getUserCredentials, options);
}
