import cx from 'classnames';
import OutlinedButton from 'common/buttons/OutlinedButton';
import Divider from 'common/dividers/Divider.component';
import { ConversionGroup } from 'components/conversion/ConversionGroup';
import CurrencyField from 'components/currency-fields/CurrencyField.component';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import styles from './Conversions.module.css';

export default function Conversions() {
  const [demo1Value, setDemo1Value] = React.useState(0);
  const [demo2Value, setDemo2Value] = React.useState(0);
  return (
    <Level hClassName={cx(styles.heading, 'type-title-medium')}>
      <section className={styles.section}>
        <hgroup>
          <H>Uncontrolled conversion</H>
          <p>Storing USD while displaying BTC and EUR.</p>
        </hgroup>
        <ConversionGroup
          // passing value/defaultValue is optional
          currency="usd"
          // get notified when the value changes
          onChange={(value) => setDemo1Value(value)}
        >
          <pre>Current value, USD: ${demo1Value}</pre>
          <CurrencyField
            // Individual fields can also have their own `onChange` and `onInput` handlers.
            onChange={console.log}
            onInput={console.log}
            currency="btc"
            label="Amount, BTC"
          />
          <CurrencyField
            currency="eur"
            label="Amount, EUR"
          />
        </ConversionGroup>
      </section>
      <Divider />
      <section className={styles.section}>
        <hgroup>
          <H>Controlled conversion</H>
          <p>Updating value from outside</p>
        </hgroup>

        <OutlinedButton onPress={() => setDemo2Value(demo2Value + 1)}>
          Increment Value
        </OutlinedButton>

        <ConversionGroup
          currency="usd"
          value={demo2Value}
          onChange={(value) => setDemo2Value(value)}
        >
          <pre>Current value, USD: ${demo2Value}</pre>
          <CurrencyField
            currency="usd"
            label="Amount, USD"
          />
          <CurrencyField
            currency="eur"
            label="Amount, EUR"
          />
        </ConversionGroup>
      </section>
      <Divider />
      <section className={styles.section}>
        <H>Default value $100</H>
        <ConversionGroup
          currency="usd"
          defaultValue={100}
        >
          <CurrencyField currency="btc" />
        </ConversionGroup>
      </section>
      <Divider />
    </Level>
  );
}
