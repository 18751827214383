import userActionTypes from './user.types';

const INITIAL_STATE = {
  address: {
    address: '',
    destTag: null,
  },
  balance: [],
  fetchingUserAddress: false,
  txLoading: false,
  totalEuBalance: 0, //legacy
  totalBalance: {},
  error: null,
  tfa: '',
  tfaEnabled: false,
  tfaNewDevice: null,
  tfaError: null,
  userCredentials: {},
  ipWhitelist: [],
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActionTypes.FETCH_USERADDRESS_START:
      return {
        ...state,
        fetchingUserAddress: true,
      };
    case userActionTypes.FETCH_USERADDRESS_SUCCESS:
      return {
        ...state,
        fetchingUserAddress: false,
        address: {
          address: action.payload.address,
          destTag: action.payload.destTag,
        },
      };
    case userActionTypes.FETCH_USERBALANCE_SUCCESS:
      const { totals, balances } = action.payload;

      return {
        ...state,
        balance: balances,
        totalBalance: totals.confirmed,
      };
    case userActionTypes.FETCH_USERADDRESS_FAILURE:
      return {
        ...state,
        address: { address: '', destTag: null },
        fetchingUserAddress: false,
        error: action.payload,
      };
    case userActionTypes.SEND_TOTAL_EU_BALANCE:
      return {
        ...state,
        totalEuBalance: action.payload,
      };
    case userActionTypes.FETCH_USER_CREDENTIALS_SUCCESS:
      const date = new Date();
      date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      document.cookie = `creds=${action.payload.name} ${
        action.payload.lastName
      } ${action.payload.email} ${localStorage.getItem(
        'token'
      )}; Expires=${expires}; Domain=paybitx.com`;

      // SEND AUTH SOCKET
      // socket.emit('AUTH', action.payload.id);

      return {
        ...state,
        userCredentials: action.payload,
      };
    case userActionTypes.TFA_GENERATE_SUCCESS:
      return {
        ...state,
        tfa: action.payload,
        tfaEnabled: false,
      };
    case userActionTypes.TFA_GENERATE_FAILURE:
      return {
        ...state,
        tfaEnabled: true,
      };
    case userActionTypes.TFA_VERIFY:
      return {
        ...state,
        tfaError: null,
      };
    case userActionTypes.TFA_DISABLE:
      return {
        ...state,
        tfaError: null,
      };
    case userActionTypes.TFA_NEW_DEVICE:
      return {
        ...state,
        tfaNewDevice: action.payload,
      };
    case userActionTypes.TFA_ERROR:
      return {
        ...state,
        tfaError: 'Invalid Token',
      };
    case userActionTypes.FETCH_IP_WHITELIST_SUCCESS:
      return {
        ...state,
        ipWhitelist: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
