// @ts-check

import { Item, Section } from '@react-stately/collections';
import { Accordion, AccordionItem } from 'common/accordion/Accordion.component';
import StatefulListBox from 'common/listoboxes/StatefullListBox.component';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import { RiExchangeFill } from 'react-icons/ri';
import styles from './Collections.module.css';

export default function Collections() {
  return (
    <div className={styles.container}>
      <Level hClassName="h type-headline-medium">
        <H>Collections</H>
        <p className="type-body-large">
          Collections are common interface across many components that render
          lists of some kind
        </p>
        <p className="type-body-large">
          These lists can have multiple selection, single selection or no
          selection at all
        </p>
        <p className="type-body-large">
          To use collection element you need to provide an array of
          <code>{'<Item />'}</code>
          's or function that maps through <code>items</code> prop and returns
          an <code>Item</code>. for farther information refer to{' '}
          <a href="https://react-spectrum.adobe.com/react-stately/collections.html">
            Adobe's Documentation
          </a>
        </p>

        <Level hClassName="h type-headline-small">
          <H>ListBoxes</H>
          <div className={styles.listBoxes}>
            <div className={styles.listBoxContainer}>
              <StatefulListBox
                label="Basic"
                selectionMode="multiple"
              >
                <Item>Option 1</Item>
                <Item>Option 2</Item>
                <Item>Option 3</Item>
                <Item>Option 4</Item>
                <Item>Option 5</Item>
              </StatefulListBox>
            </div>

            <div className={styles.listBoxContainer}>
              <StatefulListBox
                label="Extra content"
                selectionMode="single"
              >
                <Section title="section 1">
                  <Item>
                    <span>Option 1</span>
                    <span slot="description">Lorem ipsum dolor sit amet.</span>
                    <RiExchangeFill data-slot="icon" />
                  </Item>
                  <Item>
                    <span>Option 2</span>
                    <span slot="description">Lorem ipsum dolor sit amet.</span>
                    <RiExchangeFill data-slot="icon" />
                  </Item>
                </Section>
                <Section title="Section 2">
                  <Item>
                    <span>Option 3</span>
                    <span slot="description">Lorem ipsum dolor sit amet.</span>
                    <RiExchangeFill data-slot="icon" />
                  </Item>
                  <Item>
                    <span>Option 4</span>
                    <span slot="description">Lorem ipsum dolor sit amet.</span>
                    <RiExchangeFill data-slot="icon" />
                  </Item>
                  <Item>
                    <span>Option 5</span>
                    <span slot="description">Lorem ipsum dolor sit amet.</span>
                    <RiExchangeFill data-slot="icon" />
                  </Item>
                </Section>
              </StatefulListBox>
            </div>

            <div className={styles.listBoxContainer}>
              <StatefulListBox
                label="No selection"
                selectionMode="none"
              >
                <Item>Option 1</Item>
                <Item>Option 2</Item>
                <Item>Option 3</Item>
                <Item>Option 4</Item>
                <Item>Option 5</Item>
              </StatefulListBox>
            </div>
          </div>

          <H>Accordions</H>

          <div
            style={{
              height: 500,
              border: '2px solid var(--color-outline)',
              padding: 24,
              borderRadius: 12,
            }}
          >
            <Accordion
              selectionMode="multiple"
              autoOpen
            >
              <AccordionItem
                summary="Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laboriosam, rem!"
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laboriosam, rem!
              </AccordionItem>
              <AccordionItem summary="Bar">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Explicabo debitis fugiat natus odio. Ratione, vero?
              </AccordionItem>
            </Accordion>
          </div>
        </Level>
      </Level>
    </div>
  );
}
