import CurrencyColors from 'assets/data/currency-colors';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import ExplorerCopy from 'components/copy-to-clipboard/ExplorerCopy.component';
import useThemeVariables from 'hooks/useThemeVariables';
import React from 'react';
import { Level } from 'react-accessible-headings';
import styles from './Description.module.css';

// TODO: translations
// TODO: onClick event on spans. a11y concern
// TODO: navigator.clipboard.writeText prob wont work on safari
// TODO: prefer remix icons over ionions + default size should be 20px, only override when neccessary

const Description = ({ data, coin }) => {
  const { colorTest7 } = useThemeVariables();
  const surfaceColor = {
    background: colorTest7,
  };

  return (
    <Level>
      <TranslucentSurface
        elementType={'article'}
        className={styles.container}
        surfaceColor={CurrencyColors[coin]}
      >
        <div className={styles.descriptionInfoItem}>
          From:{' '}
          {coin === 'xrp' ? (
            <span className={styles.address}>
              {data?.intra
                ? data?.from
                : data?.specification?.source &&
                  data?.specification?.source?.address}
              <ExplorerCopy
                margin={true}
                text={
                  data?.intra
                    ? data?.from
                    : data?.specification &&
                      data?.specification?.source?.address
                }
              />
            </span>
          ) : (
            <span className={styles.address}>
              {data?.intra
                ? data?.from
                : data?.operations && data?.operations?.records[0].from}
              <ExplorerCopy
                margin={true}
                text={
                  data?.intra
                    ? data?.from
                    : data?.operations && data?.operations?.records[0].from
                }
              />
            </span>
          )}
        </div>

        <div className={styles.descriptionInfoItem}>
          To:{' '}
          {coin === 'xrp' ? (
            <span className={styles.address}>
              {data?.intra
                ? data?.to
                : data?.specification?.destination &&
                  data?.specification?.destination?.address}
              <ExplorerCopy
                margin={true}
                text={
                  data?.intra
                    ? data?.to
                    : data?.specification &&
                      data?.specification?.destination?.address
                }
              />
            </span>
          ) : (
            <span className={styles.address}>
              {data?.intra
                ? data?.to
                : data?.operations && data?.operations?.records[0].to}
              <ExplorerCopy
                margin={true}
                text={
                  data?.intra
                    ? data?.to
                    : data?.operations && data?.operations?.records[0].to
                }
              />
            </span>
          )}
        </div>

        {!data?.intra && coin === 'xrp' && (
          <>
            <div className={styles.descriptionInfoItem}>
              <span>
                Source:{' '}
                <span className="primary">
                  {data?.specification?.source
                    ? data?.specification?.source?.tag ||
                      data?.specification?.source?.address
                    : data?.address}
                </span>
              </span>
            </div>

            <div className={styles.descriptionInfoItem}>
              <span>
                Destination tag:{' '}
                <span className="primary">
                  {data?.specification.destination &&
                    data?.specification.destination.tag}
                </span>
              </span>
            </div>
          </>
        )}
        {/* TODO: fix this explorer xrp rendering, currently doing different transaction types*/}
        <div className={styles.descriptionInfoItem}>
          <span>
            Amount delivered:{' '}
            {coin === 'xrp' ? (
              <span className={styles.number}>
                {data?.intra
                  ? data?.amount
                  : data?.outcome?.deliveredAmount
                  ? data?.outcome.deliveredAmount.value
                  : data?.outcome.orderbookChanges[data?.address][0]?.quantity
                      .value}
              </span>
            ) : (
              <span className={styles.number}>
                {data?.intra
                  ? data?.amount
                  : data?.operations &&
                    data?.operations?.records[0].amount}{' '}
                {coin === 'xlm' &&
                data?.operations?.records[0].type === 'manage_buy_offer'
                  ? data?.operations?.records[0].buying_asset_code
                  : coin.toUpperCase()}
              </span>
            )}{' '}
          </span>
        </div>

        {!data?.intra && coin === 'xrp' && (
          <div className={styles.descriptionInfoItem}>
            <span>
              Transaction's sequence number:{' '}
              <span className={styles.number}>{data && data?.sequence}</span>
            </span>
          </div>
        )}
      </TranslucentSurface>
    </Level>
  );
};

export default Description;
