// @ts-check
import Currency from 'components/content/Currency.component';
import ConversionCurrency from 'components/content/ConversionCurrency.component';
import * as React from 'react';
import useFee from 'hooks/useFee';
import { motion } from 'framer-motion';
import { useLocale } from 'context/LanguageContext';

// TODO: dl, dt, dd tags are used incorrectly

/**
 * @typedef ClassNames
 * @property {string} [descriptionList]
 * @property {string} [descriptionTerm]
 * @property {string} [descriptionDetailsContainer]
 * @property {string} [value]
 * @property {string} [convertedValue]
 */

/**
 * @template {"transfer" | "exchange" | "p2p"} T
 * @typedef FeeInfoProps
 * @property {import("hooks/useFee").FeeType<T>} feeType
 * @property {import("hooks/useFee").Payload<T>} payload
 * @property {string | null} currency
 * @property {number} amount
 * @property {ClassNames} classNames
 */

/**
 * @template {"transfer" | "exchange" | "p2p"} T
 * @param {FeeInfoProps<T>} props
 */
function DetailedFee({ currency, feeType, payload, amount, classNames }) {
  const { fee, addFee, subFee } = useFee(feeType, payload);
  const { LL } = useLocale();

  const loaded = fee !== null && fee !== undefined;

  return (
    <dl className={classNames.descriptionList}>
      <motion.div
        layout
        className={classNames.descriptionDetailsContainer}
      >
        <motion.dd
          layout="position"
          className={classNames.value}
        >
          {loaded && !!amount ? (
            <Currency currency={currency}>{amount}</Currency>
          ) : (
            LL.exchange.detailedFee.amount()
          )}
        </motion.dd>
        <motion.dd
          layout="position"
          className={classNames.convertedValue}
        >
          {loaded && !!amount && (
            <ConversionCurrency currency={currency}>
              {amount}{' '}
              <span style={{ color: 'var(--color-success)' }}>
                {LL.exchange.detailedFee.amount()}
              </span>
            </ConversionCurrency>
          )}
        </motion.dd>
      </motion.div>

      <motion.div
        layout
        className={classNames.descriptionDetailsContainer}
      >
        <motion.dd
          layout="position"
          className={classNames.value}
        >
          {loaded ? (
            <Currency currency={currency}>{fee}</Currency>
          ) : (
            LL.exchange.detailedFee.fee()
          )}
        </motion.dd>
        <motion.dd
          layout="position"
          className={classNames.convertedValue}
        >
          {loaded && (
            <ConversionCurrency currency={currency}>
              {fee}{' '}
              <span style={{ color: 'var(--color-success)' }}>
                {LL.exchange.detailedFee.fee()}
              </span>
            </ConversionCurrency>
          )}
        </motion.dd>
      </motion.div>

      <motion.div
        layout
        className={classNames.descriptionDetailsContainer}
      >
        <motion.dd
          layout="position"
          className={classNames.value}
        >
          {loaded && !!amount ? (
            <Currency currency={currency}>{subFee(amount)}</Currency>
          ) : (
            LL.exchange.detailedFee.netAmount()
          )}
        </motion.dd>
        <motion.dd
          layout="position"
          className={classNames.convertedValue}
        >
          {loaded && !!amount && (
            <ConversionCurrency currency={currency}>
              {subFee(amount)}{' '}
              <span style={{ color: 'var(--color-success)' }}>
                {LL.exchange.detailedFee.netAmount()}
              </span>
            </ConversionCurrency>
          )}
        </motion.dd>
      </motion.div>
    </dl>
  );
}

export default DetailedFee;
