/**
 * Arbitrary but helpful abstraction over screen sizes when working with media queries.
 *
 * WHEN EDITING THIS FILE PLEASE MAKE SURE THE VALUES MATCH THE SAME KEYS IN ALTERNATE
 * CSS FILE: "styles/css-values/breakpoints.module.css"
 */
const breakpoints = {
  // small phone (0 < width <= maxLargePhonne)
  maxLargePhone: 449,

  // large phone
  minLargePhone: 450,
  maxSmallTablet: 599,

  // small tablet
  minSmallTablet: 600,
  maxLargeTablet: 904,

  // large tablet
  minLargeTablet: 905,
  maxLaptop: 1239,

  // laptop
  minLaptop: 1240,
  maxSmallDesktop: 1439,

  // small desktop
  minSmallDesktop: 1440,
  maxLargeDesktop: 1739,

  // large desktop (minLargeDesktop <= width < Infinity)
  minLargeDesktop: 1740,
};

export default breakpoints;
