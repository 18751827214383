// @ts-check
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import { MdAccessAlarm } from 'react-icons/md';
import { connect } from 'react-redux';
import Collections from 'components/design-system-demos/Collections';
import Colors from 'components/design-system-demos/Colors';
import Surface from 'common/surfaces/Surface.component';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import ElevatedButton from 'common/buttons/ElevatedButton';
import FilledButton from 'common/buttons/FilledButton';
import FilledTonalButton from 'common/buttons/FilledTonalButton';
import IconButton from 'common/buttons/IconButton';
import OutlinedButton from 'common/buttons/OutlinedButton';
import TextButton from 'common/buttons/TextButton';
import FormElements from 'components/design-system-demos/FormElements';
import SentientBackground from 'components/sentient-background/SentientBackground';
import useThemeVariables from 'hooks/useThemeVariables';
import { hexToRGBA } from 'utils/colorUtils';
import styles from './DesignSystem.module.css';
import NavigationElements from 'components/design-system-demos/NavigationElements';
import Overlays from 'components/design-system-demos/Overlays';

// initialize custom element
if (!customElements.get('sentient-background')) {
  customElements.define('sentient-background', SentientBackground);
}

const randomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const randomColor = () =>
  '#' + Math.floor(Math.random() * 16777215).toString(16);

function DesignSystem({ switchTheme }) {
  const {
    colorTertiary,
    colorTertiaryContainer,
    colorOnTertiaryContainer,
    MdRefPaletteNeutral40,
    MdRefPaletteNeutral50,
    isDarkTheme,
    MdRefPaletteNeutralVariant80,
    MdRefPaletteNeutralVariant30,
    MdRefPaletteNeutralVariant40,
    MdRefPaletteNeutralVariant90,
  } = useThemeVariables();

  const [cellSize, setCellSize] = React.useState(24);
  const [itemRGBA, setItemRGBA] = React.useState(
    isDarkTheme ? MdRefPaletteNeutralVariant30 : MdRefPaletteNeutralVariant80
  );
  const [itemThickness, setItemThickness] = React.useState(2);
  const [highlightRGBA, setHighlightRGBA] = React.useState(
    isDarkTheme ? MdRefPaletteNeutralVariant40 : MdRefPaletteNeutralVariant90
  );
  const [highlightRadius, setHighlightRadius] = React.useState(240);
  const [highlightWidth, setHighlightWidth] = React.useState(12);

  React.useEffect(() => {
    setItemRGBA(
      isDarkTheme ? MdRefPaletteNeutralVariant30 : MdRefPaletteNeutralVariant80
    );
    setHighlightRGBA(
      isDarkTheme ? MdRefPaletteNeutralVariant40 : MdRefPaletteNeutralVariant90
    );
  }, [
    MdRefPaletteNeutralVariant30,
    MdRefPaletteNeutralVariant40,
    MdRefPaletteNeutralVariant80,
    MdRefPaletteNeutralVariant90,
    isDarkTheme,
  ]);

  return (
    // @ts-ignore ts expects to have a "sentient-background" declaration in .d.ts,
    // but in order to do that we have to install typescript and stuff
    <sentient-background
      class={styles.sentientBackground}
      // highlightRgba={hexToRGBA(highlightRGBA)}
      highlightRgba={hexToRGBA(highlightRGBA)}
      itemRgba={hexToRGBA(itemRGBA)}
      cellsize={cellSize}
      highlightradius={highlightRadius}
      highlightwidth={highlightWidth}
      itemthickness={itemThickness}
    >
      <Level
        hClassName="h"
        value={2}
      >
        <form>
          <H className="type-headline-medium">Background Demo</H>
          <OutlinedButton
            attachesBackground
            pressesBackground
            onPress={switchTheme}
          >
            Switch Theme
          </OutlinedButton>
          <OutlinedButton
            attachesBackground
            pressesBackground
            onPress={() => setCellSize(randomInt(24, 64))}
          >
            Random cellsize
          </OutlinedButton>
          <OutlinedButton
            attachesBackground
            pressesBackground
            onPress={() => setItemRGBA(randomColor())}
          >
            Random item rgba
          </OutlinedButton>
          <OutlinedButton
            attachesBackground
            pressesBackground
            onPress={() => setItemThickness(randomInt(1, 24))}
          >
            Random item thickness
          </OutlinedButton>
          <OutlinedButton
            attachesBackground
            pressesBackground
            onPress={() => setHighlightRadius(randomInt(100, 400))}
          >
            Random highlight radius
          </OutlinedButton>
          <OutlinedButton
            attachesBackground
            pressesBackground
            onPress={() => setHighlightRGBA(randomColor())}
          >
            Random highlight rgba
          </OutlinedButton>
          <OutlinedButton
            attachesBackground
            pressesBackground
            onPress={() => setHighlightWidth(randomInt(12, 24))}
          >
            Random highlight width
          </OutlinedButton>
        </form>

        <section
          aria-labelledby="buttons"
          className={styles.buttons}
        >
          <header>
            <H
              className="type-headline-medium"
              id="buttons"
            >
              Buttons
            </H>
            <p className="type-body-large">
              Ordered from highest emphasis to lowest
            </p>
          </header>
          <div>
            <FilledButton
              attachesBackground
              pressesBackground
            >
              Filled Button
            </FilledButton>
            <FilledButton isDisabled>Filled Button</FilledButton>
          </div>

          <div>
            <FilledTonalButton
              attachesBackground
              pressesBackground
            >
              Tonal Button
            </FilledTonalButton>
            <FilledTonalButton isDisabled>Tonal Button</FilledTonalButton>
          </div>

          <div>
            <ElevatedButton
              attachesBackground
              pressesBackground
            >
              Elevated Button
            </ElevatedButton>
            <ElevatedButton isDisabled>Elevated Button</ElevatedButton>
          </div>

          <div>
            <OutlinedButton
              attachesBackground
              pressesBackground
            >
              Outlined Button
            </OutlinedButton>
            <OutlinedButton isDisabled>Outlined Button</OutlinedButton>
          </div>

          <div>
            <TextButton
              attachesBackground
              pressesBackground
            >
              Text Button
            </TextButton>
            <TextButton isDisabled>Text Button</TextButton>
          </div>

          <div>
            <IconButton
              aria-label="IconButton demo"
              attachesBackground
              pressesBackground
            >
              <MdAccessAlarm></MdAccessAlarm>
            </IconButton>
            <IconButton
              aria-label="IconButton demo"
              isDisabled
            >
              <MdAccessAlarm></MdAccessAlarm>
            </IconButton>
          </div>
        </section>

        <Colors />

        <section
          aria-labelledby="surfaces"
          className={styles.surfaces}
        >
          <H
            id="surfaces"
            className="type-headline-medium"
          >
            Surfaces
          </H>

          <div className={styles.secondaryHeading}>
            <H className="type-title-large">Elevation with surfaces</H>
            <p className="type-body-large">
              Surfaces at elevation levels of 1-5 recive tint color derived from
              primary brand color
            </p>
          </div>
          <div className={styles.surfaceGrid}>
            <Surface className={styles.defaultSurface}>
              <H className="type-title-medium">Elevation: 0</H>
            </Surface>
            <Surface
              baseElevation={1}
              className={styles.defaultSurface}
            >
              <H className="type-title-medium">Elevation: 1</H>
            </Surface>
            <Surface
              baseElevation={2}
              className={styles.defaultSurface}
            >
              <H className="type-title-medium">Elevation: 2</H>
            </Surface>
            <Surface
              baseElevation={3}
              className={styles.defaultSurface}
            >
              <H className="type-title-medium">Elevation: 3</H>
            </Surface>
            <Surface
              baseElevation={4}
              className={styles.defaultSurface}
            >
              <H className="type-title-medium">Elevation: 4</H>
            </Surface>
            <Surface
              baseElevation={5}
              className={styles.defaultSurface}
            >
              <H className="type-title-medium">Elevation: 5</H>
            </Surface>
          </div>

          <div className={styles.secondaryHeading}>
            <H className="type-title-large">Expressing elevation</H>
            <p className="type-body-large">
              Surfaces can Express elevation with tint color, shadow, or both.
            </p>
          </div>
          <div className={styles.surfaceGrid}>
            <Surface
              baseElevation={2}
              elevationType="tint"
              className={styles.defaultSurface}
            >
              <H className="type-title-medium">Elevation: 2 with tint</H>
            </Surface>
            <Surface
              baseElevation={2}
              elevationType="shadow"
              className={styles.defaultSurface}
            >
              <H className="type-title-medium">Elevation: 2 with shadow</H>
            </Surface>
            <Surface
              baseElevation={2}
              elevationType="both"
              className={styles.defaultSurface}
            >
              <H className="type-title-medium">Elevation: 2 with both</H>
            </Surface>
          </div>

          <div className={styles.secondaryHeading}>
            <H className="type-title-large">Nested Elevations are automatic</H>
            <p className="type-body-large">
              Nested elements with elevations automatically calculate relative
              elevation. Elevations with tint color add up while shadows remain
              consitant
            </p>
          </div>
          <div className={styles.surfaceGrid}>
            <Surface
              baseElevation={2}
              elevationType="tint"
              className={styles.defaultSurface}
            >
              <H className="type-title-medium">Elevation: 2 with tint</H>

              <Surface
                baseElevation={2}
                elevationType="tint"
                className={styles.defaultSurface}
                style={{ marginBottom: 8 }}
              >
                <p className="type-title-medium">Elevation: 2 with tint</p>
              </Surface>
              <Surface
                baseElevation={2}
                elevationType="both"
                className={styles.defaultSurface}
              >
                <p className="type-title-medium">
                  Elevation: 2 with shadow & tint
                </p>
              </Surface>
            </Surface>

            <Surface
              className={styles.defaultSurface}
              baseElevation={1}
              hoverElevation={2}
              pressElevation={3}
            >
              <H
                className="type-title-medium"
                style={{ marginTop: 8 }}
              >
                My children's tint color changes when you hover me or press me
              </H>

              <ElevatedButton>I change</ElevatedButton>

              <Surface
                baseElevation={1}
                elevationType="both"
                className={styles.defaultSurface}
                style={{ marginTop: 8 }}
              >
                <p className="type-title-medium">I change too</p>
              </Surface>
            </Surface>
          </div>
          <div className={styles.secondaryHeading}>
            <H className="type-title-large">Translucent Surface</H>
          </div>

          <div className={styles.surfaceGrid}>
            <TranslucentSurface
              className={styles.defaultSurface}
              style={{ marginBottom: 8 }}
            >
              <p className="type-title-medium">Surface Color</p>
            </TranslucentSurface>
            <TranslucentSurface
              surfaceColor="surface-variant"
              className={styles.defaultSurface}
              style={{ marginBottom: 8 }}
            >
              <p className="type-title-medium">Surface Variant Color</p>
            </TranslucentSurface>
            <TranslucentSurface
              surfaceColor={{
                background: colorTertiaryContainer,
                color: colorOnTertiaryContainer,
              }}
              className={styles.defaultSurface}
              style={{ marginBottom: 8 }}
            >
              <p className="type-title-medium">Custom Color</p>
            </TranslucentSurface>
          </div>
        </section>

        <FormElements />
        <Collections />
        <NavigationElements />
        <Overlays />
      </Level>
      {/* @ts-ignore */}
    </sentient-background>
  );
}

const mapDispatchToProps = (dispatch) => ({
  switchTheme: () => dispatch({ type: 'SWITCH_THEME' }),
});

export default connect(null, mapDispatchToProps)(DesignSystem);
