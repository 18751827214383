import * as React from 'react';

/** @param {import("react").MutableRefObject<HTMLElement>} ref */
export default function useHighlightRadius(
  ref,
  attachMulriplier = 1,
  pressMultiplier = 1.3
) {
  const [attachRadius, setAttachRadius] = React.useState();
  const [pressRadius, setPressRadius] = React.useState();

  React.useLayoutEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();

      setAttachRadius(Math.max(rect.width * attachMulriplier, 160));
      setPressRadius(Math.max(rect.width * pressMultiplier, 160));
    }
  }, [ref, attachMulriplier, pressMultiplier]);

  return {
    attachRadius,
    pressRadius,
  };
}
