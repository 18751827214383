// @ts-check
import useDefaultRef from 'hooks/useDefaultRef';
import * as React from 'react';
import IconButton from '../buttons/IconButton';
import styles from './DefaultFieldIconButton.module.css';

/**
 * @param {Omit<React.ComponentProps<typeof IconButton>, "classNames">} props
 */
function FieldIconButtonWithRef(props, parentRef) {
  const ref = useDefaultRef(parentRef);
  return (
    <IconButton
      classNames={styles}
      ref={ref}
      {...props}
    />
  );
}

const FieldIconButton = React.forwardRef(FieldIconButtonWithRef);
export default FieldIconButton;
