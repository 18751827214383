import React, { useContext, useState } from 'react';

const ExplorerContext = React.createContext(undefined);

export function useExplorerContext() {
  return useContext(ExplorerContext);
}

export function ExplorerPovider({ children }) {
  const [data, setData] = useState(null);
  const [dataFetched, setDataFetched] = useState(null);
  const [serverError, setServerError] = useState(null);

  const [isMobileScreen, setIsMobileScreen] = React.useState(false);
  const [isLargeScreen, setLargeScreen] = React.useState(false);

  React.useEffect(() => {
    const mediaCheck = window.matchMedia('(max-width: 599px)');
    setIsMobileScreen(mediaCheck.matches);

    function updateIsMobileScreen(windowSize) {
      setIsMobileScreen(windowSize.matches);
    }
    mediaCheck.addEventListener('change', updateIsMobileScreen);

    return function cleanup() {
      mediaCheck.removeEventListener('change', updateIsMobileScreen);
    };
  }, []);

  React.useEffect(() => {
    const mediaCheck = window.matchMedia('(min-width: 997px)');
    setLargeScreen(mediaCheck.matches);

    function updateIsLargeScreen(windowSize) {
      setLargeScreen(windowSize.matches);
    }
    mediaCheck.addEventListener('change', updateIsLargeScreen);

    return function cleanup() {
      mediaCheck.removeEventListener('change', updateIsLargeScreen);
    };
  }, []);

  return (
    <ExplorerContext.Provider
      value={{
        data,
        setData,
        dataFetched,
        setDataFetched,
        isMobileScreen,
        isLargeScreen,
        serverError,
        setServerError,
      }}
    >
      {children}
    </ExplorerContext.Provider>
  );
}
