// @ts-check

import { Item } from '@react-stately/collections';
import cx from 'classnames';
import Surface from 'common/surfaces/Surface.component';
import Tabs from 'common/tabs/Tabs.component';
import QuickP2P from 'components/quick-exchange/QuickP2P.component';
import QuickReceive from 'components/quick-exchange/QuickReceive.component';
import QuickSwap from 'components/quick-exchange/QuickSwap.component';
import { useExchangeContext } from 'context/ExchangeContext';
import useThemeVariables from 'hooks/useThemeVariables';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import styles from './QuickExchange.module.css';
import QuickSend from './QuickSend.component';
// import Radar from 'common/animations/Radar.component';
import OutlineButton from 'common/buttons/OutlinedButton';
import { useLocale } from 'context/LanguageContext';

function QuickExchange({ className, setOpenQuickExchange }) {
  const { LL } = useLocale();

  /** @type {React.MutableRefObject<React.ElementRef<Tabs>>} */
  const tabRef = React.useRef(null);
  // const radarRef = React.useRef(null);

  const { colorTest4 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };

  // prettier-ignore
  const { 
    selectedTab, 
    setSelectedTab, 
    tabs, 
    quickExchangeId,
    addEventListener 
  } = useExchangeContext();

  React.useEffect(() => {
    const removeEventListener = addEventListener('quickExchange', () => {
      tabRef.current?.focusIntoPanel();
      // radarRef.current?.dispatchEvent(new CustomEvent('animate')); trigger radar animation
    });
    return removeEventListener;
  }, [addEventListener]);

  return (
    <Surface
      elementType="aside"
      surfaceColor={surfaceColor}
      baseElevation={0}
      elevationType="both"
      className={cx(className, styles.container)}
      id={quickExchangeId}
    >
      <Surface
        className={styles.scrollContainer}
        surfaceColor={surfaceColor}
      >
        <Level>
          <H className={styles.heading}>
            {LL.exchange.quickExchange.header()}
            <OutlineButton
              attachesBackground
              pressesBackground
              onPress={() => setOpenQuickExchange(false)}
              className={styles.closeButton}
            >
              {LL.exchange.quickExchange.close()}
            </OutlineButton>
          </H>
          <Tabs
            selectedKey={selectedTab}
            onSelectionChange={setSelectedTab}
            ref={tabRef}
          >
            <Item
              key={tabs.send}
              title={LL.app.send()}
            >
              <QuickSend />
            </Item>
            <Item
              key={tabs.receive}
              title={LL.app.deposit()}
            >
              <QuickReceive />
            </Item>
            <Item
              key={tabs.p2p}
              title="P2P"
            >
              <QuickP2P />
            </Item>
            <Item
              key={tabs.swap}
              title={LL.app.swap()}
            >
              <QuickSwap />
            </Item>
          </Tabs>
        </Level>
      </Surface>
    </Surface>
  );
}

export default QuickExchange;
