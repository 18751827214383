import { takeLatest, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import { fetchInvoiceSuccess, fetchInvoiceFail } from './invoice.actions';
import { FETCH_INVOICE_START } from './invoice.types';


export function* fetchInvoiceAsync({ payload }) {
  try {
    const { data } = yield axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}invoices/${payload}`,
    });

    console.log('DATA:: ', data);
    yield put(fetchInvoiceSuccess(data));
  } catch (err) {
    yield put(fetchInvoiceFail(err));
  }
}

export function* onFetchInvoice() {
  yield takeLatest(FETCH_INVOICE_START, fetchInvoiceAsync);
}

export function* invoiceSagas() {
  yield all([call(onFetchInvoice)]);
}
