// @ts-check
import { createInterpolator } from 'range-interpolator';
import Grid from './Grid';
import trackInteractions from './trackInteractions';
import {
  createVecTransition,
  createColorTransition,
  createTransition,
  createConstantTransition,
} from '../../utils/transitions';
import { reactDidItBetter } from '../../utils';
import * as Vector from '../../utils/Vector';
import UpdateTracker from './UpdateTracker';
import Ripples from './Ripples';
import { parseRGBA, createRGBAInterpolator } from '../../utils/colorUtils';

/**
 * @typedef {import("./trackInteractions").State} InteractionState
 * @typedef {import("../../utils/Vector").Vec2} Vec2
 */

const DEFAULT_CELL_SIZE = 24;
/** @type {[number, number, number, number]} */
const DEFAULT_ITEM_RGBA = [55, 55, 55, 1];
/** @type {[number, number, number, number]} */
const DEFAULT_HIGHLIGHT_RGBA = [100, 100, 100, 1];
const DEFAULT_HIGHLIGHT_RADIUS = 200;
const DEFAULT_HIGHLIGHT_WIDTH = 12;
const DEFAULT_HOW_THICC = 3;
// attributes
const CELL_SIZE = 'cellsize';
const ITEM_RGBA = 'itemrgba';
const HOW_THICC = 'itemthickness';
const HIGHLIGHT_RGBA = 'highlightrgba';
const HIGHLIGHT_RADIUS = 'highlightradius';
const HIGHLIGHT_WIDTH = 'highlightwidth';

export default class SentientBackground extends HTMLElement {
  _resizeObserver;
  _grid;
  _initialized = false;
  _animatedItems = [];

  _smoothMouse = createVecTransition();
  _impactRadius = createTransition();

  _gridItemDirection = createVecTransition(0.4);
  _gridItemColor = createColorTransition(0.2);
  _gridItemWidth = createConstantTransition(1);

  // not all of these attributes are necessary to be observed but all the attributes are here for clearity
  static get observedAttributes() {
    return [
      CELL_SIZE,
      ITEM_RGBA,
      HIGHLIGHT_RGBA,
      HIGHLIGHT_RADIUS,
      HIGHLIGHT_WIDTH,
      HOW_THICC,
    ];
  }

  constructor() {
    super();
    this._grid = new Grid();
    /*
			-- element structure --

			shadowDOM: 
			<link />
			
			<gridContainer .grid-container></gridContainer>

			<slotContainer .slot-container>
				<slot />
			</slotContainer> 
		*/

    const shadow = this.attachShadow({ mode: 'open' });

    const grid = this._grid.domNode;

    const slotContainer = document.createElement('div');
    slotContainer.setAttribute('class', 'slot-container');
    slotContainer.style.position = 'relative';
    slotContainer.style.zIndex = '1';

    const slot = document.createElement('slot');
    slotContainer.appendChild(slot);

    // create tree
    slotContainer.appendChild(slot);
    shadow.appendChild(grid);
    shadow.appendChild(slotContainer);

    // add / remove elements in grid based on parent size
    this._resizeObserver = new ResizeObserver(
      this._grid.resizeObserverCallback
    );
    this.updateTracker = new UpdateTracker();
    this.ripples = new Ripples();
  }

  // ========== setup for custom lifecycle ============
  connectedCallback() {
    if (!this._initialized) {
      // add custom lifecycle which is called on every subsequent request after connect.
      this.adapter = reactDidItBetter(this, {
        [CELL_SIZE]: this[CELL_SIZE],
        [ITEM_RGBA]: this[ITEM_RGBA],
        [HOW_THICC]: this[HOW_THICC],
      })(this.attributesDidChange);

      // add another custom lifecycle for when component got connected
      this.componentDidConnect();

      this._initialized = true;
    }
  }
  attributeChangedCallback(...args) {
    if (this._initialized) {
      this.adapter(...args);
    }
  }
  // ========== don't add lifecycle logic up here ===========

  componentDidConnect = () => {
    this.style.display = this.style.display || 'block';

    this._grid.gridItemThickness = this[HOW_THICC];
    this._grid.gridItemColor = this[ITEM_RGBA];
    this._grid.cellsize = this[CELL_SIZE];
    // connect resize observer
    this._resizeObserver.observe(this);

    // mouse animation
    // this.removeMouseTracker = addMouseTracker(this.trackMouse, 0.3);
    this.stopTracking = trackInteractions({
      beforeUpdate: this.beforeUpdate,
      whileMove: this.whileMove,
      whileAttached: this.whileAttached,
      whilePressed: this.whilePressed,
      afterUpdate: this.afterUpdate,
      onPressEnd: (state) => {
        this.ripples.add(
          state.pressPoint,
          state.pressImpactRadius || this[HIGHLIGHT_RADIUS],
          state.pressHighlightRGBA || this[HIGHLIGHT_RGBA],
          state.pressHighlightWidth || this[HIGHLIGHT_WIDTH]
        );
      },
    });
    // custom tap event
    // this.addEventListener("tap", /** @type {() => any} */ (this.handleTap));
  };

  attributesDidChange = async (pastState) => {
    const cellSize = this[CELL_SIZE];
    if (pastState[CELL_SIZE] !== cellSize) {
      this._grid.cellsize = cellSize;
    }

    const itemrgba = this[ITEM_RGBA];
    if (pastState[ITEM_RGBA] !== itemrgba) {
      this._grid.gridItemColor = itemrgba;
    }

    const itemthickness = this[HOW_THICC];
    if (pastState[HOW_THICC] !== itemthickness) {
      this._grid.gridItemThickness = itemthickness;
    }
  };

  disconnectedCallback() {
    // remove resize observer
    this._resizeObserver.unobserve(this);
    this.stopTracking();
  }

  /** @param {InteractionState} state */
  beforeUpdate = (state) => {
    if (!this.ripples.size) return;
    const distFromRadiusVec = Vector.create();
    const distFromOriginVec = Vector.create();
    const itemrgba = this.itemrgba;
    const direction = Vector.create();

    this.ripples.update((ripple) => {
      const {
        origin,
        endRadius,
        startRadius,
        smallRadius,
        highlightRGBA,
        highlightWidth,
        getDistFromRadius,
        getDistFromOrigin,
      } = ripple;

      const clampMaxWidth = createInterpolator({
        inputRange: [24, 48],
        outputRange: [0, highlightWidth],
        extrapolate: 'clamp',
      });

      // highlight color should not be as vibrant when radius is smaller. less then arbitrary 24 in this case
      const dimHighlightrgba = createRGBAInterpolator({
        inputRange: [24, 48],
        outputRGBARange: [itemrgba, highlightRGBA],
        extrapolate: 'clamp',
      });

      const clampedMaxWidth = clampMaxWidth(smallRadius);
      const getWidth = createInterpolator({
        inputRange: [0, smallRadius],
        outputRange: [clampedMaxWidth, 0],
        extrapolate: 'clamp',
      });

      const dimmedHighlightrgba = dimHighlightrgba(smallRadius);
      const getRGBA = createRGBAInterpolator({
        inputRange: [0, smallRadius],
        outputRGBARange: [dimmedHighlightrgba, itemrgba],
      });

      this._grid.forEachBFS(this._grid.indicesOf(origin), (gridItem) => {
        getDistFromOrigin(distFromOriginVec, gridItem.offset);
        const distFromOrigin = Vector.len(distFromOriginVec);

        if (distFromOrigin < endRadius) {
          // inside the torus edge
          if (distFromOrigin > startRadius) {
            // inside the torus start
            getDistFromRadius(distFromRadiusVec, gridItem.offset);
            const distFromRadius = Vector.len(distFromRadiusVec);

            gridItem.direction = this._gridItemDirection.transition(
              Vector.normalize(direction, distFromOriginVec),
              gridItem.direction
            );
            gridItem.width = this._gridItemWidth.transition(
              getWidth(distFromRadius),
              gridItem.width
            );
            gridItem.rgba = this._gridItemColor.transition(
              getRGBA(distFromRadius),
              gridItem.rgba
            );

            // mark gridItem as animated to delete on next tick if it left the circle / is no longer animated
            this.updateTracker.add(gridItem);
          }

          // continue BFS if still inside the bigger radius
          return true;
        }
      });
    });
  };

  /** @param {InteractionState} state */
  whileMove = (state) => {
    if (this.ripples.size) return;

    this._smoothMouse.changeSpeed(0.3);
    this._impactRadius.changeSpeed(0.1);

    const smoothMouse = this._smoothMouse.transition(state.mouse);
    const impactRadius = this._impactRadius.transition(this[HIGHLIGHT_RADIUS]);

    const highlightrgba = this[HIGHLIGHT_RGBA];
    const itemrgba = this[ITEM_RGBA];
    const direction = Vector.create();

    const getWidth = createInterpolator({
      inputRange: [0, impactRadius / 2, impactRadius],
      outputRange: [0, this[HIGHLIGHT_WIDTH], 1],
    });

    const getRGBA = createRGBAInterpolator({
      inputRange: [0, impactRadius],
      outputRGBARange: [highlightrgba, itemrgba],
    });

    const getDirection = (_, distVec) => {
      Vector.set(direction, distVec[1], distVec[0]);
      return Vector.normalize(direction, direction);
    };

    this.updateCircle({
      center: smoothMouse,
      radius: impactRadius,
      getDirection,
      getWidth,
      getRGBA,
    });
  };

  /** @param {InteractionState} state */
  whileAttached = (state) => {
    if (this.ripples.size) return;

    this._smoothMouse.changeSpeed(0.1);
    this._impactRadius.changeSpeed(0.2);

    const itemrgba = this.itemrgba;
    const highlightrgba = state.attachHighlightRGBA || this[HIGHLIGHT_RGBA];
    const higlightWidth = state.attachHighlightWidth || this[HIGHLIGHT_WIDTH];
    const maxImpactRadius = state.attachImpactRadius || this[HIGHLIGHT_RADIUS];
    const direction = Vector.create();

    const smoothMouse = this._smoothMouse.transition(state.attachPoint);
    const impactRadius = this._impactRadius.transition(maxImpactRadius);

    const getWidth = createInterpolator({
      inputRange: [0, impactRadius / 3, impactRadius],
      outputRange: [0, higlightWidth, 1],
    });

    const getRGBA = createRGBAInterpolator({
      inputRange: [0, impactRadius],
      outputRGBARange: [highlightrgba, itemrgba],
    });

    const getDirection = (_, distVec) => {
      return Vector.normalize(direction, distVec);
    };

    this.updateCircle({
      center: smoothMouse,
      radius: impactRadius,
      getDirection,
      getWidth,
      getRGBA,
    });
  };

  /** @param {InteractionState} state */
  whilePressed = (state) => {
    if (this.ripples.size) return;

    this._smoothMouse.changeSpeed(0.8);
    this._impactRadius.changeSpeed(0.1);

    // get these in advance because element.getAttribute is slow
    const itemrgba = this.itemrgba;
    const smoothMouse = this._smoothMouse.transition(state.pressPoint);
    const impactRadius = this._impactRadius.transition(50);

    const zeroVector = Vector.create();

    this.updateCircle({
      center: smoothMouse,
      radius: impactRadius,
      getDirection: () => zeroVector,
      getWidth: () => 0,
      getRGBA: () => itemrgba,
    });
  };

  afterUpdate = () => {
    const cellsize = this.cellsize;
    const color = this.itemrgba;
    const zeroVector = Vector.create();
    this.updateTracker.draw(cellsize, (gridItem) => {
      gridItem.width = 0;
      gridItem.direction = zeroVector;
      gridItem.rgba = color;
      gridItem.draw(cellsize);
    });
  };

  /**
   *
   * @param {object} params
   * @param {Vec2} params.center
   * @param {number} params.radius
   * @param {(distance: number, distanceVec?: Vec2) => Vec2} params.getDirection
   * @param {(distance: number, distanceVec?: Vec2) => number} params.getWidth
   * @param {(distance: number, distanceVec?: Vec2) => number[]} params.getRGBA
   */
  updateCircle({ center, radius, getDirection, getWidth, getRGBA }) {
    const distVec = Vector.create();

    this._grid.forEachBFS(this._grid.indicesOf(center), (gridItem) => {
      // distance from offset to center
      Vector.sub(distVec, gridItem.offset, center);
      const dist = Vector.len(distVec);

      if (dist < radius) {
        gridItem.direction = this._gridItemDirection.transition(
          getDirection(dist, distVec),
          gridItem.direction
        );

        gridItem.width = this._gridItemWidth.transition(
          getWidth(dist, distVec),
          gridItem.width
        );

        gridItem.rgba = this._gridItemColor.transition(
          getRGBA(dist, distVec),
          gridItem.rgba
        );

        this.updateTracker.add(gridItem);

        // return true continue bfs search for other items in the circle
        return true;
      }
    });
  }

  /**
   * NOTE: lowercase lettering in the name to follow html attribute convention
   */
  get [CELL_SIZE]() {
    const attr = this.getAttribute(CELL_SIZE);
    if (attr) {
      if (+attr < DEFAULT_CELL_SIZE) {
        throw new Error(
          'cellsize should be greater or equal to ' + DEFAULT_CELL_SIZE
        );
      }
      return +attr;
    }
    return DEFAULT_CELL_SIZE;
  }

  get [ITEM_RGBA]() {
    const attr = this.getAttribute(ITEM_RGBA);
    if (attr) {
      return parseRGBA(attr);
    }
    return DEFAULT_ITEM_RGBA;
  }

  get [HIGHLIGHT_RGBA]() {
    const attr = this.getAttribute(HIGHLIGHT_RGBA);
    if (attr) {
      return parseRGBA(attr);
    }
    return DEFAULT_HIGHLIGHT_RGBA;
  }

  get [HIGHLIGHT_RADIUS]() {
    const attr = this.getAttribute(HIGHLIGHT_RADIUS);
    if (attr) {
      return parseFloat(attr);
    }
    return DEFAULT_HIGHLIGHT_RADIUS;
  }

  get [HIGHLIGHT_WIDTH]() {
    const attr = this.getAttribute(HIGHLIGHT_WIDTH);
    if (attr) {
      return parseFloat(attr);
    }
    return DEFAULT_HIGHLIGHT_WIDTH;
  }

  get [HOW_THICC]() {
    const attr = this.getAttribute(HOW_THICC);
    if (attr) {
      return parseFloat(attr);
    }
    return DEFAULT_HOW_THICC;
  }
}
