//@ts-check
import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import FieldIconButton from 'common/fields/FieldIconButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { useLocale } from 'context/LanguageContext';
import { useFormik } from 'formik';
import useThemeVariables from 'hooks/useThemeVariables';
import qs from 'qs';
import React, { useState } from 'react';
import { H } from 'react-accessible-headings';
import { BiHide, BiShowAlt } from 'react-icons/bi';
import { object, ref, string } from 'yup';
import styles from './PasswordChange.module.css';

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

function useFormError(errorMap, resetDeps = []) {
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    serverError: errorMap[errorKey],
    setServerError: setErrorKey,
  };
}

function PasswordChange() {
  const { LL } = useLocale();
  const [showCurrent, setShowCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [successfulChange, setSuccessfulChange] = useState(false);
  const { colorTest4 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: object({
      currentPassword: string().required('Required'),
      newPassword: string().required('Required'),
      confirmPassword: string()
        .oneOf([ref('newPassword'), null], "Passwords don't match")
        .required('Required'),
    }),
    onSubmit: async (values, { resetForm, setTouched }) => {
      const data = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      };
      try {
        const res = await axios({
          method: 'patch',
          url: `${process.env.REACT_APP_API_URI}users/password`,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: localStorage.getItem('token'),
          },
          data: qs.stringify(data),
        });

        setServerError(null);
        setTimeout(() => {
          setSuccessfulChange(true);
        }, 5000);
        setSuccessfulChange(true);
      } catch (error) {
        setTouched({});
        console.log(error);
        if (error.response && errorMap[error.response.data.error]) {
          console.log('Error: ', error.response.data.error);

          setServerError(error.response.data.error);
        } else {
          console.warn('Password change unexpected error: ', error);
          setServerError('ERR_GENERIC');
        }
      }
      resetForm();
    },
  });

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  const errorMap = {
    ERR_GENERIC: LL.profile.serverErrors.genericShort(),
    ERR_INVALID_CREDENTIALS: LL.profile.serverErrors.invalidCredentials(),
    ERR_INVALID_CURRENT_PASSWORD:
      LL.profile.serverErrors.invalidCurrentPassword(),
    SAME_PASSWORD_FORBIDDEN: LL.profile.serverErrors.samePassword(),
  };

  const { serverError, setServerError } = useFormError(errorMap, []);

  return (
    <Surface
      elementType="form"
      onSubmit={formik.handleSubmit}
      surfaceColor={surfaceColor}
      className={styles.container}
    >
      <>
        <div className={styles.formBody}>
          <H className={styles.heading}>{LL.profile.passwordChange.header()}</H>
          <ErrorContainer>
            {serverError ? (
              <InlineError
                onClose={() => setServerError(null)}
                success={false}
              >
                {serverError}
              </InlineError>
            ) : (
              successfulChange && (
                <InlineError
                  onClose={() => setSuccessfulChange(false)}
                  success={true}
                >
                  {LL.profile.passwordChange.success()}
                </InlineError>
              )
            )}
          </ErrorContainer>
          <H className={styles.subHeading}>
            {LL.profile.passwordChange.currentPassword()}
          </H>
          <TextField
            rightButton={
              <FieldIconButton
                onPress={() => setShowCurrent(!showCurrent)}
                aria-label="show-hide button"
              >
                {showCurrent ? <BiHide /> : <BiShowAlt />}
              </FieldIconButton>
            }
            type={showCurrent ? 'text' : 'password'}
            placeholder={LL.profile.passwordChange.currentPasswordPlaceholder()}
            value={formik.values.currentPassword}
            onChange={(val) =>
              formik.setFieldValue('currentPassword', val, false)
            }
            onBlur={handleBlur('currentPassword')}
            validationState={getValidationState(formik, 'currentPassword')}
            errorMessage={formik.errors.currentPassword}
          />
          <H className={styles.subHeading}>
            {LL.profile.passwordChange.newPassword()}
          </H>
          <TextField
            rightButton={
              <FieldIconButton
                onPress={() => setShowNew(!showNew)}
                aria-label="show-hide button"
              >
                {showNew ? <BiHide /> : <BiShowAlt />}
              </FieldIconButton>
            }
            type={showNew ? 'text' : 'password'}
            placeholder={LL.profile.passwordChange.newPasswordPlaceholder()}
            value={formik.values.newPassword}
            onChange={(val) => formik.setFieldValue('newPassword', val, false)}
            onBlur={handleBlur('newPassword')}
            validationState={getValidationState(formik, 'newPassword')}
            errorMessage={formik.errors.newPassword}
          />
          <H className={styles.subHeading}>
            {LL.profile.passwordChange.confirmPassword()}
          </H>
          <TextField
            rightButton={
              <FieldIconButton
                onPress={() => setShowConfirm(!showConfirm)}
                aria-label="show-hide button"
              >
                {showConfirm ? <BiHide /> : <BiShowAlt />}
              </FieldIconButton>
            }
            type={showConfirm ? 'text' : 'password'}
            placeholder={LL.profile.passwordChange.confirmPasswordPlaceholder()}
            value={formik.values.confirmPassword}
            onChange={(val) =>
              formik.setFieldValue('confirmPassword', val, false)
            }
            onBlur={handleBlur('confirmPassword')}
            validationState={getValidationState(formik, 'confirmPassword')}
            errorMessage={formik.errors.confirmPassword}
          />
        </div>
        <div className={styles.formSubmit}>
          <OutlineButton
            attachesBackground
            pressesBackground
            type="reset"
            onPress={formik.handleReset}
          >
            {LL.profile.resetFields()}
          </OutlineButton>
          <FilledButton
            type="submit"
            isDisabled={
              (!formik.values.currentPassword &&
                !formik.values.newPassword &&
                !formik.values.confirmPassword) ||
              formik.isSubmitting
            }
          >
            {LL.profile.confirmChange()}
          </FilledButton>
        </div>{' '}
      </>
    </Surface>
  );
}

export default PasswordChange;
