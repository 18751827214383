// @ts-check

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'classnames';
import Surface from 'common/surfaces/Surface.component';
import ConversionCurrency from 'components/content/ConversionCurrency.component';
import Currency from 'components/content/Currency.component';
import InfiniteScroll from 'components/infinite-scroll/InfiniteScroll';
import BufferingWheel from 'components/loader/LoadingAnimation.component';
import OperationButton from 'components/transactions/OperationButton.component';
import Status from 'components/transactions/Status.component';
import { useLocale } from 'context/LanguageContext';
import { format } from 'date-fns/esm';
import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';
import useThemeVariables from 'hooks/useThemeVariables';
import { useOperationsQuery } from 'queries/operations';
import * as React from 'react';
import { VisuallyHidden } from 'react-aria';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import styles from './TransactionsTable.module.css';

// TODO: sub tables should be wrapped in tbody. tr is not a valid child of table

/**
 * @typedef {import('queries/operations').Operation} Operation
 * @typedef {import('@tanstack/react-table').ColumnHelper<Operation>} ColumnHelper
 * @typedef {import('@tanstack/react-table').Table<Operation>} Table
 *
 * @typedef TransactionsTableProps
 * @property {number} maxRows Maximum number of rows to display. set to infinity to enable infinite scroll.
 * @property {HTMLElement | null} [scrollContainer] Used for infinite scrolling.
 * @property {string} [className]
 * @property {React.ReactNode} [children]
 * @property {Omit<import("queries/operations").GetOperationsPayload, "page">} [filters]
 */

/** @type {ColumnHelper} */
const columnHelper = createColumnHelper();

/**
 * @param {TransactionsTableProps} props
 */
function TransactionsTable({
  maxRows = 10,
  scrollContainer,
  className,
  children,
  filters = {
    currencies: [],
    from: null,
    to: null,
    type: null,
    address: '',
    txId: '',
  },
}) {
  const { fetchNextPage, data, hasNextPage } = useOperationsQuery(filters, {
    onError: () => setError(true),
  });
  const [error, setError] = React.useState(false);
  const {
    LL: { transactionsTable: TT },
  } = useLocale();

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        id: 'operations',
        header: () => TT.operationColumn.header(),
        // @ts-ignore // Too tired to fix this, it's not important
        cell: (props) => <OperationButton {...props.row.original} />,
      }),
      columnHelper.accessor('status', {
        header: (props) => (
          <div className={styles.statusHeader}>
            {/* No visual header on small screens. */}
            <VisuallyHidden>{TT.statusColumn.header()}</VisuallyHidden>
            {/* Regular header on large screens */}
            <span>{TT.statusColumn.header()}</span>
          </div>
        ),
        cell: (props) => (
          <div className={styles.statusCell}>
            {/* small visual version on small screens */}
            {/* <Status
            type={props.row.original.status}
            size="sm"
          /> */}
            {/* large version on large screens */}
            <Status
              type={props.row.original.status}
              size="lg"
            />
          </div>
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: () => TT.dateColumn.header(),
        cell: (props) => <>{format(parseISO(props.getValue()), 'Pp')}</>,
      }),
      columnHelper.accessor('amount', {
        header: () =>
          window.screen.width <= 905
            ? 'Amount/Status'
            : TT.amountColumn.header(),
        cell: (props) => (
          <div
            className={
              props.row.original.status === 'success'
                ? styles.success
                : props.row.original.status === 'fail'
                ? styles.fail
                : styles.pending
            }
          >
            {props.row.original.type === 'exchange' ? (
              <span>
                <Currency
                  currency={props.row.original.currency.split(' -> ')[0]}
                >
                  {Number(props.row.original.amountfrom)}
                </Currency>
                {' -> '}
                <br className={styles.lineBreak} />
                <Currency
                  currency={props.row.original.currency.split(' -> ')[1]}
                >
                  {Number(props.row.original.amount)}
                </Currency>
              </span>
            ) : (
              <>
                <span>
                  <Currency
                    currency={props.row.original.currency.split(' -> ')[0]}
                  >
                    {props.getValue()}
                  </Currency>
                </span>
                <span className={styles.amountCell}>
                  {' / '}
                  <ConversionCurrency
                    currency={props.row.original.currency.split(' -> ')[0]}
                  >
                    {/* zero width char to maintain height when loading */}
                    {Number(props.getValue())}&#8203;
                  </ConversionCurrency>
                </span>
              </>
            )}
          </div>
        ),
      }),
    ],
    [TT.amountColumn, TT.dateColumn, TT.operationColumn, TT.statusColumn]
  );

  // React Table goes crazy unless you memoize the data
  const { hasEndReached, tableData } = React.useMemo(() => {
    const flattened = data
      ? data.pages.map((pageData) => pageData.items).flat()
      : [];

    return {
      hasEndReached: flattened.length >= maxRows || !hasNextPage,
      tableData:
        flattened.length >= maxRows ? flattened.slice(0, maxRows) : flattened,
    };
  }, [data, hasNextPage, maxRows]);

  /** @type {Table} */
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { colorTest2 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest2,
  };

  return (
    <>
      <div className={className}>
        <table className={styles.table}>
          <div className={styles.loader}>
            {error && !data ? (
              <div className={styles.loaderItem}>{TT.unexpectedError()}</div>
            ) : (
              !data && <BufferingWheel color="var(--color-logo)" />
            )}
            {data?.pages[0].page === 1 && data?.pages[0].items.length === 0 && (
              <div className={styles.loaderItem}>{TT.notFound()}</div>
            )}
          </div>
          <Surface
            surfaceColor={surfaceColor}
            elementType="thead"
            className={styles.thead}
          >
            {children && (
              <tr>
                <th
                  colSpan={table.getAllLeafColumns().length}
                  style={{ padding: 0 }}
                >
                  {children}
                </th>
              </tr>
            )}
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={cx(
                      'type-title-medium',
                      styles[`${header.id}Col`]
                    )}
                    key={header.id}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </Surface>

          {GetTableBodies(table)}

          {/* Loading indicator */}
          {!hasEndReached && (
            <Surface
              elementType="tbody"
              baseElevation={1}
            >
              <tr>
                <th
                  colSpan={table.getAllLeafColumns().length}
                  style={{ padding: 0 }}
                >
                  <InfiniteScroll
                    scrollContainer={scrollContainer}
                    visibilityThreshold={400}
                    fetchMore={() => fetchNextPage({ cancelRefetch: true })}
                  >
                    <BarLoader
                      aria-label="Loading more..."
                      width={'100%'}
                      height={'0.8rem'}
                      color="var(--color-surface)"
                    />
                  </InfiniteScroll>
                </th>
              </tr>
            </Surface>
          )}
        </table>
      </div>
    </>
  );
}

/**
 * @param {Table} table
 *
 * returned tree:
 * ...
 * <tbody>
 *  <tr><th>Aug 12, 2022</th></tr>
 *  <tr>
 *    <td>XRP Invoice</td>
 *    ...
 *    <td>XRP 32.00000</td>
 *  </tr>
 * </tbody>
 * ...
 **/
function GetTableBodies(table) {
  const baseUrl = window.location.origin;

  let lastDate;
  let subTables = [];
  let currentSubTable = [];
  const navigate = useNavigate();

  const openOp = (curr, type, id, network) => {
    if (type === 'invoice') {
      navigate(`/payment-requested/${id}`);
    } else if (type === 'exchange') {
      navigate(`/explorer/exchange/${id}`);
    } else {
      navigate(`/explorer/${curr}/${network}/${id}`);
    }
  };

  const { colorTest5, colorTest4 } = useThemeVariables();
  const { isDarkTheme } = useThemeVariables();

  const surfaceColor = {
    background: colorTest5,
  };
  const secondSurface = {
    background: colorTest4,
  };

  table.getRowModel().rows.forEach((row, index) => {
    const createdAt = parseISO(row.original.createdAt);

    if (!lastDate || !isSameDay(lastDate, createdAt)) {
      // reset assemblying of the current subtable
      currentSubTable = [];

      // finish creating subtable when it's not the same day;
      // wrap it in a tbody and assign header to it.
      subTables.push(
        <>
          <tr
            className={
              isDarkTheme ? styles.createdAtHeader : styles.createdAtHeaderDark
            }
          >
            <th
              className="type-title-medium"
              scope="rowgroup"
              colSpan={row.getVisibleCells().length}
            >
              {format(createdAt, 'PP')}
            </th>
          </tr>
          {currentSubTable}
        </>
      );
    }

    // add rows to the subtable
    currentSubTable.push(
      <Surface
        elementType="tr"
        key={row.id}
        surfaceColor={index % 2 === 0 ? secondSurface : surfaceColor}
        // TODO: direct onClick prop on unfocusable element
        onClick={() => {
          openOp(
            row.original.currency,
            row.original.type,
            row.original.id,
            row.original.network
          );
        }}
      >
        {row.getVisibleCells().map((cell) => (
          <td
            className={cx(styles.cell, styles[`${cell.column.id}Col`])}
            key={cell.id}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </Surface>
    );

    // keep track of the last date
    lastDate = createdAt;
  });

  return subTables;
}

export default TransactionsTable;
