import { useEffect, useState } from 'react';

const useCountdown = (targetDate) => {
  const [countDown, setCountDown] = useState(targetDate);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((targetDate -= 1000));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  const seconds = Math.floor(countDown / 1000);

  return seconds;
};

export { useCountdown };
