import React, { useContext, useState } from 'react';

const CurrencyAndThemeContext = React.createContext(undefined);

export function useCurrencyAndThemeContext() {
  return useContext(CurrencyAndThemeContext);
}

export function CurrencyAndThemePovider({ children }) {
  function getInitialCurrency() {
    const savedCurrency = localStorage.getItem('currency');
    return savedCurrency ? savedCurrency : 'usd';
  }

  //TODO: implement theme changer
  function getInitialTheme() {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'light';
  }

  const [conversionCurrency, setConversionCurrency] =
    useState(getInitialCurrency);
  const [theme, setTheme] = useState(getInitialTheme);

  React.useEffect(() => {
    localStorage.setItem('currency', conversionCurrency);
    localStorage.setItem('theme', theme);
  }, [conversionCurrency, theme]);

  return (
    <CurrencyAndThemeContext.Provider
      value={{
        conversionCurrency,
        setConversionCurrency,
        theme,
        setTheme,
      }}
    >
      {children}
    </CurrencyAndThemeContext.Provider>
  );
}
