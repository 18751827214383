import { GregorianCalendar } from '@internationalized/date';
import cx from 'classnames';
import DateSegment from 'common/calendar/DateSegment.component';
import ValidationAndHints from 'common/fields/ValidationAndHints.component';
import { useLocale } from 'context/LanguageContext';
import * as React from 'react';
import { useDateField } from 'react-aria';
import { useDateFieldState } from 'react-stately';
import styles from './DateField.module.css';

/**
 * DateFields allow users to enter and edit date and time values using a keyboard.
 * Each part of a date value is displayed in an individually editable segment.
 *
 * For the dedailed documentation of props and usage please refer to:
 * {@link https://react-spectrum.adobe.com/react-spectrum/DateField.html}
 * @param {DateFieldProps} props
 **/
export function DateField(props) {
  const { trueLocale, LL } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale: trueLocale,
    createCalendar,
  });
  const ref = React.useRef();
  const { labelProps, fieldProps, descriptionProps, errorMessageProps } =
    useDateField(props, state, ref);

  return (
    <>
      <div className={styles.wrapper}>
        <span
          className={styles.label}
          {...labelProps}
        >
          {props.label}
        </span>
        {/* TODO: surround width FieldWrapper for error icons */}
        <div
          {...fieldProps}
          ref={ref}
          className={cx(styles.field, { '--isDisabled': props.isDisabled })}
        >
          {state.segments[0].text.startsWith('m')
            ? LL.transactionsPage.datePlaceholder()
            : state.segments.map((segment, i) => (
                <DateSegment
                  key={i}
                  segment={segment}
                  state={state}
                />
              ))}
        </div>
        <ValidationAndHints
          {...props}
          {...{ descriptionProps, errorMessageProps }}
        />
      </div>
    </>
  );
}

/**
 * Because we probably just require gregorian calendar, we can reduce
 * bundle size by just using GregorianCalendar interface
 */
function createCalendar(identifier) {
  switch (identifier) {
    case 'gregory':
      return new GregorianCalendar();
    default:
      // Not sure if throwing is right. Copied the example
      throw new Error(`Unsupported calendar ${identifier}`);
  }
}

/**
 * @typedef {DateFieldLocalProps & import("types").AriaLabelingProps} DateFieldProps
 *
 * @typedef DateFieldLocalProps
 * @property {(date : import("@react-types/datepicker").DateValue) => boolean} [isDateUnavailable]
 * 👆 Callback that is called for each date of the calendar.
 *    If it returns true, then the date is unavailable
 * @property {12 | 24} [hourCycle]
 * 👆 Whether to display the time in 12 or 24 hour format.
 *    By default, this is determined by the user's locale.
 * @property {'day' | 'hour' | 'minute' | 'second'} [granularity]
 * 👆 Determines the smallest unit that is displayed in the date picker.
 *    By default, this is "day" for dates, and "minute" for times.
 * @property {boolean} [hideTimeZone]
 * 👆 Whether to hide the time zone abbreviation.
 * @property {boolean} [isDisabled]
 * 👆 Whether the input is disabled.
 * @property {boolean} [isReadOnly]
 * 👆 Whether the input can be selected but not changed by the user.
 * @property {"valid" | "invalid" | null} [validationState]
 * 👆 Whether the input should display its "valid" or "invalid" visual styling.
 * @property {boolean} [isRequired]
 * 👆 Whether user input is required on the input before form submission.
 *    You should pair with some sort of necessity indicator on label like an asterisk
 * @property {boolean} [autoFocus]
 * 👆 Whether the element should receive focus on render.
 * @property {import('@react-types/calendar').DateValue} [minValue]
 * 👆 The minimum allowed date that a user may select.
 * @property {import('@react-types/calendar').DateValue} [maxValue]
 * 👆 The maximum allowed date that a user may select.
 * @property {import('@react-types/calendar').DateValue} [placeholderValue]
 * 👆 A placeholder date that influences the format of the placeholder shown
 * when no value is selected. Defaults to today's date at midnight.
 * Note that by default, time values will not have a time zone because none was supplied.
 * You can override this by setting the placeholderValue prop explicitly.
 * Values emitted from onChange will use the time zone of the placeholder value.
 * @property {import('@react-types/calendar').DateValue} [value]
 * 👆 The current value (controlled).
 * @property {import('@react-types/calendar').DateValue} [defaultValue]
 * 👆 The current value (controlled).
 * @property {(value: import('@react-types/calendar').MappedDateValue<import('@react-types/calendar').DateValue>) => void} [onChange]
 * 👆 Handler that is called when the value changes
 * @property {(e : React.FocusEvent) => void} [onFocus]
 * @property {(e : React.FocusEvent) => void} [onBlur]
 * @property {(isFocused : boolean) => void} [onFocusChange]
 * @property {(e : React.KeyboardEvent) => void} [onKeyDown]
 * @property {(e : React.KeyboardEvent) => void} [onKeyUp]
 * @property {React.ReactNode} [label]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {boolean} [isOpen]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen : boolean) => void} [onOpenChange]
 */
