import * as tokens from '../material-theme/dist/styledictionary/js/tokens';

const darkTheme = {
  colorPrimary: tokens.MdSysColorPrimaryDark,
  colorOnPrimary: tokens.MdSysColorOnPrimaryDark,
  colorPrimaryContainer: tokens.MdSysColorPrimaryContainerDark,
  colorOnPrimaryContainer: tokens.MdSysColorOnPrimaryContainerDark,
  colorSecondary: tokens.MdSysColorSecondaryDark,
  colorOnSecondary: tokens.MdSysColorOnSecondaryDark,
  colorSecondaryContainer: tokens.MdSysColorSecondaryContainerDark,
  colorOnSecondaryContainer: tokens.MdSysColorOnSecondaryContainerDark,
  colorTertiary: tokens.MdSysColorTertiaryDark,
  colorOnTertiary: tokens.MdSysColorOnTertiaryDark,
  colorTertiaryContainer: tokens.MdSysColorTertiaryContainerDark,
  colorOnTertiaryContainer: tokens.MdSysColorOnTertiaryContainerDark,
  colorError: tokens.MdSysColorErrorDark,
  colorErrorContainer: tokens.MdSysColorErrorContainerDark,
  colorOnError: tokens.MdSysColorOnErrorDark,
  colorOnErrorContainer: tokens.MdSysColorOnErrorContainerDark,
  colorBackground: tokens.MdSysColorBackgroundDark,
  colorOnBackground: tokens.MdSysColorOnBackgroundDark,
  colorSurface: tokens.MdSysColorSurfaceDark,
  colorOnSurface: tokens.MdSysColorOnSurfaceDark,
  colorSurfaceVariant: tokens.MdSysColorSurfaceVariantDark,
  colorOnSurfaceVariant: tokens.MdSysColorOnSurfaceVariantDark,
  colorOutline: tokens.MdSysColorOutlineDark,
  colorInverseOnSurface: tokens.MdSysColorInverseOnSurfaceDark,
  colorInverseSurface: tokens.MdSysColorInverseSurfaceDark,
  colorInversePrimary: tokens.MdSysColorInversePrimaryDark,
  colorShadow: tokens.MdSysColorShadowDark,
  colorSurfaceTintAt1: tokens.SysColorSurfaceTintAt1Dark,
  colorSurfaceTintAt2: tokens.SysColorSurfaceTintAt2Dark,
  colorSurfaceTintAt3: tokens.SysColorSurfaceTintAt3Dark,
  colorSurfaceTintAt4: tokens.SysColorSurfaceTintAt4Dark,
  colorSurfaceTintAt5: tokens.SysColorSurfaceTintAt5Dark,
  colorSuccess: tokens.MdSysColorSuccessDark,
  colorOnSuccess: tokens.MdSysColorOnSuccessDark,
  colorSuccessContainer: tokens.MdSysColorSuccessContainerDark,
  colorOnSuccessContainer: tokens.MdSysColorOnSuccessContainerDark,
  colorDisabledContainer: tokens.MdSysColorDisabledContainerDark,
  colorDisabledContent: tokens.MdSysColorDisabledContentDark,
  colorTest: tokens.MdRefPaletteNeutral20,
  colorTest2: tokens.MdRefPaletteNeutral30,
  colorTest3: tokens.MdRefPaletteNeutral20,
  colorTest4: 'rgba(224, 227, 227, 0.12)',
  colorTest5: 'rgba(25, 28, 29, 0.12)',
  colorTest6: '#444748',
  colorTest7: 'rgba(224, 227, 227, 0.12)',
  colorTest8: '#fe8B00',
};

export default darkTheme;
