// @ts-check
import * as React from 'react';
import TabsBase from './TabsBase.component';
import { mergeClassNames } from 'utils';
import styles from './Tabs.module.css';

/**
 * @template T
 * @typedef {import("./TabsBase.component").TabsBaseProps<T>} TabsBaseProps
 */

/**
 * @typedef LocalProps
 * @property {"inline" | "stretch"} [width]
 * @property {TabsBaseProps<any>["classNames"]} [classNames]
 */

/**
 * @template T
 * @typedef {Omit<TabsBaseProps<T>, "classNames" | "orientation"> & LocalProps} TabsProps
 */

/**
 * @template T
 * @param {TabsProps<T>} props
 * @param {React.ForwardedRef<import('./TabsBase.component').FocusHandle>} parentRef
 */
function TabsWithRef(
  { children, width, classNames = {}, ...props },
  parentRef
) {
  const allClassNames = mergeClassNames(classNames, styles, {
    tabs: width === 'stretch' ? '--stretch' : '--inline',
  });

  return (
    <TabsBase
      {...props}
      classNames={allClassNames}
      ref={parentRef}
    >
      {children}
    </TabsBase>
  );
}

const Tabs = React.forwardRef(TabsWithRef);

export default Tabs;
