// @ts-check
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef Operation
 * @property {string} id
 * @property {number} user_id
 * @property {string} amountfrom
 * @property {number} amount
 * @property {null | "exchange" | "invoice" | "deposit" | "withdrawal" | "p2p"} type
 * @property {import('currencies').Currency | `${import('currencies').Currency} -> ${import('currencies').Currency}`} currency
 * @property {"pending" | "fail" | "success"} status
 * @property {import('types').NetworkType} network
 * @property {string} createdAt
 * @property {object} price
 *   @property {number} price.btc
 *   @property {number} price.usd
 *   @property {number} price.eur
 *   @property {number} price.rub
 *
 * @typedef GetOperationsPayload
 * @property {number} page
 * @property {Operation["type"]} type
 * @property {import('currencies').Currency[]} currencies
 * @property {null | Date} from
 * @property {null | Date} to
 * @property {string} txId (Transaction ID)
 * @property {string} address
 */

/** @param {GetOperationsPayload} payload */
export async function getOperations(payload) {
  /** @type {import('axios').AxiosResponse<{page: number, pages: number, items: Operation[]}>} */
  const response = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URI}operations`,
    data: payload,
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });

  if (!response) {
    throw new Error('Unexpected Error');
  }

  return response.data;
}

/**
 *  @param {Omit<GetOperationsPayload, "page">} payload
 *  @param {{enabled?: boolean, retry?: boolean, onSuccess?: any, onError?: any}} [options]
 */
export function useOperationsQuery(payload, options) {
  return useInfiniteQuery({
    queryKey: ['operations', payload],
    // fetch first page by default
    queryFn: ({ pageParam = { ...payload, page: 1 } }) => {
      return getOperations(pageParam);
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.page < lastPage.pages) {
        return {
          ...payload,
          page: lastPage.page + 1,
        };
      }
    },
    keepPreviousData: true,
    ...options,
  });
}
