const INIT_STATE = {
  sendLoading: false,
};

const sendReducer = (state = INIT_STATE, action) => {
  const { type } = action;
  switch (type) {
    case 'SEND_START':
      return {
        ...state,
        sendLoading: true,
      };
    case 'SEND_SUCCESS':
    case 'SEND_FAIL':
      return {
        ...state,
        sendLoading: false,
      };
    default:
      return state;
  }
};

export default sendReducer;
