import CurrencyColors from 'assets/data/currency-colors';
import FilledButton from 'common/buttons/FilledButton';
import Surface from 'common/surfaces/Surface.component';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import Currency from 'components/content/Currency.component';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useLocale } from 'context/LanguageContext';
import { format, parseISO } from 'date-fns';
import useThemeVariables from 'hooks/useThemeVariables';
import { usePayableInvoicesQuery } from 'queries/payableInvoices';
import React from 'react';
import { H, Level } from 'react-accessible-headings';
import { useNavigate } from 'react-router';
import styles from './Payables.module.css';

export default function Payables({ setSelectedTab, setInvoiceId }) {
  const { LL } = useLocale();
  const { data } = usePayableInvoicesQuery();
  const { conversionCurrency } = useCurrencyAndThemeContext();
  const { colorTest7 } = useThemeVariables();
  const surfaceColor = {
    background: colorTest7,
  };
  const navigate = useNavigate();

  return (
    <Level>
      <div className={styles.operationsContainer}>
        <TranslucentSurface
          className={styles.headerContainer}
          surfaceColor={surfaceColor}
        >
          <div className={styles.detailsHeader}>
            <H>{LL.merchant.headers.email()}</H>
            <H>{LL.merchant.headers.invoicedAmount()}</H>
            <H>{LL.merchant.headers.totalPaid()}</H>
            <H>{LL.merchant.headers.validTill()}</H>
          </div>
        </TranslucentSurface>
        {data?.invoices.map((invoice) => (
          <TranslucentSurface
            className={styles.container}
            surfaceColor={CurrencyColors[invoice.currency]}
          >
            <div className={styles.details}>
              <Surface
                className={styles.detail}
                surfaceColor={surfaceColor}
              >
                <span className={styles.mobileHeader}>
                  {LL.merchant.headers.email()}:
                </span>
                {invoice.email}
              </Surface>

              <Surface
                className={styles.detail}
                surfaceColor={surfaceColor}
              >
                <span className={styles.mobileHeader}>
                  {LL.merchant.headers.invoicedAmount()}:
                </span>
                {invoice.amount_invoiced} {invoice.currency.toLocaleUpperCase()}{' '}
                /{' '}
                <Currency currency={conversionCurrency}>
                  {Number(invoice.amount_invoiced_fiat[conversionCurrency])}
                </Currency>
                {' USD'}
              </Surface>

              <Surface
                className={styles.detail}
                surfaceColor={surfaceColor}
              >
                <span className={styles.mobileHeader}>
                  {LL.merchant.headers.totalPaid()}:
                </span>
                {invoice.total_paid} {invoice.currency.toLocaleUpperCase()} /{' '}
                <Currency currency={conversionCurrency}>
                  {invoice.total_paid_fiat[conversionCurrency]}
                </Currency>
              </Surface>

              <Surface
                className={styles.detail}
                surfaceColor={surfaceColor}
              >
                <span className={styles.mobileHeader}>
                  {LL.merchant.headers.validTill()}:
                </span>
                {format(
                  parseISO(invoice.valid_till.toString()),
                  'yyyy-MM-dd HH:mm:ss'
                )}
                <FilledButton
                  className={styles.paymentButton}
                  onClick={() => {
                    setSelectedTab('Payment');
                    setInvoiceId(invoice.id);
                  }}
                >
                  Pay
                </FilledButton>
              </Surface>
            </div>
          </TranslucentSurface>
        ))}
      </div>
    </Level>
  );
}
