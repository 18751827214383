import userActionTypes from './user.types';

export const fetchUserAddressStart = (data) => ({
  type: userActionTypes.FETCH_USERADDRESS_START,
  payload: data,
});

export const fetchUserAddressSuccess = (data) => ({
  type: userActionTypes.FETCH_USERADDRESS_SUCCESS,
  payload: data,
});
export const fetchUserAddressFailure = (err) => ({
  type: userActionTypes.FETCH_USERADDRESS_FAILURE,
  payload: err,
});
export const fetchUserBalanceStart = () => ({
  type: userActionTypes.FETCH_USER_BALANCE_START,
});

export const fetchUserBalanceSuccess = (data) => {
  return {
    type: userActionTypes.FETCH_USERBALANCE_SUCCESS,
    payload: data,
  };
};

export const fetchUserCredentialsSstart = () => {
  return {
    type: userActionTypes.FETCH_USER_CREDENTIALS_START,
  };
};

export const fetchUserCredentialsSuccess = (data) => {
  return {
    type: userActionTypes.FETCH_USER_CREDENTIALS_SUCCESS,
    payload: data,
  };
};

export const tfaGenerateStart = () => ({
  type: userActionTypes.TFA_GENERATE_START,
});

export const tfaGenerateSuccess = (data) => ({
  type: userActionTypes.TFA_GENERATE_SUCCESS,
  payload: data,
});

export const tfaGenerateFailure = () => ({
  type: userActionTypes.TFA_GENERATE_FAILURE,
});

export const tfaVerifyStart = (pin) => ({
  type: userActionTypes.TFA_VERIFY_START,
  payload: pin,
});

export const tfaVerify = () => ({
  type: userActionTypes.TFA_VERIFY,
});

export const tfaDisableStart = (pin) => ({
  type: userActionTypes.TFA_DISABLE_START,
  payload: pin,
});

export const tfaDisable = () => ({
  type: userActionTypes.TFA_DISABLE,
});

export const onTfaError = () => ({
  type: userActionTypes.TFA_ERROR,
});

export const tfaNewDeviceStart = (data) => ({
  type: userActionTypes.TFA_NEW_DEVICE_START,
  payload: data,
});

export const tfaNewDevice = (data) => ({
  type: userActionTypes.TFA_NEW_DEVICE,
  payload: data,
});

export const fetchIpWhitelistStart = () => ({
  type: userActionTypes.FETCH_IP_WHITELIST_START,
});

export const fetchIpWhitelistSuccess = (data) => ({
  type: userActionTypes.FETCH_IP_WHITELIST_SUCCESS,
  payload: data,
});
