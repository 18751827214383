// @ts-check

import { Template, Slot } from 'common/template/Template';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import styles from './NavBar.module.css';
import { RiEditLine, RiEditFill } from 'react-icons/ri';
import { useId } from 'react-aria';
import { ReactComponent as RoundedTriangle } from 'assets/rounded-triangle.svg';
import useBackgroundInteractions from 'hooks/useBackgroundInteractions';
import useThemeVariables from 'hooks/useThemeVariables';
import { useLink } from '@react-aria/link';
import useHighlightRadius from 'hooks/useHighlightRadius';
import { motion } from 'framer-motion';
import { hexToRGBA } from 'utils';

/**
 * @typedef  NavBarProps
 * @property  {React.ReactNode} children // Collection of NavBarItem's
 * @property  {"vertical" | "horizontal" | "auto"} [orientation] // auto alignment is done with media queries
 * @property {string} [className]
 * @property  {string} [aria-label]
 * @property  {string} [aria-labelledby]
 *
 * @param {NavBarProps} props
 */
export function NavBar({
  children,
  orientation = 'auto',
  className,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledby,
}) {
  return (
    <nav
      className={cx(className, styles.nav, `--${orientation}Orientation`)}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
    >
      <ul className={styles.list}>{children}</ul>
    </nav>
  );
}

const Link = motion(NavLink);

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {import("react-router-dom").To} props.to
 */
export function NavBarItem({ children, to }) {
  const ref = React.useRef();
  const id = useId();
  // TODO: implement focus ring
  // const {} = useFocusRing();
  const { colorSecondary } = useThemeVariables();

  const backgroundInteractions = useBackgroundInteractions({
    attachesBackground: true,
    pressesBackground: true,
    attachRadius: 250,
    attachHighlight: hexToRGBA(colorSecondary),
    pressRadius: 200,
  });

  // @ts-ignore
  const { linkProps } = useLink(backgroundInteractions, ref);

  return (
    <li className={styles.navBarItem}>
      <Template content={children}>
        <Link
          to={to}
          className={({ isActive }) =>
            cx(styles.navBarLink, isActive && '--isActive')
          }
          aria-labelledby={id}
          ref={ref}
          // {...linkProps}
        >
          {({ isActive }) => (
            <>
              {/* TODO: implement circular shape for labelless items */}
              <div className={styles.activeIndicator} aria-hidden>
                <RoundedTriangle />
              </div>
              <div className={styles.navBarItemIcon}>
                <Slot name="icon" isActive={isActive}>
                  {isActive ? <RiEditFill /> : <RiEditLine />}
                </Slot>
              </div>
              <div className={styles.navBarItemLabel} id={id}>
                <Slot name="label" isActive={isActive}>
                  slot=label
                </Slot>
              </div>
            </>
          )}
        </Link>
      </Template>
    </li>
  );
}
