import type { Currency } from 'currencies';

const CurrencyColors = {
  btc: { background: '#F7931A' },
  eth: { background: '#537FEE' },
  xrp: { background: 'var(--color-xrp)' },
  usdt: { background: '#50AF95' },
  ltc: { background: '#345D9D' },
  xlm: { background: 'var(--color-xlm)' },
  bch: { background: '#0AC18E' },
  trx: { background: '#FF060A' },
  uni: { background: '#FF007A' },
  usdc: { background: 'var(--color-usdc)' },
  busd: { background: 'var(--color-busd)' },
  bnb: { background: '#F3BA2F' },
  reef: { background: 'var(--color-reef)' },
  shib: { background: 'var(--color-shib)' },
  doge: { background: 'var(--color-doge)' },
  btt: { background: 'var(--color-btt)' },
  ton: { background: '#5090E2' },
  usd: { background: '#85BB65' },
  eur: { background: '#5D7EA7' },
  gel: { background: '#D49989' },
  rub: { background: '#2D5755' },
} satisfies Record<Currency, { background: string }>;

export default CurrencyColors;
