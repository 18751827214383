import * as React from 'react';
import { useDateSegment } from 'react-aria';
import cx from 'classnames';
import styles from './DateSegment.module.css';

// in ch
const HORIZONTAL_PADDING = 0.4;

/**
 * @typedef DateSegmentProps
 * @property {import("react-stately").DateSegment} segment
 * @property {import("react-stately").DateFieldState} state
 *
 * @param {DateSegmentProps} props
 */
export default function DateSegment({ segment, state }) {
  const ref = React.useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  // get max possible charachters the segment's text could be + 1ch for safety
  // to avoid layout shifts
  const minWidth =
    [segment.minValue, segment.maxValue, segment.placeholder, segment.text]
      .filter(Boolean)
      .map((numberOrString) => String(numberOrString))
      .reduce((a, b) => (a.length > b.length ? a : b)).length +
    (segment.type === 'literal' ? 0 : 1);

  return (
    <div
      {...segmentProps}
      style={{
        ...segmentProps.style,
        minWidth: `${minWidth + HORIZONTAL_PADDING * 2}ch`,
        padding: `0 ${HORIZONTAL_PADDING}ch`,
      }}
      ref={ref}
      className={cx(styles.segment, {
        '--isPlaceholder': segment.isPlaceholder,
      })}
    >
      {segment.text}
    </div>
  );
}
