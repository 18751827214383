// @ts-check
import { Item } from '@react-stately/collections';
import currencies from 'assets/currencies';
import FieldSelect from 'common/fields/FieldSelect.component';
import Select from 'common/pickers/Select.component';
import Currency from 'components/content/Currency.component';
import { useLocale } from 'context/LanguageContext';
import { NoAvailableBalance, NoBalanceInCurrency } from 'errors';
import { useBalancesQuery } from 'queries/balances';
import * as React from 'react';

/**
 * @typedef {import("queries/balances").Balance} Balance
 *
 *
 * @typedef Props
 * @property {boolean} [isInline] whether to use inline variant (as a companion to a field)
 * @property {string} [defaultText]
 * @property {React.ReactNode} [label]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {string} [placeholder]
 * @property {string | null} [selectedKey] // shorthand
 * @property {(key: React.Key) => void} [onSelectionChange]
 * @property {boolean} [autoFocus]
 * @property {( e : React.FocusEvent ) => void} [onFocus]
 * @property {( e : React.FocusEvent ) => void} [onBlur]
 * @property {( isFocused : boolean ) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyUp]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {React.MutableRefObject<HTMLElement>} [positionRef]
 *
 * @param {Props} props
 */
export function DepositPicker(props) {
  const { LL } = useLocale();

  const balances = useBalancesQuery(true);

  if (balances.isError) {
    console.log('error', balances.error);
    // TODO: idk about this error message.
    return <>Problem trying to load your deposits. Please try refreshing </>;
  }

  // throw if no desposits in any currency
  if (!balances.isLoading && balances.data.length === 0) {
    throw new NoAvailableBalance();
  }

  // throw if props.selectedKey is a currency that has not been deposted to
  if (
    !balances.isLoading &&
    props.selectedKey &&
    !balances.data.some((bal) => bal.coin === props.selectedKey)
  ) {
    throw new NoBalanceInCurrency(props.selectedKey);
  }

  const SelectComponent = props.isInline ? FieldSelect : Select;

  return (
    <SelectComponent
      isLoading={balances.isLoading}
      items={balances.data || []}
      renderValue={props.isInline && ((balance) => balance.coin.toUpperCase())}
      {...props}
    >
      {(balance) => (
        <Item
          key={balance.coin}
          textValue={LL.currency[balance.coin]()}
        >
          {LL.currency[balance.coin]()}

          {currencies[balance.coin] ? (
            <img
              src={currencies[balance.coin]}
              alt=""
              data-slot="icon"
            />
          ) : (
            <React.Fragment data-slot="icon" />
          )}
          <Currency
            data-slot="description"
            currency={balance.coin}
          >
            {balance.confirmed_balance}
          </Currency>
        </Item>
      )}
    </SelectComponent>
  );
}

/** @type {React.Component<{noAvailableBalance: () => React.ReactNode, noBalanceInCurrency: ()=> React.ReactNode}>} */
export class DepositPickerBoundry extends React.Component {
  state = {
    /** @type {Error} */
    error: null,
  };

  static getDerivedStateFromError(error) {
    return error;
  }

  render() {
    const { error } = this.state;

    if (!error) return this.props.children;

    if (error instanceof NoAvailableBalance) {
      return this.props.noAvailableBalance(error);
    }
    if (error instanceof NoBalanceInCurrency) {
      return this.props.noBalanceInCurrency(error);
    }

    // If it's not a BalanceError let upper error boundries handle it
    throw this.state.error;
  }
}
