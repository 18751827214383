import { useLocale } from 'context/LanguageContext';
import { ConversionError } from 'errors';
import { useErrorMap } from 'errors/useErrorMap';
import * as React from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';
import styles from './ConversionErrorView.module.css';

export default function ConversionErrorView() {
  const error = useErrorMap(ConversionError);
  const { LL } = useLocale();

  return (
    <p className={styles.container}>
      <RiErrorWarningFill />
      {LL.exchange.errors.conversion({ from: error.from, to: error.to })}
    </p>
  );
}
