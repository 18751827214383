import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import formsReducer from './forms/forms.reducer';
import coinReducer from './coin/coin.reducer';
import exchangeReducer from './exchange/exchange.reducer';
import userReducer from './user/user.reducer';
import authReducer from './auth/auth.reducer';
import themeReducer from './theme/theme.reducer';
import adminReducer from './admin/admin.reducer';
import alertReducer from './alert/alert.reducer';
import operationsReducer from './operations/operations.reducer';
import sendReducer from './send/send.reducer';
import invoiceReducer from './invoice/invoice.reducer';
import notificationsReducer from './notifications/notifications.reducer';
import networkReducer from './network/network.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['theme'],
};

const rootReducer = combineReducers({
  forms: formsReducer,
  coin: coinReducer,
  exchange: exchangeReducer,
  user: userReducer,
  auth: authReducer,
  theme: themeReducer,
  admin: adminReducer,
  alert: alertReducer,
  operations: operationsReducer,
  send: sendReducer,
  invoice: invoiceReducer,
  notifications: notificationsReducer,
  networks: networkReducer,
});

export default persistReducer(persistConfig, rootReducer);
