import axios from 'axios';
import BufferingWheel from 'components/loader/LoadingAnimation.component';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { logOut } from 'redux/auth/auth.actions';

const PrivateRoute = ({ children, logOut }) => {
  // Need better check for auth
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isTokenValidated, setIsTokenValidated] = React.useState(false);
  const [attempt, setAttempt] = React.useState(0);
  const token = localStorage.getItem('token');

  if (
    window.location.href.startsWith(
      window.location.origin + '/merchant?tab=Payment&id'
    )
  )
    sessionStorage.setItem('p2p_invoice_url', window.location.href);

  React.useEffect(() => {
    if (token) {
      axios(
        attempt <= 5 && {
          method: 'get',
          url: `${process.env.REACT_APP_API_URI}v1/auth/token`,
          headers: {
            Authorization: token,
          },
        }
      )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.success) setIsAuthenticated(true);
        })
        .catch((err) => {
          setInterval(() => {
            setAttempt(attempt + 1);
          }, 2000);

          if (attempt > 5) {
            setIsAuthenticated(false);
            localStorage.removeItem('token');
          }
        })
        .then(() => setIsTokenValidated(true));
    }
  }, [attempt, token]);

  if (!token && !isAuthenticated) return <Navigate to="/" />;

  if (isTokenValidated && !isAuthenticated)
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BufferingWheel color="var(--color-logo)" />
      </div>
    );

  return isAuthenticated && children;
  // if (token) {
  //   axios({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_API_URI}v1/auth/token`,
  //     headers: {
  //       Authorization: JSON.stringify({ token }),
  //     },
  //   })
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .then((data) => {
  //       if (data.success) setIsAuthenticated(true);
  //     })
  //     .catch((err) => {
  //       setIsAuthenticated(false);
  //       localStorage.removeItem('token');
  //     })
  //     .then(() => setIsTokenValidated(true));
  // } else {
  //   setIsTokenValidated(true);
  // }

  // const token = jwtDecode(localStorage.getItem('token'));
  // console.log(token);
  // // @ts-ignore
  // if (!!localStorage.getItem('token') && token.exp < Date.now() / 1000)
  //   logOut();
  // return children;
};

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
});

export default connect(null, mapDispatchToProps)(PrivateRoute);
