// @ts-check
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef P2pFee
 * @property {import('currencies').Currency} currency
 * @property {number} amount Fixed fee. net amount = amount + p2pFee.amount.
 *
 * @typedef UnconvertedP2pFee P2pFee returned from the server. Amount is string instead of number
 * @property {import('currencies').Currency} currency
 * @property {string} amount
 *
 * @typedef {import('currencies').Currency} P2pFeePayload Currency
 */

/**
 * @param {P2pFeePayload} currency
 */
export async function getP2pFee(currency) {
  /** @type {import("axios").AxiosResponse<[UnconvertedP2pFee]>} */
  const response = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URI}p2p-transactions/fees`,
    data: {
      currencies: [currency],
    },
    headers: {
      Authorization: localStorage.getItem('token'),
      // 'content-type': 'application/x-www-form-urlencoded',
    },
  });

  // cast returned value to a number.
  /** @type {P2pFee} */
  const data = {
    ...response.data[0],
    amount: +response.data[0].amount,
  };

  return data;
}

/**
 * @param {P2pFeePayload} currency
 * @param {{enabled: boolean}} options
 *
 * @returns {import('@tanstack/react-query').UseQueryResult<P2pFee, Error>}
 */
export function useP2pFeeQuery(currency, options) {
  return useQuery(
    ['p2pFee', currency],
    async () => {
      return await getP2pFee(currency);
    },
    options
  );
}
