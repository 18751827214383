import {
  FETCH_OPERATIONS_SUCCESS,
  FETCH_OPERATIONS_START,
  FETCH_OPERATIONS_FAIL,
} from './operations.types';

const INIT_STATE = {
  operations: [],
  pages: 1,
  loadginOp: false,
  operationsErr: null,
};

const operationsReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_OPERATIONS_START:
      return {
        ...state,
        loadginOp: true,
        operationsErr: null,
      };
    case FETCH_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: payload.items,
        pages: payload.pages,
        loadginOp: false,
        operationsErr: null,
      };
    case FETCH_OPERATIONS_FAIL:
      return {
        ...state,
        loadginOp: false,
        operationsErr: payload,
      };
    default:
      return state;
  }
};

export default operationsReducer;
