import * as tokens from '../material-theme/dist/styledictionary/js/tokens';

const lightTheme = {
  colorPrimary: tokens.MdSysColorPrimaryLight,
  colorOnPrimary: tokens.MdSysColorOnPrimaryLight,
  colorPrimaryContainer: tokens.MdSysColorPrimaryContainerLight,
  colorOnPrimaryContainer: tokens.MdSysColorOnPrimaryContainerLight,
  colorSecondary: tokens.MdSysColorSecondaryLight,
  colorOnSecondary: tokens.MdSysColorOnSecondaryLight,
  colorSecondaryContainer: tokens.MdSysColorSecondaryContainerLight,
  colorOnSecondaryContainer: tokens.MdSysColorOnSecondaryContainerLight,
  colorTertiary: tokens.MdSysColorTertiaryLight,
  colorOnTertiary: tokens.MdSysColorOnTertiaryLight,
  colorTertiaryContainer: tokens.MdSysColorTertiaryContainerLight,
  colorOnTertiaryContainer: tokens.MdSysColorOnTertiaryContainerLight,
  colorError: tokens.MdSysColorErrorLight,
  colorErrorContainer: tokens.MdSysColorErrorContainerLight,
  colorOnError: tokens.MdSysColorOnErrorLight,
  colorOnErrorContainer: tokens.MdSysColorOnErrorContainerLight,
  colorBackground: tokens.MdSysColorBackgroundLight,
  colorOnBackground: tokens.MdSysColorOnBackgroundLight,
  colorSurface: tokens.MdSysColorSurfaceLight,
  colorOnSurface: tokens.MdSysColorOnSurfaceLight,
  colorSurfaceVariant: tokens.MdSysColorSurfaceVariantLight,
  colorOnSurfaceVariant: tokens.MdSysColorOnSurfaceVariantLight,
  colorOutline: tokens.MdSysColorOutlineLight,
  colorInverseOnSurface: tokens.MdSysColorInverseOnSurfaceLight,
  colorInverseSurface: tokens.MdSysColorInverseSurfaceLight,
  colorInversePrimary: tokens.MdSysColorInversePrimaryLight,
  colorShadow: tokens.MdSysColorShadowLight,
  colorSuccess: tokens.MdSysColorSuccessLight,
  colorOnSuccess: tokens.MdSysColorOnSuccessLight,
  colorSuccessContainer: tokens.MdSysColorSuccessContainerLight,
  colorOnSuccessContainer: tokens.MdSysColorOnSuccessContainerLight,
  colorDisabledContainer: tokens.MdSysColorDisabledContainerLight,
  colorDisabledContent: tokens.MdSysColorDisabledContentLight,
  colorTest: tokens.MdRefPaletteNeutral90,
  colorTest2: tokens.MdRefPaletteNeutral80,
  colorTest3: tokens.MdRefPaletteNeutral80,
  colorTest4: 'rgb(224, 227, 227, 0.38)',
  colorTest5: 'rgba(224, 227, 227, 0.12)',
  colorTest6: '#e0e3e3',
  colorTest7: 'rgba(224, 227, 227, 0.5)',
  colorTest8: '#ff4500',
};

export default lightTheme;
