// @ts-check
import cx from 'classnames';
import Alert from 'components/alert/alert.component';
import { useLocale } from 'context/LanguageContext';
import * as React from 'react';
import { Level } from 'react-accessible-headings';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import styles from './AppLayout.module.css';

export default function AppLayout() {
  const { locale } = useLocale();
  const isDarkTheme = useSelector((state) => state.theme.dark);

  return (
    <div
      className={cx(styles.container, {
        '--is-dark-theme': isDarkTheme,
        '--is-light-theme': !isDarkTheme,
        '--locale-en': locale === 'en',
        '--locale-ka': locale === 'ka',
      })}
    >
      <Level hClassName="__heading">
        <Outlet />
        <Alert />
      </Level>
    </div>
  );
}
