// @ts-check
import { Accordion, AccordionItem } from 'common/accordion/Accordion.component';
import FilledButton from 'common/buttons/FilledButton';
import OutlinedButton from 'common/buttons/OutlinedButton';
import FieldIconButton from 'common/fields/FieldIconButton';
import TextField from 'common/fields/TextField';
import { ConversionGroup } from 'components/conversion/ConversionGroup';
import CopyToClipboard from 'components/copy-to-clipboard/CopyToClipboard.component';
import CurrencyField from 'components/currency-fields/CurrencyField.component';
import CurrencyPicker from 'components/currency-fields/CurrencyPicker.component';
import NetworkPicker, {
  getInitialSelectedKey,
} from 'components/currency-fields/NetworkPicker.component';
import ConversionErrorView from 'components/error-views/ConversionErrorView';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useExchangeContext } from 'context/ExchangeContext';
import { useLocale } from 'context/LanguageContext';
import { fiatCurrencies } from 'currencies';
import { ConversionError } from 'errors';
import { ErrorMap } from 'errors/ErrorMap';
import useThemeVariables from 'hooks/useThemeVariables';
import { QRCodeCanvas } from 'qrcode.react';
import {
  useChangeCurrencyAddress,
  useCurrencyAddressQuery,
} from 'queries/currencyAddress';
import * as React from 'react';
import { RiClipboardLine, RiRefreshLine } from 'react-icons/ri';
import styles from './DepositTab.module.css';

function getValidationState(sendForm, field) {
  return !sendForm.touched[field]
    ? null
    : sendForm.errors[field]
    ? 'invalid'
    : 'valid';
}

export default function DepositTab() {
  const { colorOnSurface } = useThemeVariables();

  const receiveForm = useExchangeContext().receive;

  const { LL } = useLocale();
  const { conversionCurrency } = useCurrencyAndThemeContext();

  // const currencyNetworks = useCurrencyNetworksQuery();

  const currencyAddress = useCurrencyAddressQuery(
    {
      coin: receiveForm.values.coin,
      network: receiveForm.values.network,
    },
    // TODO: network stays the same on the first rerender after the coin changes and it causes a bad request with a stale network value.
    // It's best to refactor NetworkPicker.
    { enabled: !!(receiveForm.values.coin && receiveForm.values.network) }
  );

  const { mutate: regenerateAddress } = useChangeCurrencyAddress();

  const handleRegenerate = () => {
    regenerateAddress({
      coin: receiveForm.values.coin,
      network: receiveForm.values.network,
    });
  };

  const handleBlur = (field) => () => {
    if (field === 'requestAmount') {
      if (receiveForm.values.requestAmount)
        receiveForm.setFieldTouched(field, true);
    } else if (field !== 'coin') receiveForm.setFieldTouched(field, true);
    // if (field !== 'coin') receiveForm.validateField(field);
  };

  return (
    <form
      className={styles.container}
      onSubmit={receiveForm.handleSubmit}
    >
      <CurrencyPicker
        label={LL.exchange.depositCurrency()}
        defaultText={LL.exchange.chooseCurrency()}
        selectedKey={receiveForm.values.coin}
        onSelectionChange={async (key) => {
          receiveForm.setUrl('');
          receiveForm.resetForm();
          const network = await getInitialSelectedKey(key + '');
          receiveForm.setFieldValue('coin', key, false);
          receiveForm.setFieldValue('network', network.network_name, false);
        }}
        onBlur={handleBlur('coin')}
        validationState={getValidationState(receiveForm, 'coin')}
        errorMessage={receiveForm.errors.coin}
      />

      <NetworkPicker
        allowSingleItemSelect
        label={LL.exchange.networkStandard()}
        coin={receiveForm.values.coin}
        selectedKey={receiveForm.values.network}
        onSelectionChange={(value) =>
          receiveForm.setFieldValue('network', value, false)
        }
        validationState={getValidationState(receiveForm, 'network')}
        errorMessage={receiveForm.errors.network}
        isDisabled={!receiveForm.values.coin}
      />
      {fiatCurrencies.includes(
        // @ts-ignore
        receiveForm.values.coin
      ) && (
        <>
          <CurrencyField
            currency={receiveForm.values.coin}
            label={LL.exchange.amountLabel({
              currency: receiveForm.values.coin,
            })}
            onChange={(val) =>
              receiveForm.setFieldValue('requestAmount', val, false)
            }
            value={receiveForm.values.requestAmount}
            onBlur={handleBlur('requestAmount')}
            validationState={getValidationState(receiveForm, 'requestAmount')}
            errorMessage={receiveForm.errors.requestAmount}
            isDisabled={!receiveForm.values.coin}
          />
          {receiveForm.url ? (
            <a
              href={receiveForm.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.depositButton}
            >
              <FilledButton
                href={receiveForm.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {LL.exchange.deposit.finalizeDeposit()}
              </FilledButton>
            </a>
          ) : (
            <OutlinedButton
              attachesBackground
              pressesBackground
              onPress={() => receiveForm.handleSubmit()}
            >
              {LL.exchange.deposit.validateDeposit()}
            </OutlinedButton>
          )}
        </>
      )}
      {currencyAddress.data && (
        <div className={styles.accordions}>
          <Accordion>
            <AccordionItem summary={LL.exchange.depositAddress()}>
              <div className={styles.shareAccordion}>
                <QRCodeCanvas
                  value={currencyAddress.data.address}
                  includeMargin
                  bgColor={'transparent'}
                  fgColor={colorOnSurface}
                  size={200}
                  aria-label={LL.exchange.deposit.qrCodeLabel()}
                ></QRCodeCanvas>
                <div className={styles.shareFields}>
                  <TextField
                    label={LL.exchange.destinationAddress()}
                    isReadOnly
                    inputElementType="textarea"
                    value={currencyAddress.data.address}
                    rightButton={
                      <>
                        <CopyToClipboard text={currencyAddress.data.address}>
                          <FieldIconButton
                            aria-label={LL.exchange.copyDestinationAddress()}
                          >
                            <RiClipboardLine />
                          </FieldIconButton>
                        </CopyToClipboard>
                        <FieldIconButton
                          aria-label={LL.exchange.regenerate()}
                          onPress={handleRegenerate}
                        >
                          <RiRefreshLine />
                        </FieldIconButton>
                      </>
                    }
                  />
                  {currencyAddress.data.dest_tag && (
                    <TextField
                      label={LL.exchange.destinationTag()}
                      value={currencyAddress.data.dest_tag}
                      isReadOnly
                      rightButton={
                        <CopyToClipboard text={currencyAddress.data.dest_tag}>
                          <FieldIconButton
                            aria-label={LL.exchange.copyDestinationTag()}
                          >
                            <RiClipboardLine />
                          </FieldIconButton>
                        </CopyToClipboard>
                      }
                    />
                  )}
                </div>
              </div>
            </AccordionItem>
            <AccordionItem
              summary={LL.exchange.paymentLink()}
              onToggle={() => {
                receiveForm.resetForm({
                  values: {
                    ...receiveForm.values,
                    requestAmount: receiveForm.initialValues.requestAmount,
                  },
                });
              }}
            >
              <div className={styles.requestFields}>
                <ErrorMap
                  errors={new Map([[ConversionError, <ConversionErrorView />]])}
                >
                  <ConversionGroup
                    // Value can change from useExchangeContext().navigate("deposit", {amount:123}) so it needs to be set here
                    value={receiveForm.values.requestAmount}
                    onChange={(val) =>
                      receiveForm.setFieldValue('requestAmount', val, false)
                    }
                    currency={receiveForm.values.coin}
                  >
                    <fieldset className={styles.amount}>
                      <CurrencyField
                        currency={receiveForm.values.coin}
                        label={LL.exchange.amountLabel({
                          currency: receiveForm.values.coin,
                        })}
                        onBlur={handleBlur('requestAmount')}
                        validationState={getValidationState(
                          receiveForm,
                          'requestAmount'
                        )}
                        errorMessage={receiveForm.errors.requestAmount}
                      />
                      <CurrencyField
                        label={LL.exchange.conversionAmountLabel({
                          currency: conversionCurrency,
                        })}
                        currency={conversionCurrency}
                        onBlur={handleBlur('requestAmount')}
                        validationState={getValidationState(
                          receiveForm,
                          'requestAmount'
                        )}
                      />
                    </fieldset>
                  </ConversionGroup>
                  <div className={styles.createLink}>
                    <OutlinedButton
                      attachesBackground
                      pressesBackground
                      type="submit"
                    >
                      {LL.exchange.deposit.shareableLink()}
                    </OutlinedButton>
                  </div>
                </ErrorMap>
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      )}
    </form>
  );
}
