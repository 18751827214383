import React, { useState } from 'react';
import styles from './Outputs.module.css';

import CurrencyColors from 'assets/data/currency-colors';
import OutlinedButton from 'common/buttons/OutlinedButton';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import ExplorerCopy from 'components/copy-to-clipboard/ExplorerCopy.component';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';

const eths = [
  'busd',
  'chz',
  'enj',
  'link',
  'mkr',
  'omg',
  'reef',
  'snx',
  'uni',
  'usdc',
  'usdt',
  'vet',
  'zrx',
  'eth',
  'trx',
  'bnb',
  'shib',
];

const Outputs = ({ data, coin, txid }) => {
  const [showAll, setShowAll] = useState(false);
  const { LL } = useLocale();

  const { colorTest7 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest7,
  };

  return (
    <TranslucentSurface
      elementType={'article'}
      className={styles.outputs}
      surfaceColor={CurrencyColors[coin]}
    >
      {txid.startsWith('PBXINTRA') ? (
        <>
          <div className={styles.outputs_user}>
            <span className={styles.outputs_user__address}>
              {LL.explorer.data.paybitxUser()}: {data.to}
              <ExplorerCopy
                margin={true}
                text={data.to}
              />
            </span>
          </div>
        </>
      ) : data.intra || eths.includes(coin) || coin === 'btt' ? (
        <div className={styles.outputs_user}>
          <span className={styles.outputs_user__address}>
            {data.to}
            <ExplorerCopy
              margin={true}
              text={data.to}
            />
          </span>
        </div>
      ) : (
        data.vout &&
        data.vout.slice(0, 5).map((vout) => {
          return (
            <div
              className={styles.outputs_user}
              key={vout.scriptPubKey?.hex}
            >
              <span className={styles.outputs_user__address}>
                {vout.scriptPubKey?.address || vout.scriptPubKey?.addresses
                  ? vout.scriptPubKey.address || vout.scriptPubKey.addresses[0]
                  : vout.scriptPubKey.asm.startsWith('OP_RETURN')
                  ? 'OP_RETURN'
                  : 'Not Found'}
                <ExplorerCopy
                  margin={true}
                  text={
                    vout.scriptPubKey?.address || vout.scriptPubKey?.addresses
                      ? vout.scriptPubKey.address ||
                        vout.scriptPubKey.addresses[0]
                      : vout.scriptPubKey.asm.startsWith('OP_RETURN')
                      ? 'OP_RETURN'
                      : 'Not Found'
                  }
                />
              </span>
              <span className="bold">
                {vout.value} {coin}
              </span>
            </div>
          );
        })
      )}

      {showAll &&
        data.vout &&
        data.vout.slice(5).map((vout) => (
          <div
            className={styles.outputs_user}
            key={vout.scriptPubKey?.hex}
          >
            <span className={styles.outputs_user__address}>
              {vout.scriptPubKey?.address || vout.scriptPubKey?.addresses
                ? vout.scriptPubKey.address || vout.scriptPubKey.addresses[0]
                : vout.scriptPubKey.asm.startsWith('OP_RETURN')
                ? 'OP_RETURN'
                : 'Not Found'}
              <ExplorerCopy
                margin={true}
                text={
                  vout.scriptPubKey?.address || vout.scriptPubKey?.addresses
                    ? vout.scriptPubKey.address ||
                      vout.scriptPubKey.addresses[0]
                    : vout.scriptPubKey.asm.startsWith('OP_RETURN')
                    ? 'OP_RETURN'
                    : 'Not Found'
                }
              />
            </span>
            <span className="bold">
              {vout.value} {coin}
            </span>
          </div>
        ))}

      {data.vout && data.vout.length > 5 && (
        <OutlinedButton
          onPress={() => setShowAll((prevState) => !prevState)}
          // styles={{ backgroundColor: '#ff9800', width: '250px' }}
        >
          {showAll ? LL.explorer.showLess() : LL.explorer.showMore()}
        </OutlinedButton>
      )}
    </TranslucentSurface>
  );
};

export default Outputs;
