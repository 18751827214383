import { Item } from '@react-stately/collections';
import currencies from 'assets/currencies';
import CurrencyColors from 'assets/data/currency-colors';
import axios from 'axios';
import big from 'bigjs-literal/macro';
import FilledButton from 'common/buttons/FilledButton';
import IconButton from 'common/buttons/IconButton';
import OutlinedButton from 'common/buttons/OutlinedButton';
import Select from 'common/pickers/Select.component';
import Surface from 'common/surfaces/Surface.component';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import Currency from 'components/content/Currency.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { CurrencyFieldPicker } from 'components/currency-fields/CurrencyFieldPicker.component';
import BufferingWheel from 'components/loader/LoadingAnimation.component';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useLocale } from 'context/LanguageContext';
import { useFormik } from 'formik';
import useThemeVariables from 'hooks/useThemeVariables';
import InvoicePage from 'pages/invoice-page/invoice.page';
import qs from 'qs';
import { useBalancesQuery } from 'queries/balances';
import { usePayableInvoicesQuery } from 'queries/payableInvoices';
import React, { useEffect, useState } from 'react';
import { H } from 'react-accessible-headings';
import { RiCloseLine } from 'react-icons/ri';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { date, number, object, string } from 'yup';
import styles from './InvoicePayment.module.css';

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

function useFormError(errorMap, resetDeps = []) {
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    serverError: errorMap[errorKey],
    setServerError: setErrorKey,
  };
}

async function updateScannedInvoices(id) {
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URI}invoices/potential-payer?id=${id}`,
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: localStorage.getItem('token'),
    },
  });
}

function InvoicePayment({ navigateInvoiceId, invoice }) {
  const balance = useBalancesQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const invoiceData = invoice;
  const [invoiceId, setInvoiceId] = useState(
    searchParams.get('id')
      ? searchParams.get('id')
      : navigateInvoiceId
      ? navigateInvoiceId
      : null
  );
  const { data, error, refetch } = usePayableInvoicesQuery({ enabled: false });
  const [successfulChange, setSuccessfulChange] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [paymentTotals, setPaymentTotals] = React.useState({});
  const [amount, setAmount] = React.useState(NaN);
  const [currency, setCurrency] = React.useState(null);
  const [fees, setFees] = React.useState(null);
  const { conversionCurrency } = useCurrencyAndThemeContext();
  const { LL } = useLocale();
  const statusRef = React.useRef(null);

  React.useEffect(() => {
    refetch();
    if (
      searchParams.get('id') &&
      data &&
      !data?.invoices
        .map((el) => el.id)
        .includes(Number(searchParams.get('id')))
    ) {
      updateScannedInvoices(searchParams.get('id'));
      setTimeout(refetch, 401);
    }
  }, [data, searchParams]);

  React.useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}exchange/fees`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    }).then((value) => setFees(value.data));
  }, []);

  const { colorTest4 } = useThemeVariables();
  const surfaceColor = {
    background: colorTest4,
  };

  function softReset() {
    setAmount(NaN);
    setPaymentTotals({});
  }

  function handleReset() {
    if (searchParams.get('id') && searchParams.get('tab')) {
      searchParams.delete('id');
      searchParams.delete('tab');
      setSearchParams(searchParams);
    }
    setCurrency(invoiceData?.currency ? invoiceData.currency : null);
    setAmount(NaN);
    setInvoiceId(null);
    setPaymentTotals({});
    formik.resetForm();
  }

  function NetAmount() {
    if (formik.values.currency && formik.values.amount) {
      const from = formik.values.currency;
      const to = formik.values.invoiceCurrency;
      const amount = formik.values.amount;
      const exchangeRate = Number(
        +big`${invoiceData.historic_rates[from]} / ${invoiceData.historic_rates[to]}`
      );
      const fee =
        from === to
          ? +big`0`
          : +big`(${amount} * (${fees[from]} + ${fees[to]})) / 100`;
      const afterFee = +big`${amount} - ${fee}`;

      if (to === 'btt' || to === 'shib') {
        const totalBeforeFee = Number(
          (+big`${amount} * ${exchangeRate}`).toFixed(0)
        );
        const totalFee = Number((+big`${fee} * ${exchangeRate}`).toFixed(0));
        const netAmount = Number(
          (+big`${afterFee} * ${exchangeRate}`).toFixed(0)
        );

        return { totalBeforeFee, totalFee, netAmount };
      }

      const totalBeforeFee = +big`${amount} * ${exchangeRate}`;
      const totalFee = +big`${fee} * ${exchangeRate}`;
      const netAmount = +big`${afterFee} * ${exchangeRate}`;

      return { totalBeforeFee, totalFee, netAmount };
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      invoiceId: invoiceId,
      currency: /** @type {import('currencies').Currency | null} */ (currency),
      amount: amount,
      invoiceCurrency: /** @type {import('currencies').Currency | null} */ (
        invoiceData.currency ? invoiceData.currency : null
      ),
      invoiceUpdatedAt: invoiceData.updatedAt ? invoiceData.updatedAt : null,
      // @ts-ignore
      netAmount: paymentTotals ? paymentTotals.netAmount?.toFixed(5) : NaN,
    },
    validationSchema: object({
      invoiceId: string().required(),
      currency: string()
        .nullable()
        .required(LL.exchange.errors.chooseBalance()),
      invoiceCurrency: string()
        .nullable()
        .required(LL.exchange.errors.chooseBalance()),
      amount: number()
        .test({
          message: LL.exchange.serverErrors.funds(),
          test: (amount, { parent }) =>
            amount <
            balance?.data?.filter((el) => el.coin === parent.currency)[0]
              .confirmed_balance,
        })
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(LL.exchange.errors.amount())
        .positive(LL.exchange.errors.amountPositive()),
      invoiceUpdatedAt: date().required(),
      netAmount: number()
        .min(Number(invoiceData.amount_invoiced), 'Must equal invoiced amount')
        .max(Number(invoiceData.amount_invoiced), 'Must equal invoiced amount')
        .required(),
    }),
    onSubmit: async (values, { resetForm, setTouched }) => {
      setIsLoading(true);
      let refetchInvoiceId = values.invoiceId;
      const data = {
        currency: values.currency,
        amount: values.amount,
        invoiceId: values.invoiceId,
        invoiceUpdatedAt: values.invoiceUpdatedAt,
      };
      try {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URI}invoice-payments/pay`,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: localStorage.getItem('token'),
          },
          data: qs.stringify(data),
        });

        setServerError(null);
        setSuccessfulChange(true);
        setTimeout(() => {
          setSuccessfulChange(false);
        }, 5000);
        setTimeout(() => refetch(), 301);
      } catch (error) {
        setTouched({});
        console.log(error);
        if (error.response && errorMap[error.response.data.error]) {
          console.log('Error: ', error.response.data.error);

          setServerError(error.response.data.error);
        } else {
          console.warn('P2PInvoice unexpected error: ', error);
          setServerError('ERR_GENERIC');
        }
      }
      handleReset();
      setIsLoading(false);
    },
  });

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  const errorMap = {
    ERR_GENERIC: LL.profile.serverErrors.genericShort(),
    ERR_NO_INVOICE_ID: LL.profile.serverErrors.invalidCredentials(),
    ERR_INVOICE_DOES_NOT_EXIST: 'Invoice you are trying to pay does not exist',
    ERR_INVOICE_ID_MISMATCH: 'Invoice id mismatch, please reselect the invoice',
    ERR_INVOICE_RATES_MISMATCH:
      'Invoice rates have been updated, please refresh the page and retry payment',
    ERR_INVOICE_ALREADY_PAID: 'This invoice has already been paid',
    ERR_INVOICE_EXPIRED: 'Invoice has expired',
    ERR_NO_PAYMENT: 'Please select a currency and press "Pay" button first',
    ERR_OWN_INVOICE: 'Cannot pay for your own invoice',
  };

  const { serverError, setServerError } = useFormError(errorMap, []);

  useEffect(() => {
    if (invoiceData?.currency) {
      setCurrency(invoiceData.currency);
    }
  }, [invoiceData]);

  useEffect(() => {
    if (formik.values.amount) {
      setPaymentTotals(NetAmount());
    }
    if (!formik.values.amount) {
      setPaymentTotals({});
    }
  }, [formik.values.amount]);

  if (error)
    return (
      <div>
        <ErrorContainer>Unexpected error please try again later</ErrorContainer>
      </div>
    );

  if (!data || !data.invoices)
    return <BufferingWheel color="var(--color-logo)"></BufferingWheel>;

  return (
    <div className={styles.container}>
      <div ref={statusRef}>
        <ErrorContainer>
          {serverError ? (
            <InlineError
              onClose={() => setServerError(null)}
              success={false}
            >
              {serverError}
            </InlineError>
          ) : (
            successfulChange && (
              <InlineError
                onClose={() => setSuccessfulChange(false)}
                success={true}
              >
                {LL.merchant.invoicePayment.success()}
              </InlineError>
            )
          )}
        </ErrorContainer>
      </div>
      {isLoading && (
        <>
          <BufferingWheel
            color={CurrencyColors[invoiceData.currency].background}
          ></BufferingWheel>
        </>
      )}
      <Select
        items={data.invoices}
        label={'List of payable invoices'}
        onSelectionChange={(key) => {
          handleReset();
          setInvoiceId(key);
        }}
        defaultSelectedKey={invoiceId && invoiceId.toString()}
      >
        {data.invoices.map((invoice) => (
          <Item key={invoice.id}>
            {currencies[invoice.currency] ? (
              <img
                src={currencies[invoice.currency]}
                alt=""
                data-slot="icon"
              />
            ) : (
              <React.Fragment data-slot="icon" />
            )}
            {invoice.email} {invoice.amount_invoiced}{' '}
            {invoice.currency.toLocaleUpperCase()} /{' '}
            <Currency currency={conversionCurrency}>
              {invoice.amount_invoiced_fiat[conversionCurrency]}
            </Currency>
          </Item>
        ))}
      </Select>
      {Object.keys(invoiceData).length ? (
        <Surface
          elementType="form"
          onSubmit={formik.handleSubmit}
          surfaceColor={surfaceColor}
          className={styles.paymentInfoContainer}
        >
          {balance && invoiceData?.historic_rates && fees && (
            <div className={styles.currencyField}>
              <CurrencyFieldPicker
                label={'Currencies, with enough balance to pay for the invoice'}
                selectedKey={
                  currency ? currency : formik.values.invoiceCurrency
                }
                onSelectionChange={(key) => {
                  setAmount(NaN);
                  setCurrency(key);
                }}
                balance={balance}
                invoiceData={invoiceData}
                fees={fees}
              />
              <FilledButton
                onPress={() => {
                  invoiceData &&
                    fees &&
                    setAmount(
                      fillRemaining(
                        formik.values.currency,
                        formik.values.invoiceCurrency,
                        invoiceData,
                        fees
                      )
                    );
                }}
                isDisabled={
                  !formik.values.currency &&
                  !formik.values.invoiceCurrency &&
                  !formik.values.amount
                }
              >
                Pay
              </FilledButton>
            </div>
          )}

          <div className={styles.currencyEntries}>
            {formik.values.currency && formik.values.amount ? (
              <>
                <H className={styles.paymentHeader}>Payment Preview</H>
                <TranslucentSurface
                  surfaceColor={CurrencyColors[formik.values?.currency]}
                  className={styles.currencyEntry}
                >
                  <Currency currency={formik.values?.currency}>
                    {formik.values?.amount}
                  </Currency>
                  <IconButton
                    aria-label="remove"
                    onPress={() => softReset()}
                  >
                    <RiCloseLine />
                  </IconButton>
                </TranslucentSurface>
              </>
            ) : (
              ''
            )}
          </div>
          <div className={styles.paymentPreview}>
            {formik.values.amount &&
            formik.values.currency &&
            paymentTotals &&
            fees
              ? Object.entries(paymentTotals).map((a) => {
                  return (
                    <span>
                      {LL.merchant.invoicePayment[a[0]]()}
                      <span
                        style={
                          a[0] === 'netAmount'
                            ? {
                                color: 'var(--color-success)',
                              }
                            : {}
                        }
                      >
                        <Currency currency={formik.values.invoiceCurrency}>
                          {a[1]}
                        </Currency>
                      </span>
                    </span>
                  );
                })
              : ''}
          </div>
          <div className={styles.submitForm}>
            <OutlinedButton onPress={() => handleReset()}>
              Reset form
            </OutlinedButton>

            <FilledButton
              type="submit"
              onPress={() => {
                !formik.values.amount && setServerError('ERR_NO_PAYMENT');
                scrollToStatus(statusRef);
              }}
            >
              Confirm payment
            </FilledButton>
          </div>
        </Surface>
      ) : (
        ''
      )}
      {invoiceId && <InvoicePage invoiceId={invoiceId} />}
    </div>
  );
}

function fillRemaining(currency, invoiceCurrency, invoiceData, fees) {
  const exchangeRate =
    +big`${invoiceData.historic_rates[invoiceCurrency]} / ${invoiceData.historic_rates[currency]}`;
  let result;

  if (currency === invoiceCurrency) {
    result = Number(invoiceData.amount_invoiced);
  } else {
    result = +big`(((${invoiceData.amount_invoiced})/(100 - (${
      fees[currency]
    } + ${fees[invoiceData.currency]}))) * 100) * ${exchangeRate}`;
  }

  return result;
}

function scrollToStatus(statusRef) {
  statusRef.current.scrollIntoView({
    behavior: 'smooth',
    block: 'end',
    inline: 'nearest',
  });
  statusRef.current.focus({ preventScroll: true });
}

const mapStateToProps = (state) => ({
  invoice: state.invoice.invoice,
});

export default connect(mapStateToProps)(InvoicePayment);
