import ButtonBase from 'common/buttons/ButtonBase';
import { useLocale } from 'context/LanguageContext';
import * as React from 'react';
import { RiErrorWarningFill, RiRefreshLine } from 'react-icons/ri';
import styles from './CorruptedField.module.css';

/**
 * @typedef CorruptedFieldProps
 * @property {()=> void} [onRetry]
 * @property {React.ReactNode} [label]
 * @property {React.ReactNode} [description]
 */

/**
 * A component in a style of a field to replace a field when error occurs.
 * @param {CorruptedFieldProps} props
 */
export function CorruptedField({ onRetry, label, description }) {
  const { LL } = useLocale();
  return (
    <div className={styles.container}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.input}>
        <RiErrorWarningFill />
        {onRetry && (
          <ButtonBase
            classNames={{
              button: styles.retryButton,
              focusRing: styles.retryFocusRing,
            }}
            onPress={onRetry}
            // whileTap doesn't work for some reason
          >
            {LL.app.retry()}
            <RiRefreshLine />
          </ButtonBase>
        )}
      </div>

      <div className={styles.description}>{description}</div>
    </div>
  );
}
