// @ts-check
import { useNumberField } from '@react-aria/numberfield';
import { useNumberFieldState } from '@react-stately/numberfield';
import { useLocale } from 'context/LanguageContext';
import * as React from 'react';
import styles from './DefaultField.module.css';
import FieldBase from './FieldBase';
import FieldWrapper from './FieldWrapper';

/**
 * @typedef {NumberFieldLocalProps & WrapperProps} NumberFieldProps
 */

/**
 * @typedef {Omit<React.ComponentProps<FieldWrapper>, "classNames" | "children">} WrapperProps
 */

/**
 * @typedef NumberFieldLocalProps
 * @property {Intl.NumberFormatOptions} [formatOptions]
 * 👆 Formatting options for the value displayed in the number field.
 *    This also affects what characters are allowed to be typed by the user.
 *    See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @property {number} [step]
 * 👆 The amount that the input value changes with each increment or decrement "tick".
 * @property {string} [id]
 * @property {number} [value]
 * @property {number} [defaultValue]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {string} [placeholder]
 * @property {React.ReactNode} [label]
 * @property {"valid" | "invalid" | (undefined | null)} [validationState]
 * @property {number} [maxValue]
 * 👆 The largest value allowed for the input.
 * @property {number} [minValue]
 * 👆 The smallest value allowed for the input.
 * @property {boolean} [isReadOnly]
 * @property {React.ReactNode} [description]
 * A description for the field. Provides a hint such as specific requirements for what to choose.
 * @property {React.ReactNode} [errorMessage]
 * @property {'text' | 'search' | 'url' | 'tel' | 'email' | 'password'} [type]
 * @property {'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'} [inputMode]
 * 👆 Hints at the type of data that might be entered by the user while editing the element or its contents. See MDN.
 * @property {(value: number) => void} [onChange]
 * 👆 Fired after user moves focus away from input, but before blur event
 * @property {React.FormEventHandler<HTMLInputElement>} [onBeforeInput]
 * 👆 Handler that is called when the input value is about to be modified.
 *    See [MDN](https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/beforeinput_event).
 * @property {React.FormEventHandler<HTMLInputElement>} [onInput]
 * 👆 Handler that is called when the input value is modified.
 *    See [MDN](https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/input_event).
 * @property {(e: React.FocusEvent) => void} [onFocus]
 * @property {(e: React.FocusEvent) => void} [onBlur]
 * @property {(isFocused: boolean) => void} [onFocusChange]
 * @property {(e: React.KeyboardEvent) => void} [onKeyDown]
 * @property {(e: React.KeyboardEvent) => void} [onKeyUp]
 * @property {boolean} [autoFocus]
 * @property {string} [autocomplete]
 * @property {boolean} [excludeFromTabOrder]
 * 👆 Whether to exclude the element from the sequential tab order.
 *    If true, the element will not be focusable via the keyboard by tabbing.
 *    This should be avoided except in rare scenarios where an alternative means of
 *    accessing the element or its functionality via the keyboard is available.
 * @property {string} [aria-activedescendant]
 * 👆 Identifies the currently active element when DOM focus is on a composite widget, textbox, grou
 * @property {'none' | 'inline'| 'list'| 'both'} [aria-autocomplete]
 * 👆 Indicates whether inputting text could trigger display of one or more
 *    predictions of the user's intended value for an input and specifies how predictions would be p
 * @property {boolean | 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'} [aria-haspopup]
 * 👆 indicates the availability and type of interactive popup element,
 *    such as menu or dialog, that can be triggered by an element.
 * @property {string} [aria-label]
 * 👆 Defines a string value that labels the current element.
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 */

/** @param {NumberFieldProps} props */
function NumberField({
  leftButton,
  leftDecoration,
  rightDecoration,
  rightButton,

  ...props
}) {
  const ref = React.useRef();
  const { trueLocale, LL } = useLocale();
  const state = useNumberFieldState({ ...props, locale: trueLocale });

  // not implementing increase & decrese buttons. I don't think we need it
  const { inputProps, labelProps, descriptionProps, errorMessageProps } =
    useNumberField(
      {
        ...props,
        // TODO: A hack until this resolves:
        // https://github.com/adobe/react-spectrum/issues/3206
        onBlur:
          props.onBlur &&
          ((e) => {
            e.persist();
            setTimeout(() => props.onBlur(e));
          }),

        incrementAriaLabel: LL.app.increment(),
        decrementAriaLabel: LL.app.decrement(),
      },
      state,
      ref
    );

  return (
    <FieldWrapper
      leftButton={leftButton}
      leftDecoration={leftDecoration}
      rightDecoration={rightDecoration}
      rightButton={rightButton}
      validationState={props.validationState}
      isDisabled={props.isDisabled}
      classNames={styles}
    >
      <FieldBase
        inputElementType="input"
        ref={ref}
        classNames={styles}
        validationState={props.validationState}
        isDisabled={props.isDisabled}
        //
        label={props.label}
        description={props.description}
        errorMessage={props.errorMessage}
        //
        inputProps={inputProps}
        labelProps={labelProps}
        descriptionProps={descriptionProps}
        errorMessageProps={errorMessageProps}
      />
    </FieldWrapper>
  );
}

export default NumberField;
