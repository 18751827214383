import { ExplorerPovider } from 'context/ExplorerContext';
import React from 'react';
import styles from './sign-up-page.module.css';
import SignUp from './sign-up.component';

const SignUpPage = () => {
  return (
    <div className={styles.signupPage}>
      <ExplorerPovider>
        <SignUp />
      </ExplorerPovider>
    </div>
  );
};

export default SignUpPage;
