//@ts-check
import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import LocationPicker from 'components/location-fields/LocationPicker.component';
import { useLocale } from 'context/LanguageContext';
import { useFormik } from 'formik';
import useThemeVariables from 'hooks/useThemeVariables';
import qs from 'qs';
import { usePhoneCodesQuery } from 'queries/phoneCodes';
import { useUserCredentialsQuery } from 'queries/userCredentials';
import React, { useState } from 'react';
import { H } from 'react-accessible-headings';
import { object, string } from 'yup';
import styles from './PhoneNumberChange.module.css';

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

function useFormError(errorMap, resetDeps = []) {
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    formError: errorMap[errorKey],
    setFormError: setErrorKey,
  };
}

function PhoneNumberChange() {
  const { LL } = useLocale();
  const [successfulChange, setSuccessfulChange] = useState(false);
  const [phoneCodeArray, setPhoneCodeArray] = useState(null);
  const [showPhoneVerificationForm, setShowPhoneVerificationForm] =
    useState(false);
  const [displayPhoneNumber, setDisplayPhoneNumber] = useState(null);

  const { colorTest4 } = useThemeVariables();
  const { data: user } = useUserCredentialsQuery();

  const surfaceColor = {
    background: colorTest4,
  };

  const { data: phoneCodes } = usePhoneCodesQuery({
    onSuccess: () => {
      phoneCodes && setPhoneCodeArray(phoneCodes.map((el) => el.phone_code));
    },
  });

  async function generatePin() {
    try {
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}users/pin/generate-existing`,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: localStorage.getItem('token'),
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phoneCodeId: '',
      phoneNumber: '',
      pin: '',
    },
    validationSchema: object(
      !showPhoneVerificationForm
        ? {
            phoneCodeId: string().required('Required'),
            phoneNumber: string().required('Required'),
          }
        : {
            pin: string()
              .length(6, 'must contain exactly 6 digits')
              .required('Required'),
          }
    ),
    onSubmit: async (values, { resetForm, setTouched }) => {
      let data = {};
      !showPhoneVerificationForm
        ? (data = {
            phoneCodeId: values.phoneCodeId.substring(
              0,
              values.phoneCodeId.toString().indexOf('+')
            ),
            phoneNumber: values.phoneNumber.replace(/\s/g, ''),
          })
        : (data = { pin: values.pin.replace(/\s/g, '') });
      try {
        if (!showPhoneVerificationForm) {
          const res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URI}users/phone-number/update`,
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              Authorization: localStorage.getItem('token'),
            },
            data: qs.stringify(data),
          });

          setShowPhoneVerificationForm(true);
          setDisplayPhoneNumber(
            values.phoneCodeId.substring(
              values.phoneCodeId.toString().indexOf('+'),
              values.phoneCodeId.toString().length
            ) + values.phoneNumber
          );
        } else {
          const res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URI}users/pin/verify-existing`,
            data: qs.stringify(data),
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              Authorization: localStorage.getItem('token'),
            },
          });

          setFormError(null);
          setSuccessfulChange(true);
          setTimeout(() => {
            setSuccessfulChange(false);
          }, 5000);
        }
      } catch (error) {
        setTouched({});
        console.log(error);
        if (error.response && errorMap[error.response.data.error]) {
          console.log('Error: ', error.response.data.error);

          setFormError(error.response.data.error);
        } else {
          console.warn('PIN_Verification unexpected error: ', error);
          setFormError('ERR_GENERIC');
        }
      }
      resetForm();
    },
  });

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  const errorMap = {
    ERR_GENERIC: LL.profile.serverErrors.genericShort(),
    ERR_USER_ALREADY_EXISTS: LL.profile.serverErrors.emailExists(),
    ERR_PHONE_NUMBER_ALREADY_REGISTERED:
      LL.profile.serverErrors.phoneNumberExists(),
    ERR_PHONE_UNVERIFIED: LL.profile.serverErrors.phoneUnverified(),
    ERR_PIN_EXPIRED: LL.profile.serverErrors.pinExpired(),
  };

  const { formError, setFormError } = useFormError(errorMap, []);

  return (
    <Surface
      elementType="form"
      onSubmit={formik.handleSubmit}
      surfaceColor={surfaceColor}
      className={styles.container}
    >
      <div className={styles.formBody}>
        <H className={styles.heading}>{LL.profile.changeNumber.header()}</H>
        <ErrorContainer>
          {formError ? (
            <InlineError
              onClose={() => setFormError(null)}
              success={false}
            >
              {formError}
            </InlineError>
          ) : (
            successfulChange && (
              <InlineError
                onClose={() => setSuccessfulChange(false)}
                success={true}
              >
                {LL.profile.changeNumber.success()}
              </InlineError>
            )
          )}
        </ErrorContainer>
        <H className={styles.subHeading}>
          {LL.profile.changeNumber.currentNumber()}
        </H>
        <div style={{ color: 'var(--color-logo)' }}>
          {user && user.phoneNumber
            ? user.phoneNumber
            : LL.profile.changeNumber.notSet()}
        </div>
        <H className={styles.subHeading}>
          {LL.profile.changeNumber.newNumber()}
        </H>
        <div className={styles.input}>
          <LocationPicker
            label={LL.profile.changeNumber.phoneCode()}
            placeholder={LL.profile.changeNumber.code()}
            locations={!!phoneCodes && phoneCodes}
            selectedKey={formik.values.phoneCodeId}
            onSelectionChange={(key) => {
              formik.setFieldValue('phoneCodeId', key, false);
            }}
            onBlur={handleBlur('phoneCodeId')}
            validationState={getValidationState(formik, 'phoneCodeId')}
            errorMessage={formik.errors.phoneCodeId}
          />

          <TextField
            label={`\u00A0${LL.profile.changeNumber.phoneNumber()}`}
            placeholder=""
            value={formik.values.phoneNumber}
            onChange={(val) => formik.setFieldValue('phoneNumber', val, false)}
            onBlur={handleBlur('phoneNumber')}
            validationState={getValidationState(formik, 'phoneNumber')}
            errorMessage={formik.errors.phoneNumber}
          />
        </div>
      </div>
      {showPhoneVerificationForm && (
        <>
          <H className={styles.subHeading}>
            {LL.profile.changeNumber.verifyNotification()}
            <br style={{ marginBottom: '1rem' }}></br>
            {LL.profile.changeNumber.sentNotification()}{' '}
            <span style={{ color: 'var(--color-logo)' }}>
              {displayPhoneNumber}
            </span>
            .
          </H>

          <div className={styles.pinNumberChange}>
            <TextField
              value={formik.values.pin}
              onChange={(val) => {
                formik.setFieldValue('pin', val, false);
              }}
              onBlur={handleBlur('pin')}
              validationState={getValidationState(formik, 'pin')}
              errorMessage={formik.errors.pin}
            />
            <FilledButton
              className={styles.resendButton}
              onPress={() => generatePin()}
            >
              {LL.profile.changeNumber.resend()}
            </FilledButton>
          </div>
          <div className={styles.verifyButtonSignUp}>
            <FilledButton
              attachesBackground
              pressesBackground
              type="submit"
              isDisabled={formik.isSubmitting}
            >
              {LL.profile.changeNumber.verifyPIN()}
            </FilledButton>
          </div>
        </>
      )}
      <div className={styles.formSubmit}>
        <OutlineButton
          attachesBackground
          pressesBackground
          type="reset"
          onPress={formik.handleReset}
        >
          {LL.profile.resetFields()}
        </OutlineButton>
        <FilledButton
          attachesBackground
          pressesBackground
          type="submit"
          isDisabled={
            !formik.values.phoneCodeId ||
            !formik.values.phoneNumber ||
            formik.isSubmitting
          }
        >
          {LL.profile.confirmChange()}
        </FilledButton>
      </div>{' '}
    </Surface>
  );
}

export default PhoneNumberChange;
