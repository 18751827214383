// @ts-check

import { AnimatePresence, Expand } from 'common/animations/Expand.component';
import IconButton from 'common/buttons/IconButton';
import Surface from 'common/surfaces/Surface.component';
import * as React from 'react';
import { VisuallyHidden } from 'react-aria';
import { BsCheckCircleFill } from 'react-icons/bs';
import { RiCloseLine, RiErrorWarningFill } from 'react-icons/ri';
import styles from './InlineError.module.css';

/**
 *
 * @typedef ErrorContainerProps
 * @property {React.ReactNode} children
 * @property {React.ReactNode} [title]
 * @property {boolean} [showTitle]
 *
 * @param {ErrorContainerProps} props
 */
export function ErrorContainer({ children, title, showTitle = false }) {
  if (!title) {
    title = React.Children.count(children) > 1 ? 'Errors' : 'Error';
  }

  const titleNode = <p className={styles.title}>{title}</p>;

  return (
    <div
      style={{ display: 'contents' }}
      role="alert"
      hidden={React.Children.count(children) > 1}
    >
      {showTitle ? titleNode : <VisuallyHidden>{titleNode}</VisuallyHidden>}

      <ul className={styles.errorList}>
        <AnimatePresence>
          {React.Children.map(children, (child) => (
            <Expand elementType="div">{child}</Expand>
          ))}
        </AnimatePresence>
      </ul>
    </div>
  );
}

/**
 * @typedef InlineErrorProps
 * @property {React.ReactNode} children
 * @property {() => void} onClose
 * @property {boolean} success?
 * @property {boolean} [standAlone]
 * this option should be used if the error is rendered without the container and it should handle accessibility on it's own
 *
 * @param {InlineErrorProps} props
 */
export function InlineError({ children, onClose, success, standAlone }) {
  // TODO: make errors tabbable and add keyboard navigation
  return (
    <div
      className={styles.error}
      aria-live={standAlone ? 'assertive' : 'off'}
    >
      <div
        className={styles.errorIcon}
        style={
          success
            ? { color: 'var(--color-success)' }
            : { color: 'var(--md-ref-palette-error-50)' }
        }
      >
        {success ? <BsCheckCircleFill /> : <RiErrorWarningFill />}
      </div>
      <p
        className={styles.errorMessage}
        style={
          success
            ? { color: 'var(--color-success)' }
            : { color: 'var(--md-ref-palette-error-50)' }
        }
      >
        {children}
      </p>

      {onClose && (
        <Surface className={styles.buttonContainer}>
          <IconButton
            className={styles.button}
            aria-label="close"
            onPress={onClose}
          >
            <RiCloseLine
              style={
                success
                  ? { color: 'var(--color-success)' }
                  : { color: 'var(--md-ref-palette-error-50)' }
              }
            />
          </IconButton>
        </Surface>
      )}
    </div>
  );
}
