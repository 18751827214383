import currencies from 'assets/currencies';
import CurrencyColors from 'assets/data/currency-colors';
import Surface from 'common/surfaces/Surface.component';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import ExplorerCopy from 'components/copy-to-clipboard/ExplorerCopy.component';
import BufferingWheel from 'components/loader/LoadingAnimation.component';
import { useExplorerContext } from 'context/ExplorerContext';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import React, { useState } from 'react';
import { H, Level } from 'react-accessible-headings';
import { HiXMark } from 'react-icons/hi2';
import {
  RiArrowDownSFill,
  RiArrowRightSFill,
  RiCheckLine,
} from 'react-icons/ri';
import { useParams } from 'react-router';
import ErrorImage from '../../assets/error/Ooops.png';
import Description from './Description.component';
import Details from './Details.component';
import styles from './ExplorerData.module.css';
import ExplorerOperations from './ExplorerOperations.component';
import Inputs from './Inputs.component';
import Outputs from './Outputs.component';
import Summary from './Summary.component';

// TODO: a tags without href + use Link instead if appropriate

function ExplorerData() {
  const { coin, network, txid } = useParams();
  const { data, dataFetched, isLargeScreen, serverError, setServerError } =
    useExplorerContext();
  const { LL } = useLocale();

  const { colorTest4 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };

  const [detailsVisible, setDetailsVisible] = useState(true);
  const [inputsVisible, setInputsVisible] = useState(false);
  const [outputsVisible, setOutputsVisible] = useState(false);

  const confirmationStyle = (confirmations, color) =>
    !confirmations
      ? {
          background: `
          linear-gradient(${color} 0%, 5%, 
          ${colorTest4} 45%, 90%, 
          ${color} 99%)`,
        }
      : confirmations < 5
      ? {
          background: `conic-gradient(#2d3132 ${
            confirmations * 1
          }deg,${color} ${confirmations * 72}deg, 
        ${confirmations * 85}grad, ${colorTest4})`,
        }
      : {
          background: `linear-gradient(${colorTest4}, 20%,  ${color})`,
        };

  const intraConfirmationStyle = (status, color) =>
    status === 'pending'
      ? {
          background: `
          linear-gradient(${color} 0%, 2%, 
          ${colorTest4} 45%, 90%, 
          ${color} 99%)`,
        }
      : {
          background: `linear-gradient(white, 20%,  ${color})`,
        };

  const dotsStyle = (color, opacity) => {
    return {
      backgroundColor: `${color}`,
      opacity: `${opacity}`,
    };
  };

  return coin && txid && !dataFetched && !data ? (
    serverError ? (
      serverError && (
        <div className={styles.errorContainer}>
          <img
            src={ErrorImage}
            alt=""
            className={styles.errorImage}
          />
          <span>{serverError}</span>
        </div>
      )
    ) : (
      <div className={styles.errorContainer}>
        <BufferingWheel color={CurrencyColors[coin].background} />
      </div>
    )
  ) : (
    <div className={styles.container}>
      <div className={styles.explorerContainer}>
        {dataFetched &&
          coin &&
          data &&
          (txid.startsWith('pending-') ? (
            <Surface
              baseElevation={0}
              className={styles.sectionPending}
              surfaceColor={surfaceColor}
            >
              <Level>
                <H className={styles.headline}>
                  <img
                    className={styles.icon}
                    src={currencies[coin]}
                    alt=""
                    style={{ height: '5rem', width: '5rem' }}
                  />
                  {LL.currency[coin]()}
                  <br />
                  Paybitx pending transaction
                </H>
                <TranslucentSurface
                  surfaceColor={CurrencyColors[coin]}
                  className={styles.sectionPendingAdditional}
                >
                  <a>
                    Amount:{' '}
                    <span className={styles.sectionPendingContent}>
                      {data.amount} {coin.toLocaleUpperCase()}
                    </span>
                  </a>
                  <a>
                    Fee:{' '}
                    <span className={styles.sectionPendingContent}>
                      {data.fee} {coin.toLocaleUpperCase()}
                    </span>
                  </a>
                  <a>
                    Destination Address:{' '}
                    <span className={styles.sectionPendingContent}>
                      {data.destination_address}
                    </span>
                  </a>
                  <a>
                    Created At:{' '}
                    <span className={styles.sectionPendingContent}>
                      {data.createdAt}
                    </span>
                  </a>
                </TranslucentSurface>
              </Level>
            </Surface>
          ) : txid.startsWith('PBXINTRA') ? (
            <>
              <Surface
                baseElevation={0}
                className={styles.section}
                surfaceColor={surfaceColor}
              >
                <Level>
                  <H className={styles.headline}>
                    <img
                      className={styles.icon}
                      src={currencies[coin]}
                      alt=""
                      style={{ height: '5rem', width: '5rem' }}
                    />
                    <div className={styles.headlineContent}>
                      <a className={styles.headlineText}>
                        {LL.explorer.data.transactionHash()}
                      </a>
                      <a className={styles.headlineNumber}>
                        {data.tx_id}
                        <ExplorerCopy
                          margin={true}
                          text={data.tx_id}
                        />
                      </a>
                    </div>
                  </H>
                  <Summary
                    data={data}
                    coin={coin}
                    network={network}
                    txid={txid}
                  />
                </Level>
              </Surface>
              <div className={styles.detailsContainer}>
                <Surface
                  baseElevation={0}
                  className={styles.sectionAdditional}
                  surfaceColor={surfaceColor}
                >
                  <Level>
                    <H
                      className={styles.headlineAdditional}
                      onClick={() => setDetailsVisible(!detailsVisible)}
                    >
                      <div
                        style={{
                          fontSize: '2.1rem',
                          fontWeight: '600',
                        }}
                        className={styles.confirmationsHeader}
                      >
                        <div
                          className={
                            data.status === 'pending' ? styles.pie0 : styles.pie
                          }
                          style={intraConfirmationStyle(
                            data.status,
                            CurrencyColors[coin].background
                          )}
                        >
                          {data.status === 'success' ? (
                            <RiCheckLine
                              className={styles.checkIcon}
                              size="3.5rem"
                            />
                          ) : (
                            <HiXMark
                              className={styles.checkIcon}
                              size="3.5rem"
                            />
                          )}
                          {data.status === 'pending' && (
                            <div className={styles.dots}>
                              <div
                                className={styles.dotOne}
                                style={dotsStyle(
                                  CurrencyColors[coin].background,
                                  0.2
                                )}
                              ></div>
                              <div
                                className={styles.dotTwo}
                                style={dotsStyle(
                                  CurrencyColors[coin].background,
                                  0.5
                                )}
                              ></div>
                              <div
                                className={styles.dotThree}
                                style={dotsStyle(
                                  CurrencyColors[coin].background,
                                  1
                                )}
                              ></div>
                            </div>
                          )}
                        </div>
                        <span className={styles.transactionSuccessful}>
                          {data.status === 'success'
                            ? 'Transaction Successful'
                            : data.status === 'fail'
                            ? 'Transaction Failed'
                            : 'Transaction Pending'}
                        </span>
                      </div>
                    </H>
                  </Level>
                </Surface>
                <Surface
                  baseElevation={0}
                  className={styles.sectionSenders}
                  surfaceColor={surfaceColor}
                >
                  <Level>
                    {isLargeScreen ? (
                      <>
                        <H className={styles.headline}>Sender </H>
                        <Inputs
                          data={data}
                          coin={coin}
                          txid={txid}
                        />
                      </>
                    ) : (
                      <>
                        <H
                          className={styles.headlineSenders}
                          onClick={() => setInputsVisible(!inputsVisible)}
                        >
                          {inputsVisible ? (
                            <span>
                              <RiArrowDownSFill color="var(--color-logo)" />
                            </span>
                          ) : (
                            <span>
                              <RiArrowRightSFill />
                            </span>
                          )}
                          <span>Sender</span>
                          {'  '}
                        </H>
                        {inputsVisible && (
                          <Inputs
                            data={data}
                            coin={coin}
                            txid={txid}
                          />
                        )}
                      </>
                    )}
                    {isLargeScreen ? (
                      <>
                        <H className={styles.headline}>
                          Receiver{' '}
                          {data.vout && (
                            <span className={styles.number}>
                              &nbsp;{data.vout.length}&nbsp;
                            </span>
                          )}
                        </H>
                        <Outputs
                          data={data}
                          coin={coin}
                          txid={txid}
                        />
                      </>
                    ) : (
                      <>
                        <H
                          className={styles.headlineSenders}
                          onClick={() => setOutputsVisible(!outputsVisible)}
                        >
                          {outputsVisible ? (
                            <span>
                              <RiArrowDownSFill color="var(--color-logo)" />
                            </span>
                          ) : (
                            <span>
                              <RiArrowRightSFill />
                            </span>
                          )}
                          <span>
                            Receiver{' '}
                            {data.vout && (
                              <span className={styles.number}>
                                &nbsp;{data.vout.length}&nbsp;
                              </span>
                            )}
                          </span>
                        </H>

                        {outputsVisible && (
                          <Outputs
                            data={data}
                            coin={coin}
                            txid={txid}
                          />
                        )}
                      </>
                    )}
                  </Level>
                </Surface>
              </div>
            </>
          ) : coin === 'xrp' || coin === 'xlm' ? (
            <>
              <Surface
                baseElevation={0}
                className={styles.section}
                surfaceColor={surfaceColor}
              >
                <Level>
                  <H className={styles.headline}>
                    <img
                      className={styles.icon}
                      src={currencies[coin]}
                      alt=""
                      style={{ height: '5rem', width: '5rem' }}
                    />
                    <div className={styles.headlineContent}>
                      <a className={styles.headlineText}>
                        {LL.explorer.data.transactionHash()}
                      </a>
                      <a className={styles.headlineNumber}>
                        {data.id}
                        <ExplorerCopy
                          margin={true}
                          text={data.id}
                        />
                      </a>
                    </div>
                  </H>
                  <Summary
                    data={data}
                    coin={coin}
                    network={network}
                    txid={txid}
                  />
                </Level>
              </Surface>
              <div className={styles.detailsContainer}>
                <Surface
                  baseElevation={0}
                  className={styles.sectionAdditional}
                  surfaceColor={surfaceColor}
                >
                  <Level>
                    <H
                      className={styles.headline}
                      onClick={() => setDetailsVisible(!detailsVisible)}
                      style={{ marginBottom: '1rem' }}
                    >
                      <RiArrowRightSFill />
                      {LL.explorer.data.additionalInfo()}
                    </H>
                    {detailsVisible && (
                      <Details
                        size={'lg'}
                        data={data}
                        coin={coin}
                        txid={txid}
                      />
                    )}
                  </Level>
                </Surface>

                {coin === 'xrp' && (
                  <Surface
                    baseElevation={0}
                    className={styles.section}
                    surfaceColor={surfaceColor}
                  >
                    <Level>
                      <H className={styles.headline}>
                        {LL.explorer.data.description()}
                      </H>
                      <Description
                        data={data}
                        coin={coin}
                      />
                    </Level>
                  </Surface>
                )}
              </div>
            </>
          ) : (
            <>
              <Surface
                baseElevation={0}
                className={styles.section}
                surfaceColor={surfaceColor}
              >
                <Level>
                  <H className={styles.headline}>
                    <img
                      className={styles.icon}
                      src={currencies[coin]}
                      alt=""
                      style={{ height: '5rem', width: '5rem' }}
                    />
                    <div className={styles.headlineContent}>
                      <a className={styles.headlineText}>
                        {LL.explorer.data.transactionHash()}
                      </a>
                      <a className={styles.headlineNumber}>
                        {data.txid || data.transactionHash || data.txId}
                        <ExplorerCopy
                          margin={true}
                          text={
                            data.txid
                              ? data.txid
                              : data.transactionHash
                              ? data.transactionHash
                              : data.txId
                          }
                        />
                      </a>
                    </div>
                  </H>
                  <Summary
                    data={data}
                    coin={coin}
                    network={network}
                    txid={txid}
                  />
                </Level>
              </Surface>
              <div className={styles.detailsContainer}>
                <Surface
                  baseElevation={0}
                  className={styles.sectionAdditional}
                  surfaceColor={surfaceColor}
                >
                  <Level>
                    <H
                      className={styles.headlineAdditional}
                      onClick={() => setDetailsVisible(!detailsVisible)}
                    >
                      <div
                        style={{
                          fontSize: '2.1rem',
                          fontWeight: '600',
                        }}
                        className={styles.confirmationsHeader}
                      >
                        <div
                          className={
                            !data.confirmations
                              ? styles.pie0
                              : data.confirmations < 5
                              ? styles[`pie${data.confirmations}`]
                              : styles.pie
                          }
                          style={confirmationStyle(
                            data.confirmations,
                            CurrencyColors[coin].background
                          )}
                        >
                          {data.confirmations >= 5 && (
                            <RiCheckLine
                              className={styles.checkIcon}
                              size="3.5rem"
                            />
                          )}
                          {data.confirmations === 0 ||
                            (!data.confirmations && (
                              <div className={styles.dots}>
                                <div
                                  className={styles.dotOne}
                                  style={dotsStyle(
                                    CurrencyColors[coin].background,
                                    0.2
                                  )}
                                ></div>
                                <div
                                  className={styles.dotTwo}
                                  style={dotsStyle(
                                    CurrencyColors[coin].background,
                                    0.5
                                  )}
                                ></div>
                                <div
                                  className={styles.dotThree}
                                  style={dotsStyle(
                                    CurrencyColors[coin].background,
                                    1
                                  )}
                                ></div>
                              </div>
                            ))}
                        </div>
                        <span style={{ marginTop: '1.6rem' }}>
                          {data.confirmations
                            ? LL.explorer.data.confirmations(data.confirmations)
                            : LL.explorer.data.pendingConfirmations()}
                        </span>
                      </div>
                      <div className={styles.infoDropdown}>
                        {detailsVisible ? (
                          <RiArrowDownSFill
                            className={styles.dropdownIcon}
                            color="var(--color-logo)"
                          />
                        ) : (
                          <RiArrowRightSFill className={styles.dropdownIcon} />
                        )}
                        {LL.explorer.data.additionalInfo()}
                      </div>
                    </H>
                    {detailsVisible && (
                      <Details
                        size={'lg'}
                        data={data}
                        coin={coin}
                        txid={txid}
                      />
                    )}
                  </Level>
                </Surface>
                <Surface
                  baseElevation={0}
                  className={styles.sectionSenders}
                  surfaceColor={surfaceColor}
                >
                  <Level>
                    {isLargeScreen ? (
                      <>
                        <H className={styles.headline}>
                          {LL.explorer.data.inputs()}{' '}
                          {data.vin && (
                            <span className={styles.number}>
                              &nbsp;{data.vin.length}&nbsp;
                            </span>
                          )}
                        </H>
                        <Inputs
                          data={data}
                          coin={coin}
                          txid={txid}
                        />
                      </>
                    ) : (
                      <>
                        <H
                          className={styles.headlineSenders}
                          onClick={() => setInputsVisible(!inputsVisible)}
                        >
                          {inputsVisible ? (
                            <span>
                              <RiArrowDownSFill color="var(--color-logo)" />
                            </span>
                          ) : (
                            <span>
                              <RiArrowRightSFill />
                            </span>
                          )}
                          <span>{LL.explorer.data.inputs()}</span>
                          {'  '}
                          {data.vin && (
                            <span className={styles.number}>
                              &nbsp;{data.vin.length}&nbsp;
                            </span>
                          )}
                        </H>
                        {inputsVisible && (
                          <Inputs
                            data={data}
                            coin={coin}
                            txid={txid}
                          />
                        )}
                      </>
                    )}
                    {isLargeScreen ? (
                      <>
                        <H className={styles.headline}>
                          {LL.explorer.data.outputs()}{' '}
                          {data.vout && (
                            <span className={styles.number}>
                              &nbsp;{data.vout.length}&nbsp;
                            </span>
                          )}
                        </H>
                        <Outputs
                          data={data}
                          coin={coin}
                          txid={txid}
                        />
                      </>
                    ) : (
                      <>
                        <H
                          className={styles.headlineSenders}
                          onClick={() => setOutputsVisible(!outputsVisible)}
                        >
                          {outputsVisible ? (
                            <span>
                              <RiArrowDownSFill color="var(--color-logo)" />
                            </span>
                          ) : (
                            <span>
                              <RiArrowRightSFill />
                            </span>
                          )}
                          <span>
                            {LL.explorer.data.outputs()}{' '}
                            {data.vout && (
                              <span className={styles.number}>
                                &nbsp;{data.vout.length}&nbsp;
                              </span>
                            )}
                          </span>
                        </H>

                        {outputsVisible && (
                          <Outputs
                            data={data}
                            coin={coin}
                            txid={txid}
                          />
                        )}
                      </>
                    )}
                  </Level>
                </Surface>
              </div>
            </>
          ))}
      </div>
      <div className={styles.operations}>
        {coin === 'xlm' &&
          (!txid.startsWith('PBXINTRA') || !txid.startsWith('pending-')) && (
            <Surface
              baseElevation={0}
              className={styles.section}
              surfaceColor={surfaceColor}
            >
              <Level>
                <H className={styles.headline}>
                  {LL.explorer.data?.operations() || 'Empty'} (
                  {data.operations?.records?.length > 0 &&
                    data.operations?.records?.length}
                  )
                </H>
                <ExplorerOperations
                  data={data}
                  coin={coin}
                />
              </Level>
            </Surface>
          )}
      </div>
    </div>
  );
}

export default ExplorerData;
