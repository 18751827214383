import BottomSheetStyles from './BottomSheet.module.css';
import FullScreenStyles from './FullScreen.module.css';
import ModalStyles from './Modal.module.css';
import PopoverStyles from './Popover.module.css';

/**
 * @param {"full screen" | "modal" | "bottom sheet" | "popover"} variant
 * @returns {{overlay?: string, underlay?: string}}
 */
export default function getoverlayClassNames(variant) {
  switch (variant) {
    case 'full screen':
      return FullScreenStyles;
    case 'modal':
      return ModalStyles;
    case 'bottom sheet':
      return BottomSheetStyles;
    case 'popover':
      return PopoverStyles;
    default:
      return ModalStyles;
  }
}
