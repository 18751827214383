import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import OutlinedButton from 'common/buttons/OutlinedButton';
import FieldIconButton from 'common/fields/FieldIconButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaTrash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { RiClipboardLine, RiCloseLine } from 'react-icons/ri';
import { connect } from 'react-redux';
import { selectTfaEnabled } from 'redux/user/user.selectors';
import { setAlertStart } from '../../../redux/alert/alert.actions';
import { getDate } from '../../../utils';
import './api-key.styles.css';

const errors = {
  ERR_INVALID_2FA_TOKEN: '2FA token is invalid',
  'Access Forbidden!': 'Your IP is not in the whitelist',
  ERR_TOKEN_REQUIRED: '2FA token is required',
  '"address" is not allowed to be empty': 'IP address required',
};

const ApiKeyPage = ({ tfaEnabled }) => {
  const [keyList, setKeyList] = useState([]);
  const [newKey, setNewKey] = useState(null);
  const [keyCopied, setKeyCopied] = useState(false);
  const [keyId, setKeyId] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [tfaPin, setTfaPin] = useState('');
  const [tfaPopup, setTfaPopup] = useState('');
  const [error, setError] = useState(null);

  const { colorTest4, colorTest6 } = useThemeVariables();
  const { LL } = useLocale();

  const surfaceColor = {
    background: colorTest4,
  };

  const surfaceColor2 = {
    background: colorTest6,
  };

  if (keyCopied) {
    setTimeout(() => {
      setKeyCopied(false);
    }, 3000);
  }

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URI}users/keys`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });

        setKeyList(data);
      } catch (err) {
        console.log('Error: ', err);
      }
    };

    fetchKeys();
  }, [toggle]);

  const onNewKeyGenerate = async () => {
    try {
      const { data } = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}users/keys`,
        data: qs.stringify({ tfaToken: tfaPin.replace(' ', '') }),
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      console.log(data.key);
      setNewKey(data.key);
      setTfaPin('');
      setTfaPopup('');
    } catch (err) {
      const errMessage = err.data?.message
        ? err.data.message
        : err.response?.data?.error
        ? err.response.data.error
        : 'Unexpected error';

      setError(errMessage);
      setTimeout(() => setError(null), 5000);
    }
  };

  const onKeyDelete = async (id) => {
    try {
      await axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URI}users/keys/${id}`,
        data: { tfaToken: tfaPin.replace(' ', '') },
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });

      const updatedKeys = keyList.filter((key) => key.id !== id);
      setKeyList(updatedKeys);
      setTfaPin('');
      setTfaPopup('');
    } catch (err) {
      const errMessage = err.data?.message
        ? err.data.message
        : err.response?.data?.error
        ? err.response.data.error
        : 'Unexpected error';

      setError(errMessage);
      setTimeout(() => setError(null), 5000);
    }
  };

  return (
    <div className="api-key-wrapper">
      {['add', 'delete'].includes(tfaPopup) && (
        <Surface
          surfaceColor={surfaceColor2}
          className="tfa-check"
        >
          <OutlinedButton
            className="tfa-check-close"
            onPress={() => {
              setTfaPopup('');
              setTfaPin('');
              setKeyId(null);
            }}
          >
            <RiCloseLine />
          </OutlinedButton>
          {error && (
            <div className="inline-error">
              <ErrorContainer>
                <InlineError
                  children={errors[error] ? errors[error] : 'Unexpected error'}
                  onClose={() => setError(null)}
                  success={false}
                ></InlineError>
              </ErrorContainer>
            </div>
          )}
          <span className={`${error ? 'error' : ''}`}>
            {LL.security.ipWhitelist.tfaPinHeader()}
          </span>
          <TextField
            type="text"
            placeholder={LL.security.ipWhitelist.tfaPin()}
            value={tfaPin}
            onChange={(e) => setTfaPin(e)}
          />
          <FilledButton
            onPress={() =>
              tfaPopup === 'delete'
                ? onKeyDelete(keyId)
                : tfaPopup === 'add' && onNewKeyGenerate()
            }
          >
            {LL.general.confirm()}
          </FilledButton>
        </Surface>
      )}
      {newKey && (
        <Surface
          className="new-api-key"
          surfaceColor={surfaceColor2}
        >
          <h3>{LL.security.apiKeys.apiKeyWarning()}</h3>
          <span>{newKey}</span>

          <CopyToClipboard text={newKey}>
            <FieldIconButton aria-label={LL.exchange.copyDestinationAddress()}>
              <RiClipboardLine />
            </FieldIconButton>
          </CopyToClipboard>

          <div
            className="new-key-close"
            onClick={() => {
              setNewKey(null);
              setToggle((prevState) => !prevState);
            }}
          >
            <MdClose />
          </div>
        </Surface>
      )}
      <div className="container">
        <Surface
          className="api-whitelist-wrapper"
          surfaceColor={surfaceColor}
        >
          <div className="api-list-wrapper">
            <h1>{LL.security.apiKeys.apiHeader()}</h1>
            {tfaEnabled ? (
              <form className="add-new-api">
                {/* TODO: use buttons from our design system instead */}
                <FilledButton onPress={() => setTfaPopup('add')}>
                  {LL.security.apiKeys.apiGenerate()}
                </FilledButton>
              </form>
            ) : (
              <div className="enable-tfa-message-api">
                {LL.security.apiKeys.apiTfaWarning()}
              </div>
            )}
            <div className="api-list">
              {keyList.length > 0 ? (
                keyList.map((key) => (
                  <TranslucentSurface
                    className="api"
                    key={key.id}
                    surfaceColor={surfaceColor2}
                  >
                    <div className="key-info">
                      <span>{key.prefix}</span>
                      <span className="key-date">
                        {getDate(key.created_at)}
                      </span>
                    </div>

                    {tfaEnabled && (
                      <span
                        className="remove-icon"
                        onClick={() => {
                          setKeyId(key.id);
                          setTfaPopup('delete');
                        }}
                      >
                        <FaTrash />
                      </span>
                    )}
                  </TranslucentSurface>
                ))
              ) : (
                <span className="api-not-found">
                  {LL.security.apiKeys.apiList()}
                </span>
              )}
            </div>
          </div>
        </Surface>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tfaEnabled: selectTfaEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (data) => dispatch(setAlertStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyPage);
