// @ts-check

import big from 'bigjs-literal/macro';
import Currency from 'components/content/Currency.component';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useRatesQuery } from 'queries/rates';
import * as React from 'react';

// TODO: get this value from state

/**
 * @typedef {import("components/content/Currency.component").CurrencyProps} ConversionCurrencyProps
 * @param {ConversionCurrencyProps} props
 */
export default function ConversionCurrency({ children, currency, ...props }) {
  const { data: rates } = useRatesQuery();
  const { conversionCurrency } = useCurrencyAndThemeContext();
  try {
    return (
      <>
        {React.Children.map(children, (child) => {
          if (typeof child === 'number') {
            return (
              <Currency
                currency={conversionCurrency}
                {...props}
              >
                {rates
                  ? +big`${child} * ${
                      rates[currency.toLocaleLowerCase()][conversionCurrency]
                    }`
                  : null}
              </Currency>
            );
          }
          return child;
        })}
      </>
    );
  } catch (error) {
    console.log(error);
  }
}
