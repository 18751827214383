// @ts-check
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {Array.<Object>} PhoneCodes
 * @property {Object} phoneCode
 * @property {number} phoneCode.id
 * @property {string} phoneCode.country
 * @property {string} phoneCode.phone_code
 */

/**
 * @returns {Promise<PhoneCodes>}
 */

export async function getPhoneCodes() {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URI}locations/phone-codes`
  );

  return data;
}

/**
 * @param {{enabled?: boolean, retry?: boolean, onSuccess?: any, onError?: any}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<PhoneCodes, Error>}
 **/
export function usePhoneCodesQuery(options) {
  return useQuery(['phoneCodes'], getPhoneCodes, {
    staleTime: 30_0000,
    ...options,
  });
}
