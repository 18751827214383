import { all, call } from 'redux-saga/effects';

import { coinSagas } from './coin/coin.sagas';
import { userSagas } from './user/user.sagas';
import { authSagas } from './auth/auth.sagas';
import { adminSagas } from './admin/admin.sagas';
import { alertSagas } from './alert/alert.sagas';
import { operationsSagas } from './operations/operations.sagas';
import { invoiceSagas } from './invoice/invoice.sagas';
import { notificationsSagas } from './notifications/notifications.sagas';
import { networksSagas } from './network/network.sagas';

export default function* rootSaga() {
  yield all([
    call(coinSagas),
    call(userSagas),
    call(authSagas),
    call(adminSagas),
    call(alertSagas),
    call(operationsSagas),
    call(invoiceSagas),
    call(notificationsSagas),
    call(networksSagas),
  ]);
}
