import cx from 'classnames';
import React from 'react';
import { mergeProps, useCalendarCell, useFocusRing } from 'react-aria';
import styles from './CalendarCell.module.css';

export default function CalendarCell({ state, date }) {
  let ref = React.useRef(null);
  let {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);

  let { focusProps, isFocusVisible } = useFocusRing();
  return (
    <td {...cellProps}>
      <div
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        {...mergeProps(buttonProps, focusProps)}
        className={cx(styles.cell, {
          [styles.selected]: isSelected,
          [styles.focusRing]: isFocusVisible,
          [styles.disabled]: isDisabled,
        })}
      >
        {formattedDate}
      </div>
    </td>
  );
}
