import { useMenuTriggerState } from '@react-stately/menu';
import { useListState } from '@react-stately/list';
import { useState } from 'react';
import { useControlledState } from '@react-stately/utils';
import { MultiSelectProps, MultiSelectState } from './types';

/**
 * Provides state management for a MultiSelect component. Handles building a collection
 * of items from props, handles the open state for the popup menu, and manages
 * multiple selection state.
 * Some functionality might be disabled or buggy because it's a temp implementation before
 * react-aria drops MultiSelectComponent
 */
export function useMultiSelectState<T extends object>(
  props: MultiSelectProps<T>
): MultiSelectState<T> {
  let [selectedKeys, setSelectedKeys] = useControlledState(
    props.selectedKeys,
    props.defaultSelectedKeys ?? [],
    props.onSelectionChange
  );

  let triggerState = useMenuTriggerState(props);

  let listState = useListState({
    ...props,
    selectionMode: 'multiple',
    selectedKeys,
    allowDuplicateSelectionEvents: true,
    onSelectionChange: (keys: Set<React.Key>) => {
      let areKeysUnchanged = true;
      for (let i = 0; i < keys.size; i++) {
        if (keys[i] !== selectedKeys[i]) {
          areKeysUnchanged = false;
          break;
        }
      }

      // Always fire onSelectionChange, even if the key is the same
      // as the current key (useControlledState does not).
      if (areKeysUnchanged && props.onSelectionChange) {
        props.onSelectionChange(keys);
      }

      setSelectedKeys(keys);
    },
  });

  let [isFocused, setFocused] = useState(false);

  return {
    ...listState,
    ...triggerState,
    open() {
      // Don't open if the collection is empty.
      if (listState.collection.size !== 0) {
        triggerState.open();
      }
    },
    toggle(focusStrategy) {
      if (listState.collection.size !== 0) {
        triggerState.toggle(focusStrategy);
      }
    },
    isFocused,
    setFocused,
    selectedKeys,
    setSelectedKeys,
    selectedItems:
      selectedKeys === 'all'
        ? [...listState.collection.getKeys()].map((key) =>
            listState.collection.getItem(key)
          )
        : [...selectedKeys].map((key) => listState.collection.getItem(key)),
  };
}
