// @ts-check

import FilledTonalButton from 'common/buttons/FilledTonalButton';
import TextButton from 'common/buttons/TextButton';
import Dialog from 'common/overlays/Dialog.component';
import ModalTrigger from 'common/overlays/triggers/ModalTrigger.component';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import styles from './NavigationElements.module.css';
import NoiceModal from 'common/overlays/NoiceModal';
import TextField from 'common/fields/TextField';
import AlertDialog from 'common/overlays/AlertDialog.component';
import { RiArrowGoForwardLine, RiArrowRightUpLine } from 'react-icons/ri';
import OutlinedButton from 'common/buttons/OutlinedButton';
import PopoverTrigger from 'common/overlays/triggers/PopoverTrigger.component';

const DemoNoiceModalDialog = NoiceModal.create(
  (/** @type {{renderedBy: string}} */ { renderedBy }) => {
    const [value, setValue] = React.useState('Hello world!');

    const modal = NoiceModal.useModal();

    return (
      <Dialog
        title={
          <>
            I was rendered by <code>{renderedBy}</code>
          </>
        }
      >
        <p>
          This means I can pass data back to the function that rendered me using{' '}
          <code>modal.resolve(data)</code> or <code>modal.reject(data)</code>
        </p>

        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
          <TextField
            value={value}
            onChange={setValue}
            label="Message to return"
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 16,
            marginRight: -8,
          }}
        >
          <TextButton
            onPress={() => {
              modal.resolve(value);
              modal.hide();
            }}
          >
            Close with message
          </TextButton>
          <TextButton onPress={() => modal.hide()}>Close</TextButton>
        </div>
      </Dialog>
    );
  },
  {
    isDismissable: true,
    smVariant: 'modal',
  }
);

export default function Overlays() {
  const [NoiceModalMessage, setNoiceModalMessage] = React.useState('');

  const openNoiceModal = async () => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    await sleep(1000);
    const message = await NoiceModal.show(DemoNoiceModalDialog, {
      renderedBy: 'await NoiceModal.show()',
    });
    if (typeof message === 'string') {
      setNoiceModalMessage(message);
    }
  };

  return (
    <div className={styles.container}>
      <Level>
        <H className={'type-headline-medium'}>Overlays</H>

        <Level>
          <div style={{ display: 'flex', gap: 24, flexWrap: 'wrap' }}>
            <div>
              <H className={'type-headline-small'}>ModalTrigger + Dialog</H>
              <ModalTrigger smVariant="bottom sheet">
                <FilledTonalButton>Trigger the dialog</FilledTonalButton>
                {(close) => (
                  <Dialog title="I appear as bottom sheet on smaller screen size. Try it">
                    <p>
                      You can costumize this behaviour with <br />
                      <code>smVariant: "bottom sheet" | "modal"</code>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: -8,
                      }}
                    >
                      <TextButton onPress={close}>Close</TextButton>
                    </div>
                  </Dialog>
                )}
              </ModalTrigger>
            </div>
            <div>
              <H className={'type-headline-small'}>
                NoiceModal + Dialog + isDismissable (press outside)
              </H>
              <FilledTonalButton onPress={openNoiceModal}>
                {NoiceModalMessage
                  ? 'Message from modal: ' + NoiceModalMessage
                  : 'Open after 1 sec'}
              </FilledTonalButton>
            </div>
            <div>
              <H className={'type-headline-small'}>PopoverTrigger + Dialog</H>
              <PopoverTrigger>
                <FilledTonalButton>Trigger popover</FilledTonalButton>
                <Dialog title="Example popover">
                  This is the content of the popover
                </Dialog>
              </PopoverTrigger>
            </div>
          </div>

          <div style={{ display: 'flex', gap: 24, flexWrap: 'wrap' }}>
            <div>
              <H className={'type-headline-small'}>Confirmation alert dialog</H>
              <ModalTrigger>
                <OutlinedButton>Exit</OutlinedButton>
                <ConfirmationDialog />
              </ModalTrigger>
            </div>
            <div>
              <H className={'type-headline-small'}>Information alert dialog</H>
              <ModalTrigger>
                <OutlinedButton>Thank You</OutlinedButton>
                <InformationDialog />
              </ModalTrigger>
            </div>
            <div>
              <H className={'type-headline-small'}>Warning alert dialog</H>
              <ModalTrigger maxWidth={null}>
                <OutlinedButton>External link</OutlinedButton>
                <WarningDialog />
              </ModalTrigger>
            </div>
            <div>
              <H className={'type-headline-small'}>Error alert dialog</H>
              <ModalTrigger maxWidth={null}>
                <OutlinedButton>Transfer BTC</OutlinedButton>
                <ErrorDialog />
              </ModalTrigger>
            </div>
            <div>
              <H className={'type-headline-small'}>Destructive alert dialog</H>
              <ModalTrigger>
                <OutlinedButton>Delete my account</OutlinedButton>
                <DestructiveDialog />
              </ModalTrigger>
            </div>
          </div>
        </Level>
      </Level>
    </div>
  );
}

function ConfirmationDialog() {
  return (
    <AlertDialog
      variant="confirmation"
      title="Exit the app?"
    >
      You pressed the back button whole bunch of times, we just want to make
      sure you wanted to leave.
      <span data-slot="cancelLabel">Cancel</span>
    </AlertDialog>
  );
}

function InformationDialog() {
  return (
    <AlertDialog
      variant="information"
      title="Thanks for being with us!"
    >
      We want to thank you for your loyalty! As a gift we made you a little
      present. Go to Transactions to claim your reward.
      <span data-slot="cancelLabel">close</span>
      <RiArrowRightUpLine data-slot="primaryActionIcon" />
      <span data-slot="primaryActionLabel">Transactions</span>
    </AlertDialog>
  );
}

function WarningDialog() {
  return (
    <AlertDialog
      variant="warning"
      title="Exit the app?"
    >
      Your recent transfer might not go through if you leave now
      <span data-slot="cancelLabel">Cancel</span>
      <span data-slot="primaryActionLabel">Exit</span>
    </AlertDialog>
  );
}

function ErrorDialog() {
  return (
    <AlertDialog
      variant="error"
      title="Connect to wifi"
    >
      Transaction was interrupted due to loss of conectivity.
      <span data-slot="primaryActionLabel">Retry</span>
    </AlertDialog>
  );
}

function DestructiveDialog() {
  return (
    <AlertDialog
      variant="destructive"
      title="Delete your Account"
    >
      After this you may never recover your account. If you wish, you can delete
      your history instead.
      <span data-slot="cancelLabel">Cancel</span>
      <span data-slot="secondaryActionLabel">View other options</span>
      <span data-slot="primaryActionLabel">Delete</span>
    </AlertDialog>
  );
}
