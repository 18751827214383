import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import axios from 'axios';
import qs from 'qs';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CHECK_2FA_TOKEN, LOGIN_START } from './auth.types';

import {
  check2faTokenFail,
  checkPhoneVerification,
  loginFail,
  loginSuccess,
  show2faForm,
} from './auth.actions';

export function* login({ payload }) {
  try {
    const res = yield axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}v1/auth`,
      data: qs.stringify({
        email: payload.email,
        password: payload.password,
        two_factor_auth_token: payload.token,
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    });

    const fpPromise = FingerprintJS.load({
      apiKey: 'USfRL9GRaAdf7Cir2Dq2',
      region: 'eu',
    });

    if (res.data.is_phone_verified === false) {
      yield put(checkPhoneVerification(res.data.is_phone_verified));
    } else if (res.data.two_factor_auth_enabled === true) {
      yield put(
        show2faForm({ email: payload.email, password: payload.password })
      );
    } else {
      fpPromise
        .then((fp) => fp.get())
        .then((result) => console.log(result.visitorId));
      yield put(loginSuccess(res.data.token));
    }
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.error === 'user.incorrect_credentials'
    ) {
      yield put(
        loginFail({
          error: true,
          data: 'The email or password you entered is incorrect',
        })
      );
    }
    console.log(err.response);
  }
}

export function* check2fa({ payload }) {
  try {
    const res = yield axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}v1/auth`,
      data: qs.stringify({
        email: payload.email,
        password: payload.password,
        two_factor_auth_token: payload.token,
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    });

    yield put(loginSuccess(res.data.token));
  } catch (err) {
    console.log(err.response.data.error);
    if (err.response.data.error === 'ERR_INVALID_2FA_TOKEN') {
      yield put(check2faTokenFail());
    }
  }
}

export function* onLoginStart() {
  yield takeLatest(LOGIN_START, login);
}

export function* on2faTokenCheck() {
  yield takeLatest(CHECK_2FA_TOKEN, check2fa);
}

export function* authSagas() {
  yield all([call(onLoginStart), call(on2faTokenCheck)]);
}
