// @ts-check

import * as React from 'react';
import { useTab, useTabList, useTabPanel } from '@react-aria/tabs';
import { useTabListState } from '@react-stately/tabs';
import cx from 'classnames';
import { FocusRing } from '@react-aria/focus';
import { FocusScope, useFocusManager } from '@react-aria/focus';

/**
 * @typedef ClassNames
 * @property {string} [tabs]
 * @property {string} [tab]
 * @property {string} [tabPanel]
 */

/**
 * @typedef FocusHandle
 * @property {() => void} focusIntoPanel
 */

/**
 * @template T
 * @typedef TabsBaseProps
 * @property {import("@react-types/shared").CollectionChildren <T>} children
 * @property {ClassNames} classNames
 * @property {Iterable <T>} [items]
 * @property {Iterable <React.Key>} [disabledKeys]
 * @property {React.Key | null} [selectedKey]
 * @property {React.Key} [defaultSelectedKey]
 * @property {( key : React.Key ) => any} [onSelectionChange]
 * @property {'automatic' | 'manual'} [keyboardActivation] Whether tabs are activated automatically on focus or manually.
 * @property {'horizontal' | 'vertical'} [orientation]
 * @property {boolean} [isDisabled]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 */

/**
 * @template {{}} T
 * @param {TabsBaseProps<T>} props
 * @param {React.ForwardedRef<FocusHandle>} parentRef
 */
function TabsBaseWithRef(props, parentRef) {
  const state = useTabListState(props);
  const ref = React.useRef(null);
  const { tabListProps } = useTabList(props, state, ref);

  // Changing props.selectedKey wont automatically focus on selected pannel.
  // If consumer whant this functionallity they have to do it imperatively via ref
  const tabPanelRef = React.useRef(null);
  React.useImperativeHandle(parentRef, () => ({
    focusIntoPanel: tabPanelRef.current?.focusIntoPanel,
  }));

  const { classNames } = props;
  return (
    <>
      <div
        {...tabListProps}
        className={classNames.tabs}
        ref={ref}
      >
        {[...state.collection].map((item) => (
          <Tab
            key={item.key}
            item={item}
            state={state}
            className={classNames.tab}
          />
        ))}
      </div>
      <FocusScope>
        <TabPanel
          key={state.selectedItem?.key}
          state={state}
          className={classNames.tabPanel}
          ref={tabPanelRef}
        />
      </FocusScope>
    </>
  );
}

const Tabs = React.forwardRef(TabsBaseWithRef);
export default Tabs;

/**
 *
 * @template T
 * @param {object} props
 * @param {import("@react-types/shared").Node<T>} props.item
 * @param {import("@react-stately/tabs").TabListState<T>} props.state
 * @param {string} props.className
 */
function Tab({ item, state, className }) {
  const { key, rendered } = item;
  const ref = React.useRef();
  const { tabProps } = useTab({ key }, state, ref);

  return (
    <FocusRing focusRingClass="--focusRing">
      <div
        {...tabProps}
        ref={ref}
        className={cx(className, {
          '--isSelected': state.selectedKey === key,
          '--isDisabled': state.disabledKeys.has(key),
        })}
      >
        {rendered}
      </div>
    </FocusRing>
  );
}

/**
 *
 * @template T
 * @param {object} props
 * @param {import("@react-stately/tabs").TabListState<T>} props.state
 * @param {string} props.className
 * @param {React.ForwardedRef<FocusHandle>} parentRef
 */
function TabPanelWithRef({ state, className }, parentRef) {
  const ref = React.useRef();
  const { tabPanelProps } = useTabPanel({}, state, ref);

  const focusManager = useFocusManager();
  React.useImperativeHandle(parentRef, () => ({
    focusIntoPanel: () => {
      // without setTimout
      setTimeout(() => {
        focusManager.focusFirst({ tabbable: true });
      });
    },
  }));

  return (
    <div
      {...tabPanelProps}
      ref={ref}
      className={className}
    >
      {state.selectedItem?.props.children}
    </div>
  );
}

const TabPanel = React.forwardRef(TabPanelWithRef);
