// @ts-check
import Footer from 'components/footer/footer.component';
import { NavBar, NavBarItem } from 'components/navigation/NavBar';
import QuickExchange from 'components/quick-exchange/QuickExchange.component';
import SentientBackground from 'components/sentient-background/SentientBackground';
import { ExchangeProvider } from 'context/ExchangeContext';
import { ExplorerPovider } from 'context/ExplorerContext';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import Explorer from 'pages/explorer/Explorer.component';
import * as React from 'react';
import { FaFileInvoice } from 'react-icons/fa';
import { IoStorefrontOutline, IoStorefrontSharp } from 'react-icons/io5';
import {
  RiCameraLensFill,
  RiCameraLensLine,
  RiDashboardFill,
  RiDashboardLine,
  RiExchangeFill,
  RiExchangeLine,
  RiHistoryFill,
  RiHistoryLine,
  RiWallet3Fill,
  RiWallet3Line,
} from 'react-icons/ri';
import { Outlet, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { hexToRGBA } from 'utils/colorUtils';
import LogoDark from '../assets/mobile-logo-dark.png';
import Logo from '../assets/mobile-logo.png';
import styles from './DashboardLayout.module.css';

// initialize custom element
if (!customElements.get('sentient-background')) {
  customElements.define('sentient-background', SentientBackground);
}
/**
 * @typedef DashboardLayoutProps
 * @property {string[]} [hideQuickExchangeOn] routes that should not show the quick exchange side bar
 * @property {boolean} [quickExchangeOpen]
 * @param {DashboardLayoutProps} props
 */
function DashboardLayout({ hideQuickExchangeOn = [], quickExchangeOpen }) {
  const { isDarkTheme, colorOnSuccess, MdRefPaletteTertiary95 } =
    useThemeVariables();

  const [navLogo, setNavLogo] = React.useState(0);
  const refScrollable = React.useRef(null);

  const [openQuickExchange, setOpenQuickExchange] = React.useState(false);

  const { pathname } = useLocation();

  const shouldHideQuickExchange = hideQuickExchangeOn.includes(pathname);

  return (
    // @ts-ignore ts expects to have a "sentient-background" declaration in .d.ts,
    // but in order to do that we have to install typescript and stuff
    <sentient-background
      class={styles.sentientBackground}
      // highlightRgba={hexToRGBA(highlightRGBA)}
      highlightRgba={hexToRGBA(
        isDarkTheme ? colorOnSuccess : MdRefPaletteTertiary95
      )}
      itemRgba={hexToRGBA(
        isDarkTheme ? colorOnSuccess : MdRefPaletteTertiary95
      )}
      cellsize={24}
      highlightradius={200}
      highlightwidth={12}
      itemthickness={2}
    >
      <div className={styles.container}>
        <Navigation navLogo={navLogo} />
        <ExplorerPovider>
          <div
            ref={refScrollable}
            // allows logo movement
            // onScroll={() =>
            //   refScrollable.current?.scrollTop === 0
            //     ? setNavLogo(1)
            //     : setNavLogo(2)
            // }
            className={styles.scrollable}
          >
            <ExchangeProvider>
              <main className={styles.main}>
                <Explorer />
                <Outlet context={[openQuickExchange, setOpenQuickExchange]} />
                <Footer />
              </main>
              {!shouldHideQuickExchange && openQuickExchange && (
                <QuickExchange
                  className={styles.sideBar}
                  setOpenQuickExchange={setOpenQuickExchange}
                />
              )}
            </ExchangeProvider>
          </div>
        </ExplorerPovider>
      </div>
      {/* @ts-ignore */}
    </sentient-background>
  );
}

export function Navigation({ navLogo }) {
  const { LL } = useLocale();
  const { isDarkTheme } = useThemeVariables();
  const navigate = useNavigate();
  const [isMobileScreen, setIsMobileScreen] = React.useState(false);
  React.useEffect(() => {
    const mediaCheck = window.matchMedia('(max-width: 599px)');
    setIsMobileScreen(mediaCheck.matches);
  }, []);
  if (!!localStorage.getItem('token')) {
    return (
      <NavBar
        aria-label="main"
        className={styles.navBar}
      >
        {/* TODO: use <Link to=...><img /></Link> */}
        <img
          src={isDarkTheme ? LogoDark : Logo}
          className={styles.img}
          onClick={() => navigate('/overview')}
        />
        <NavBarItem to="/overview">
          <React.Fragment data-slot="icon">
            {
              // @ts-ignore
              ({ isActive }) =>
                isActive ? <RiDashboardFill /> : <RiDashboardLine />
            }
          </React.Fragment>
          <React.Fragment data-slot="label">
            {LL.navbar.overview()}
          </React.Fragment>
        </NavBarItem>
        <NavBarItem to="/my-wallets">
          <React.Fragment data-slot="icon">
            {
              // @ts-ignore
              ({ isActive }) =>
                isActive ? <RiWallet3Fill /> : <RiWallet3Line />
            }
          </React.Fragment>
          <React.Fragment data-slot="label">
            {LL.navbar.myWallets()}
          </React.Fragment>
        </NavBarItem>
        {!isMobileScreen && (
          <NavBarItem to="/markets">
            <React.Fragment data-slot="icon">
              {
                // @ts-ignore
                ({ isActive }) =>
                  isActive ? <IoStorefrontSharp /> : <IoStorefrontOutline />
              }
            </React.Fragment>
            <React.Fragment data-slot="label">
              {LL.navbar.markets()}
            </React.Fragment>
          </NavBarItem>
        )}
        <NavBarItem to="/merchant">
          <React.Fragment data-slot="icon">
            {
              // @ts-ignore
              ({ isActive }) =>
                isActive ? <FaFileInvoice /> : <FaFileInvoice />
            }
          </React.Fragment>
          <React.Fragment data-slot="label">
            {LL.navbar.merchant()}
          </React.Fragment>
        </NavBarItem>
        <NavBarItem to="/transactions">
          <React.Fragment data-slot="icon">
            {
              // @ts-ignore
              ({ isActive }) =>
                isActive ? <RiHistoryFill /> : <RiHistoryLine />
            }
          </React.Fragment>
          <React.Fragment data-slot="label">
            {LL.navbar.transactions()}
          </React.Fragment>
        </NavBarItem>
        <NavBarItem to="/exchange">
          <React.Fragment data-slot="icon">
            {
              // @ts-ignore
              ({ isActive }) =>
                isActive ? <RiExchangeFill /> : <RiExchangeLine />
            }
          </React.Fragment>
          <React.Fragment data-slot="label">
            {LL.navbar.exchange()}
          </React.Fragment>
        </NavBarItem>
        <NavBarItem to="/explorer">
          <React.Fragment data-slot="icon">
            {
              // @ts-ignore
              ({ isActive }) =>
                isActive ? <RiCameraLensFill /> : <RiCameraLensLine />
            }
          </React.Fragment>
          <React.Fragment data-slot="label">
            {LL.navbar.explorer()}
          </React.Fragment>
        </NavBarItem>
      </NavBar>
    );
  }
}

export default DashboardLayout;
