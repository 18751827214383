import * as React from 'react';
import { IoCopyOutline } from 'react-icons/io5';
import styles from './ExplorerCopy.module.css';
export default function ExplorerCopy({ ...props }) {
  return (
    <span
      onClick={() => {
        console.log(JSON.stringify({ copyText: props.text }));
        navigator.clipboard.writeText(props.text);
      }}
      style={props.margin && { marginLeft: '1rem' }}
    >
      <IoCopyOutline
        size="1.7rem"
        className={styles.copyLogo}
      />
    </span>
  );
}

/**
 * @typedef {object} LocalProps
 * @property {string | object} [text]
 * @property {boolean} [margin]
 *
 * @typedef {LocalProps & React.HTMLAttributes} ExplorerCopyProps
 *
 * @param {ExplorerCopyProps} props
 */
