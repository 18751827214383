// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef ExchangeFee
 * @property {number} fee persentage. Net amount = amount + amount * exchangeFee.rate / 100
 *
 * @typedef ExchangeFeePayload
 * @property {import('currencies').Currency} from
 * @property {import('currencies').Currency} to
 */

/**
 * @param {ExchangeFeePayload} payload
 */
export async function getExchangeFee({ from, to }) {
  /** @type {import('axios').AxiosResponse<ExchangeFee>} */
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URI}exchange/fees/${from}/${to}`,
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });

  return response.data;
}

/**
 * @param {ExchangeFeePayload} payload
 * @param {{enabled: boolean}} options
 */
export function useExchangeFee(payload, options) {
  return useQuery(
    ['exchangeFee', payload],
    () => getExchangeFee(payload),
    options
  );
}
