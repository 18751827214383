// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef FiatUrl
 * @property {string | null} url
 * @property {object | null} data
 * @property {boolean} is_outstanding
 */

/**
 * @returns {Promise<FiatUrl>}
 */
export async function getFiatUrl() {
  const { data } = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URI}fiat/pending`,
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });
  return data;
}

/**
 * @param {{enabled: boolean, refetchInterval: any}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<FiatUrl, Error>}
 **/
export function useFiatUrlQuery(options) {
  return useQuery(['fiatUrl'], getFiatUrl, {
    staleTime: 10_0000,
    ...options,
  });
}
