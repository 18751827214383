import React from 'react';
import { connect } from 'react-redux';

import styles from './MobileThemeToggler.module.css';
import { FiSun } from 'react-icons/fi';
import { GiMoon } from 'react-icons/gi';
import { useLocale } from 'context/LanguageContext';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';

const ThemeToggler = ({ toggleTheme, darkMode }) => {
  const { LL } = useLocale();
  const { theme, setTheme } = useCurrencyAndThemeContext();

  return (
    <div
      className="theme-toggler"
      onClick={() => {
        toggleTheme();
        setTheme(theme === 'dark' ? 'light' : 'dark');
      }}
    >
      {darkMode ? (
        <div className={styles.themeInfo}>
          <span>
            <FiSun />
          </span>
          <span className={styles.info}>{LL.settings.lightTheme()}</span>
        </div>
      ) : (
        <div className={styles.themeInfo}>
          <span>
            <GiMoon />
          </span>
          <span className={styles.info}>{LL.settings.darkTheme()}</span>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleTheme: () => dispatch({ type: 'SWITCH_THEME' }),
});

export default connect(null, mapDispatchToProps)(ThemeToggler);
