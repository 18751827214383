// @ts-check
import { DateField } from 'common/calendar/DateField.component';
import { CorruptedField } from 'common/fields/CorruptedField.component';
import FieldIconButton from 'common/fields/FieldIconButton';
import FieldSelect from 'common/fields/FieldSelect.component';
import NumberField from 'common/fields/NumberField';
import SearchField from 'common/fields/SearchFieldV2.component';
import TextField from 'common/fields/TextField';
import { Checkbox, CheckboxGroup } from 'common/forms/Checkbox.component';
import ComboBox from 'common/pickers/ComboBox.component';
import MultiSelect from 'common/pickers/MultiSelect.component';
import Select from 'common/pickers/Select.component';
import Conversions from 'components/design-system-demos/formElements/Conversions';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import {
  RiCarLine,
  RiCloseLine,
  RiMoneyDollarBoxLine,
  RiSearchLine,
} from 'react-icons/ri';
import { Item } from 'react-stately';
import styles from './FormElements.module.css';

export default function FormElements() {
  const [value, setValue] = React.useState(0);
  const petField = React.useRef();
  return (
    <Level hClassName="h type-headline-medium">
      <div className={styles.container}>
        <H>Form Elements</H>

        <div className={styles.fields}>
          <Level hClassName="h type-title-large">
            <H>Default Text Fields</H>

            <Level hClassName="h type-title-medium">
              <ul>
                <li>
                  <H>Basic</H>
                  <TextField
                    label="Name"
                    placeholder="Your name"
                    description="Bro just do it"
                  />
                </li>
                <li>
                  <H>isReadOnly</H>
                  <TextField
                    label="Address"
                    value="Copy me or something"
                    isReadOnly
                  />
                </li>
                <li>
                  <H>validationState='valid'</H>
                  <TextField
                    label="Email"
                    value="dont@m.e"
                    validationState="valid"
                    type="email"
                  />
                </li>
                <li>
                  <H>validationState='invalid'</H>
                  <TextField
                    label="Email"
                    value="sabachkamail.ru"
                    validationState="invalid"
                    description="I don't even show up"
                    errorMessage="Just git good"
                  />
                </li>
                <li>
                  <H>isDisabled</H>
                  <TextField
                    label="Email"
                    value="dont@m.e"
                    isDisabled
                    description="This Field is disabled"
                  />
                </li>
                <li>
                  <H>Stress Test</H>
                  <TextField
                    label="Search short-word vehicles"
                    placeholder="eg: car, bike..."
                    validationState="invalid"
                    leftButton={
                      <FieldIconButton aria-label="stress test demo">
                        <RiSearchLine />
                      </FieldIconButton>
                    }
                    leftDecoration={<RiCarLine />}
                    rightDecoration="0/4"
                    rightButton={
                      <FieldIconButton aria-label="demo button">
                        <RiCloseLine />
                      </FieldIconButton>
                    }
                    errorMessage="Must not be empty, and no, pony is not a vehicle"
                  />
                </li>
                <li>
                  <H>With Select</H>
                  <TextField
                    ref={petField}
                    label="Favourite Food for pets"
                    placeholder="what does your pet eat?"
                    leftButton={
                      <FieldSelect
                        aria-label="kind of pet"
                        positionRef={petField}
                      >
                        <Item textValue="test">Cat</Item>
                        <Item>Dog</Item>
                        <Item>Fish</Item>
                      </FieldSelect>
                    }
                  />
                </li>
              </ul>
            </Level>
          </Level>
        </div>

        <div className={styles.fields}>
          <Level hClassName="h type-title-large">
            <H>Number Fields</H>
            <p className="type-body-large">
              Number fields have the same default and quiet styling as Text
              fields. One main difference is the prop `formatOptions` which
              expects a value of type
              <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat">
                `Intl.NumberFormatOptions`
              </a>
            </p>
            <Level hClassName="h type-title-medium">
              <ul>
                <li>
                  <H>Common styles</H>
                  <NumberField
                    leftDecoration={<RiMoneyDollarBoxLine />}
                    label="Amount, $"
                    rightButton={
                      <FieldIconButton aria-label="demo button">
                        <RiCloseLine />
                      </FieldIconButton>
                    }
                  />
                </li>
                <li>
                  <H>Percentages</H>
                  <NumberField
                    label="Sales tax"
                    placeholder="percentage"
                    formatOptions={{ style: 'percent' }}
                  />
                </li>
                <li>
                  <H>Fiat currencies</H>
                  <NumberField
                    label="Currency, $"
                    value={value}
                    onChange={setValue}
                    formatOptions={{ style: 'currency', currency: 'usd' }}
                  />
                </li>
              </ul>
            </Level>
          </Level>
        </div>

        <div className={styles.fields}>
          <Level hClassName="h type-title-large">
            <H>SearchField</H>
            <SearchField aria-label="Search Demo" />
          </Level>
        </div>

        <div className={styles.fields}>
          <Level hClassName="h type-title-large">
            <H>DateField</H>
            <DateField label="Unconfigured date field" />
          </Level>
        </div>

        <div className={styles.fields}>
          <Level hClassName="h type-title-large">
            <H>Corrupted Field</H>
            <CorruptedField
              label="Something went wrong"
              onRetry={() => console.log('retry')}
            />
          </Level>
        </div>

        <div className={styles.fields}>
          <Level hClassName="h type-title-large">
            <H>Pickers</H>
            <Level hClassName="h type-title-medium">
              <ul>
                <li>
                  <H>Default Select</H>
                  <Select
                    label="Choose one"
                    validationState="invalid"
                  >
                    <Item>first value</Item>
                    <Item>second shvalue</Item>
                    <Item>third sha boom boom</Item>
                  </Select>
                </li>
                <li>
                  <H>MultiSelect Select</H>
                  <MultiSelect label="Favourite animal">
                    <Item>Fish</Item>
                    <Item>Cat</Item>
                    <Item>Dog</Item>
                    <Item>Horse</Item>
                  </MultiSelect>
                </li>

                <li>
                  <H>ComboBox</H>
                  <ComboBox label="Favourite animal">
                    <Item>Fish</Item>
                    <Item>Cat</Item>
                    <Item>Dog</Item>
                    <Item>Horse</Item>
                  </ComboBox>
                </li>
              </ul>
            </Level>
          </Level>
        </div>

        <div className={styles.fields}>
          <Level hClassName="h type-title-large">
            <H>Conversions</H>
            <Conversions />
          </Level>
        </div>

        <div className={styles.checkboxes}>
          <Level hClassName="h type-title-large">
            <H>Checkboxes</H>
            <Level hClassName="h type-title-medium">
              <ul>
                <li>
                  <H>Default checkboxs</H>
                  <Checkbox value="unsubscribe">Unsubscribe</Checkbox>
                  <Checkbox
                    value="disabled"
                    isDisabled={true}
                  >
                    Disabled
                  </Checkbox>
                  <Checkbox
                    value="disabled style"
                    isIndeterminate={true}
                  >
                    Indeterminate
                  </Checkbox>
                  <Checkbox
                    value="without label"
                    aria-label="without label"
                  />
                  <Checkbox
                    value="default selected"
                    defaultSelected
                  >
                    Default selected
                  </Checkbox>
                </li>
                <li>
                  <H>Vertical checkbox group</H>
                  <CheckboxGroup
                    defaultValue={['fish']}
                    label="Favourite animals"
                  >
                    <Checkbox value="dog">Dog</Checkbox>
                    <Checkbox value="cat">Cat</Checkbox>
                    <Checkbox value="fish">Fish</Checkbox>
                  </CheckboxGroup>
                </li>
                <li>
                  <H>Horizontal checkbox group</H>
                  <CheckboxGroup
                    orientation="horizontal"
                    defaultValue={['fish']}
                    label="Favourite animals"
                  >
                    <Checkbox value="dog">Dog</Checkbox>
                    <Checkbox value="cat">Cat</Checkbox>
                    <Checkbox value="fish">Fish</Checkbox>
                  </CheckboxGroup>
                </li>
              </ul>
            </Level>
          </Level>
        </div>
      </div>
    </Level>
  );
}
