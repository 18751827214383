import coinActionTypes from './coin.types';

export const fetchCoinDataStart = (coinId) => ({
  type: coinActionTypes.FETCH_COINDATA_START,
  payload: coinId,
});

export const fetchCoinDataSuccess = (data) => ({
  type: coinActionTypes.FETCH_COINDATA_SUCCESS,
  payload: data,
});

export const fetchCoinDataFailure = (err) => ({
  type: coinActionTypes.FETCH_COINDATA_SUCCESS,
  payload: err,
});

export const fetchCoinValueSuccess = (coinValue) => ({
  type: coinActionTypes.FETCH_COIN_VALUE_SUCCESS,
  payload: coinValue,
});

export const onCoinPick = (pickedCoin) => ({
  type: coinActionTypes.ON_COIN_PICK,
  payload: pickedCoin,
});

export const fetchFeeAmountStart = (coin, type) => ({
  type: coinActionTypes.FETCH_FEE_AMOUNT_START,
  payload: { coin, type },
});

export const fetchFeeAmountSuccess = (data) => ({
  type: coinActionTypes.FETCH_FEE_AMOUNT_SUCCESS,
  payload: data,
});

export const fetchFeeAmountFailure = (error) => ({
  type: coinActionTypes.FETCH_FEE_AMOUNT_FAILURE,
  payload: error,
});

export const fetchP2pFeeAmountStart = (coin, type) => ({
  type: coinActionTypes.FETCH_P2P_FEE_AMOUNT_START,
  payload: { coin, type },
});

export const fetchP2pFeeAmountSuccess = (data) => ({
  type: coinActionTypes.FETCH_P2P_FEE_AMOUNT_SUCCESS,
  payload: data,
});

export const fetchP2pFeeAmountFailure = (error) => ({
  type: coinActionTypes.FETCH_P2P_FEE_AMOUNT_FAILURE,
  payload: error,
});
