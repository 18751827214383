// @ts-check

import * as React from 'react';

const NOTICABLE_COLOR = 'yellow';
const isCssVariable = (value) => value?.startsWith('--');
const withInherit = (cssVariable) => `var(${cssVariable}, inherit)`;
const withNoticableFallback = (cssVariable) =>
  `var(${cssVariable}, ${NOTICABLE_COLOR})`;

/**
 * @typedef {"surface" | "surface-variant" | {background: string, color?: string }} SurfaceColor
 * @param {SurfaceColor} surfaceColor
 */
export default function useSurfaceColor(surfaceColor) {
  return React.useMemo(() => {
    let containerColor = '';
    let contentColor = '';

    if (surfaceColor === 'surface') {
      containerColor = 'var(--color-surface)';
      contentColor = 'var(--color-on-surface)';
    } else if (surfaceColor === 'surface-variant') {
      containerColor = 'var(--color-surface-variant)';
      contentColor = 'var(--color-on-surface-variant)';
    } else {
      const { background, color } = surfaceColor;
      // if color is an invalid variable / value it should be noticable by an eye to fix it.
      // we can't detect it otherwise.
      if (isCssVariable(background)) {
        containerColor = withNoticableFallback(background);
      } else {
        containerColor = background || NOTICABLE_COLOR;
      }

      // content color is optional and it makes sense to be inherited most of the time.
      if (isCssVariable(color)) {
        contentColor = withInherit(color);
      } else {
        contentColor = color || 'inherit';
      }
    }

    return {
      '--container-color': containerColor,
      '--content-color': contentColor,
    };
  }, [surfaceColor]);
}
