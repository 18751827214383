import type { Currency } from 'currencies';

const CurrencyFormats = {
  trx: {
    style: 'decimal',
    minimumFractionDigits: 6,
  },
  bch: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  eth: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  ltc: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  btc: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  xrp: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  usdt: {
    style: 'decimal',
    minimumFractionDigits: 4,
  },
  uni: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  usdc: {
    style: 'decimal',
    minimumFractionDigits: 4,
  },
  xlm: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  busd: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  bnb: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  reef: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  shib: {
    style: 'decimal',
    maximumFractionDigits: 0,
  },
  doge: {
    style: 'decimal',
    minimumFractionDigits: 4,
  },
  btt: {
    style: 'decimal',
    maximumFractionDigits: 0,
  },
  ton: {
    style: 'decimal',
    minimumFractionDigits: 5,
  },
  usd: {
    style: 'currency',
    currency: 'usd',
  },
  eur: {
    style: 'currency',
    currency: 'eur',
  },
  gel: {
    style: 'currency',
    currency: 'gel',
  },
  rub: {
    style: 'currency',
    currency: 'rub',
  },
} satisfies Record<
  Currency,
  {
    style: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    currency?: string;
  }
>;

export default CurrencyFormats;
