// @ts-check

import * as React from 'react';
import { isFragment } from 'react-is';
import { SlotProvider, SlotContext, useSlots } from 'context/SlotContext';

/**
 * @param {object} props;
 * @param {React.ReactNode} [props.content]
 * @param {React.ReactNode | ((value: import("context/SlotContext").SlotContextValue) => React.ReactNode)} [props.children]
 */
export function Template({ content, children }) {
  return (
    <SlotProvider content={content}>
      {typeof children === 'function' ? (
        // just {children} would be enaugh but ts won't shut up about it
        <SlotContext.Consumer>{(val) => children(val)}</SlotContext.Consumer>
      ) : (
        children
      )}
    </SlotProvider>
  );
}

/**
 * @typedef {{name?: string, children?: React.ReactNode, [key: string]: any}} SlotProps
 *
 * @param {SlotProps} props
 */
export function Slot({ name, children, ...rest }) {
  const slots = useSlots();

  let replacedContent;

  if (!name && slots.defaults.length > 0) {
    // nemeless slot. render defaults
    replacedContent = getFragmentContent(slots.defaults, rest);
  } else if (slots[name] !== undefined && slots[name] !== null) {
    // named slot. render the slot
    replacedContent = getFragmentContent(slots[name], rest);
  } else {
    // slot not specified. render default content instead
    replacedContent = children;
  }

  return <>{replacedContent}</>;
}

/**
 *
 * @param {React.ReactNode} children
 * @param {any} props
 * @returns
 */
function getFragmentContent(children, props) {
  return React.Children.map(children, (child) => {
    if (
      React.isValidElement(child) &&
      isFragment(child) &&
      typeof child.props.children === 'function'
    ) {
      // Child's signature looks like <>{(props: any)=> React.ReactNode}</>.
      // Call the fragment's inner function with props
      return child.props.children(props);
    }
    // Child is not a react element, or not a fragment, or a fragment that does not have children function.
    // It still has the right to exist though
    return child;
  });
}
