import React from 'react';
import './error.page.styles.css';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  let navigate = useNavigate();

  return (
    <div className="error-page-container">
      <div className="error-page">
        <div className="error-num">
          4<span style={{ color: '#e56907' }}>0</span>4
        </div>
        <span className="error-text">PAGE NOT FOUND</span>
        <span className="error-button" onClick={() => navigate('/')}>
          Back Home
        </span>
      </div>
    </div>
  );
};

export default ErrorPage;
