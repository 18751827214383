// @ts-check
import { useId } from '@react-aria/utils';
import * as React from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import { AnimatePresence, motion } from 'framer-motion';
import cx from 'classnames';
import ButtonBase from 'common/buttons/ButtonBase';
import styles from './Accordion.module.css';

/**
 * @typedef AccordionContextValue
 * @property {(key: React.Key) => boolean} isOpen
 * @property {(key: React.Key) => void} toggle
 *
 * @typedef {React.Context<AccordionContextValue>} AccordionContext
 *
 * @typedef AccordionProps
 * @property {"single" | "multiple"} [selectionMode]
 * @property {React.ReactElement[]} children
 * @property {boolean} [autoOpen]
 *
 *
 * @typedef AccordionItemProps
 * @property {React.ReactNode} summary
 * @property {React.ReactNode} children
 * @property {() => void} [onToggle]
 *
 * @typedef AccordionProviderProps
 * @property {"single" | "multiple"} [selectionMode]
 * @property {React.ReactElement} children
 * @property {string} [defaultOpenId]
 */

/** @param {AccordionProps} props */
export function Accordion({ selectionMode = 'single', children, autoOpen }) {
  const firstChildId = useId();

  return (
    <AccordionProvider
      selectionMode={selectionMode}
      defaultOpenId={autoOpen && firstChildId}
    >
      <ul
        className={styles.listbox}
        aria-label="Accordion Control Button Group"
      >
        {React.Children.map(children, (child, i) => {
          if (i === 0 && autoOpen) {
            return React.cloneElement(child, { defaultId: firstChildId });
          }
          return child;
        })}
      </ul>
    </AccordionProvider>
  );
}

/** @param {AccordionItemProps} props */
export function AccordionItem({ summary, children, onToggle, ...props }) {
  // @ts-ignore
  const defaultId = props.defaultId;
  const id = useId(defaultId);
  const { isOpen: _isOpen, toggle } = React.useContext(AccordionContext);
  const isOpen = _isOpen(id);

  const contentId = useId();

  const handleClick = () => {
    toggle(id);
    onToggle && onToggle();
  };

  return (
    <motion.li className={cx(styles.listItem, { '--isOpen': isOpen })}>
      <ButtonBase
        onPress={handleClick}
        classNames={{
          button: styles.summary,
          focusRing: styles.focusRing,
        }}
        aria-expanded={isOpen}
        aria-controls={contentId}
        baseElevation={0}
        hoverElevation={1}
        pressElevation={1}
        isolateElevation
        elevationType="tint"
      >
        <div
          className={styles.icon}
          aria-hidden={isOpen}
        >
          <RiArrowDownSLine />
        </div>
        <div className={styles.summaryText}>{summary}</div>
      </ButtonBase>
      <div id={contentId}>
        <AnimatePresence>
          {isOpen && children && (
            <motion.div
              className={styles.content}
              variants={{
                open: { height: 'auto', opacity: 1 },
                collapsed: {
                  height: 0,
                  opacity: 0,
                  transition: { height: { delay: 0.2 } },
                },
              }}
              initial={'collapsed'}
              animate={'open'}
              exit={'collapsed'}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.li>
  );
}

/** @type {AccordionContext} */
const AccordionContext = React.createContext(undefined);

/** @param {AccordionProviderProps} props */
function AccordionProvider({ children, selectionMode, defaultOpenId }) {
  const [openIds, setOpenIds] = React.useState(
    defaultOpenId ? [defaultOpenId] : []
  );

  /** @param {string} id */
  const isOpen = (id) => openIds.includes(id);

  /** @param {string} id */
  const toggle = (id) => {
    if (selectionMode === 'single') {
      setOpenIds(isOpen(id) ? [] : [id]);
    } else {
      setOpenIds(
        isOpen(id) ? openIds.filter((_id) => id !== _id) : openIds.concat(id)
      );
    }
  };

  return (
    <AccordionContext.Provider value={{ isOpen, toggle }}>
      {children}
    </AccordionContext.Provider>
  );
}
