//@ts-check
import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { useLocale } from 'context/LanguageContext';
import { useFormik } from 'formik';
import useThemeVariables from 'hooks/useThemeVariables';
import qs from 'qs';
import React, { useState } from 'react';
import { H } from 'react-accessible-headings';
import { useNavigate } from 'react-router-dom';
import { object, ref, string } from 'yup';
import styles from './PasswordRecovery.module.css';

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

function useFormError(errorMap, resetDeps = []) {
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    formError: errorMap[errorKey],
    setFormError: setErrorKey,
  };
}

function PasswordRecovery() {
  const [successfulChange, setSuccessfulChange] = useState(false);
  const { LL } = useLocale();

  const { colorTest4 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      confirmEmail: '',
    },
    validationSchema: object({
      email: string().required('Required'),
      confirmEmail: string()
        .oneOf([ref('email'), null], "Emails don't match")
        .required('Required'),
    }),
    onSubmit: async (values, { resetForm, setTouched }) => {
      let data = {};
      data = {
        email: values.email,
        confirmEmail: values.confirmEmail,
      };
      try {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URI}users/password-recovery/send-email`,
          data: qs.stringify(data),
        });

        setFormError(null);
        setSuccessfulChange(true);
        setTimeout(() => {
          setSuccessfulChange(false);
        }, 5000);
      } catch (error) {
        setTouched({});
        console.log(error);
        if (error.response && errorMap[error.response.data.error]) {
          console.log('Error: ', error.response.data.error);

          setFormError(error.response.data.error);
        } else {
          console.warn('PIN_Verification unexpected error: ', error);
          setFormError('ERR_GENERIC');
        }
      }
      resetForm();
    },
  });

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  const errorMap = {
    ERR_GENERIC: 'Unexpected error. please try again later',
    EMAILS_DO_NOT_MATCH: 'Emails do not match',
    USER_DOES_NOT_EXIST: 'User with this email does not exist',
  };

  const { formError, setFormError } = useFormError(errorMap, []);
  const navigate = useNavigate();
  return (
    <div className={styles.pageContainer}>
      <Surface
        elementType="form"
        onSubmit={formik.handleSubmit}
        surfaceColor={surfaceColor}
        className={styles.container}
      >
        <div className={styles.formBody}>
          <div className={styles.header}>
            <H className={styles.heading}>{LL.passwordRecovery.header()}</H>
            <OutlineButton onClick={() => navigate('/')}>
              {LL.passwordRecovery.signIn()}
            </OutlineButton>
          </div>
          <ErrorContainer>
            {formError ? (
              <InlineError
                onClose={() => setFormError(null)}
                success={false}
              >
                {formError}
              </InlineError>
            ) : (
              successfulChange && (
                <InlineError
                  onClose={() => setSuccessfulChange(false)}
                  success={true}
                >
                  {LL.passwordRecovery.emailSent()}
                </InlineError>
              )
            )}
          </ErrorContainer>
          <H className={styles.subHeading}>
            {LL.passwordRecovery.description()}
          </H>
          <div className={styles.input}>
            <TextField
              label={
                <span className={styles.label}>
                  {LL.passwordRecovery.recoveryEmail()}
                </span>
              }
              placeholder=""
              value={formik.values.email}
              onChange={(val) => formik.setFieldValue('email', val, false)}
              onBlur={handleBlur('email')}
              validationState={getValidationState(formik, 'email')}
              errorMessage={formik.errors.email}
            />
            <TextField
              label={
                <span className={styles.label}>
                  {LL.passwordRecovery.confirmRecoveryEmail()}
                </span>
              }
              placeholder=""
              value={formik.values.confirmEmail}
              onChange={(val) =>
                formik.setFieldValue('confirmEmail', val, false)
              }
              onBlur={handleBlur('confirmEmail')}
              validationState={getValidationState(formik, 'confirmEmail')}
              errorMessage={formik.errors.confirmEmail}
            />
          </div>
        </div>
        <div className={styles.formSubmit}>
          <OutlineButton
            attachesBackground
            pressesBackground
            type="reset"
            onPress={formik.handleReset}
          >
            {LL.passwordRecovery.resetFields()}
          </OutlineButton>
          <FilledButton
            attachesBackground
            pressesBackground
            type="submit"
            isDisabled={
              !formik.values.email ||
              !formik.values.confirmEmail ||
              formik.isSubmitting
            }
          >
            {LL.passwordRecovery.sendEmail()}
          </FilledButton>
        </div>{' '}
      </Surface>
    </div>
  );
}

export default PasswordRecovery;
