import {
  QueryClient,
  QueryClientProvider as QUERY_CLIENT_PROVIDER,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';

export const queryClient = new QueryClient({
  logger: {
    log: (...args) => {
      // Log debugging information
    },
    warn: (...args) => {
      // Log warning
    },
    error: (...args) => {
      // Log error
    },
  },
});

export function QueryClientProvider({ children }) {
  return (
    <QUERY_CLIENT_PROVIDER client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QUERY_CLIENT_PROVIDER>
  );
}
