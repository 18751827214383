// @ts-check

import * as React from 'react';
import FieldWrapper from './FieldWrapper';
import FieldBase from './FieldBase';
import { useTextField } from '@react-aria/textfield';
import styles from './DefaultField.module.css';
import useDefaultRef from 'hooks/useDefaultRef';

/**
 * @typedef {Omit<React.ComponentProps<FieldWrapper>, "classNames" | "children">} WrapperProps
 */

/**
 * @typedef TextFieldProps
 * @property {string} [id]
 * @property {"input" | "textarea"} [inputElementType="input"]
 * @property {string} [value]
 * @property {string} [defaultValue]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {string} [placeholder]
 * @property {React.ReactNode} [label]
 * @property {"valid" | "invalid" | (undefined | null)} [validationState]
 * @property {number} [maxLength]
 * @property {number} [minLength]
 * @property {boolean} [isReadOnly]
 * @property {React.ReactNode} [description]
 * 👆 A description for the field. Provides a hint such as
 *    specific requirements for what to choose.
 * @property {React.ReactNode} [errorMessage]
 * @property {'text' | 'search' | 'url' | 'tel' | 'email' | 'password'} [type]
 * @property {'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'} [inputMode]
 * 👆 Hints at the type of data that might be entered by the user
 *    while editing the element or its contents. See MDN.
 * @property {(value: string) => void} [onChange]
 * 👆 Handler that is fired when user modifies the value and moves focus away from input.
 *    It's fired just before onBlur.
 * @property {React.FormEventHandler<HTMLInputElement>} [onBeforeInput]
 * 👆 Handler that is called when the input value is about to be modified.
 *    See [MDN](https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/beforeinput_event).
 * @property {React.FormEventHandler<HTMLInputElement>} [onInput]
 * 👆 Handler that is called when the input value is modified.
 *    See [MDN](https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/input_event).
 * @property {(e: React.FocusEvent) => void} [onFocus]
 * @property {(e: React.FocusEvent) => void} [onBlur]
 * @property {(isFocused: boolean) => void} [onFocusChange]
 * @property {(e: React.KeyboardEvent) => void} [onKeyDown]
 * @property {(e: React.KeyboardEvent) => void} [onKeyUp]
 * @property {boolean} [autoFocus]
 * @property {string} [autocomplete]
 * @property {boolean} [excludeFromTabOrder]
 * 👆 Whether to exclude the element from the sequential tab order.
 *    If true, the element will not be focusable via the keyboard by tabbing.
 *    This should be avoided except in rare scenarios where an alternative means of
 *    accessing the element or its functionality via the keyboard is available.
 * @property {string} [aria-activedescendant]
 * 👆 Identifies the currently active element when DOM focus is on a composite
 *    widget, textbox, group, or application.
 * @property {'none' | 'inline'| 'list'| 'both'} [aria-autocomplete]
 * 👆 Indicates whether inputting text could trigger display of one or more
 *    predictions of the user's intended value for an input and specifies how
 *    predictions would be presented if they are made.
 * @property {boolean | 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'} [aria-haspopup]
 * 👆 indicates the availability and type of interactive popup element,
 *    such as menu or dialog, that can be triggered by an element.
 * @property {string} [aria-label]
 * 👆 Defines a string value that labels the current element.
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * 👆 Identifies the element (or elements) that provide a detailed, extended description for the object.
 */

/** @param {WrapperProps & TextFieldProps} props */
function TextFieldWithRef(
  {
    leftButton,
    leftDecoration,
    rightDecoration,
    rightButton,

    ...textFieldProps
  },
  parentRef
) {
  const ref = useDefaultRef(parentRef);
  const { inputProps, labelProps, descriptionProps, errorMessageProps } =
    useTextField(textFieldProps, ref);

  return (
    <FieldWrapper
      leftButton={leftButton}
      leftDecoration={leftDecoration}
      rightDecoration={rightDecoration}
      rightButton={rightButton}
      validationState={textFieldProps.validationState}
      isDisabled={textFieldProps.isDisabled}
      classNames={styles}
    >
      <FieldBase
        ref={ref}
        classNames={styles}
        validationState={textFieldProps.validationState}
        inputElementType={textFieldProps.inputElementType || 'input'}
        isDisabled={textFieldProps.isDisabled}
        //
        label={textFieldProps.label}
        description={textFieldProps.description}
        errorMessage={textFieldProps.errorMessage}
        //
        inputProps={inputProps}
        labelProps={labelProps}
        descriptionProps={descriptionProps}
        errorMessageProps={errorMessageProps}
      />
    </FieldWrapper>
  );
}

const TextField = React.forwardRef(TextFieldWithRef);
export default TextField;
