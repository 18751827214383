// @ts-check
import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import IconButton from 'common/buttons/IconButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import FormDivider from 'common/dividers/FormDivider.component';
import TextField from 'common/fields/TextField';
import ComboBox from 'common/pickers/ComboBox.component';
import DetailedFee from 'components/content/DetailedFee.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { ConversionGroup } from 'components/conversion/ConversionGroup';
import CurrencyField from 'components/currency-fields/CurrencyField.component';
import { DepositPicker } from 'components/currency-fields/DepositPicker.component';
import FeePicker from 'components/currency-fields/FeePicker.component';
import NetworkPicker, {
  getInitialSelectedKey,
} from 'components/currency-fields/NetworkPicker.component';
import ConversionErrorView from 'components/error-views/ConversionErrorView';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useExchangeContext } from 'context/ExchangeContext';
import { useLocale } from 'context/LanguageContext';
import { fiatCurrencies } from 'currencies';
import { ConversionError } from 'errors';
import { ErrorMap } from 'errors/ErrorMap';
import { useBalancesQuery } from 'queries/balances';
import { useFiatUrlQuery } from 'queries/fiatUrl';
import { useTemplateAddressesQuery } from 'queries/templateAddresses';
import * as React from 'react';
import { RiDeleteBin2Line, RiSendPlaneFill } from 'react-icons/ri';
import { Item } from 'react-stately';
import OutstandingFiatTransaction from './exchangeComponents/OutstandingFiatTransaction.component';
import styles from './SendTab.module.css';

function getValidationState(sendForm, field) {
  return !sendForm.touched[field]
    ? null
    : sendForm.errors[field]
    ? 'invalid'
    : 'valid';
}

export default function SendTab() {
  const sendForm = useExchangeContext().send;
  const { LL } = useLocale();
  const { conversionCurrency } = useCurrencyAndThemeContext();
  const { data, refetch } = useFiatUrlQuery({
    // @ts-ignore
    enabled: fiatCurrencies.includes(sendForm.values.coin),
    refetchInterval: (data) => data?.is_outstanding && 6000,
  });
  const [addressSave, setAddressSave] = React.useState(false);
  const [templateDeleted, setTemplateDeleted] = React.useState(false);
  const [fetchedData, setFetchedData] = React.useState(false);

  const handleBlur = (field) => () => {
    if (field === 'amount') {
      if (sendForm.values.amount) sendForm.setFieldTouched(field, true);
    } else if (field !== 'coin') sendForm.setFieldTouched(field, true);
    // if (field !== 'coin') sendForm.validateField(field);
  };

  const balances = useBalancesQuery(true);
  const templates = useTemplateAddressesQuery({
    enabled: !fetchedData,
    onSuccess: () => {
      setFetchedData(true);
    },
  });

  async function saveAddressTemplate() {
    const data = {
      templateName: sendForm.values.templateName,
      destinationAddress: sendForm.values.address,
      destinationTag: sendForm.values.tag,
      coin: sendForm.values.coin,
      network: sendForm.values.network,
    };
    try {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}templates/address`,
        data: data,
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      setAddressSave(true);
      setTimeout(() => {
        setAddressSave(false);
      }, 5000);
    } catch (error) {
      sendForm.setServerError(error.response.data.error);
    }
  }

  async function deleteAddressTemplate(templateId) {
    try {
      await axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URI}templates/address`,
        params: { templateId },
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      setTemplateDeleted(true);
      setTimeout(() => {
        setTemplateDeleted(false);
      }, 5000);
    } catch (error) {
      sendForm.setServerError(error.response.data.error);
    }
  }

  return (
    <form
      onSubmit={sendForm.handleSubmit}
      onReset={sendForm.handleReset}
      className={styles.container}
    >
      <div className={styles.left}>
        <div ref={sendForm.statusRef}>
          <ErrorContainer>
            {sendForm.serverError ? (
              <InlineError
                onClose={() => sendForm.setServerError(null)}
                success={false}
              >
                {sendForm.serverError}
              </InlineError>
            ) : (
              data?.is_outstanding &&
              // @ts-ignore
              fiatCurrencies.includes(sendForm.values.coin) && (
                <InlineError
                  onClose={() => {}}
                  success={false}
                >
                  {LL.exchange.serverErrors.outstandingPayment()}
                </InlineError>
              )
            )}
            {sendForm.success && (
              <InlineError
                onClose={() => sendForm.setSuccess(false)}
                success={true}
              >
                {sendForm.url
                  ? 'Please press the Withdraw button and you will be redirected to the payment URL'
                  : 'Transaction Successful!'}
              </InlineError>
            )}
            {addressSave && (
              <InlineError
                onClose={() => setAddressSave(false)}
                success={true}
              >
                {'Template Saved!'}
              </InlineError>
            )}
            {templateDeleted && (
              <InlineError
                onClose={() => setAddressSave(false)}
                success={true}
              >
                {'Template deleted!'}
              </InlineError>
            )}
          </ErrorContainer>
        </div>
        {data?.is_outstanding &&
          // @ts-ignore
          fiatCurrencies.includes(sendForm.values.coin) && (
            <OutstandingFiatTransaction data={data} />
          )}
        {!balances.isLoading && balances.data.length === 0 ? (
          LL.exchange.noDepositMessage()
        ) : (
          <DepositPicker
            label={LL.exchange.balance()}
            defaultText={LL.exchange.chooseBalance()}
            selectedKey={sendForm.values.coin}
            onSelectionChange={async (key) => {
              // @ts-ignore
              if (fiatCurrencies.includes(key)) {
                refetch();
                sendForm.setServerError(null);
                sendForm.setSuccess(false);
              }
              await sendForm.resetForm({
                values: {
                  address: '',
                  tag: '',
                  amount: NaN,
                  coin: 'usd',
                  comment: '',
                  comment_to: '',
                  fee_type: 'standard',
                  network: '',
                  xrpOrXlm: false,
                  templateName: '',
                },
              });
              const network = await getInitialSelectedKey(key + '');
              sendForm.setFieldValue('coin', key, false);
              sendForm.setFieldValue('network', network.network_name, false);
              key === 'xlm' ||
              key === 'xrp' ||
              key === 'ton' ||
              (key === 'usdt' && network.network_name === 'TON')
                ? sendForm.setFieldValue('xrpOrXlm', true, false)
                : sendForm.setFieldValue('xrpOrXlm', false, false);
            }}
            onBlur={handleBlur('coin')}
            validationState={getValidationState(sendForm, 'coin')}
            errorMessage={sendForm.errors.coin}
          />
        )}
        {!fiatCurrencies.includes(
          // @ts-ignore
          sendForm.values.coin
        ) ? (
          <ErrorMap
            errors={new Map([[ConversionError, <ConversionErrorView />]])}
          >
            {!templates.isLoading && templates.data && (
              <ComboBox
                id="send-form"
                label={LL.exchange.destinationAddress()}
                inputValue={sendForm.values.address}
                defaultItems={
                  sendForm.values.coin
                    ? templates.data.filter((template) =>
                        sendForm.values.address.length < 8
                          ? template.coin === sendForm.values.coin &&
                            template.template_name
                              .toLocaleLowerCase()
                              .includes(sendForm.values.address)
                          : template.coin === sendForm.values.coin
                      )
                    : templates.data.filter((template) =>
                        sendForm.values.address.length < 8
                          ? template.template_name
                              .toLocaleLowerCase()
                              .includes(sendForm.values.address)
                          : template
                      )
                }
                onInputChange={(val) => {
                  sendForm.setFieldValue('address', val, false);
                }}
                onSelectionChange={(key) => {
                  const currData = templates.data.find(
                    (template) => template.id === key
                  );
                  if (currData) {
                    currData.coin === 'xrp' ||
                    currData.coin === 'xlm' ||
                    currData.coin === 'ton' ||
                    (currData.coin === 'usdt' && currData.network === 'TON')
                      ? sendForm.setFieldValue('xrpOrXlm', true, false)
                      : sendForm.setFieldValue('xrpOrXlm', false, false);
                    sendForm.setFieldValue('coin', currData.coin, false);
                    sendForm.setFieldValue(
                      'tag',
                      currData.destination_tag,
                      false
                    );
                    sendForm.setFieldValue('network', currData.network, false);
                  }
                }}
                onFocus={() =>
                  // document
                  //   .getElementById('send-form')
                  //   .scrollIntoView({ block: 'center', inline: 'center' })
                  window.scrollTo(0, 100)
                }
                onBlur={handleBlur('address')}
                validationState={getValidationState(sendForm, 'address')}
                errorMessage={sendForm.errors.address}
                allowsCustomValue
                noFilter
              >
                {(template) => (
                  <Item textValue={template.destination_address}>
                    <div className={styles.comboboxItems}>
                      {template.template_name} (
                      {template.coin.toLocaleUpperCase()})
                      <IconButton
                        aria-label={template.id}
                        onClick={async () => {
                          await deleteAddressTemplate(template.id);
                          sendForm.handleReset();
                          await templates.refetch();
                        }}
                      >
                        <RiDeleteBin2Line
                          style={{
                            display: 'inline-flex',
                            alignSelf: 'flex-end',
                            color: 'var(--md-ref-palette-error-50)',
                          }}
                        />
                      </IconButton>
                    </div>
                  </Item>
                )}
              </ComboBox>
            )}
            {sendForm.values.xrpOrXlm && (
              <TextField
                label="Destination Tag"
                value={sendForm.values.tag}
                onChange={(val) => sendForm.setFieldValue('tag', val, false)}
                onBlur={handleBlur('tag')}
                validationState={getValidationState(sendForm, 'tag')}
                // @ts-ignore
                errorMessage={sendForm.errors.tag}
                isDisabled={!sendForm.values.coin}
              />
            )}
            <ConversionGroup
              currency={sendForm.values.coin}
              // Value can change from useExchangeContext().navigate({amount:123}) so it needs to be set here
              value={sendForm.values.amount}
              onChange={(val) => sendForm.setFieldValue('amount', val, false)}
            >
              <fieldset className={styles.amount}>
                <CurrencyField
                  currency={sendForm.values.coin}
                  label={LL.exchange.amountLabel({
                    currency: sendForm.values.coin,
                  })}
                  onBlur={handleBlur('amount')}
                  validationState={getValidationState(sendForm, 'amount')}
                  errorMessage={sendForm.errors.amount}
                  isDisabled={!sendForm.values.coin}
                />
                <CurrencyField
                  label={LL.exchange.conversionAmountLabel({
                    currency: conversionCurrency,
                  })}
                  currency={conversionCurrency}
                  onBlur={handleBlur('amount')}
                  validationState={getValidationState(sendForm, 'amount')}
                  isDisabled={!sendForm.values.coin}
                />
              </fieldset>
            </ConversionGroup>
            <TextField
              inputElementType="textarea"
              label={LL.exchange.description()}
              placeholder={LL.exchange.descriptionPlaceholder()}
              isDisabled={!sendForm.values.coin}
            />
            <FormDivider
              title={LL.exchange.advanced.title()}
              containerClassName={styles.advanced}
            >
              <FeePicker
                label={LL.exchange.transactionFee()}
                onSelectionChange={(value) => {
                  sendForm.setFieldValue('fee_type', value, false);
                }}
                isDisabled={!sendForm.values.coin}
              />
              <NetworkPicker
                label={LL.exchange.networkStandard()}
                coin={sendForm.values.coin}
                selectedKey={sendForm.values.network}
                onSelectionChange={(value) => {
                  sendForm.setFieldValue('network', value, false);
                  sendForm.values.coin === 'usdt' && value === 'TON'
                    ? sendForm.setFieldValue('xrpOrXlm', true, false)
                    : sendForm.setFieldValue('xrpOrXlm', false, false);
                }}
                isDisabled={!sendForm.values.coin}
              />
            </FormDivider>
            <div className={styles.advancedTemplate}>
              <TextField
                label={LL.exchange.templateName()}
                value={sendForm.values.templateName}
                onChange={(val) =>
                  sendForm.setFieldValue('templateName', val, false)
                }
                onBlur={handleBlur('templateName')}
                validationState={getValidationState(sendForm, 'templateName')}
                errorMessage={sendForm.errors.templateName}
                isDisabled={
                  !sendForm.values.coin ||
                  !sendForm.values.address ||
                  !sendForm.values.coin ||
                  !sendForm.values.network
                }
              />
              <OutlineButton
                isDisabled={
                  !sendForm.values.coin ||
                  !sendForm.values.address ||
                  !sendForm.values.coin ||
                  !sendForm.values.network
                }
                onPress={async () => {
                  await saveAddressTemplate();
                }}
              >
                {LL.exchange.saveTemplate()}
              </OutlineButton>
            </div>
          </ErrorMap>
        ) : (
          <ConversionGroup
            currency={sendForm.values.coin}
            // Value can change from useExchangeContext().navigate({amount:123}) so it needs to be set here
            value={sendForm.values.amount}
            onChange={(val) => sendForm.setFieldValue('amount', val, false)}
          >
            <fieldset className={styles.amount}>
              <CurrencyField
                currency={sendForm.values.coin}
                label={LL.exchange.amountLabel({
                  currency: sendForm.values.coin,
                })}
                onBlur={handleBlur('amount')}
                validationState={getValidationState(sendForm, 'amount')}
                errorMessage={sendForm.errors.amount}
                isDisabled={!sendForm.values.coin}
              />
              <CurrencyField
                label={LL.exchange.conversionAmountLabel({
                  currency: conversionCurrency,
                })}
                currency={conversionCurrency}
                onBlur={handleBlur('amount')}
                validationState={getValidationState(sendForm, 'amount')}
                isDisabled={!sendForm.values.coin}
              />
            </fieldset>
          </ConversionGroup>
        )}
      </div>

      <div className={styles.right}>
        <DetailedFee
          currency={sendForm.values.coin}
          feeType="transfer"
          payload={{
            coin: sendForm.values.coin,
            type: sendForm.values.fee_type,
            network: sendForm.values.network,
          }}
          amount={sendForm.values.amount}
          classNames={styles}
        />
      </div>
      <div className={styles.actions}>
        <OutlineButton
          attachesBackground
          pressesBackground
          onPress={() => {
            sendForm.setSuccess(false);
            sendForm.setServerError(null);
            sendForm.handleReset();
          }}
        >
          {LL.app.reset()}
        </OutlineButton>
        {sendForm.success && sendForm.url ? (
          <a
            href={sendForm.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FilledButton>{LL.exchange.send.finalizeOrder()}</FilledButton>
          </a>
        ) : (
          <FilledButton
            attachesBackground
            pressesBackground
            left={<RiSendPlaneFill />}
            isDisabled={
              !sendForm.values.coin ||
              sendForm.isSubmitting ||
              (data?.is_outstanding &&
                // @ts-ignore
                fiatCurrencies.includes(sendForm.values.coin))
            }
            onPress={() => {
              sendForm.handleSubmit();
              sendForm.scrollToStatus();
            }}
          >
            {fiatCurrencies.includes(
              // @ts-ignore
              sendForm.values.coin
            )
              ? LL.exchange.send.generateOrder()
              : LL.app.send()}
          </FilledButton>
        )}
      </div>
    </form>
  );
}
