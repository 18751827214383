// @ts-check
import { Item } from '@react-stately/collections';
import Select from 'common/pickers/Select.component';
import {
  currencyNetworksKey,
  getCurrencyNetworks,
  useCurrencyNetworksQuery,
} from 'queries/currencyNetworks';
import { queryClient } from 'queries/queryClient';
import * as React from 'react';
import styles from './NetworkPicker.module.css';

/**
 * Gets first item of CurrencyPicker. Usfull when you want to display first item by default
 * when coin changes
 * @param {string} coin
 **/
export async function getInitialSelectedKey(coin) {
  const networks = await queryClient.fetchQuery(
    currencyNetworksKey,
    getCurrencyNetworks
  );
  return networks[coin][0];
}

/**
 * @typedef Props
 * @property {(key: import('types').NetworkType) => void} onSelectionChange
 * @property {string | null} selectedKey // shorthand
 * @property {boolean} [allowSingleItemSelect]
 * @property {boolean} [explorer]
 * @property {string | null} coin
 * @property {React.ReactNode} [label]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {boolean} [autoFocus]
 * @property {( e : React.FocusEvent ) => void} [onFocus]
 * @property {( e : React.FocusEvent ) => void} [onBlur]
 * @property {( isFocused : boolean ) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyUp]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {React.MutableRefObject<HTMLElement>} [positionRef]
 *
 * @param {Props} props
 */
export default function NetworkPicker(props) {
  const networks = useCurrencyNetworksQuery();

  if (networks.isError) {
    console.log(networks.error);
    return <div />;
  }

  if (networks.isLoading) {
    if (props.allowSingleItemSelect) {
      return (
        <Select
          items={[]}
          isLoading={networks.isLoading}
          {...props}
        >
          {/* @ts-ignore */}
          {() => {}}
        </Select>
      );
    } else {
      return <div />;
    }
  }

  /** if no specific coin is provided component works on a list of all possible networks */
  const coinNetworks = props.coin
    ? networks.data[props.coin]
    : getAllNetworks(networks.data);

  if (props.explorer && coinNetworks.length === 1) {
    return <div style={{ marginLeft: '0.2rem' }}></div>;
  } else if (!props.allowSingleItemSelect && coinNetworks.length === 1) {
    return (
      <SingleNetworkVariant
        label={props.label}
        networkName={props.selectedKey}
      />
    );
  }

  return (
    <Select
      items={coinNetworks}
      selectedKey={coinNetworks[props.coin]}
      {...props}
    >
      {(/** @type {typeof coinNetworks[0]} */ network) => (
        <Item key={network.network_name}>
          {/* @ts-ignore */}
          {network.network_name}
        </Item>
      )}
    </Select>
  );
}

function SingleNetworkVariant({ label, networkName }) {
  return (
    <dl className={styles.singleValue}>
      <dt>{label}: </dt>
      <dd>{networkName}</dd>
    </dl>
  );
}

/** @param {import('types').CurrencyNetworks} currencyNetworks */
function getAllNetworks(currencyNetworks) {
  return Object.values(currencyNetworks).flat();
}
