// @ts-check
import * as React from 'react';
import styles from './FormDivider.module.css';

/**
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode} props.title
 * @param {string} [props.containerClassName]
 */
export default function FormDivider({ children, title, containerClassName }) {
  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.legend}>{title}</legend>
      <div className={containerClassName}>{children}</div>
    </fieldset>
  );
}
