// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';

/**
 * @typedef {Object} data
 */

export async function getExplorerData({ coin, network, txid, atm }) {
  const { data } = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URI}tx/${coin}/${
      txid.startsWith('PBXINTRA') ? 'PBX' : network
    }/${txid}${atm ? `?atm=${atm}` : ''}`,
  });
  if (!data) {
    throw new Error('Transaction not found');
  }
  return data;
}

/**
 * @param {object} payload
 * @param {string} payload.coin
 * @param {string} payload.network
 * @param {string} payload.txid
 * @param {string} payload.atm
 * @param {{enabled?: boolean, retry?: boolean, onSuccess?: any, onError?: any}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<getExplorerData, Error>}
 */

export function useExplorerDataQuery({ coin, network, txid, atm }, options) {
  const [refetchCounter, setRefetchCounter] = useState(0);

  return useQuery(
    ['explorerData', { coin, network, txid, atm }],
    () => getExplorerData({ coin, network, txid, atm }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: () =>
        refetchCounter === 1 || refetchCounter === 2 || refetchCounter === 3
          ? 6000
          : refetchCounter === 4
          ? 30000
          : refetchCounter === 5
          ? 60000
          : false,
      ...options,
      onSettled: () => {
        setRefetchCounter(
          refetchCounter === 1
            ? refetchCounter + 3
            : refetchCounter === 2
            ? refetchCounter + 2
            : refetchCounter + 1
        );
      },
    }
  );
}
