import { useLocale } from 'context/LanguageContext';
import React from 'react';
import { H } from 'react-accessible-headings';
import EmailChange from './EmailChange.component';
import NotificationUrlUpdate from './NotificationUrlUpdate.component';
import PasswordChange from './PasswordChange.component';
import PhoneNumberChange from './PhoneNumberChange.component';
import styles from './Profile.module.css';

function Profile() {
  const { LL } = useLocale();
  return (
    <div>
      <H className={styles.heading}>{LL.profile.header()}</H>
      <div className={styles.container}>
        <EmailChange />
        <PasswordChange />
        <NotificationUrlUpdate />
        <PhoneNumberChange />
      </div>
    </div>
  );
}

export default Profile;
