import Footer from 'components/footer/footer.component';
import { ExplorerPovider } from 'context/ExplorerContext';
import useThemeVariables from 'hooks/useThemeVariables';
import Explorer from 'pages/explorer/Explorer.component';
import React from 'react';
import { Outlet, useNavigate } from 'react-router';
import { hexToRGBA } from 'utils';
import LogoDark from '../assets/mobile-logo-dark.png';
import Logo from '../assets/mobile-logo.png';
import styles from './LandingLayout.module.css';

const LandingLayout = () => {
  const { isDarkTheme, colorOnSuccess, MdRefPaletteTertiary95 } =
    useThemeVariables();

  const navigate = useNavigate();

  return (
    // @ts-ignore
    <sentient-background
      id="page"
      class={styles.sentientBackground}
      // highlightRgba={hexToRGBA(highlightRGBA)}
      highlightRgba={hexToRGBA(
        isDarkTheme ? colorOnSuccess : MdRefPaletteTertiary95
      )}
      itemRgba={hexToRGBA(
        isDarkTheme ? colorOnSuccess : MdRefPaletteTertiary95
      )}
      cellsize={24}
      highlightradius={200}
      highlightwidth={12}
      itemthickness={2}
    >
      <div className={styles.explorer}>
        <img
          src={isDarkTheme ? LogoDark : Logo}
          className={styles.img}
          onClick={() => {
            navigate('/');
          }}
        />
        <ExplorerPovider>
          <Explorer />
        </ExplorerPovider>
      </div>
      <Outlet />
      <Footer />
      {/* @ts-ignore */}
    </sentient-background>
  );
};

export default LandingLayout;
