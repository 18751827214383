// @ts-check
import * as React from 'react';
import * as Tokens from '../styles';
import darkTheme from '../styles/js/dark-theme';
import lightTheme from '../styles/js/light-theme';
import { useSelector } from 'react-redux';

export default function useThemeVariables() {
  // @ts-ignore
  const isDarkTheme = useSelector((state) => state.theme.dark);

  return React.useMemo(() => {
    const themeVaraibles = isDarkTheme ? darkTheme : lightTheme;

    return { ...Tokens, ...themeVaraibles, isDarkTheme };
  }, [isDarkTheme]);
}
