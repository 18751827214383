// @ts-check
import { Item } from '@react-stately/collections';
import ComboBox from 'common/pickers/ComboBox.component';
import * as React from 'react';

/**
 *
 *
 * @typedef Props
 * @property {boolean} [isInline] whether to use inline variant (as a companion to a field)
 * @property {string} [defaultText]
 * @property {React.ReactNode} [label]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {string} [placeholder]
 * @property {string | null} [selectedKey] // shorthand
 * @property {string} [inputValue]
 * @property {(key : React.Key) => any} [onSelectionChange]
 * @property {(value : string ) => void} [onInputChange]
 * @property {boolean} [autoFocus]
 * @property {boolean} [allowsCustomValue]
 * @property {( e : React.FocusEvent ) => void} [onFocus]
 * @property {( e : React.FocusEvent ) => void} [onBlur]
 * @property {( isFocused : boolean ) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyUp]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {React.MutableRefObject<HTMLElement>} [positionRef]
 * @property {array} [locations]
 *
 * @param {Props} props
 */

export default function LocationPicker(props) {
  const locations = props.locations;

  return (
    locations &&
    (locations[0].phone_code ? (
      <ComboBox {...props}>
        {locations.map((el) => (
          <Item textValue={el.phone_code}>
            ({el.country_code}) {el.phone_code}
          </Item>
        ))}
      </ComboBox>
    ) : (
      <ComboBox {...props}>
        {locations.map((el) => (
          <Item
            key={el.id}
            textValue={el.name}
          >
            {el.name}
          </Item>
        ))}
      </ComboBox>
    ))
  );
}
