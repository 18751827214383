// @ts-check
import big from 'bigjs-literal/macro';
import cx from 'classnames';
import Currency from 'components/content/Currency.component';
import { useCurrencyAndThemeContext } from 'context/CurrencyAndThemeContext';
import { useLocale } from 'context/LanguageContext';
import { useBalancesQuery } from 'queries/balances';
import { useRatesQuery } from 'queries/rates';
import * as React from 'react';
import styles from './BalanceInfo.module.css';

/**
 * @typedef {React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>} BalanceInfoProps
 * @param {BalanceInfoProps} props
 */
function BalanceInfo({ className, ...props }) {
  const { LL } = useLocale();
  const { data: balances } = useBalancesQuery(true);
  const { data: rates } = useRatesQuery();
  const { conversionCurrency } = useCurrencyAndThemeContext();

  if (!balances || !rates) return <></>;

  try {
    const totalBalance = balances.reduce((a, b) => {
      return +big`${a} + ${b.confirmed_balance} * ${
        rates[b.coin][conversionCurrency]
      }`;
    }, 0);

    return (
      <span
        className={cx(className, styles.container)}
        {...props}
      >
        <strong className={styles.big}>
          <Currency currency={conversionCurrency}>{totalBalance}</Currency>
        </strong>
        <span>
          {' '}
          {LL.markets.totalWallets({ walletsCount: balances.length })}{' '}
        </span>
      </span>
    );
  } catch (error) {
    console.log(error);
  }
}

export default BalanceInfo;
