import {
  FETCH_INVOICE_START,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAIL,
} from './invoice.types';

const INITIAL_STATE = {
  invoice: {},
  isFetching: false,
  error: null,
};

const invoiceReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_INVOICE_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        invoice: payload,
        error: null,
      };
    case FETCH_INVOICE_FAIL:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default invoiceReducer;
