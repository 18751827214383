// @ts-check

import Tabs from 'common/tabs/Tabs.component';
import { NavBar, NavBarItem } from 'components/navigation/NavBar';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import { Item } from 'react-stately';
import styles from './NavigationElements.module.css';

export default function NavigationElements() {
  return (
    <div className={styles.container}>
      <Level>
        <H className={'type-headline-medium'}>Navigation Elements</H>

        <Level>
          <H className={'type-headline-small'}>NavBar</H>

          <div className={styles.navBars}>
            <NavBar
              orientation="vertical"
              aria-label={'main navigation vertical'}
              className={styles.navBar}
            >
              <NavBarItem to="overview">
                <span slot="label">Overview</span>
              </NavBarItem>
              <NavBarItem to="my-wallets">
                <span slot="label">My Wallets</span>
              </NavBarItem>
              <NavBarItem to="exchange">
                <span slot="label">Exchange</span>
              </NavBarItem>
              <NavBarItem to="transactions">
                <span slot="label">Transactions</span>
              </NavBarItem>
              <NavBarItem to="settings">
                <span slot="label">Settings</span>
              </NavBarItem>
            </NavBar>

            <NavBar
              orientation="horizontal"
              aria-label={'main navigation horizontal'}
              className={styles.navBar}
            >
              <NavBarItem to="overview">
                <span slot="label">Overview</span>
              </NavBarItem>
              <NavBarItem to="my-wallets">
                <span slot="label">My Wallets</span>
              </NavBarItem>
              <NavBarItem to="exchange">
                <span slot="label">Exchange</span>
              </NavBarItem>
              <NavBarItem to="transactions">
                <span slot="label">Transactions</span>
              </NavBarItem>
              <NavBarItem to="settings">
                <span slot="label">Settings</span>
              </NavBarItem>
            </NavBar>
          </div>

          <H className="type-headline-small">Tabs</H>
          <Tabs>
            <Item key="send" title="Send">
              <label>
                Leave a note for Jane: <input type="text" />
              </label>
            </Item>
            <Item key="receive" title="Receive">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui,
                quo.
              </p>
            </Item>
            <Item key="P2P" title="P2P">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Dolorum consequuntur corrupti quam perspiciatis tenetur ad?
              </p>
            </Item>
            <Item key="swap" title="Swap">
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Corrupti commodi pariatur repudiandae porro iusto nisi voluptate
                placeat unde officiis aut?
              </p>
            </Item>
          </Tabs>
        </Level>
      </Level>
    </div>
  );
}
