// @ts-check
import * as React from 'react';

/**
 * @typedef InfiniteScrollProps
 * @property {HTMLElement | null} scrollContainer
 * Element that has a scrollbar. null = document
 *
 * @property {number} [visibilityThreshold]
 * How far (in px) the element should be to where it becomes visible to call fetchMore)
 *
 * @property {React.ReactNode} [children]
 * @property {() => void} fetchMore
 *
 * @param {InfiniteScrollProps} props
 */

function InfiniteScroll({
  scrollContainer,
  visibilityThreshold = 0,
  children,
  fetchMore,
}) {
  const ref = React.useRef(null);

  // call fetch more when the element comes into view
  const observer = React.useMemo(() => {
    return new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchMore();
        }
      },
      {
        root: scrollContainer,
        rootMargin: visibilityThreshold + 'px',
      }
    );
  }, [fetchMore, scrollContainer, visibilityThreshold]);

  React.useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
    // Dependancies are removed on purpose. Sometimes this element stays on screen even after
    // the new batch of data is loaded and appended, this makes intersection observer to not
    // fire anymore and thus not load any more data.
    //
    // By removing the dependancy array we are making sure that, every time the parent updates
    // (eg: after rendering the new data) observer fires again and checks for intersection.
    //
    // This will work as long as this component is not memoized.
  });

  return (
    <div
      role="presentation"
      ref={ref}
    >
      {children}
    </div>
  );
}

export default InfiniteScroll;
