import { Accordion, AccordionItem } from 'common/accordion/Accordion.component';
import { useLocale } from 'context/LanguageContext';
import React, { Fragment, useEffect } from 'react';
import { H } from 'react-accessible-headings';
import { connect } from 'react-redux';
import { tfaGenerateStart } from '../../redux/user/user.actions';
import { selectTfaEnabled } from '../../redux/user/user.selectors';
import TwoFactorPage from './2fa/two-factor.page';
import ApiKeyPage from './api-key/api-key.page';
import IpWhitelistPage from './ip-whitelist/ip-whitelist.page';
import styles from './security.module.css';

const Security = ({ tfaGenerateStart, tfaEnabled }) => {
  const { LL } = useLocale();

  const [show2fa, setShow2fa] = React.useState(false);
  const [showIp, setShowIp] = React.useState(false);
  const [showApi, setShowApi] = React.useState(false);

  useEffect(() => {
    !tfaEnabled && tfaGenerateStart();
  }, [tfaEnabled, tfaGenerateStart]);

  return (
    <Fragment>
      <div className={styles.securityPage}>
        <H className={styles.pageHeadline}>Security Page</H>
        <div className={styles.accordion}>
          <Accordion selectionMode="multiple">
            <AccordionItem
              summary={
                <>
                  {tfaEnabled
                    ? LL.security['2faEdit']()
                    : LL.security['2faEnable']()}
                  <span className={styles.accordion2FA}>
                    {LL.security.currentStatus()}:&nbsp;&nbsp;
                    {tfaEnabled ? (
                      // TODO: use colors from our design system instead
                      <span
                        style={{
                          color: 'green',
                          fontWeight: '800',
                          fontSize: '1.5rem',
                        }}
                      >
                        {LL.security.enabled()}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: 'grey',
                          fontWeight: '800',
                          fontSize: '1.5rem',
                        }}
                      >
                        {LL.security.disabled()}
                      </span>
                    )}
                  </span>
                </>
              }
              onToggle={() => setShow2fa(!show2fa)}
            >
              <TwoFactorPage />
            </AccordionItem>
            <AccordionItem
              summary={LL.security.ipWhitelist.ipManage()}
              onToggle={() => setShowIp(!showIp)}
            >
              <IpWhitelistPage />
            </AccordionItem>
            <AccordionItem summary={LL.security.apiKeys.apiManage()}>
              <ApiKeyPage />
            </AccordionItem>
          </Accordion>
        </div>
        <div className={styles.botMargin} />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  tfaEnabled: selectTfaEnabled(state),
});

export default connect(mapStateToProps, {
  tfaGenerateStart,
})(Security);

// Previous version:
/* <Accordion>
          <section className="tfa-section">
            <div className="tfa-main">
              <h1>{LL.security['2faHeader']()}</h1>
              <span>{LL.security['2faDescription']()} </span>
            </div>
            <div className="tfa-info">
              <span className="tfa-status">
                {LL.security.status()}:{' '}
                {tfaEnabled ? (
                  // TODO: use colors from our design system instead
                  <span style={{ color: 'green' }}>
                    {LL.security.enabled()}
                  </span>
                ) : (
                  <span style={{ color: 'red' }}>{LL.security.disabled()}</span>
                )}
              </span>
              <AccordionItem
                summary={
                  tfaEnabled
                    ? LL.security['2faEdit']()
                    : LL.security['2faEnable']()
                }
                onToggle={() => setShow2fa(!show2fa)}
              >
                <></>
              </AccordionItem>
            </div>
          </section>
          {show2fa && <TwoFactorPage />}
          <section className="ip-whitelist-section">
            <h1>{LL.security.ipHeader()}</h1>
            <div className="ip-whitelist-action">
              <AccordionItem
                summary={LL.security.ipManage()}
                onToggle={() => setShowIp(!showIp)}
              >
                <></>
              </AccordionItem>
            </div>
          </section>
          {showIp && <IpWhitelistPage />}
          <section className="api-key-section">
            <h1>{LL.security.apiHeader()}</h1>
            <div className="ip-whitelist-action">
              <AccordionItem
                summary={LL.security.apiManage()}
                onToggle={() => setShowApi(!showApi)}
              >
                <></>
              </AccordionItem>
            </div>
          </section>
          {showApi && <ApiKeyPage show2fa={show2fa} />}
        </Accordion> */
