// @ts-check
import { createTransition } from '../../utils/transitions';
import * as Vector from '../../utils/Vector';

// allocate in advance for distFromCenter method
const radiusVec = Vector.create();

class Ripple {
  _endRadius;
  _startRadius;
  _startRadiusSpeed = 4;

  origin = Vector.create();
  highlightRGBA;
  highlightWidth;

  constructor(origin, maxRadius, highlightRGBA, highlightWidth) {
    Vector.set(this.origin, origin[0], origin[1]);
    this._endRadius = createTransition(0.3, 1, maxRadius);
    this._startRadius = 0;
    this.highlightRGBA = highlightRGBA;
    this.highlightWidth = highlightWidth;
  }

  endFaster() {
    // make startradius faster
    this._startRadiusSpeed += this._startRadiusSpeed;
    // make end radius slower
    const currentSpeed = this._endRadius.speed();
    this._endRadius.changeSpeed(currentSpeed / 2);
  }

  get endRadius() {
    return this._endRadius.value();
  }

  get startRadius() {
    return this._startRadius;
  }

  get smallRadius() {
    return (this.endRadius - this.startRadius) / 2;
  }

  get bigRadius() {
    return this.startRadius + this.smallRadius;
  }

  /**
   * @param {Vector.Vec2} out
   * @param {Vector.Vec2} offset
   */
  getDistFromRadius = (out, offset) => {
    // distance from origin to offset
    Vector.sub(out, offset, this.origin);
    // distance from origin to small radius of torus
    Vector.normalize(radiusVec, out);
    Vector.scale(radiusVec, radiusVec, this.bigRadius);
    // distance from small radius to offset
    return Vector.sub(out, out, radiusVec);
  };

  /**
   * @param {Vector.Vec2} out
   * @param {Vector.Vec2} offset
   */
  getDistFromOrigin = (out, offset) => {
    // distance from origin to offset
    Vector.sub(out, offset, this.origin);
  };

  update = (endReachedCallback) => {
    this._endRadius.transition();
    this._startRadiusSpeed += -0.0001;
    this._startRadius += this._startRadiusSpeed;

    if (this.endRadius - this.startRadius <= 1) {
      endReachedCallback();
    }
  };
}

export default class Ripples {
  /** @type {Ripple[]} */
  ripples = [];

  get size() {
    return this.ripples.length;
  }

  /**
   * @param {Vector.Vec2} origin
   * @param {number} maxRadius
   * @param {number[]} highlightRGBA
   * @param {number} highlightWidth
   */
  add = (origin, maxRadius, highlightRGBA, highlightWidth) => {
    this.ripples.forEach((r) => {
      r.endFaster();
    });

    this.ripples.push(
      new Ripple(origin, maxRadius, highlightRGBA, highlightWidth)
    );
  };

  /**
   * @param {(ripple: Ripple) => void} fn
   */
  update = (fn) => {
    for (let i = this.ripples.length - 1; i >= 0; i--) {
      const r = this.ripples[i];
      fn(r);

      r.update(() => {
        this.ripples.splice(this.ripples.indexOf(r), 1);
      });
    }
  };
}
