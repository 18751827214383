// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * @typedef Balance
 * @property {number} id
 * @property {number} user_id
 * @property {string} default
 * @property {import('currencies').Currency} coin Currency code
 * @property {string} coin_name Full name in English
 * @property {number} confirmed_balance
 * @property {number} unconfirmed_deposit
 * @property {number} unconfirmed_withdrawal
 *
 * @typedef UnconvertedBalance Balance as is returned from the server. Uses strings instead of numbers
 * @property {number} id
 * @property {number} user_id
 * @property {string} default
 * @property {import('currencies').Currency} coin Currency code
 * @property {string} coin_name Full name in English
 * @property {String} confirmed_balance
 * @property {String} unconfirmed_deposit
 * @property {String} unconfirmed_withdrawal
 */

/**  @param {boolean} [deposeted] filter out undeposeted balances */
export async function getBalances(deposeted) {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URI}balances/`,
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });

  /** @type {UnconvertedBalance[]} */
  const balances = response.data;

  const sortedBalances = balances.map((balance) => {
    /** @type {Balance} */
    const converted = {
      ...balance,
      confirmed_balance: +balance.confirmed_balance,
      unconfirmed_deposit: +balance.unconfirmed_deposit,
      unconfirmed_withdrawal: +balance.unconfirmed_withdrawal,
    };
    return converted;
  });

  if (deposeted) {
    return sortedBalances.filter((balance) => +balance.confirmed_balance > 0);
  }

  return sortedBalances;
}

/**
 * @param {boolean} [deposeted]
 * @returns {import('@tanstack/react-query').UseQueryResult<Balance[], Error>}
 */
export function useBalancesQuery(deposeted) {
  return useQuery(['balance', deposeted], () => getBalances(deposeted));
}
