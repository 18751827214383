import { takeLatest, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchOperationsSuccess,
  fetchOperationsFail,
} from './oeprations.actions';
import { FETCH_OPERATIONS_START } from './operations.types';

export function* fetchOperationsAsync({ payload }) {
  const {
    txSelectedPage: page,
    type,
    currencies,
    from,
    to,
    txId,
    address,
  } = payload;

  try {
    const {
      data: { items, pages },
    } = yield axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}operations`,
      data: {
        page,
        type,
        currencies,
        from,
        to,
        txId,
        address,
      },
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    yield put(fetchOperationsSuccess({ items, pages }));
  } catch (err) {
    yield put(fetchOperationsFail('Failed to load operations'));
  }
}

export function* onFetchOperationsStart() {
  yield takeLatest(FETCH_OPERATIONS_START, fetchOperationsAsync);
}

export function* operationsSagas() {
  yield all([call(onFetchOperationsStart)]);
}
