// @ts-check

import * as React from 'react';
import cx from 'classnames';
import { RiCheckFill, RiErrorWarningFill } from 'react-icons/ri';
import styles from './FieldWrapper.module.css';
import useResizeObserver from 'beautiful-react-hooks/useResizeObserver';

/**
 * @typedef ClassNames
 * @property {string} [fieldWrapper]
 * @property {string} [fieldWrapperLeft]
 * @property {string} [leftButton]
 * @property {string} [leftDecoration]
 * @property {string} [fieldWrapperRight]
 * @property {string} [rightDecoration]
 * @property {string} [rightValidation]
 * @property {string} [rightButton]
 *
 * @param {object} props
 * @param {any} props.children
 * @param {ClassNames} [props.classNames]
 * @param {any} [props.leftButton]
 * @param {any} [props.leftDecoration]
 * @param {any} [props.rightDecoration]
 * @param {any} [props.rightButton]
 * @param {"valid" | "invalid" | (undefined | null) } [props.validationState]
 * @param {boolean} [props.isDisabled]
 */
function FieldWrapper({
  children,
  classNames,
  leftButton,
  leftDecoration,
  rightDecoration,
  rightButton,
  validationState,
  isDisabled,
}) {
  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const containerRef = React.useRef();
  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const leftRef = React.useRef();
  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const rightRef = React.useRef();

  const leftRect = useResizeObserver(leftRef, 0);
  const rightRect = useResizeObserver(rightRef, 0);

  React.useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.setProperty(
        '--left-width',
        (leftRect?.width || 0) + 'px'
      );
      containerRef.current.style.setProperty(
        '--right-width',
        (rightRect?.width || 0) + 'px'
      );
    }
  }, [leftRect, rightRect]);

  return (
    <div
      className={cx(classNames.fieldWrapper, {
        '--isValid': validationState === 'valid',
        '--isInvalid': validationState === 'invalid',
        '--isDisabled': isDisabled,
      })}
      ref={containerRef}
    >
      <div
        ref={leftRef}
        className={cx(classNames.fieldWrapperLeft, styles.pointersDisabled)}
      >
        {leftButton && (
          <span className={cx(classNames.leftButton, styles.pointersEnabled)}>
            {leftButton}
          </span>
        )}
        {/* decorations such as icons or text should not be visible to screen readers 
          and should not receive pointer events if placed on top of an input */}
        {leftDecoration && (
          <span
            aria-hidden
            className={classNames.leftDecoration}
          >
            {leftDecoration}
          </span>
        )}
      </div>
      {children}
      <div
        ref={rightRef}
        className={cx(classNames.fieldWrapperRight, styles.pointersDisabled)}
      >
        {validationState && (
          <span
            aria-hidden
            className={classNames.rightValidation}
            style={{ pointerEvents: 'none' }}
          >
            {validationState === 'valid' ? (
              <RiCheckFill />
            ) : (
              <RiErrorWarningFill />
            )}
          </span>
        )}
        {rightDecoration && (
          <span
            aria-hidden
            className={classNames.rightDecoration}
          >
            {rightDecoration}
          </span>
        )}
        {rightButton && (
          <span className={cx(classNames.rightButton, styles.pointersEnabled)}>
            {rightButton}
          </span>
        )}
      </div>
    </div>
  );
}

export default FieldWrapper;
