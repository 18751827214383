import { ErrorMapProvider } from 'errors/useErrorMap';
import * as React from 'react';

/**
 * @typedef ErrorMapProps
 * @property {React.ReactNode} children Elements that might throw
 * @property {Map<{ new(): Error }, React.ReactElement>} errors
 * @property {(error: Error) => void} [onError]
 */

/**
 * @typedef ErrorMapState
 * @property {Error} error
 */

/**
 * A react Error boundry component that maps errors to specific views.
 * By default if an error was caught that is not mapped,
 * it will be rethrown for an upper error boundry. change this behavior by providing
 * a catch-all error view with the Base Error class at the end of the map.
 * eg: `new Map([[CustomError, <CustomErrorView />], [Error, <CathAllErrorView />]])`
 *
 * @extends React.Component<ErrorMapProps>
 */
export class ErrorMap extends React.Component {
  /** @type {ErrorMapState} */
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  render() {
    const { children, errors } = this.props;
    const { error } = this.state;

    if (error) {
      const ErrorView = errors.get(/** @type {any} */ (error.constructor));
      if (ErrorView) {
        return <ErrorMapProvider error={error}>{ErrorView}</ErrorMapProvider>;
      }
      throw error;
    }

    return children;
  }
}
