// @ts-check
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { queryClient } from 'queries/queryClient';

/**
 * @typedef {number} ExchangeRate
 */

export async function getExchangeRate({ from, to }) {
  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URI}exchange/rates/${from}/${to}`,
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });

  /** @type {ExchangeRate} */
  const exchangeRate = response.data.rate;
  return exchangeRate;
}

/**
 * @param {object} payload
 * @param {import('currencies').Currency} payload.from
 * @param {import('currencies').Currency} payload.to
 * @param {{enabled: boolean}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<ExchangeRate, Error>}
 */
export function useExchangeRateQuery({ from, to }, options) {
  return useQuery(
    [
      'exchangeRate',
      {
        from,
        to,
      },
    ],
    () => getExchangeRate({ from, to }),
    options
  );
}

export function fetchExchangeRateQuery({ from, to }) {
  return queryClient.fetchQuery({
    queryKey: ['exchangeRate', { from, to }],
    queryFn: () => getExchangeRate({ from, to }),
    staleTime: 60_000, // Rates are invalidated every 60 seconds on the server
  });
}
