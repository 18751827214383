import coinActionTypes from './coin.types';

const INITIAL_STATE = {
  coinData: {},
  coinName: 'bitcoin',
  fetchingCoinValue: false,
  coinError: undefined,
  coinValue: null,
  feeAmount: null,
  p2pFeeAmount: null,
  fetchingFeeAmount: false,
  feeAmountError: null,
};

const explorerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case coinActionTypes.FETCH_COIN_VALUE_SUCCESS:
      return {
        ...state,
        coinValue: action.payload,
      };
    case coinActionTypes.FETCH_COINDATA_START:
      return {
        ...state,
        fetchingCoinValue: true,
      };
    case coinActionTypes.FETCH_COINDATA_SUCCESS:
      return {
        ...state,
        fetchingCoinValue: false,
        coinData: action.payload,
      };
    case coinActionTypes.FETCH_COINDATA_FAILURE:
      return {
        ...state,
        fetchingCoinValue: false,
        coinError: action.payload,
      };
    case coinActionTypes.ON_COIN_PICK:
      return {
        ...state,
        coinName: action.payload,
      };
    case coinActionTypes.FETCH_FEE_AMOUNT_START:
    case coinActionTypes.FETCH_P2P_FEE_AMOUNT_START:
      return {
        ...state,
        fetchingFeeAmount: true,
      };
    case coinActionTypes.FETCH_FEE_AMOUNT_SUCCESS:
      return {
        ...state,
        feeAmount: action.payload,
        fetchingFeeAmount: false,
      };
    case coinActionTypes.FETCH_P2P_FEE_AMOUNT_SUCCESS:
      return {
        ...state,
        p2pFeeAmount: action.payload,
        fetchingFeeAmount: false,
      };
    case coinActionTypes.FETCH_FEE_AMOUNT_FAILURE:
    case coinActionTypes.FETCH_P2P_FEE_AMOUNT_FAILURE:
      return {
        ...state,
        feeAmountError: action.payload,
        fetchingFeeAmount: false,
      };
    default:
      return state;
  }
};

export default explorerReducer;
