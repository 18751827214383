import {
  FETCH_OPERATIONS_START,
  FETCH_OPERATIONS_SUCCESS,
  FETCH_OPERATIONS_FAIL,
} from './operations.types';

export const fetchOperationsStart = (payload) => ({
  type: FETCH_OPERATIONS_START,
  payload,
});

export const fetchOperationsSuccess = (payload) => ({
  type: FETCH_OPERATIONS_SUCCESS,
  payload,
});

export const fetchOperationsFail = (payload) => ({
  type: FETCH_OPERATIONS_FAIL,
  payload,
});
