const coinActionTypes = {
  FETCH_COINDATA_START: 'FETCH_COINDATA_START',
  FETCH_COINDATA_SUCCESS: 'FETCH_COINDATA_SUCCESS',
  FETCH_COINDATA_FAILURE: 'FETCH_COINDATA_FAILURE',
  FETCH_COIN_VALUE_SUCCESS: 'FETCH_COIN_VALUE_SUCCESS',
  ON_COIN_PICK: 'ON_COIN_PICK',
  FETCH_FEE_AMOUNT_START: 'FETCH_FEE_AMOUNT_START',
  FETCH_FEE_AMOUNT_SUCCESS: 'FETCH_FEE_AMOUNT_SUCCESS',
  FETCH_FEE_AMOUNT_FAILURE: 'FETCH_FEE_AMOUNT_FAILURE',
  FETCH_P2P_FEE_AMOUNT_START: 'FETCH_P2P_FEE_AMOUNT_START',
  FETCH_P2P_FEE_AMOUNT_SUCCESS: 'FETCH_P2P_FEE_AMOUNT_SUCCESS',
  FETCH_P2P_FEE_AMOUNT_FAILURE: 'FETCH_P2P_FEE_AMOUNT_FAILURE',
};

export default coinActionTypes;
