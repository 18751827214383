import currencies from 'assets/currencies';
import CurrencyColors from 'assets/data/currency-colors';
import Surface from 'common/surfaces/Surface.component';
import ConversionCurrency from 'components/content/ConversionCurrency.component';
import Currency from 'components/content/Currency.component';
import BufferingWheel from 'components/loader/LoadingAnimation.component';
import { useLocale } from 'context/LanguageContext';
import { format, parseISO } from 'date-fns';
import useThemeVariables from 'hooks/useThemeVariables';
import { useExchangeRecord } from 'queries/exchangeRecord';
import styles from './ExchangeRecord.module.css';

import OutlinedButton from 'common/buttons/OutlinedButton';
import React from 'react';
import { H } from 'react-accessible-headings';
import { RiCloseLine } from 'react-icons/ri';

const ExchangeRecord = ({ id, closeButton, clickClose, small }) => {
  const { data } = useExchangeRecord({ id });
  const { LL } = useLocale();
  const { colorTest4, colorTest7 } = useThemeVariables();

  const surfaceColor = {
    background: colorTest4,
  };

  const surfaceColor2 = {
    background: colorTest7,
  };

  return !data ? (
    <BufferingWheel color="var(--color-logo)" />
  ) : (
    <Surface
      baseElevation={0}
      className={small ? styles.sectionSmall : styles.section}
      surfaceColor={surfaceColor}
    >
      {closeButton && (
        <div className={styles.closeButton}>
          <OutlinedButton onPress={clickClose}>
            <RiCloseLine />
          </OutlinedButton>
        </div>
      )}
      <H className={small ? styles.headerSmall : styles.header}>
        {LL.explorer.exchangeRecord.header()}:
      </H>
      <span className={small ? styles.headLineSmall : styles.headLine}>
        <img
          className={styles.icon}
          src={currencies[data.from]}
          alt=""
          style={{ height: '4rem', width: '4rem' }}
        />
        <span style={{ color: CurrencyColors[data.from].background }}>
          <Currency currency={data.from}>{Number(data.amount_from)}</Currency>
        </span>
        {' -> '}
        <br className={styles.lineBreak} />
        <img
          className={styles.icon}
          src={currencies[data.to]}
          alt=""
          style={{ height: '4rem', width: '4rem' }}
        />
        <span>
          <span style={{ color: CurrencyColors[data.to].background }}>
            <Currency currency={data.to}>{Number(data.amount_to)}</Currency>
          </span>
        </span>
      </span>
      <Surface
        baseElevation={0}
        surfaceColor={surfaceColor2}
        className={styles.details}
      >
        <a>
          {LL.explorer.exchangeRecord.info({ from: data.from, to: data.to })}:{' '}
          <span className={styles.detailsContent}>{data.rate}</span>
        </a>
        <a>
          {LL.explorer.exchangeRecord.totalFee()}:{' '}
          <span className={styles.detailsContent}>
            <Currency currency={data.to}>{Number(data.fee)}</Currency>
          </span>
          {' / '}
          <span className={styles.detailsContent}>
            <ConversionCurrency currency={data.to}>
              {/* zero width char to maintain height when loading */}
              {Number(data.fee)}&#8203;
            </ConversionCurrency>
          </span>
        </a>
        <a>
          {LL.explorer.exchangeRecord.createdAt()}:{' '}
          <span className={styles.detailsContent}>
            {format(parseISO(new Date(data.createdAt).toISOString()), 'Pp')}
          </span>
        </a>
        <a>
          {LL.explorer.exchangeRecord.finalAmount()}:{' '}
          <span className={styles.detailsContent}>
            <Currency currency={data.to}>{Number(data.amount_to)}</Currency>
          </span>
          {' / '}
          <span className={styles.detailsContent}>
            <ConversionCurrency currency={data.to}>
              {/* zero width char to maintain height when loading */}
              {Number(data.amount_to)}&#8203;
            </ConversionCurrency>
          </span>
        </a>
      </Surface>
    </Surface>
  );
};

export default ExchangeRecord;
