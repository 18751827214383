import NewHeader from 'components/new-header/NewHeader';
import React, { useState } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import styles from './BurgerMenu.module.css';

const BurgerMenu = ({ menu, setMenu }) => {
  return (
    <div
      className={
        menu === 0 ? styles.menu : menu === 1 ? styles.enter : styles.exit
      }
    >
      <div className={styles.menuText}>
        <div
          onClick={() => {
            setMenu(2);
          }}
          className={styles.closeButton}
        >
          <RiCloseFill size="3rem" />
        </div>
        <NewHeader
          className={styles.info}
          setMenu={setMenu}
        />
      </div>
    </div>
  );
};

export default BurgerMenu;
