export class NoAvailableBalance extends Error {
  constructor() {
    super("You don't have any deposits on Paybitx.com yet");
    this.name = 'NoAvailableBalance';
  }
}

export class NoBalanceInCurrency extends Error {
  /** @param {string} currency */
  constructor(currency) {
    super(`You don't have any deposits in ${currency}`);
    this.name = 'NoBalanceInCurrency';
    this.currency = currency;
  }
}

export class ConversionError extends Error {
  constructor(from, to) {
    super(`Cannot convert from ${from} to ${to}`);
    this.from = from;
    this.to = to;
    this.name = 'ConversionError';
  }
}
