import { HiddenSelect, useSelect } from '@react-aria/select';
import { useSelectState } from '@react-stately/select';
import currencies from 'assets/currencies';
import cx from 'classnames';
import ButtonBase from 'common/buttons/ButtonBase';
import ListBox from 'common/listoboxes/ListBox.component';
import PopoverBase from 'common/overlays/base/PopoverBase.component';
import { Slot, Template } from 'common/template/Template';
import { useLocale } from 'context/LanguageContext';
import * as React from 'react';
import { RiArrowDownSLine, RiErrorWarningFill } from 'react-icons/ri';
import styles from './SelectBase.module.css';

/**
 * @typedef ClassNames
 * @property {string} [container]
 * @property {string} [label]
 * @property {string} [focusRing]
 * @property {string} [button]
 * @property {string} [value]
 * @property {string} [right]
 * @property {string} [defaultValue]
 * @property {string} [description]
 * @property {string} [errorMessage]
 */

/**
 * @template T
 * @typedef {import("@react-types/shared").CollectionChildren<T>} CollectionChildren
 */

/**
 * @template T
 * @typedef Props
 * @property {CollectionChildren<T>} children The contents of the collection.
 *
 * @property {(selectedItem: T) => React.ReactNode} [renderValue]
 * Use this prop to change what's desplayed on the picker when value is selected.
 * For example if the slected Item is "Bitcoin", you can change it to BTC to keep it short.
 *
 * @property {ClassNames} [classNames]
 * @property {string} [defaultText]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * @property {Iterable<T>} [items]  Item objects in the collection.
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {React.ReactNode} [label]
 * @property {string} [placeholder]
 * @property {React.Key | null} [selectedKey]
 * @property {React.Key | null} [defaultSelectedKey]
 * @property {(key: React.Key) => void} [onSelectionChange]
 * @property {boolean} [autoFocus]
 * @property {( e : React.FocusEvent ) => void} [onFocus]
 * @property {( e : React.FocusEvent ) => void} [onBlur]
 * @property {( isFocused : boolean ) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyUp]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {boolean} [isLoading]
 *
 * @property {Iterable<React.Key>} [disabledKeys]
 * These items cannot be selected, focused, or otherwise interacted with.
 **/

/**
 * @template {{}} T
 * @param {Props<T>} props
 */
function SelectBase({ classNames = {}, ...props }) {
  const state = useSelectState(props);
  const { LL } = useLocale();

  // Get props for child elements from useSelect
  const ref = React.useRef(null);
  const listBoxRef = React.useRef();

  let {
    labelProps,
    triggerProps,
    valueProps,
    menuProps,
    descriptionProps,
    errorMessageProps,
  } = useSelect(props, state, ref);

  const modifiers = cx({
    '--hasError': props.errorMessage,
    '--isValid': props.validationState === 'valid',
    '--isInvalid': props.validationState === 'invalid',
    '--isDisabled': props.isDisabled,
  });

  return (
    <div className={cx(classNames.container, modifiers)}>
      {props.label && (
        <label
          className={classNames.label}
          {...labelProps}
        >
          {props.label}
        </label>
      )}
      <HiddenSelect
        state={state}
        triggerRef={ref}
        label={props.label}
      />

      <ButtonBase
        classNames={{
          focusRing: classNames.focusRing,
          button: classNames.button,
        }}
        // for some reason isDisabled is not included in triggerProps
        isolateElevation
        isDisabled={props.isDisabled}
        ref={ref}
        {...triggerProps}
      >
        <span
          className={styles.values}
          {...valueProps}
        >
          {currencies[props.selectedKey] && (
            <img
              src={currencies[props.selectedKey]}
              alt=""
              data-slot="icon"
            />
          )}
          {props.renderValue && state.selectedItem ? (
            props.renderValue(state.selectedItem.value)
          ) : (
            // filter out description & icon slots and render just the default slot
            <Template content={state.selectedItem?.rendered}>
              <Slot>
                <span className={classNames.defaultValue}>
                  {props.defaultText || LL.general.selectOption()}
                </span>
              </Slot>
            </Template>
          )}
        </span>
        <span
          aria-hidden
          className={classNames.right}
        >
          {props.validationState === 'invalid' && <RiErrorWarningFill />}
          <RiArrowDownSLine />
        </span>
      </ButtonBase>

      {state.isOpen && (
        <PopoverBase
          state={state}
          triggerRef={ref}
          placement="bottom start"
          smVariant="popover"
          maxHeight={300}
        >
          <ListBox
            {...menuProps}
            state={state}
            ref={listBoxRef}
            classNames={{ listbox: styles.padding }}
          />
        </PopoverBase>
      )}

      {props.validationState === 'invalid' && props.errorMessage && (
        <div
          {...errorMessageProps}
          className={classNames.errorMessage}
        >
          {props.errorMessage}
        </div>
      )}
      {props.description && (
        <div
          {...descriptionProps}
          className={classNames.description}
        >
          {props.description}
        </div>
      )}
    </div>
  );
}

export default SelectBase;
