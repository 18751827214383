import { createSelector } from 'reselect';

/** @param {import('../../types').RootStore} state */
const selectUser = (state) => state.user;

export const selectUserAddress = createSelector(
  selectUser,
  (user) => user.address
);

export const selectUserCredentials = createSelector(
  selectUser,
  (user) => user.userCredentials
);

export const selectIsFetchingAddress = createSelector(
  selectUser,
  (user) => user.fetchingUserAddress
);

export const selectUserBalance = createSelector(
  selectUser,
  (user) => user.balance
);

export const selectTfa = createSelector(selectUser, (user) => user.tfa);

export const selectTfaEnabled = createSelector(
  selectUser,
  (user) => user.tfaEnabled
);

export const selectTfaError = createSelector(
  selectUser,
  (user) => user.tfaError
);

export const selectTfaNewDevice = createSelector(
  selectUser,
  (user) => user.tfaNewDevice
);
