import big from 'bigjs-literal/macro';
import { ConversionError } from 'errors';
import { fetchExchangeRateQuery } from 'queries/exchangeRate';

/**
 * TODO: Add onError handler
 * @typedef ConversionHandler
 * @property {string} currency
 * @property {(value: number) => void} onInitialize
 * @property {(value: number) => void} onChange
 * @property {(error: Error) => void} [onError]
 */

export default class ConversionUpdater {
  /**
   * @type {ConversionHandler[]}
   */
  conversionHandlers = [];
  /**
   * @type {(
   *  initialize: ConversionHandler["onInitialize"],
   *  currency: ConversionHandler["currency"]
   * ) => void}
   */
  conversionHandlerInitializer;

  /**
   * The initializer function is called every time a new ConversionHandler is registered.
   * By design, registered handlers have no idea about any global value and currency,
   * so when a new handler is registered we need to initialize it with the up-to-date value
   * in its own currency, in case value is already defined.
   * @param {(initialize: (value: number) => void, currency: string) => void} conversionHandlerInitializer
   */
  constructor(conversionHandlerInitializer) {
    this.conversionHandlerInitializer = conversionHandlerInitializer;
  }

  /**
   * @param {ConversionHandler} handler
   **/
  async register(handler) {
    this.conversionHandlers.push(handler);
    try {
      await this.conversionHandlerInitializer(
        handler.onInitialize,
        handler.currency
      );
    } catch (error) {
      handler.onError?.(error);
    }
  }

  /**
   * @param {ConversionHandler} handler
   */
  unregister(handler) {
    this.conversionHandlers = this.conversionHandlers.filter(
      (h) => h !== handler
    );
  }

  /**
   * Convert the value to the currency of the other handlers and notify them.
   * @param {ConversionHandler} handler
   * @param {number} value
   */
  async notifyOtherHandlers(handler, value) {
    const otherHandlers = this.conversionHandlers.filter((h) => h !== handler);

    for (const otherHandler of otherHandlers) {
      try {
        const convertedValue = await ConversionUpdater.convert(
          value,
          handler.currency,
          otherHandler.currency
        );
        otherHandler.onChange(convertedValue);
      } catch (error) {
        otherHandler.onError?.(error);
      }
    }
  }

  /**
   * @param {number} value
   * @param {string} currency
   */
  async notifyAllHandlers(value, currency) {
    for (const handler of this.conversionHandlers) {
      try {
        const { currency: otherCurrency } = handler;
        const convertedValue = await ConversionUpdater.convert(
          value,
          currency,
          otherCurrency
        );
        handler.onChange(convertedValue);
      } catch (error) {
        handler.onError?.(error);
      }
    }
  }

  /**
   * @param {number} value
   * @param {string} from
   * @param {string} to
   */
  static async convert(value, from, to) {
    if (isNaN(value)) return NaN;
    if (value === 0) return 0;
    if (from === to) return value;

    try {
      const rate = await fetchExchangeRateQuery({ from, to });
      return +big`${rate} * ${value}`;
    } catch (error) {
      throw new ConversionError(from, to);
    }
  }
}
