import * as tokens from "../material-theme/dist/styledictionary/js/tokens";

/* 
I think type-display-1 & type-headline-6 is a bug in dsp, they are not present in figma plugin.
material dsp included it so i made the class anyway. 
use with caution 
*/
export const typeDisplay1 = {
	fontFamily: tokens.MdSysTypescaleDisplay1Weight,
	fontWeight: tokens.MdSysTypescaleDisplay1Weight,
	fontSize: tokens.MdSysTypescaleDisplay1Size,
};

export const typeDisplayLarge = {
	fontFamily: tokens.MdSysTypescaleDisplayLargeWeight,
	fontWeight: tokens.MdSysTypescaleDisplayLargeWeight,
	fontSize: tokens.MdSysTypescaleDisplayLargeSize,
};

export const typeDisplayMedium = {
	fontFamily: tokens.MdSysTypescaleDisplayMediumWeight,
	fontWeight: tokens.MdSysTypescaleDisplayMediumWeight,
	fontSize: tokens.MdSysTypescaleDisplayMediumSize,
};

export const typeDisplaySmall = {
	fontFamily: tokens.MdSysTypescaleDisplaySmallWeight,
	fontWeight: tokens.MdSysTypescaleDisplaySmallWeight,
	fontSize: tokens.MdSysTypescaleDisplaySmallSize,
};

export const typeHeadlineLarge = {
	fontFamily: tokens.MdSysTypescaleHeadlineLargeWeight,
	fontWeight: tokens.MdSysTypescaleHeadlineLargeWeight,
	fontSize: tokens.MdSysTypescaleHeadlineLargeSize,
};

export const typeHeadlineMedium = {
	fontFamily: tokens.MdSysTypescaleHeadlineMediumWeight,
	fontWeight: tokens.MdSysTypescaleHeadlineMediumWeight,
	fontSize: tokens.MdSysTypescaleHeadlineMediumSize,
};

export const typeHeadlineSmall = {
	fontFamily: tokens.MdSysTypescaleHeadlineSmallWeight,
	fontWeight: tokens.MdSysTypescaleHeadlineSmallWeight,
	fontSize: tokens.MdSysTypescaleHeadlineSmallSize,
};

export const typeHeadline6 = {
	fontFamily: tokens.MdSysTypescaleHeadline6Weight,
	fontWeight: tokens.MdSysTypescaleHeadline6Weight,
	fontSize: tokens.MdSysTypescaleHeadline6Size,
};

export const typeTitleLarge = {
	fontFamily: tokens.MdSysTypescaleTitleLargeWeight,
	fontWeight: tokens.MdSysTypescaleTitleLargeWeight,
	fontSize: tokens.MdSysTypescaleTitleLargeSize,
};

export const typeTitleMedium = {
	fontFamily: tokens.MdSysTypescaleTitleMediumWeight,
	fontWeight: tokens.MdSysTypescaleTitleMediumWeight,
	fontSize: tokens.MdSysTypescaleTitleMediumSize,
};

export const typeTitleSmall = {
	fontFamily: tokens.MdSysTypescaleTitleSmallWeight,
	fontWeight: tokens.MdSysTypescaleTitleSmallWeight,
	fontSize: tokens.MdSysTypescaleTitleSmallSize,
};

export const typeBodyLarge = {
	fontFamily: tokens.MdSysTypescaleBodyLargeWeight,
	fontWeight: tokens.MdSysTypescaleBodyLargeWeight,
	fontSize: tokens.MdSysTypescaleBodyLargeSize,
};

export const typeBodyMedium = {
	fontFamily: tokens.MdSysTypescaleBodyMediumWeight,
	fontWeight: tokens.MdSysTypescaleBodyMediumWeight,
	fontSize: tokens.MdSysTypescaleBodyMediumSize,
};

export const typeBodySmall = {
	fontFamily: tokens.MdSysTypescaleBodySmallWeight,
	fontWeight: tokens.MdSysTypescaleBodySmallWeight,
	fontSize: tokens.MdSysTypescaleBodySmallSize,
};

export const typeLabelLarge = {
	fontFamily: tokens.MdSysTypescaleLabelLargeWeight,
	fontWeight: tokens.MdSysTypescaleLabelLargeWeight,
	fontSize: tokens.MdSysTypescaleLabelLargeSize,
};

export const typeLabelMedium = {
	fontFamily: tokens.MdSysTypescaleLabelMediumWeight,
	fontWeight: tokens.MdSysTypescaleLabelMediumWeight,
	fontSize: tokens.MdSysTypescaleLabelMediumSize,
};

export const typeLabelSmall = {
	fontFamily: tokens.MdSysTypescaleLabelSmallWeight,
	fontWeight: tokens.MdSysTypescaleLabelSmallWeight,
	fontSize: tokens.MdSysTypescaleLabelSmallSize,
};
