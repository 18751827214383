// @ts-check

import cx from 'classnames';
import ButtonBase from 'common/buttons/ButtonBase';
import ListBox from 'common/listoboxes/ListBox.component';
import PopoverBase from 'common/overlays/base/PopoverBase.component';
import { useMultiSelect } from 'common/pickers/multiSelectLib/useMultiSelect';
import { useMultiSelectState } from 'common/pickers/multiSelectLib/useMultiSelectState';
import * as React from 'react';
import { RiArrowDownSLine, RiErrorWarningFill } from 'react-icons/ri';
import selectStyles from './Select.module.css';
import selectBaseStyles from './SelectBase.module.css';

/**
 * @template T
 * @typedef {import("@react-types/shared").CollectionChildren<T>} CollectionChildren
 */

/**
 * @template T
 * @typedef Props
 * @property {CollectionChildren<T>} children The contents of the collection.
 *
 * @property {(selectedKeys: Set<React.Key> | "all") => React.ReactNode} [renderValue=getRenderValue]
 * Use this prop to change what's desplayed on the picker when value is selected.
 * For example, if the expected items size is short, you can display the items in a list of comma seperated values.
 * By default trigger shows {n} items selected.
 *
 * @property {string} [defaultText="Select options"]
 * @property {boolean} [defaultOpen]
 * @property {(isOpen: boolean) => void} [onOpenChange]
 * @property {Iterable<T>} [items]  Item objects in the collection.
 * @property {"valid" | "invalid"} [validationState]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [errorMessage]
 * @property {React.ReactNode} [label]
 * @property {string} [placeholder]
 * @property {'all' | Iterable<React.Key>} [selectedKeys]
 * @property {'all' | Iterable<React.Key>} [defaultSelectedKeys]
 * @property {(keys: 'all' | Set<React.Key>) => void} [onSelectionChange]
 * @property {boolean} [autoFocus]
 * @property {( e : React.FocusEvent ) => void} [onFocus]
 * @property {( e : React.FocusEvent ) => void} [onBlur]
 * @property {( isFocused : boolean ) => void} [onFocusChange]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyDown]
 * @property {( e : React.KeyboardEvent ) => void} [onKeyUp]
 * @property {string} [id]
 * @property {string} [aria-label]
 * @property {string} [aria-labelledby]
 * @property {string} [aria-describedby]
 * @property {string} [aria-details]
 * @property {boolean} [excludeFromTabOrder]
 * @property {boolean} [isLoading]
 * @property {String[]} [resetState] Hack to reset the state of the component.
 *
 * @property {Iterable<React.Key>} [disabledKeys]
 * These items cannot be selected, focused, or otherwise interacted with.
 **/

/**
 * Temp implementation of MultiSelect before react-aria implements one.
 * Missing features:
 * * Browser autocomplete wont work.
 * * Wont be able to post data with html forms. (Since there's no name or id attributes and no underlying form element)
 * * Some other undescovered issues.
 *
 * @template {{}} T
 * @param {Props<T>} props
 */
function MultiSelect({
  defaultText = 'Select options',
  renderValue = getRenderValue,
  ...props
}) {
  const state = useMultiSelectState(props);

  React.useEffect(() => {
    if (props.resetState.length === 0) {
      state.selectionManager.clearSelection();
    }
  }, [props.resetState, state.selectionManager]);

  // Get props for child elements from useSelect
  const ref = React.useRef(null);
  const listBoxRef = React.useRef();

  let {
    labelProps,
    triggerProps,
    valueProps,
    menuProps,
    descriptionProps,
    errorMessageProps,
  } = useMultiSelect(props, state, ref);

  const modifiers = cx({
    '--hasError': props.errorMessage,
    '--isValid': props.validationState === 'valid',
    '--isInvalid': props.validationState === 'invalid',
    '--isDisabled': props.isDisabled,
  });

  return (
    <div className={cx(selectStyles.container, modifiers)}>
      {props.label && (
        <label
          className={selectStyles.label}
          {...labelProps}
        >
          {props.label}
        </label>
      )}

      <ButtonBase
        classNames={{
          button: selectStyles.button,
        }}
        // for some reason isDisabled is not included in triggerProps
        isolateElevation
        isDisabled={props.isDisabled}
        ref={ref}
        {...triggerProps}
      >
        <span {...valueProps}>
          {renderValue && state.selectionManager.selectedKeys.size > 0
            ? renderValue(state.selectionManager.rawSelection)
            : // filter out description & icon slots and render just the default slot
              defaultText}
        </span>
        <span
          aria-hidden
          className={selectStyles.right}
        >
          {props.validationState === 'invalid' && <RiErrorWarningFill />}
          <RiArrowDownSLine />
        </span>
      </ButtonBase>

      {state.isOpen && (
        <PopoverBase
          state={state}
          triggerRef={ref}
          placement="bottom start"
          smVariant="popover"
          isMultiSelect={true}
        >
          <ListBox
            {...menuProps}
            state={state}
            ref={listBoxRef}
            classNames={{ listbox: selectBaseStyles.padding }}
          />
        </PopoverBase>
      )}

      {props.validationState === 'invalid' && props.errorMessage && (
        <div
          {...errorMessageProps}
          className={selectStyles.errorMessage}
        >
          {props.errorMessage}
        </div>
      )}
      {props.description && (
        <div
          {...descriptionProps}
          className={selectStyles.description}
        >
          {props.description}
        </div>
      )}
    </div>
  );
}

/** @param {"all" | Set<React.Key>} selectedItems */
function getRenderValue(selectedItems) {
  if (selectedItems === 'all') {
    return 'All items are selected';
  }

  return `${selectedItems.size} items selected`;
}

export default MultiSelect;
