import { takeLatest, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import {
  FETCH_NOTIFICATIONS_START,
  SEEN_NOTIFICATIONS_START,
} from './notifications.types';

import {
  fetchNotificationsSuccess,
  fetchNotificationsFail,
  seenNotificationsSuccess,
} from './notifications.actions';

export function* fetchNotificationsAsync({ payload }) {
  try {
    const { data } = yield axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URI}users/notifications/`,
      params: {
        page: payload,
      },
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    yield put(fetchNotificationsSuccess(data));
  } catch (error) {
    yield put(fetchNotificationsFail());
    console.log('ERROR:: ', error);
  }
}

export function* seenNotificationsAsync({ payload }) {
  console.log('payload', payload);
  try {
    const { data } = yield axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URI}users/notifications/`,
      data: qs.stringify({
        seen: true,
        ids: payload.join(),
      }),
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });

    if (data.success) {
      yield put(seenNotificationsSuccess(payload));
    }
  } catch (error) {
    console.log('ERROR:: ', error);
  }
}

export function* onFetchNotificationStart() {
  yield takeLatest(FETCH_NOTIFICATIONS_START, fetchNotificationsAsync);
}

export function* onSeenNotificationsStart() {
  yield takeLatest(SEEN_NOTIFICATIONS_START, seenNotificationsAsync);
}

export function* notificationsSagas() {
  yield all([call(onFetchNotificationStart), call(onSeenNotificationsStart)]);
}
