import { SET_ALERT_START, SET_ALERT, REMOVE_ALERT } from './alert.types';

export const setAlertStart = (data) => ({
  type: SET_ALERT_START,
  payload: data,
});

export const setAlert = (data) => ({
  type: SET_ALERT,
  payload: data,
});

export const removeAlert = (id) => ({
  type: REMOVE_ALERT,
  payload: id,
});
