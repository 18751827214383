import exchangeActionTypes from './exchange.types';

const INITIAL_STATE = {
  isExchangeToggled: false,
  exchangeValue: null,
};

const exchangeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case exchangeActionTypes.TOGGLE_EXCHANGE:
      return {
        ...state,
        isExchangeToggled: !state.isExchangeToggled,
      };
    case 'HANDLE_BACKDROP':
      return {
        ...state,
        isExchangeToggled: false,
      };
    default:
      return state;
  }
};

export default exchangeReducer;
