//@ts-check
import axios from 'axios';
import FilledButton from 'common/buttons/FilledButton';
import OutlineButton from 'common/buttons/OutlinedButton';
import TextField from 'common/fields/TextField';
import Surface from 'common/surfaces/Surface.component';
import { ErrorContainer, InlineError } from 'components/content/InlineError';
import { useLocale } from 'context/LanguageContext';
import { useFormik } from 'formik';
import useThemeVariables from 'hooks/useThemeVariables';
import qs from 'qs';
import { useUserCredentialsQuery } from 'queries/userCredentials';
import React, { useState } from 'react';
import { H } from 'react-accessible-headings';
import { object, ref, string } from 'yup';
import styles from './EmailChange.module.css';

function getValidationState(formik, field) {
  return !formik.touched[field]
    ? null
    : formik.errors[field]
    ? 'invalid'
    : 'valid';
}

function useFormError(errorMap, resetDeps = []) {
  const [errorKey, setErrorKey] = React.useState(null);

  // reset error message when one of the item in resetDeps changes.
  const prevResetDeps = React.useRef(resetDeps);
  for (let i = 0; i < resetDeps.length; i++) {
    if (resetDeps[i] !== prevResetDeps.current[i]) {
      // No prob to call setState during render in new React if no infinite loop is created.
      setErrorKey(null);
      prevResetDeps.current = resetDeps;
      break;
    }
  }

  return {
    serverError: errorMap[errorKey],
    setServerError: setErrorKey,
  };
}

function EmailChange() {
  const { LL } = useLocale();
  const [successfulChange, setSuccessfulChange] = useState(false);

  const { colorTest4 } = useThemeVariables();
  const { data: user } = useUserCredentialsQuery();

  const surfaceColor = {
    background: colorTest4,
  };

  async function sendVerificationEmail() {
    const data = user && { to: user.email };
    try {
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}users/email/send-verification`,
        data: qs.stringify(data),
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: localStorage.getItem('token'),
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  const formik = useFormik({
    initialValues: {
      currentEmail: '',
      newEmail: '',
      confirmEmail: '',
    },
    validationSchema: object({
      currentEmail: string().required('Required'),
      newEmail: string().required('Required'),
      confirmEmail: string()
        .oneOf([ref('newEmail'), null], "Emails don't match")
        .required('Required'),
    }),

    onSubmit: async (values, { resetForm, setTouched }) => {
      const data = {
        currentEmail: values.currentEmail,
        newEmail: values.newEmail,
      };
      try {
        const res = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URI}users/email/change`,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization: localStorage.getItem('token'),
          },
          data: qs.stringify(data),
        });
        setServerError(null);
        setSuccessfulChange(true);
        setTimeout(() => {
          setSuccessfulChange(false);
        }, 5000);
      } catch (error) {
        setTouched({});
        console.log(error.response.data.error);
        if (error.response && errorMap[error.response.data.error]) {
          console.log('Error: ', error.response.data.error);

          setServerError(error.response.data.error);
        } else {
          console.warn('Password change unexpected error: ', error);
          setServerError('ERR_GENERIC');
        }
      }
      resetForm();
    },
  });

  const handleBlur = (field) => () => {
    formik.setFieldTouched(field, true, false);
    formik.validateField(field);
  };

  const errorMap = {
    ERR_GENERIC: LL.profile.serverErrors.genericShort(),
    ERR_INVALID_CREDENTIALS: LL.profile.serverErrors.invalidCredentials(),
    ERR_INVALID_CURRENT_EMAIL: LL.profile.serverErrors.invalidCurrentEmail(),
    SAME_EMAIL_FORBIDDEN: LL.profile.serverErrors.sameEmail(),
    ERR_EMAIL_ALREADY_EXISTS: LL.profile.serverErrors.emailExists(),
    '"currentEmail" must be a valid email':
      LL.profile.serverErrors.invalidEmailformat(),
  };

  const { serverError, setServerError } = useFormError(errorMap, []);

  return (
    <Surface
      elementType="form"
      onSubmit={formik.handleSubmit}
      surfaceColor={surfaceColor}
      className={styles.container}
    >
      <>
        {user && !user.isEmailVerified ? (
          <div className={styles.formBody}>
            <H className={styles.heading}>Confirm Email</H>
            <div className={styles.text}>{LL.profile.email.verifyEmail()}</div>
            <FilledButton onPress={() => sendVerificationEmail()}>
              {LL.profile.email.sendVerification()}
            </FilledButton>
          </div>
        ) : (
          <>
            <div className={styles.formBody}>
              <H className={styles.heading}>{LL.profile.email.header()}</H>
              <ErrorContainer>
                {serverError ? (
                  <InlineError
                    onClose={() => setServerError(null)}
                    success={false}
                  >
                    {serverError}
                  </InlineError>
                ) : (
                  successfulChange && (
                    <InlineError
                      onClose={() => setSuccessfulChange(false)}
                      success={true}
                    >
                      {LL.profile.email.success()}
                    </InlineError>
                  )
                )}
              </ErrorContainer>
              <H className={styles.subHeading}>
                {LL.profile.email.currentEmail()}
              </H>
              <TextField
                placeholder={LL.profile.email.currentEmailPlaceholder()}
                value={formik.values.currentEmail}
                onChange={(val) =>
                  formik.setFieldValue('currentEmail', val, false)
                }
                onBlur={handleBlur('currentEmail')}
                validationState={getValidationState(formik, 'currentEmail')}
                errorMessage={formik.errors.currentEmail}
              />
              <H className={styles.subHeading}>{LL.profile.email.newEmail()}</H>
              <TextField
                placeholder={LL.profile.email.newEmailPlaceholder()}
                value={formik.values.newEmail}
                onChange={(val) => formik.setFieldValue('newEmail', val, false)}
                onBlur={handleBlur('newEmail')}
                validationState={getValidationState(formik, 'newEmail')}
                errorMessage={formik.errors.newEmail}
              />
              <H className={styles.subHeading}>
                {LL.profile.email.confirmEmail()}
              </H>
              <TextField
                placeholder={LL.profile.email.confirmEmailPlaceholder()}
                value={formik.values.confirmEmail}
                onChange={(val) =>
                  formik.setFieldValue('confirmEmail', val, false)
                }
                onBlur={handleBlur('confirmEmail')}
                validationState={getValidationState(formik, 'confirmEmail')}
                errorMessage={formik.errors.confirmEmail}
              />
            </div>
            <div className={styles.formSubmit}>
              <OutlineButton
                attachesBackground
                pressesBackground
                type="reset"
                onPress={formik.handleReset}
              >
                {LL.profile.resetFields()}
              </OutlineButton>
              <FilledButton
                attachesBackground
                pressesBackground
                type="submit"
                isDisabled={
                  (!formik.values.currentEmail &&
                    !formik.values.newEmail &&
                    !formik.values.confirmEmail) ||
                  formik.isSubmitting
                }
              >
                {LL.profile.confirmChange()}
              </FilledButton>
            </div>{' '}
          </>
        )}
      </>
    </Surface>
  );
}

export default EmailChange;
