import bch from './BCH.svg';
import bnb from './BNB.svg';
import btc from './BTC.svg';
import btt from './BTT.svg';
import busd from './BUSD.svg';
import doge from './DOGE.png';
import enj from './ENJ.svg';
import eth from './ETH.svg';
import eur from './EURO.png';
import gbp from './GBP.svg';
import gel from './GEL.png';
import link from './LINK.svg';
import ltc from './LTC.svg';
import reef from './REEF.svg';
import rub from './RUB.png';
import shib from './SHIB.png';
import ton from './TON.svg';
import trx from './TRX.svg';
import uah from './UAH.svg';
import uni from './UNI.svg';
import usd from './USD.png';
import usdc from './USDc.svg';
import usdt from './USDt.svg';
import xlm from './XLM.svg';
import xrp from './XRP.svg';

/**
 * @example
 * <img src={images.btc} alt="" />
 */
const icons = {
  btc,
  ltc,
  eth,
  xlm,
  xrp,
  bch,
  busd,
  bnb,
  enj,
  reef,
  usdc,
  usdt,
  uni,
  doge,
  shib,
  trx,
  usd,
  eur,
  gbp,
  uah,
  rub,
  gel,
  link,
  btt,
  ton,
};

export default icons;
