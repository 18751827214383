import {
  CHECK_2FA_TOKEN_FAIL,
  CHECK_PHONE_VERIFICATION,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOG_OUT,
  SHOW_2FA_FORM,
} from './auth.types';

const INITIAL_STATE = {
  isAuthenticated: false,
  show2faForm: false,
  show2faError: false,
  showPhoneVerification: false,
  token: null,
  authError: { error: false, data: '' },
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload);
      // socket.auth = { token: action.payload };
      // socket.connect();
      return {
        ...state,
        isAuthenticated: true,
        authError: { error: false },
        token: null,
        show2faError: false,
      };
    case LOGIN_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        isAuthenticated: false,
        authError: { error: action.payload.error, data: action.payload.data },
      };
    case LOG_OUT:
      localStorage.removeItem('token');
      document.cookie = `creds= ; Expires= Thu, 01 Jan 1970 00:00:00 GMT; Domain=paybitx.com`;
      window.location.href = '/';

      return {
        ...state,
        isAuthenticated: false,
        failed: null,
        show2faForm: false,
      };
    case SHOW_2FA_FORM:
      return {
        ...state,
        show2faForm: true,
        data: action.payload,
      };
    case CHECK_2FA_TOKEN_FAIL:
      return {
        ...state,
        show2faError: true,
      };
    case CHECK_PHONE_VERIFICATION:
      return {
        ...state,
        isAuthenticated: false,
        showPhoneVerification: true,
      };
    default:
      return state;
  }
};

export default authReducer;
