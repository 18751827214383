// @ts-check
import TranslucentSurface from 'common/surfaces/TranslucentSurface';
import BalanceInfo from 'components/content/BalanceInfo.component';
import Wallet from 'components/content/Wallet.component';
import BufferingWheel from 'components/loader/LoadingAnimation.component';
import { useLocale } from 'context/LanguageContext';
import useThemeVariables from 'hooks/useThemeVariables';
import { useBalancesQuery } from 'queries/balances';
import { useRatesQuery } from 'queries/rates';
import * as React from 'react';
import { H, Level } from 'react-accessible-headings';
import styles from './MyWallets.module.css';

export default function MyWallets() {
  const { LL } = useLocale();
  const {
    data: balances,
    isError: balanceError,
    isLoading: balanceLoading,
  } = useBalancesQuery(true);
  const {
    data: rate,
    isError: ratesError,
    isLoading: ratesLoading,
  } = useRatesQuery();

  const { colorSuccessContainer, colorOnSuccessContainer } =
    useThemeVariables();

  const surfaceColor = {
    background: colorSuccessContainer,
    color: colorOnSuccessContainer,
  };

  if (balanceError || ratesError) {
    return (
      <div style={{ color: 'var(--md-ref-palette-error-50)' }}>
        {LL.markets.accessingError({ pageHeader: LL.general.wallets() })}
      </div>
    );
  }

  if (balanceLoading || ratesLoading) {
    return <BufferingWheel color="var(--color-logo)" />;
  }

  return (
    <div className={styles.container}>
      <Level>
        <hgroup>
          {/* <H className={styles.pageHeadline}>{LL.navbar.myWallets()}</H>
          <BalanceInfo /> */}
          <TranslucentSurface
            className={styles.roundedContainer}
            surfaceColor={surfaceColor}
          >
            <H className={styles.pageHeadline}>{LL.navbar.myWallets()}</H>
            <p>{<BalanceInfo />}</p>
          </TranslucentSurface>
        </hgroup>
        <div className={styles.wallets}>
          {balances
            .sort(
              (a, b) =>
                b.confirmed_balance * Number(rate[b.coin].usd) -
                a.confirmed_balance * Number(rate[a.coin].usd)
            )
            .map((wallet) => (
              <Wallet
                key={wallet.id}
                size="lg"
                currency={wallet.coin}
                depositedAmount={wallet.confirmed_balance}
                unconfirmedDeposit={wallet.unconfirmed_deposit}
                unconfirmedWithdraw={wallet.unconfirmed_withdrawal}
              />
            ))}
        </div>
      </Level>
    </div>
  );
}
