import type { FormattersInitializer } from 'typesafe-i18n';
import type { Locales, Formatters } from './i18n-types';
import { uppercase } from 'typesafe-i18n/formatters';
import type { Currency } from 'currencies';

type SwapCurrency = `${Currency} -> ${Currency}`;

export const initFormatters: FormattersInitializer<Locales, Formatters> = (
  locale: Locales
) => {
  const formatters = {
    uppercase: uppercase,

    swapFrom: (swapCurrency: SwapCurrency) => {
      if (typeof swapCurrency !== 'string') return swapCurrency;

      return swapCurrency.split(' -> ')[0];
    },

    swapTo: (swapCurrency: SwapCurrency) => {
      if (typeof swapCurrency !== 'string') return swapCurrency;

      return swapCurrency.split(' -> ')[1];
    },

    commaSeparated: (value: any) => {
      // add ", ${value}" if value is not nullish
      if (!value && value !== 0) return '';
      return `, ${value}`;
    },
  };

  return formatters;
};
