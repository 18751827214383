import React from 'react';
import styles from './MobilePageOptions.module.css';

import gb from '../../assets/flags/gb.png';
import geo from '../../assets/flags/geo.png';

import { useLocale } from 'context/LanguageContext';
import { locales } from 'i18n/i18n-util';
import { TbLanguage } from 'react-icons/tb';

/** @type {{[Locale in import('i18n/i18n-types').Locales]: string}} */
const flags = {
  en: gb,
  ka: geo,
};

const PageOptions = () => {
  const { locale, setLocale } = useLocale();

  // const [currencyPayload, setCurrencyPayload] = useState('usd');

  // useEffect(() => {
  //   if (chosenCurrency === 'eur') {
  //     setCurrencyPayload('usd');
  //   } else {
  //     setCurrencyPayload('eur');
  //   }
  // }, [chosenCurrency]);

  // const handleChoseOption = () => {
  //   changeLanguage(optionLanguage);
  //   setChosenLanguage(optionLanguage);
  //   setOptionLanguage(chosenLanguage);
  // };

  /*eslint-disable */
  // useEffect(() => {
  //   if (mainLanguage !== chosenLanguage) {
  //     setChosenLanguage(mainLanguage);
  //     setOptionLanguage(chosenLanguage);
  //   }
  // }, [mainLanguage]);
  /*eslint-enable */

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <TbLanguage />

        {locales.map((newLocale) => (
          <div
            className={styles.languageSelector}
            onClick={() => setLocale(newLocale)}
            style={locale === newLocale ? { fontWeight: '1000' } : {}}
          >
            <span>{newLocale.toLocaleUpperCase()} </span>
            <span className={styles.options}>
              <img
                src={flags[newLocale]}
                alt=""
              />
            </span>
          </div>
        ))}
      </div>

      {/* <div
        className={styles.title}
        onClick={() => changeCurrency(currencyPayload)}
      >
        {chosenCurrency === 'usd' ? (
          <RiMoneyDollarCircleLine />
        ) : (
          <RiMoneyPoundCircleLine />
        )}
        <span>{chosenCurrency.toUpperCase()}</span>
      </div> */}
    </div>
  );
};

export default PageOptions;
