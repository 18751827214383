// @ts-check
import classNames from 'classnames';
import * as React from 'react';
import useSurfaceColor from 'hooks/useSurfaceColor';
import styles from './TranslucentSurface.module.css';

/**
 * WIP: partially transparent surface, designed to have lower
 * visual emphesis. cannot/should not be elevated
 *
 * @typedef {object} LocalProps
 * @property {import('hooks/useSurfaceColor').SurfaceColor} [surfaceColor]
 * @property {string} [elementType]
 *
 * @param {LocalProps & React.HTMLAttributes} props
 */
function TranslucentSurfaceWithRef(
  {
    elementType = 'div',
    surfaceColor = 'surface',
    className,
    style,
    children,
    ...props
  },
  ref
) {
  return React.createElement(
    elementType,
    {
      ...props,
      className: classNames(className, styles.surface),
      style: { ...useSurfaceColor(surfaceColor), ...style },
      ref,
    },
    children
  );
}

const TranslucentSurface = React.forwardRef(TranslucentSurfaceWithRef);
export default TranslucentSurface;
