import React from 'react';
import { Outlet } from "react-router-dom";
import PrivateRoute from '../components/private-route/private-route.component';

function PrivateLayout() {
    return (
        <PrivateRoute>
            <Outlet />
        </PrivateRoute>
    )
}

export default PrivateLayout